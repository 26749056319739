import { Form as FormFormik, Formik } from 'formik'
import { InputFormik } from 'components/formik/formikFormComponents'
import { roleValidators } from 'utils/validators/validators'

/** @module Pages/Role/Components/FormRole */

/**
 * Componente, Formulario de registro/edición de Rol.
 * @param {object} props Propiedades del componente.
 * @param {React.MutableRefObject<any>} props.formRef Referencia del formulario.
 * @param {Function} props.onSubmit Callback a ejecutar cuando se de submit del formulario.
 * @param {object} props.selectedRole Rol seleccionado.
 * @returns {JSX.Element} Retorna el componente FormRole.
 */
function FormRole({formRef, onSubmit, selectedRole}) {
    const initialValues = selectedRole ? {
        rol: selectedRole.name,
        descripcion: selectedRole.description,
    } : { 
        rol: '',
        descripcion: '', 
    }

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={roleValidators}
            onSubmit={onSubmit}
        >
            {() => (
                <FormFormik>
                    <div>
                        <InputFormik name='rol' label='Código' />
                        <InputFormik name='descripcion' label='Nombre' />
                    </div>
                </FormFormik>
            )}
        </Formik>
    )
}

export default FormRole