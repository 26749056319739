export const adminLinks = [
    { path: '', label: 'Inicio' },
    { path: 'usuario', label: 'Usuarios', permissions: 'usuarioView' },
    { path: 'rol', label: 'Roles', permissions: 'rolView' },
    { path: 'beneficiario', label: 'Beneficiarios' },
    { path: 'cargo-invitado', label: 'Cargo-Invitado', permissions: 'cargoInvitadoView' },
    { 
        label: 'Escalas de Viáticos', 
        permissions: ['categoriaView', 'escalaViaticosView', 'reglamentoView'],
        items: [
            { path: 'categoria', label: 'Categorías', permissions: 'categoriaView' },
            { path: 'escala-viaticos', label: 'Escalas de Viáticos', permissions: 'escalaViaticosView' },
            { divider: true },
            { path: 'reglamento', label: 'Reglamentos', permissions: 'reglamentoView' },
        ] 
    },
    { 
        label: 'Estructura',
        permissions: ['daView', 'ueView', 'unidadView', 'cuentaView'],
        items: [
            { path: 'direccion-administrativa', label: 'Dirección Administrativa', permissions: 'daView' },
            { path: 'unidad-ejecutora', label: 'Unidad Ejecutora', permissions: 'ueView' },
            { path: 'unidad', label: 'Unidades', permissions: 'unidadView' },
            { path: 'estructura-programatica', label: 'Estructura Programática' },
            { divider: true },
            { path: 'cuenta', label: 'Cuentas', permissions: 'cuentaView' },
            { path: 'libreta-cuentas', label: 'Libreta de Cuentas' }
        ], 
    },
    { path: 'variables', label: 'Variables' },
]

export const autoridadLinks = [
    { path: '', label: 'Inicio' },
    { path: 'solicitud', label: 'Solicitudes', permissions: 'solicitudView' }
]

export const beneficiarioLinks = [
    { path: '', label: 'Inicio' },
    { path: 'solicitud', label: 'Solicitudes', permissions: ['solicitudView'] },
    { path: 'solicitud/create', label: 'Nuevo', permissions: ['solicitudCreate'] }
]

export const cajaLinks = [
    { path: '', label: 'Inicio' },
    { path: 'viaticos', label: 'Viáticos', permissions: 'papeletaView', end: false },
    { path: 'reembolso', label: 'Reembolsos', permissions: 'reembolsoView', end: false },
    { 
        label: 'Listados', 
        permissions: ['reembolsoReport'],
        items: [
            { path: 'listados/kardex', label: 'Kardex', permissions: 'reembolsoReport' },
            { path: 'listados/emitidos', label: 'Emitidos', permissions: 'reembolsoReport' },
            { path: 'listados', label: 'Buscar', permissions: 'reembolsoReport' },
        ]
    },
    { path: 'unidad', label: 'Unidades', permissions: 'unidadReembolsosView' },
    {
        label: 'Plan-Cuentas', items: [
            { path: 'estructura-programatica', label: 'Estructura Programática' },
            { path: 'libreta-cuentas', label: 'Libreta de Cuentas' }
        ]
    }
]

export const dafLinks = [
    { path: '', label: 'Inicio' },
    { path: 'solicitud', label: 'Solicitudes', permissions: 'solicitudView' },
    { path: 'memorandum', label: 'Memos', permissions: 'memoView', end: false },
    { path: 'template-memorandum', label: 'Plantillas', permissions: 'plantillaMemoView' },
]

export const presupuestosLinks = [
    { path: '', label: 'Inicio' },
    { path: 'solicitud', label: 'Solicitudes', permissions: 'solicitudView' },
    { path: 'estructura-programatica', label: 'Estructura Programática' }
]

export const tesoreriaLinks = [
    { path: '', label: 'Inicio' },
    { path: 'memorandum', label: 'Memos', permissions: 'memoView' },
    { path: 'viaticos', label: 'Viáticos', permissions: 'papeletaView', end: false },
    { path: 'reporte/viaticos', label: 'Reporte', permissions: 'papeletaReport' },
    { path: 'unidad', label: 'Unidades', permissions: 'unidadView' },
    { path: 'cuenta', label: 'Cuentas', permissions: 'cuentaView' },
    {
        label: 'Plan-Cuentas', items: [
            { path: 'estructura-programatica', label: 'Estructura Programática' },
            { path: 'libreta-cuentas', label: 'Libreta de Cuentas' }
        ]
    }
]