import { createSlice } from '@reduxjs/toolkit'
import { setHttpMessage } from './messageSlice'
import { ITEMTOKEN } from 'data/const'
import AuthService from 'services/auth.service'

const getToken = () => {
	return localStorage.getItem(ITEMTOKEN)
}
const token = getToken()

const initialState = {
	data: token,
	isLogged: token ? true : false,
	userInfo: {},
	permissions: {},
	roles: [],
	secciones: {},
	init: false,
}

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setUser: (state, {payload}) => {
			state.data = payload.data
			state.isLogged = true
		},
        removeUser: (state) => {
            state.data = null
            state.isLogged = false
			state.userInfo = initialState.userInfo
			state.permissions = initialState.permissions
			state.roles = initialState.roles
			state.init = initialState.init
			state.secciones = initialState.secciones
        },
		setUserAuth: (state, {payload}) => {
			state.userInfo = payload.userInfo
			state.roles = payload.roles
			state.permissions = payload.permissions
			state.secciones = payload.secciones
			state.init = true
		}
	},
})

export const login = (credentials) => async (dispatch) => {
	return AuthService.login(credentials)
		.then(res => {
			dispatch(setUser({ data: res }))
			localStorage.setItem(ITEMTOKEN, JSON.stringify({accessToken: res.accessToken}))
			return true
		})
		.catch(err => {
			if (err.status === 400) {
				return Promise.reject(err.message)
			} else {
				dispatch(setHttpMessage({status: err.status}))
				return false
			}
		})
}

export const loginGoogle = (token) => async (dispatch) => {
	const response = await AuthService.loginGoogle(token)
	if (response.status === 200) {
		dispatch(setUser({ data: response.data }))
		localStorage.setItem(ITEMTOKEN, JSON.stringify({accessToken: response.data.accessToken}))
	} else {
		dispatch(setHttpMessage({status: response.status, title: response.data.message}))
	}
}

export const logout = () => (dispatch) => {
    localStorage.removeItem(ITEMTOKEN)
    dispatch(removeUser())
}

export const getUserAuth = () => async (dispatch) => {
	const response = await AuthService.getUserAuth()
	if(response.status === 200) {
		const {userInfo} = response.data
		const {secciones} = userInfo
		const roles = response.data.userRoles
		const permissions = {}
		response.data.userPermissions.forEach(permission => {
			permissions[permission] = true
		})
		dispatch(setUserAuth({ userInfo, roles, permissions, secciones }))
	} else if (response.status === 401) {
		dispatch(logout())
		dispatch(setHttpMessage({
            status: 401, 
            title: 'Su sesión a expirado', 
            detail: 'El tiempo de su sesión ha terminado o se ha iniciado sesión con su cuenta desde otro navegador.',
            sticky: true
        }))
	} else {
		dispatch(setHttpMessage({status: response.status}))
	}
}

export const { setUser, removeUser, setUserAuth } = authSlice.actions
export default authSlice.reducer
