import { formatDate } from 'utils/utils'
import { draftJsRawToHTML } from 'utils/string.util'

/** @module Pages/Memo/Components/ViewMemo */

/**
 * Componente de Información de un memorandum.
 * @param {object} props Propiedades del componente.
 * @param {object} props.memo Memorándum seleccionado.
 * @returns {JSX.Element} Retorna el componente ViewMemo.
 */
function ViewMemo({memo}) {
    const { numero_memo, fecha_creacion, descripcion_json, fecha_anulacion, info_adicional } = memo
    
    return (
        <div>
            {fecha_anulacion && (
                <div className='mb-3 ps-3 text-danger'>Anulado: {formatDate(fecha_anulacion, 'd/m/Y H:i:s')}</div>
            )}
            <div className='d-flex p-3 border rounded'>
                <div>Cochabamba, {formatDate(fecha_creacion, 'd/m/Y')}</div>
                <div className='ms-auto'>{numero_memo}</div>
            </div>
            <div className='p-3 border rounded'>
                {draftJsRawToHTML(descripcion_json)}
            </div>
            {info_adicional && <div className='p-3 border rounded'>{info_adicional}</div>}
        </div>
    )
}

export default ViewMemo