import { classNames } from './ClassNames'
import { toPascalCase } from './ToPascalCase'
import { numberName } from './NumberName'
import { buildFilters } from './BuildFilters'
import { formatDate, parseDate } from './FormatDate'
import { fileToBase64 } from './FileToBase64'
import { isObject } from './IsObject'
import { formatNumber, formatCurrency } from './FormatNumber'
import { getQueryParams } from './GetQueryParams'

export function generateCode() {
    return Math.round(Math.random() * (16000000 - 1100000) + 1100000).toString(16);
}

export const propFromName = (obj, str) => {
    const props = str.split('.');
    return props.reduce((carry, prop)=>{
        return carry === undefined ? undefined : carry[prop];
    }, obj);
}

export {
    classNames,
    toPascalCase,
    numberName,
    buildFilters,
    formatDate,
    parseDate,
    fileToBase64,
    isObject,
    formatNumber,
    formatCurrency,
    getQueryParams
}