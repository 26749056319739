import Button from 'components/Button'
import InputFormik from 'components/formik/InputFormik'
import { Form, Formik } from 'formik'
import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import ApiService from 'services/api.service'
import { setHttpMessage } from 'store/messageSlice'
import { changePasswordValidators } from 'utils/validators/validators'

/** @module Pages/Usuario/Components/FormChangePassword */

// Valores iniciales del formulario.
const initialValues = {currentPassword: '', newPassword: '', repeatPassword: ''}

/**
 * Componente, Formulario de cambio de contraseña de usuario.
 * @returns {JSX.Element} Retorna el componente FormChangePassword.
 */
function FormChangePassword() {
    const dispatch = useDispatch()
    const formRef = useRef(null)
    const [submitting, setSubmitting] = useState(false)

    const changePassword = async (values) => {
        setSubmitting(true)
        const response = await ApiService.usuario.changePassword(values)
        if (response.status === 200) {
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            formRef?.current?.resetForm({values: initialValues})
        } else if (response.status === 400) {
            formRef?.current?.setFieldError('currentPassword', response.data.message)
        } else {
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
        }
        setSubmitting(false)
    }

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={changePassword}
            validationSchema={changePasswordValidators}
        >{() => (
            <Form>
                <InputFormik type='password' name='currentPassword' label='Contraseña actual'/>
                <InputFormik type='password' name='newPassword' label='Contraseña nueva'/>
                <InputFormik type='password' name='repeatPassword' label='Repetir contraseña'/>
                <div className='text-end'>
                    <Button type='submit' startIcon='pi pi-save' loading={submitting}>Guardar</Button>
                </div>
            </Form>
        )}</Formik>
    )
}
export default FormChangePassword