import { classNames } from 'utils/utils'

/** @module Components/InputCheckbox */

/**
 * Componente InputCheckbox
 * @param {object} props Propiedades del componente
 * @param {string | undefined} props.name Propiedad name del input.
 * @param {string | undefined} props.id ID del checkbox.
 * @param {boolean | null | undefined} props.value Valor del estado del checkbox.
 * @param {Function | undefined} props.onChange Función que se ejecuta cada vez que cambia el valor del checkbox.
 * @param {string | undefined} props.className Clase de estilos del componente.
 * @param {React.CSSProperties | undefined} props.style Estilos en linea del componente.
 * @param {boolean | undefined} props.triState Especifica si el checkbox tendrá tres estados o solo dos.
 * @param {boolean | undefined} props.disabled Especifica si el checkbox esta deshabilitado o no.
 * @returns {JSX.Element} Retorna el componente InputCheckbox.
 */
function InputCheckbox({ 
    name, 
    id, 
    value=null, 
    onChange, 
    onBlur,
    className, 
    style, 
    triState,
    disabled
}) {
    const state = triState
        ? (value === true ? 'bi-check' : ( value === false ? 'bi-x' : '' ))
        : (value ? 'bi-check' : '')

    const handleChange = (e) => {
        e.preventDefault()
        let _value = null
        if (triState) {
            // value: null -> true -> false -> null
            _value = value === true ? false : ( value === false ? null : true )
        } else {
            _value = !value
        }
        onChange&&onChange(getEvent(_value))
    }
    const handleBlur = () => onBlur&&onBlur(getEvent(value))
    const handleKeyUp = (e) => {
        if (e.key==='Enter') handleChange(e)
    } 
    const getEvent = (_value) => {
        return { target: { name: name||'', id: id||'', value: _value }, value: _value }
    }

    return (
        <div 
            className={classNames(['input-checkbox', className, disabled&&'disabled'])}
            onClick={handleChange}
            onBlur={handleBlur}
            onKeyUp={handleKeyUp}
            style={style}
            tabIndex={0}
        >
            <span className={classNames(['input-checkbox-icon', state])}></span>
        </div>
    )
}
export default InputCheckbox
