import { Card } from 'react-bootstrap'
import FormPapeleta from './components/FormPapeleta'
import TitlePage from 'components/TitlePage'

/** @module Pages/Papeleta/PapeletaCreate */

/**
 * Página, Registro de papeleta.
 * @returns {JSX.Element} Retorna el componente PapeletaCreate.
 */
function PapeletaCreate() {
    return <>
        <TitlePage title='Registro de Papeleta de Viáticos' />
        <div className='content align-items-center' style={{backgroundColor: '#efefef'}}>
            <Card className='w-xxl-75 p-4'>
                <FormPapeleta />
            </Card>
        </div>
    </>
}
export default PapeletaCreate