import Button from 'components/Button'
import EditableSelect from 'components/EditableSelect'
import InputFormik from 'components/formik/InputFormik'
import InputLayout from 'components/layouts/InputLayout'
import { ErrorMessage, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import ApiService from 'services/api.service'
import { ReembolsoService } from 'services/reembolso.service'
import { unidadReembolsosValidator } from 'utils/validators/validators'

/** @module Pages/Reembolso/Components/FormUnidadReembolso */

// Valores iniciales del formulario
const initialValues = {descripcion: '', destinatario: '', cargo_destiantario: '', enlaces: ''}

/**
 * Componente, Formulario de registro de unidad de reembolso
 * @param {object} props Propiedades del componente.
 * @param {React.MutableRefObject<any>} props.formRef Referencia del formulario.
 * @param {Function} props.onSubmit Callback a ejecutar cuando se de submit del formulario.
 * @param {object} props.selected Unidad de reembolso seleccionado.
 * @returns {JSX.Element} Retorna el componente FormUnidadReembolso.
 */
function FormUnidadReembolso({formRef, onSubmit, selected}) {
    const [das, setDas] = useState([])
    const [cuentas, setCuentas] = useState([])
    const [selectedDas, setSelectedDas] = useState([])
    const [selectedCuentas, setSelectedCuentas] = useState([])
    const [values, setValues] = useState({cuenta: '', da: ''})

    useEffect(() => {
        const loadData = async () => {
            const response = await ReembolsoService.unidad.view(selected.id)
            if (response.status === 200) {
                const {descripcion, destinatario, cargo_destinatario, das, cuentas} = response.data.data
                formRef?.current?.resetForm({
                    values: {
                        ...initialValues,
                        descripcion, 
                        destinatario: destinatario||'', 
                        cargo_destinatario: cargo_destinatario||''
                    }
                })
                setSelectedDas(das)
                setSelectedCuentas(cuentas)
            }
        }
        if (selected?.id) loadData()
    }, [selected])

    const validate = () => {
        const errors = {}
        if (selectedDas.length === 0 && selectedCuentas.length === 0) {
            errors.enlaces = 'Debe añadir por lo menos una DA o Cuenta'
        }
        return errors
    }
    const handleChange = (e) => {
        const value = e.target.value
        const name = e.target.name
        if (typeof value === 'object') {
            if (name === 'da') {
                if (!selectedDas.find(d => d.codigo===value.codigo)) {
                    setSelectedDas([...selectedDas, value])
                }
            } else if (name === 'cuenta') {
                if (!selectedCuentas.find(c => c.codigo_cuenta===value.codigo_cuenta)) {
                    setSelectedCuentas([...selectedCuentas, value])
                }
            }
            setValues({...values, [name]: ''}) 
        } else {
            setValues({...values, [name]: value}) 
        }
    }
    const buildCardCuenta = (cuenta, index) => (
        <div className='d-flex align-items-stretch mb-2 group' key={index}>
            <div className='flex-1 p-2' style={{border: '1px solid #102644'}}>
                {cuenta.codigo_cuenta} - {cuenta.descripcion}
            </div>
            <Button startIcon='pi pi-times' onClick={() => setSelectedCuentas(selectedCuentas.filter((c, i) => index!==i))}/>
        </div>
    )
    const buildCardDa = (da, index) => (
        <div className='d-flex align-items-stretch mb-2 group' key={index}>
            <div className='flex-1 p-2' style={{border: '1px solid #102644'}}>
                {da.codigo} - {da.descripcion}
            </div>
            <Button startIcon='pi pi-times' onClick={() => setSelectedDas(selectedDas.filter((c, i) => index!==i))}/>
        </div>
    )
    const handleSubmit = (values) => {
        const {descripcion, destinatario, cargo_destinatario} = values
        const _values = {
            descripcion, destinatario, cargo_destinatario,
            das: selectedDas.map(d => d.id).join(','),
            cuentas: selectedCuentas.map(c => c.id).join(','),
        }
        onSubmit(_values)
    }
    const searchDas = (da) => {
        const loadData = async () => {
            const response = await ApiService.dirAdtvas.search(da)
            if (response.status === 200) setDas(response.data)
        }
        if (da) loadData()
        else setDas([])
    }
    const searchCuentas = (cuenta) => {
        const loadData = async () => {
            const response = await ApiService.cuenta.search(cuenta)
            if (response.status === 200) setCuentas(response.data)
        }
        if (cuenta) loadData()
        else setCuentas([])
    }
    
    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={unidadReembolsosValidator}
            validate={validate}
        >
            {() => (
                <Form>
                    <InputFormik name='descripcion' label='Nombre' />
                    <InputFormik name='destinatario' />
                    <InputFormik name='cargo_destinatario' label='Cargo Destinatario' />
                    <div className='d-flex gap-3'>
                        <div className='w-50'>
                            <InputLayout label="DA's">
                                <EditableSelect 
                                    name='da'
                                    value={values.da}
                                    options={das}
                                    optionValue='codigo'
                                    onChange={handleChange}
                                    lazy valueAsOption
                                    autoSelect={false}
                                    onSearch={searchDas}
                                    itemTemplate={(item) => <>{item.codigo + ' / ' + item.descripcion}</>}
                                />
                            </InputLayout>
                            <div>
                                {selectedDas.map(buildCardDa)}
                            </div>
                        </div>
                        <div className='w-50'>
                            <InputLayout label='Cuentas'>
                                <EditableSelect 
                                    name='cuenta'
                                    value={values.cuenta}
                                    options={cuentas}
                                    optionValue='codigo_cuenta'
                                    onChange={handleChange}
                                    lazy valueAsOption
                                    onSearch={searchCuentas}
                                    itemTemplate={(item) => <>{item.codigo_cuenta + ' / ' + item.descripcion}</>}
                                />
                            </InputLayout>
                            <div>
                                {selectedCuentas.map(buildCardCuenta)}
                            </div>
                        </div>
                    </div>
                    <ErrorMessage name='enlaces'>{msg => <div className='text-center invalid-feedback d-block'>{msg}</div>}</ErrorMessage>
                </Form>
            )}
        </Formik>
    )
}
export default FormUnidadReembolso