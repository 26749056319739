import { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { classNames } from "utils/ClassNames";


/**
 * @typedef {object} SidebarItem 
 * @property {string} id ID del item.
 * @property {string} label Label del item.
 * @property {string | React.ReactNode} icon Contenido del icono. En caso de string lo toma como el class del icono.
 * @property {Array<SidebarItem>} items Lista de subitems.
 * @property {string} url URL del item.
 * @property {boolean} disabled Especifica si el item esta deshabilitado o no.
 * @property {React.CSSProperties} style Estilos en linea del item.
 * @property {string} className Clase de estilos del item.
 * @property {React.ReactNode | Function} itemTemplate Plantilla del item. Parámetros de la función {active, className, element, expanded, iconClassName, labelClassName, onClick, submenuIconClassName}.
 */

/**
 * @param {object} props Propiedades del componente.
 * @param {Array<SidebarItem>} props.items Items del sidebar.
 * @param {boolean} props.show Especifica si el sidebar esta visible o no.
 * @param {Function} props.closeSidebar Callback que se ejecuta para cerrar el Sidebar.
 * @param {React.ReactNode} props.header Header del sidebar.
 * @param {React.ReactNode} props.footer Footer del sidebar.
 * @param {string} props.theme Tema del sidebar.
 * @returns {JSX.Element} Retorna el componente Sidebar.
 */
export default function Sidebar({show, closeSidebar, items=[], header, footer, theme}) {
    const [showFadePanel, setShowFadePanel] = useState(false)
    const [activeItem, setActiveItem] = useState("")

    useEffect(() => {
        activeItem && closeSidebar && closeSidebar()
    }, [activeItem])

    const handleCloseSidebar = () => closeSidebar && closeSidebar()

    return (
        <div 
            className={classNames([
                "fade-panel", 
                (!showFadePanel&&"hidden"),
                (theme&&`sidebar-${theme}`)
            ])} 
            onClick={handleCloseSidebar}
        >
            <Collapse 
                in={show} 
                dimension="width"
                onEnter={() => setShowFadePanel(true)}
                onExited={() => setShowFadePanel(false)}
            >
                <div className="sidebar" onClick={(e) => e.stopPropagation()}>
                    <div className="sidebar-menu">
                        {header&&<div className="sidebar-header">
                            {header}
                        </div>}
                        <div className="sidebar-body">
                            {items.map((item, index) => (
                                <SidebarItem 
                                    id={index.toString()} 
                                    {...item} 
                                    activeItem={activeItem}
                                    setActiveItem={setActiveItem}
                                    key={index}
                                />
                            ))}
                        </div>
                        {footer&&<div className="sidebar-footer">
                            {footer}
                        </div>}
                    </div>
                </div>
            </Collapse>
        </div>
    )
}


/**
 * Componente SidebarItem.
 * @param {object} props Propiedades del componente.
 * @param {number} props.submenuLevel Nivel de submenu.
 * @param {string} props.activeItem ID del item activo.
 * @param {Function} props.setActiveItem Callback que actualiza el valor de activeItem.
 * @returns {JSX.Element} Retorna el componente Sidebar.
 */
function SidebarItem({submenuLevel = 1, activeItem, setActiveItem, ...props}) {
    const {icon, label, items, url, itemTemplate, className, style, id} = props
    const active = !items&&activeItem===id
    const [showItems, setShowItems] = useState(false)

    const itemIcon = (icon) => {
        const iconNode = typeof icon === 'string' ? <span className={icon}></span> : icon
        return <span className="sidebar-item-icon">{iconNode}</span>
    }

    const toggleShowItems = () => setShowItems(!showItems)
    const handleOnClick = () => {
        if (items) {
            toggleShowItems()
        } else {
            setActiveItem(id)
        }
    }
    const itemElement = (
        <a 
            href={`${url&&!items?url:"#"}`} 
            className={classNames(["sidebar-item", (active&&"active")])} 
            onClick={handleOnClick}
        >
            {icon?itemIcon(icon):null}
            <span className="sidebar-item-label">{label}</span>
            {items
                ? (
                    <span className="sidebar-item-icon-submenu">
                        <span className={showItems?"bi-chevron-up":"bi-chevron-down"}></span>
                    </span>
                ) : null
            }
        </a>
    )

    return (
        <div 
            className={classNames(["sidebar-item-container", className])}
            style={style}
        >
            {itemTemplate 
                ? (typeof itemTemplate === "function" 
                    ? itemTemplate(
                        props, 
                        {
                            active,
                            className: "sidebar-item", 
                            element: itemElement, 
                            expanded: showItems,
                            iconClassName: "sidebar-item-icon",
                            labelClassName: "sidebar-item-label",
                            onClick: handleOnClick,
                            submenuIconClassName: "sidebar-item-icon-submenu"
                        }
                    ) 
                    : itemTemplate
                ) 
                : itemElement
            }
            {!!items&&(
                <Collapse in={showItems}>
                    <div className={`sidebar-item-menu-${submenuLevel}`}>
                        {items.map((item, i) => (
                            <SidebarItem 
                                id={`${id}.${i}`} 
                                {...item} 
                                submenuLevel={submenuLevel<6?submenuLevel+1:submenuLevel} 
                                activeItem={activeItem}
                                setActiveItem={setActiveItem}
                                key={i} 
                            />
                        ))}
                    </div>
                </Collapse>
            )}
        </div>
    )
}