import { Form as FormFormik, Formik } from 'formik'

import { InputFormik } from 'components/formik/formikFormComponents'
import { userCreateValidators, userUpdateValidators } from 'utils/validators/validators'

/** @module Pages/Usuario/Components/FormUser */

/**
 * Componente, Formulario de registro/edición de Usuario.
 * @param {object} props Propiedades del componente.
 * @param {React.MutableRefObject<any>} props.formRef Referencia del formulario.
 * @param {Function} props.onSubmit Callback a ejecutar cuando se de submit del formulario.
 * @param {object} props.selectedUser Usuario seleccionado.
 * @returns {JSX.Element} Retorna el componente FormUser.
 */
function FormUser({formRef, onSubmit, selectedUser}) {
    const initialValues = selectedUser ? selectedUser : { 
        nombre: '', username: '', password: '', confirmar: '', 
    }

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={selectedUser ? userUpdateValidators : userCreateValidators}
            onSubmit={onSubmit}
        >
            {() => (
                <FormFormik>
                    <div>
                        <InputFormik name='nombre' />
                        <InputFormik name='username' label='Usuario' />
                        {
                            selectedUser ? null
                                : <>
                                    <InputFormik name='password' type='password' label='Contraseña' />
                                    <InputFormik name='confirmar' type='password' label='Confirmar contraseña' />
                                </>
                        }
                    </div>
                </FormFormik>
            )}
        </Formik>
    )
}

export default FormUser