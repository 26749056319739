import { Card } from 'react-bootstrap'
import { useRef, useState } from 'react'
import Button from 'components/Button'
import SelectPapeleta from './components/SelectPapeleta'
import PapeletaService from 'services/papeleta.service'
import Dialog, { ConfirmDialog } from 'components/Dialog'
import { formatDate } from 'utils/utils'
import { useDispatch } from 'react-redux'
import { setHttpMessage } from 'store/messageSlice'
import InputLayout from 'components/layouts/InputLayout'
import EditableSelect from 'components/EditableSelect'
import InputDropdown from 'components/InputDropdown'
import TitlePage from 'components/TitlePage'
import FormDevolucion from './components/FormDevolucion'
import { ReembolsoService } from 'services/reembolso.service'
import { verifyAccess } from 'utils/rbac.util'
import Input from 'components/Input'

/** @module Pages/Papeleta/PapeletaAction */

// Valores iniciales de los filtros
const initialFilters = { unidad: '', numeroPapeleta: '' }
// Iconos de las acciones
const btnIcon = {
    Pagar: 'pi pi-money-bill',
    Revertir: 'pi pi-history',
    Anular: 'pi pi-ban',
    'Devolución': 'pi pi-sync',
}

/**
 * Página de cambio de estado de las papeletas.
 * @returns {JSX.Element} Retorna el componente PapeletaAction.
 */
function PapeletaAction() {
    const dispatch = useDispatch()
    const [selected, setSelected] = useState([]) // Papeletas seleccionadas
    const [papeletas, setPapeletas] = useState([])
    const [unidades, setUnidades] = useState([])
    const [filters, setFilters] = useState(initialFilters)
    const [show, setShow] = useState({confirm: false, devolucion: false})
    const [action, setAction] = useState('Pagar')
    const [selectionMode, setSelectionMode] = useState('multiple')
    const [submitting, setSubmitting] = useState(false)
    const devolucionRef = useRef()
    const actions = [
        ...(verifyAccess('papeletaPay')?['Pagar']:[]), 
        ...(verifyAccess('papeletaReverse')?['Revertir']:[]), 
    ]

    const searchUnidades = async (unidad) => {
        const loadData = async () => {
            const response = await ReembolsoService.searchUnidades(unidad)
            if (response.status === 200) setUnidades(response.data)
        }
        if (unidad.length > 0) {
            loadData()
        } else setUnidades([])
    }
    
    const openConfirm = () => setShow({...show, confirm: true})
    const closeConfirm = () => setShow({...show, confirm: false})
    const openDevolucion = () => setShow({...show, devolucion: true})
    const closeDevolucion = () => setShow({...show, devolucion: false})

    const handleChange = (e) => {
        setFilters({...filters, [e.target.name]: e.target.value})
        if (selected) setSelected([])
        if (papeletas.length > 0) setPapeletas([])
    }
    const handleKey = (e) => {
        if (e.key === 'Enter') {
            if (papeletas.length === 0) {
                if (typeof filters.unidad === 'object' || filters.numeroPapeleta) {
                    handleSearch()
                }
            }
        }
    }
    const handleSelect = (selection) => {
        if (selectionMode === 'multiple') {
            setSelected(selection)
        } else {
            if (selection) setSelected([selection])
            else setSelected([])
        }
    }
    const handleClean = () => {
        setFilters(initialFilters)
        setSelected([])
        setPapeletas([])
    }
    const handleSearch = async () => {
        const _filters = {
            numeroPapeleta: filters.numeroPapeleta,
            daId: filters.unidad?.das ? filters.unidad.das.split(',') : [],
            cuentaId: filters.unidad?.cuentas ? filters.unidad.cuentas.split(',') : []
        }
        const response = await PapeletaService.searchToAction(_filters)
        if (response.status === 200) setPapeletas(response.data)
    }
    const handleAction = async ({setLoading}) => {
        if (selected.length > 0) {
            setLoading(true)
            let response = {}
            if (action === 'Revertir') {
                response = await PapeletaService.reversePapeletas({ids: selected.map(p=>p.id)})
            } else if (action === 'Pagar') {
                response = await PapeletaService.payPapeletas({ids: selected.map(p=>p.id)})
            } else if (action === 'Anular') {
                response = await PapeletaService.cancelPapeletas(selected[0].id)
            }
            if (response.status === 200) {
                closeConfirm()
                handleClean()
            }
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            setLoading(false)
        }
    }
    const handleDevolucion = async (values) => {
        if (selected.length > 0) {
            setSubmitting(true)
            const response = await PapeletaService.devolucionPapeleta(values, selected[0].id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                const newPapeleta = {...selected[0], ...values}
                setPapeletas(papeletas.map(p => p.id===newPapeleta.id?newPapeleta:p))
                setSelected([newPapeleta])
                closeDevolucion()
            }
            setSubmitting(false)
        }
    }
    const handleInputAction = (e) => {
        const value = e.target.value
        setAction(value)
        if (['Pagar', 'Revertir'].includes(value) && selectionMode !== 'multiple') {
            handleClean()
            setSelectionMode('multiple')
        } else if (['Anular', 'Devolución'].includes(value) && selectionMode !== 'single') {
            handleClean()
            setSelectionMode('single')
        }
    }
    const getConfirmMessage = () => {
        return selected.length > 0 
            ? <>
                Esta seguro de <span className='text-primary'>{action.toUpperCase()}</span> las papeletas Nro.
                {
                    selected.reduce((carry, p, index) => {
                        let nro = ''
                        if (index === selected.length-1 && selected.length > 1) nro = ' y '
                        else if (index !== 0) nro = ', '
                        nro += p.numero_papeleta + '/' + formatDate(p.fecha_registro, 'y')
                        return carry + nro
                    }, ' ')
                }?
            </>
            : 'Debe seleccionar por lo menos una papeleta'
    }

    const footerDevolucionDialog = <>
        <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeDevolucion}>Cancelar</Button>
        <Button 
            variant='text-secondary' startIcon='pi pi-save' 
            onClick={() => devolucionRef?.current?.handleSubmit()}
            loading={submitting}
        >Guardar</Button>
    </>

    return <>
        <TitlePage title='Pagar/Revertir Papeletas de Viáticos' />
        <div className='content align-items-center' style={{backgroundColor: '#efefef'}}>
            <Card className='flex-1 w-xl-75 p-5'>
                <div className='d-flex justify-content-center align-items-end gap-3 mb-3'>
                    <InputLayout label='Acción:' className='label-center' style={{width: '15rem'}}>
                        <InputDropdown 
                            options={actions} 
                            value={action} 
                            onChange={handleInputAction} 
                            className='dropdown-primary' 
                        />
                    </InputLayout>
                    <InputLayout label='Unidad:' className='label-center'>
                        <EditableSelect 
                            name='unidad'
                            options={unidades} 
                            optionValue='descripcion' 
                            valueAsOption 
                            lazy
                            value={filters.unidad} 
                            onChange={handleChange} 
                            onKeyDown={handleKey}
                            onSearch={searchUnidades}
                        />
                    </InputLayout>
                    <InputLayout label='Nro. Papeleta:' className='label-center'>
                        <Input name='numeroPapeleta' value={filters.numeroPapeleta} onChange={handleChange} onKeyUp={handleKey} type='number' />
                    </InputLayout>
                    <Button startIcon='pi pi-filter-slash' className='mb-3' onClick={handleClean} title='Limpiar'>Limpiar</Button>
                    <Button startIcon='pi pi-search' className='mb-3' onClick={handleSearch} title='Buscar'>Buscar</Button>
                </div>
                <div className='d-flex justify-content-center mb-3'>
                    <SelectPapeleta 
                        papeletas={papeletas} 
                        selected={selected} 
                        onSelectionChange={handleSelect} 
                        selectionMode={selectionMode}
                    />
                </div>
                <div className='text-center'>
                    <Button 
                        startIcon={btnIcon[action]} 
                        onClick={action === 'Devolución' ? openDevolucion : openConfirm} 
                        disabled={selected.length === 0}
                    >{action}</Button>
                </div>
            </Card>
            <ConfirmDialog 
                show={show.confirm} 
                onHide={closeConfirm}
                onReject={closeConfirm}
                onAccept={handleAction}
            >
                {getConfirmMessage()}
            </ConfirmDialog>
            <Dialog
                show={show.devolucion}
                header='Devolución'
                keyboard={false}
                onHide={closeDevolucion}
                footer={footerDevolucionDialog}
            >
                <FormDevolucion 
                    formRef={devolucionRef} 
                    onSubmit={handleDevolucion} selected={selected.length > 0 ? selected[0] : null} 
                />
            </Dialog>
        </div>
    </>
}
export default PapeletaAction