import DataTable from 'components/DataTable'
import { useEffect, useState } from 'react'
import { formatDate } from 'utils/utils'

/** @module Pages/Papeleta/Components/SelectPapeleta */

/**
 * Componente, Selección de papeletas.
 * @param {object} props Propiedades del componente.
 * @param {array} props.papeletas Papeletas disponibles para seleccionar.
 * @param {array} props.selected Papeleta seleccionada.
 * @returns {JSX.Element} Retorna el componente SelectPapeleta.
 */
function SelectPapeleta({papeletas, selected, ...props}) {
    const columns = [
        {
            selector: true,
            header: '',
            selectionOnClickRow: true,
            style: {width: '4rem'}
        },
        {
            field: 'numero_papeleta',
            header: 'Nro',
            body: rowData => rowData.numero_papeleta + '/' + formatDate(rowData.fecha_registro, 'y'),
            style: {width: '6rem'}
        },
        {
            field: 'beneficiario',
            header: 'Beneficiario',
        },
        {
            field: 'cuenta',
            header: 'Cuenta',
        },
        {
            field: 'fecha_registro',
            header: 'Fecha',
            body: rowData =>  formatDate(rowData.fecha_registro, 'd/m/Y'),
            style: {width: '8rem'}
        },
    ]
    const [selection, setSelection] = useState([])

    useEffect(() => {
        if (props.selectionMode === 'multiple') {
            setSelection(selected)
        } else {
            if (selected.length === 1) setSelection(selected[0])
            else setSelection(null)
        }
    }, [selected]) 
    
    return (
        <DataTable 
            {...props}
            values={papeletas}
            columns={columns}
            selection={selection}
        /> 
    )
}
export default SelectPapeleta