import TablePapeletas from './components/TablePapeletas'
import { useEffect, useMemo, useRef, useState } from 'react'
import { initialPagination } from 'data/const'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import PapeletaService from 'services/papeleta.service'
import Button from 'components/Button'
import Dialog, { ConfirmDialog } from 'components/Dialog'
import ViewPapeleta from './components/ViewPapeleta'
import { formatDate, getQueryParams } from 'utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { setHttpMessage } from 'store/messageSlice'
import PapeletaPdf from 'pdf/papeleta'
import TitlePage from 'components/TitlePage'
import FormDevolucion from './components/FormDevolucion'
import useFilters from 'hooks/useFilters'
import { fileToBase64 } from 'utils/utils'
import FormUploadFile from 'components/FormUploadFIle'
import { verifyAccess } from 'utils/rbac.util'
import ViewFilePdf from 'components/ViewFilePdf'

/** @module Pages/Papeleta/PapeletaIndex */

const actions = {
    pay: 'PAGAR',
    cancel: 'ANULAR',
    reverse: 'REVERTIR',
}
// Opciones de filtros para el hook useFilters.
const filterOptions = {
    contain: ['numero_papeleta', 'nombre_beneficiario', 'doc_autorizacion', 'lugar_viaje'],
    equal: ['estado', 'memo_beneficiario_id'],
    between: ['fecha_registro']
}
const paramFilter = {
    mbId: 'memo_beneficiario_id'
}

/**
 * Página, Lista de papeletas.
 * @returns {JSX.Element} Retorna el componente PapeletaIndex.
 */
function PapeletaIndex() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const tableRef = useRef()
    const [papeletas, setPapeletas] = useState([])
    const [selectedPapeleta, setSelectedPapeleta] = useState(null)
    const [pagination, setPagination] = useState(initialPagination)
    const {filters, reloadFilters, ...filterHandlers} = useFilters({initialFilters: null, filterOptions})
    const [show, setShow] = useState({confirm: false, info: false, devolucion: false, upload: false, viewFile: false})
    const [currentAction, setCurrentAction] = useState('')
    const {sectionKey} = useSelector(state => state.system)
    const [uploading, setUploading] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [file, setFile] = useState({value: null, error: ''})
    const queryParams = useMemo(() => {
        const params = getQueryParams(location.search)
        return ['mbId'].reduce((carry, param) => {
            if (params[param]!==undefined) carry[paramFilter[param]] = params[param]
            return carry
        }, {})
    }, [location.search])
    const devolucionRef = useRef()
    const pdfP = new PapeletaPdf()

    useEffect(() => {
        const loadData = async () => {
            const {filter, ..._filters} = filters
            const response = await PapeletaService.indexPapeletasPost({filter}, _filters)
            if (response.status === 200) {
                setPapeletas(response.data.data)
                setPagination(response.data.pagination)
            }
        }
        if (filters) loadData()
    }, [filters, sectionKey, location.search]) 

    // Controlador para abrir los modales
    const handleAction = (action, rowData) => {
        setSelectedPapeleta(rowData)
        if (action === 'create') {
            navigate('create')
        } else if (['cancel', 'pay', 'reverse'].includes(action)) {
            openDialog('confirm')
            setCurrentAction(action)
        } else if (action === 'view') {
            openDialog('info')
        } else if (action === 'print') {
            handlePrint(rowData)
        } else if (action === 'devolucion') {
            openDialog('devolucion')
        } else if (action === 'upload') {
            openDialog('upload')
        } else if (action === 'view-file') {
            openDialog('viewFile')
        }
    }
    const openDialog = (name) => setShow({...show, [name]: true})
    const closeConfirmDialog = () => {
        setShow({...show, confirm: false})
        setCurrentAction('')
    }
    const closeInfoDialog = () => setShow({...show, info: false})
    const closeUploadDialog = () => setShow({...show, upload: false})
    const closeDevolucion = () => setShow({...show, devolucion: false})
    const closeViewFileDialog = () => setShow({...show, viewFile: false})

    const handleConfirm = () => {
        if (currentAction === 'pay') {
            handlePay()
        } else if (currentAction === 'cancel') {
            handleCancel()
        } else if (currentAction === 'reverse') {
            handleReverse()
        }
        setCurrentAction('')
    }
    const handlePay = async () => {
        if (selectedPapeleta) {
            const response = await PapeletaService.payPapeletas({ids: [selectedPapeleta.id]})
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                reloadFilters()
                closeConfirmDialog()
            }
        }
    }
    const handleReverse = async () => {
        if (selectedPapeleta) {
            const response = await PapeletaService.reversePapeletas({ids: [selectedPapeleta.id]})
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                reloadFilters()
                closeConfirmDialog()
            }
        }
    }
    const handleCancel = async () => {
        if (selectedPapeleta) {
            const response = await PapeletaService.cancelPapeleta(selectedPapeleta.id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                reloadFilters()
                closeConfirmDialog()
            }
        }
    }
    const handlePrint =  async (data) => {
        const response = await PapeletaService.getImpresionOriginal(data.id)
        if (response.status === 200) {
            pdfP.papeleta(response.data.data, response.data.configPapeleta)
        }
    }
    const handleDevolucion = async (values) => {
        if (selectedPapeleta) {
            setSubmitting(true)
            const response = await PapeletaService.devolucionPapeleta(values, selectedPapeleta.id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                reloadFilters()
                closeDevolucion()
            }
            setSubmitting(false)
        }
    }
    const uploadFile = async () => {
        setUploading(true)
        if (selectedPapeleta) {
            const {value, error} = file
            if (value) {
                if (!error) {
                    const base64 = await fileToBase64(value)
                    const filename = value.name.slice(0, value.name.length-4)+'-'+formatDate(new Date(), 'YmdHi')
                    const response = await PapeletaService.uploadPapeleta({file: base64, filename}, selectedPapeleta.id)
                    dispatch(setHttpMessage({status: response.status, title: response.data.message}))
                    if (response.status === 200) {
                        reloadFilters()
                        closeUploadDialog()
                    }
                }
            } else {
                setFile({value: null, error: 'Requerido'})
            }
        }
        setUploading(false)
    }
    const clearSelected = () => setSelectedPapeleta(null)

    const infoFooterDialog = <Button startIcon='pi pi-times' variant='text-secondary' type='submit' onClick={closeInfoDialog}>Cerrar</Button>
    const footerDevolucionDialog = <>
        <Button startIcon='pi pi-times' variant='text-secondary' onClick={closeDevolucion}>Cancelar</Button>
        <Button 
            variant='text-secondary' startIcon='pi pi-save' 
            onClick={() => devolucionRef?.current?.handleSubmit()}
            loading={submitting}
        >Guardar</Button>
    </>
    const footerUploadDialog = <>
        <Button startIcon='pi pi-times' variant='text-secondary' onClick={closeUploadDialog}>Cancelar</Button>
        <Button startIcon='bi-cloud-upload-fill' variant='text-secondary' onClick={uploadFile} loading={uploading}>Subir</Button>
    </>
    const options = sectionKey === 'caja' ? verifyAccess(['papeletaPay', 'papeletaReverse'], 'or') && (
            <Link className='btn' to={'accion'}>
                <span className='bs-btn-icon-start'><i className='pi pi-bars'></i></span>
                <span className='bs-btn-label'>Pagar/Revertir en Lote</span>
            </Link>
        )
        : verifyAccess('papeletaCreateWithoutMemo') && (
            <Link className='btn' to={'create'}>
                <span className='bs-btn-icon-start'><i className='pi pi-plus'></i></span>
                <span className='bs-btn-label'>Nuevo</span>
            </Link>
        )

    return <>
        <TitlePage title='Lista de Papeletas de Viáticos' options={options} />
        <div className='content'>
            <div>
                <TablePapeletas 
                    tableRef={tableRef} 
                    papeletas={papeletas} 
                    pagination={pagination}
                    filterHandlers={filterHandlers}
                    handleAction={handleAction} 
                    sectionKey={sectionKey}
                    initialFilters={queryParams}
                /> 
                <Dialog
                    show={show.viewFile}
                    header='Papeleta'
                    footer={<Button startIcon='pi pi-times' variant='text-secondary' onClick={closeViewFileDialog}>Cerrar</Button>}
                    onHide={closeViewFileDialog}
                    style={{width: '60rem'}}
                    onExited={clearSelected}
                    scrollable
                >
                    <ViewFilePdf selected={selectedPapeleta} />
                </Dialog>
                <Dialog
                    show={show.info}   
                    header='Papeleta de pago de pasajes y/o viáticos' 
                    footer={infoFooterDialog}
                    onHide={closeInfoDialog}
                    style={{ width: '75rem' }}
                    onExited={clearSelected}
                >
                    {selectedPapeleta ? <ViewPapeleta id={selectedPapeleta.id} /> : null}
                </Dialog>
                <ConfirmDialog
                    show={show.confirm}
                    onHide={closeConfirmDialog}
                    onReject={closeConfirmDialog}
                    onAccept={handleConfirm}
                    onExited={clearSelected}
                >
                    Esta seguro de <span className='text-primary'>{actions[currentAction]}</span> la papeleta {selectedPapeleta?.numero_papeleta}/{formatDate(selectedPapeleta?.fecha_registro, 'y')}"?
                </ConfirmDialog>
                <Dialog
                    show={show.devolucion}
                    header='Devolución'
                    footer={footerDevolucionDialog}
                    keyboard={false}
                    style={{width: '30rem'}}
                    onHide={closeDevolucion}
                    onExited={clearSelected}
                >
                    <FormDevolucion 
                        formRef={devolucionRef} 
                        onSubmit={handleDevolucion} selected={selectedPapeleta} 
                    />
                </Dialog>
                <Dialog
                    show={show.upload}
                    header='Subir PDF'
                    footer={footerUploadDialog}
                    keyboard={false}
                    onHide={closeUploadDialog}
                    onExited={() => {
                        clearSelected()
                        setFile({value: null, error: ''})
                    }}
                >
                    <FormUploadFile file={file} setFile={setFile} />
                </Dialog>
            </div>
        </div>
    </>
}
export default PapeletaIndex