export const estados = {
    R: 'Registrado', // 'R'
    D: 'Observado', // 'D'
    A: 'Registrado', // 'A'
    M: 'Aprobado', // 'M'
    C: 'Anulado', // 'C'
}

export const adjuntoSolicitudTipos = { 
    2: { label: 'Cronograma', value: 2 },
    3: { label: 'Resolución', value: 3 },
    4: { label: 'Autorización', value: 4 },
    5: { label: 'Invitación', value: 5 },
    6: { label: 'Tríptico', value: 6 },
    99: { label: 'Otros', value: 99 }, 
}

export const adjuntoInformeTipos = { 
    2: { label: 'Cronograma', value: 2 },
    3: { label: 'Resolución', value: 3 },
    4: { label: 'Autorización', value: 4 },
    5: { label: 'Invitación', value: 5 },
    6: { label: 'Tríptico', value: 6 },
    8: { label: 'Diploma', value: 8 }, 
    9: { label: 'Certificado', value: 9 }, 
    10: { label: 'Programa', value: 10 }, 
    11: { label: 'Informe', value: 11 }, 
    12: { label: 'Boleto, ticket o pase de transporte', value: 12 }, 
    99: { label: 'Otros', value: 99 }, 
}

export const destinatarios = {
    1: { title: 'DAF', value: 1, label: 'Dirección Administrativa y Financiera' },
    2: { title: 'DICyT', value: 2, label: 'Dirección de Investigación Científica y Tecnológica' },
    3: { title: 'EUPG', value: 3, label: 'Escuela Universitaria de Posgrado' },
}