import { Collapse, Spinner } from 'react-bootstrap'
import SolicitudService from 'services/solicitud.service'
import { estados } from 'data/constSolicitud'
import { useEffect, useMemo, useState } from 'react'
import { formatDate } from 'utils/utils'
import { useSelector } from 'react-redux'
import Button from 'components/Button'
import { verifyAccess } from 'utils/rbac.util'

/** @module Pages/Solicitud/Components/ViewSolicitud */

const currentDate = formatDate(new Date(), 'Y-m-d')

/**
 * Componente, Información de la solicitud.
 * @param {object} props Propiedades del componente.
 * @param {object} props.selected Solicitud seleccionada.
 * @returns {JSX.Element} Retorna el componente ViewSolicitud.
 */
function ViewSolicitud({selected, handleAction}) {
    const [solicitud, setSolicitud] = useState(null)
    const {sectionKey} = useSelector(state => state.system)
    const isPresupuestado = useMemo(() => ['presupuestos', 'daf'].includes(sectionKey) && (solicitud?.presupuestado), [sectionKey, solicitud])
    const [show, setShow] = useState({confirmAnular: false})

    useEffect(() => {
        const loadSolicitud = async () => {
            const response = await SolicitudService.viewSolicitud({ id: selected.id, restrict: !['daf', 'presupuestos'].includes(sectionKey) ? 1 : 0 })
            if (response.status === 200) {
                setSolicitud(response.data.solicitud)
            }
        }
        selected && loadSolicitud()
    }, []) 
    
    const getDestinos = () => {
        const groups = {}
        solicitud.solicitudBeneficiarios.forEach((sb) => {
            const _key = sb.lugar_viaje
            if (groups[_key]) groups[_key].push(sb)
            else groups[_key] = [sb]
        })
        const groups2 = {}
        for (const key in groups) {
            groups[key].forEach(sb => {
                const _key = sb.fecha_salida + '*' + sb.fecha_llegada
                if (groups2[key]) {
                    if (groups2[key][_key]) groups2[key][_key].push(sb)
                    else groups2[key][_key] = [sb]
                } else {
                    groups2[key] = {[_key]: [sb]}
                }
            })
        }
        const destinos = []
        for (const key in groups2) {
            const fechas = []
            for (const _key in groups2[key]) {
                const data = _key.split('*')
                fechas.push({
                    fecha_salida: data[0],
                    fecha_llegada: data[1],
                    beneficiarios: groups2[key][_key]
                })
            }
            destinos.push({lugar_viaje: key, fechas})
        }
        return destinos
    }
    
    return solicitud ? (
        <div>
            <div className='row mb-3'>
                <div className='col-sm'><span className='fw-bold'>Fecha de registro: </span>{formatDate(solicitud.fecha_creacion, 'd/m/Y H:i:s')}</div>
                <div className='col-sm-auto' style={{minWidth: '14rem'}}>
                    <span className='fw-bold'>Estado: </span>
                    <span className={['D'].includes(solicitud.estado) ? 'text-danger' : ''}>{estados[solicitud.estado]}</span>
                </div>
            </div>
            {
                solicitud.observaciones && ['D'].includes(solicitud.estado) ? (
                    <div className='mb-3'>
                        <span className='fw-bold'>Observaciones: </span>{solicitud.observaciones.split(';').pop()}
                    </div>
                ) : null
            }
            <div className='text-center fs-3'>Solicitud N°{solicitud.numero_solicitud}</div>
            <div className='mb-2'>
                <span className='fw-bold'>Identificador:</span> {solicitud.identificador||'-'}
            </div>
            <div className='mb-2'>
                <div className='fw-bold mb-2'>Objetivo:</div>
                {solicitud.objetivo_viaje}
            </div>
            {solicitud.resolucion&&<div className='mb-1'><span className='fw-bold'>Resolución: </span>{solicitud.resolucion}</div>}
            {getDestinos().map((destino, index) => {
                const {lugar_viaje, fechas} = destino
                return (
                    <div className='mb-2 bs-border-title' key={index}>
                        <div className='fw-bold bs-title'>Destino</div>
                        <div className='mb-1'><span className='fw-bold'>Lugar de viaje: </span>{lugar_viaje}</div>
                        {
                            fechas.map((fecha, _index) => {
                                const {fecha_salida, fecha_llegada, beneficiarios} = fecha
                                return (
                                    <div className={fechas.length > 1 ? 'ms-3' : null} key={_index}>
                                        <div className='row'>
                                            <div className='col-sm'><span className='fw-bold'>Fecha salida: </span>{formatDate(fecha_salida, 'd/m/Y H:i')}</div>
                                            <div className='col-sm'><span className='fw-bold'>Fecha llegada: </span>{formatDate(fecha_llegada, 'd/m/Y H:i')}</div>
                                        </div>
                                        {
                                            beneficiarios.length > 0 && (
                                                <div className='mb-2'>
                                                    <div className='fw-bold mb-2'>Beneficiarios:</div>
                                                    <table className='table-simple'>
                                                        <thead>
                                                            <tr>
                                                                <th>Nombre</th>
                                                                <th>Cargo</th>
                                                                <th>{solicitud.invitados ? 'Lugar Origen' : 'CI'}</th>
                                                                {isPresupuestado && <th>Actividad</th>}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {beneficiarios.map((beneficiario, __index) => (
                                                                <tr key={__index}>
                                                                    <td>{beneficiario.nombre_beneficiario} {beneficiario.apellido_beneficiario}</td>
                                                                    <td>{beneficiario.funcion||beneficiario.cargo}</td>
                                                                    <td>{solicitud.invitados ? beneficiario.origen : beneficiario.documento}</td>
                                                                    {isPresupuestado && <td title={beneficiario.actividad}>{beneficiario.codigo_actividad}</td>}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            })}
            <div className='mb-1'><span className='fw-bold'>Certificación Presupuestaria?: </span>{solicitud.presupuestado?'Si':'No'}</div>
            {
                isPresupuestado ? 
                    <div className='bs-border-title'>
                        <div className='fw-bold bs-title'>Certificación Presupuestaria</div>
                        <div><span className='fw-bold'>Fecha: </span>{formatDate(solicitud.fecha_presupuestado, 'd/m/Y H:i', '')}</div>
                        <div><span className='fw-bold'>Partida pasajes: </span>{solicitud.partida_pasajes}</div>
                        <div><span className='fw-bold'>Partida viáticos: </span>{solicitud.partida_viaticos}</div>
                    </div> 
                    : null
            }
            {
                sectionKey === 'beneficiario' && <div className='d-flex align-items-center'>
                    <div className='fw-bold me-3'>Opciones:</div>
                    {selected.propietario&&(solicitud.estado==='D'||(solicitud.estado==='A'&&currentDate<=`${formatDate(solicitud.fecha_creacion, 'Y-m-d')} 23:59:59`))&&verifyAccess('solicitudUpdate')&&
                        <Button 
                            variant='primary'
                            startIcon='bi-pencil'
                            onClick={() => handleAction('update', selected)}
                            className='me-3'
                        >Editar</Button>
                    }
                    {selected.propietario&&solicitud.estado==='A'&&!solicitud.presupuestado&&verifyAccess('solicitudCancel')
                        && (
                            <div className='d-flex align-items-center'>
                                <Button 
                                    variant='danger'
                                    startIcon='bi-x-lg'
                                    onClick={() => setShow({...show, confirmAnular: true})} 
                                    className='me-2'
                                >Anular</Button>
                                <div style={{width: '12rem'}}>
                                    <Collapse in={show.confirmAnular} dimension="width">
                                        <div className='text-nowrap'>
                                            ¿Esta seguro?
                                            <Button onClick={() => setShow({...show, confirmAnular: false})} variant='text-secondary'>No</Button>
                                            <Button variant='text-blue' onClick={() => handleAction('cancel', selected)}>Si</Button>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        )
                    }
                </div>
            }
        </div>
    ) : (
        <div 
            className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center' 
            style={{top: 0, left: 0, background: '#00000090'}}
        >
            <Spinner animation='border' variant='light'>
                <span className='visually-hidden'>Loading...</span>
            </Spinner>
        </div>
    )
}
export default ViewSolicitud