import Button from 'components/Button'
import { ConfirmDialog } from 'components/Dialog'
import DraftEditor from 'components/DraftEditor'
import Input from 'components/Input'
import TitlePage from 'components/TitlePage'
import InputLayout from 'components/layouts/InputLayout'
import Loading from 'pages/Loading'
import { useEffect, useRef, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import MemoService from 'services/memo.service'
import { setHttpMessage } from 'store/messageSlice'
import { classNames } from 'utils/utils'
import { verifyAccess } from 'utils/rbac.util'

/** @module Pages/Memo/MemoTemplate */

// Valores iniciales del formulario de registro de plantilla de memorándum.
const initialValues = {
    nombre: '',
    plantilla_json: {blocks: [], entityMap: {}}
}

/**
 * Página de administración de plantillas de memorándum.
 * @returns {JSX.Element} Retorna el componente MemoTemplate.
 */
function MemoTemplate() {
    const dispatch = useDispatch()
    const [template, setTemplate] = useState(initialValues)
    const [selected, setSelected] = useState(null)
    const [templates, setTemplates] = useState([])
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const {userInfo} = useSelector(state => state.auth)
    const [loading, setLoading] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const editorRef = useRef()

    useEffect(() => {
        loadTemplates()
    }, [])
    useEffect(() => {
        if (selected) editorRef?.current?.load(selected.plantilla_json)
        else editorRef?.current?.load(initialValues.plantilla_json)
    }, [selected])

    const loadTemplates = async () => {
        setLoading(true)
        const response = await MemoService.plantillaMemo.getAll()
        if (response.status === 200) setTemplates(response.data)
        setLoading(false)
    }

    const openConfirmDialog = () => setShowConfirmDialog(true)
    const closeConfirmDialog = () => setShowConfirmDialog(false)

    const handleDelete = async () => {
        if (selected) {
            const response = await MemoService.plantillaMemo.delete(selected.id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                setTemplates(templates.filter(t => !Object.is(t, selected)))
                closeConfirmDialog()
            }
        }
    }
    const handleSubmit = async () => {
        setSubmitting(true)
        if (template.nombre) {
            if (selected) {
                const response = await MemoService.plantillaMemo.update(template, selected.id)
                dispatch(setHttpMessage({status: response.status, title: response.data.message}))
                if (response.status === 200) {
                    clean(false)
                    loadTemplates()
                }
            } else {
                const response = await MemoService.plantillaMemo.create({...template, usuario_id: userInfo.id})
                dispatch(setHttpMessage({status: response.status, title: response.data.message}))
                if (response.status === 201) {
                    clean(false)
                    loadTemplates()
                }
            }
        }
        setSubmitting(false)
    }

    const clean = (_selected=null) => {
        setTemplate(initialValues)
        setSelected(_selected)
    }
    const buildTemplateCard = (t, index) => {
        const isSelected = Object.is(t, selected)
        return (
            <div 
                className={classNames(['d-flex align-items-stretch mb-2 group', isSelected && 'text-primary'])} 
                key={index}
            >
                <div 
                    className='flex-1 p-2 cursor-pointer' 
                    style={{border: '1px solid #102644'}}
                    onClick={() => {
                        if (t && isSelected) {
                            clean()
                        } else {
                            setSelected(t)
                            setTemplate({...t})
                        }
                    }}
                >
                    <div>{t.nombre}</div>
                </div>
                {
                    t.usuario_id === userInfo.id && verifyAccess('plantillaMemoDelete') &&
                        <Button startIcon='pi pi-times' onClick={() => {
                            clean(t)
                            openConfirmDialog()
                        }} />
                }
            </div>
        )
    }
    const infoPlantilla = <div>
        <div className='text-decoration-underline' style={{fontSize: '1.05rem'}}>Variables:</div>
        <div>
            - {'{{objetivo_viaje}}'} <br/>
            - {'{{numero_solicitud}}'} <br/>
            Ejm. Solicitud 1/23 <br/>
            - {'{{remitente_solicitud}}'} <br/>
            - {'{{lugar_viaje}}'} <br/>
            - {'{{dias_viaje}}'} <br/>
            Ejm. 18 al 20 de Enero de 2023 <br/>
            - {'{{beneficiarios}}'}
        </div>
    </div>
    
    return <>
        <TitlePage title='Plantillas de Memos'/>
        <div className='content' style={{backgroundColor: '#efefef'}}>
            <div className='flex-1 row g-3 justify-content-center'>
                <div className='d-flex flex-column col-md-4 col-xxl-3'>
                    <Card className='flex-1 p-4'>
                        {loading ? <Loading/> : templates.map(buildTemplateCard)}
                    </Card>
                </div>
                <div className='d-flex flex-column col-md-8 col-xxl-6'>
                    <Card className='flex-1 p-3'>
                        <div className='fs-2 mb-3'>
                            {selected ? (selected.usuario_id !== userInfo.id ? 'Información de' : 'Editar') : 'Registrar'} Plantilla
                        </div>
                        <div>
                            <InputLayout label='Nombre'>
                                <Input value={template.nombre} onChange={(e) => setTemplate({...template, nombre: e.target.value})} />
                            </InputLayout>
                            <InputLayout label='Plantilla' info={infoPlantilla}>
                                <DraftEditor 
                                    innerRef={editorRef}
                                    value={template.plantilla_json}
                                    className='resize-v'
                                    style={{height: '20rem', minHeight: '20rem'}}
                                    onChange={(value) => setTemplate({...template, plantilla_json: value})}
                                />
                            </InputLayout>
                        </div>
                        {
                            (selected?.usuario_id === userInfo.id || !selected) && verifyAccess(['plantillaMemoCreate', 'plantillaMemoUpdate'], 'or') &&
                            <div className='d-flex justify-content-center'>
                                <Button 
                                    startIcon='pi pi-save'
                                    onClick={handleSubmit} 
                                    loading={submitting}
                                >Guardar</Button>
                            </div>
                        }
                    </Card>
                </div>
                <ConfirmDialog 
                    show={showConfirmDialog}
                    onHide={closeConfirmDialog}
                    onAccept={handleDelete}
                    onReject={closeConfirmDialog}
                    onExited={() => setSelected(null)}
                >
                    Esta seguro de <span className='text-primary'>ELIMINAR</span> la plantilla {selected?.nombre}?
                </ConfirmDialog>
            </div>
        </div>
    </>
}
export default MemoTemplate