import React, { useEffect, useState, useRef, forwardRef } from 'react'
import { classNames } from 'utils/utils'

/** @module Components/EditableSelect */

const initialItem = {option: '', index: -1, type: 'string', label: '', value: ''}

/**
 * Componente EditableSelect.
 * @param {object} props Propiedades del componente.
 * @param {Array<string | object>} props.options Array de opciones del select.
 * @param {string | object} props.value Valor seleccionado.
 * @param {string | undefined} props.name Propiedad name del input.
 * @param {string | undefined} props.optionValue Especifica la propiedad que se mostrará en las opciones.
 * @param {string | undefined} props.placeholder Descripción por defecto que se muestra en el select.
 * @param {string | undefined} props.className Clase de estilo del componente.
 * @param {React.CSSProperties | undefined} props.style Estilos en linea del componente.
 * @param {React.ReactNode | undefined} props.itemTemplate Plantilla que se mostrará en las opciones del select.
 * @param {function | undefined} props.onChange Callback que se ejecuta cada vez que el valor de la propiedad value cambia.
 * @param {function | undefined} props.onBlur Callback que se ejecuta cuando el select pierde el foco.
 * @param {function | undefined} props.onKeyDown Callback que se ejecuta cuando se presiona alguna tecla.
 * @param {function | undefined} props.onKeyUp Callback que se ejecuta cuando se deja de presionar alguna tecla.
 * @param {function | undefined} props.onSearch Callback que se ejecuta cada vez que el valor del input.
 * @param {boolean | undefined} props.disabled Especifica si el select esta deshabilitado o no.
 * @param {boolean | undefined} props.valueAsOption Especifica si el valor devuelto sera igual a la opción seleccionada o no.
 * @param {boolean | undefined} props.isInvalid Especifica si el input tendrá estilos de validación incorrecta.
 * @param {boolean | undefined} props.isValid Especifica si el input tendrá estilos de validación correcta.
 * @param {boolean | undefined} props.autoSelect Especifica si se auto seleccionara si solo hay una opción.
 * @param {boolean | undefined} props.lazy Especifica si las opciones se cargan dinámicamente cuando cambia la propiedad value.
 * @returns {JSX.Element} Retorna el componente EditableSelect.
 */
function EditableSelect({
    id, name, 
    value=null,  valueAsOption,
    onChange, onBlur,
    options, 
    optionValue='value',
    itemTemplate,
    disabled, placeholder='Seleccione',
    lazy, onSearch,
    autoSelect=true,
    defaultClassName, className, style, 
    isInvalid, isValid,
    onKeyDown, onKeyUp
}) {
    const [items, setItems] = useState([])
    const [filteredItems, setFilteredItems] = useState([])
    const [selected, setSelected] = useState(initialItem)
    const [hovered, setHovered] = useState(null)
    const [inputValue, setInputValue] = useState('')
    const menuRef = useRef(null)
    const itemsRef = useRef([])

    useEffect(() => {
        if (options) {
            const _value = typeof value === 'object' && value !== null ? value[optionValue] : value
            const _items = options.map(buildItem)
            setItems(_items)
            setFilteredItems(lazy?_items:filterItems(_items, _value))
            setHovered(null)
            if (menuRef?.current) menuRef.current.scrollTop = 0
        }
    }, [options]) 
    useEffect(() => {
        setSelected(searchSelected())
    }, [items, value])
    useEffect(() => {
        if (!lazy) {
            const _value = typeof value === 'object' && value !== null ? value[optionValue] : value
            setFilteredItems(filterItems(items, _value))
        }
    }, [value])
    useEffect(() => {
        if (autoSelect && typeof value === 'string' && filteredItems.length===1 && value===filteredItems[0].label) {
            handleSelection(filteredItems[0])
        }
    }, [filteredItems])
    
    /** Filtra los items. */
    const filterItems = (_items, filter) => {
        let _filteredItems = _items
        let index = 0
        if (filter) {
            _filteredItems = _items.reduce((carry, item) => {
                const optionFilterValue = item===null?'':(item.type==='object'?item.option[optionValue]:item.option)
                if (new RegExp(`^${filter.toLocaleLowerCase()}.*`).test(optionFilterValue.toLocaleLowerCase())) {
                    carry.push({...item, index}) 
                    index++
                }
                return carry
            }, [])
        }
        return _filteredItems
    }
    /** 
     * Construye un objeto Item de una opción. 
     * La propiedad value de Item es independiente de la propiedad valueAsOption.
     */
    const buildItem = (option, index) => {
        const typeOption = typeof option
        let _item = {
            option, index, type: 'null', label: '', value: ''
        }
        if (option !== null) {
            if (typeOption==='object') {
                const _value = option[optionValue]||''
                _item = {
                    ..._item, 
                    type: typeOption,
                    label: _value.toString(), 
                    value: _value
                }
            } else if (typeOption==="string"||typeOption==="number"||typeOption==="bigint"||typeOption==="boolean") {
                _item = {
                    ..._item,
                    type: typeOption,
                    label: option.toString(), 
                    value: option
                }
            }
        }
        return _item
    }
    /** Verifica si la opción es la seleccionada. */
    const isSelected = (item) => {
        let is = false
        const typeValue = value===null?'null':typeof value
        const valueItem = item.value
        /*
         * Tabla de comparación.
         *
         * value   item     valueAsObject | isSelected
         * -------------------------------------------------------------------------
         * obj     obj      true          | value[optionValue]===valueItem -> true
         * obj     obj      false         | false
         * obj     no-obj   true          | false
         * obj     no-obj   false         | false
         * no_obj  obj      true          | false
         * no_obj  obj      false         | value===valueItem -> true
         * no_obj  no-obj   true          | value===valueItem -> true
         * no_obj  no-obj   false         | value===valueItem -> true
         */
        if (typeValue==='object') {
            if (item.type==='object' && valueAsOption) {
                is = (value[optionValue]||'').toString()===valueItem
            }
        } else {
            if (item.type==='object') {
                if (!valueAsOption) {
                    is = value===valueItem
                }
            } else {
                is = value===valueItem
            }
        }
        return is
    }
    /** Busca la opción seleccionada. */
    const searchSelected = () => {
        let _selected = buildItem(value)
        for (let i = 0; i < items.length; i++) {
            const item = items[i]
            if (isSelected(item)) {
                _selected = item
                break
            }
        }
        if (_selected.label !== inputValue) setInputValue(_selected.label) 
        return _selected
    }

    /** Ejecuta la prop onChange. */
    const handleSelection = (item) => {
        if (typeof onChange === 'function') {
            onChange(buildEvent(valueAsOption?item.option:item.value))
        }
        if (menuIsOpen()) closeMenu()
    }
    /** Ejecuta la prop onBlur */
    const handleBlur = () => {
        if (typeof onBlur === 'function') {
            const timeout = setTimeout(() => {
                onBlur(buildEvent(value))
                clearTimeout(timeout)
            }, 200)
        }
    }
    /** 
     * Handler del evento de presionar una tecla.
     * Previene el evento por defecto al presionar la tecla Enter.
     */
    const handleKeyDown = (e) => {
        if (['Enter'].includes(e.key)) e.preventDefault()
        onKeyDown && onKeyDown(e)
    }
    /** 
     * Handler del evento de dejar de presionar una tecla.
     * Controla las teclas de flechas arriba y abajo para desplazarse por las opciones del menu del dropdown.
     * Controla la tecla Enter para seleccionar una opción del menu del dropdown.
     */
    const handleKeyUp = (e) => {
        const items = filteredItems
        const open = menuIsOpen()
        if (e.key === 'Enter') {
            if (open && hovered !== null) {
                if (JSON.stringify(hovered)!==JSON.stringify(selected)) {
                    handleSelection(hovered)
                    closeMenu()
                }
            }
        } else if (e.key === 'ArrowDown') {
            if (open) {
                if (hovered === null) {
                    itemsRef.current[0].scrollIntoView({block: 'nearest'})
                    setHovered(items[0])
                } else if (hovered.index+1<items.length) {
                    itemsRef.current[hovered.index+1].scrollIntoView({block: 'nearest'})
                    setHovered(items[hovered.index+1])
                }
            }
        } else if (e.key === 'ArrowUp') {
            if (open && hovered) {
                if (hovered.index===0) setHovered(null)
                else if (hovered.index>0) {
                    itemsRef.current[hovered.index-1].scrollIntoView({block: 'nearest'})
                    setHovered(items[hovered.index-1])
                }
            }
        }
        onKeyUp && onKeyUp(e)
    }

    /** Construye el evento del input-dropdown. */
    const buildEvent = (_value) => ({ target: { name: name||'', value: _value, id: id||'' }, value: _value }) 
    /** Verifica si el menu esta abierto. */
    const menuIsOpen = () => !!menuRef?.current?.classList.contains('show')
    /** Cierra el menu. */
    const closeMenu = () => { menuRef?.current?.classList.remove('show') }
    /** Abre el menu. */
    const openMenu = () => { menuRef?.current?.classList.add('show') }

    /** Filtra los items y actualiza el valor del estado filterValue. */
    const handleChangeInput = (e) => {
        const _value = e.target.value
        setInputValue(_value)
        lazy && onSearch && onSearch(_value)
        handleSelection({option: _value, index: -1, type: 'string', label: _value, value: _value})
        openMenu()
    }
    const handleBlurInput = () => { 
        const timer = setTimeout(() => {
            closeMenu()
            clearTimeout(timer)
        }, 250);
    }
    
    return (
        <div 
            className={classNames([
                'editable-select dropdown', 
                className,
            ])||undefined} 
            style={style}
            onBlur={handleBlur}
        >
            <input
                className={classNames([
                    'dropdown-toggle',
                    defaultClassName||'form-select', 
                    (isInvalid&&'is-invalid'), 
                    (isValid&&'is-valid')
                ])} 
                data-bs-toggle='dropdown'
                value={inputValue}
                onChange={handleChangeInput}
                onBlur={handleBlurInput}
                onKeyUp={handleKeyUp} 
                onKeyDown={handleKeyDown}
                disabled={disabled}
                placeholder={placeholder}
            />
            <ul ref={menuRef} className={classNames(['dropdown-menu'])} >
                {(filteredItems.length>0) 
                    ? filteredItems.map(item => (
                        <DropdownItem 
                            key={item.index}
                            item={item} 
                            itemTemplate={itemTemplate} 
                            selected={selected?.index===item.index} 
                            hovered={hovered?.index===item.index}
                            onClick={() => handleSelection(item)}
                            onMouseEnter={() => setHovered(item)}
                            ref={(el) => {if (el) itemsRef.current[item.index] = el}}
                        />
                    ))
                    : <li className='px-3 fw-normal text-nowrap' key='empty'>
                        Sin resultados.
                    </li>
                }
            </ul>
        </div>
    )
}
export default EditableSelect
/**
 * @param {object} props Propiedades del componente.
 * @param {object} props.item Datos del item.
 * @param {React.ReactNode} props.itemTemplate Plantilla del label del item.
 * @param {boolean} props.selected Especifica si el item esta seleccionado o no.
 * @param {boolean} props.hovered Especifica si el item esta con hover o no.
 * @param {React.MouseEventHandler<HTMLLIElement>} props.onClick Callback que se ejecuta cuando se da clic en el item.
 * @param {React.MouseEventHandler<HTMLLIElement>} props.onMouseEnter Callback que se ejecuta cuando se coloca el mouse sobre en el item.
 */
const DropdownItem = forwardRef(({
    item, itemTemplate, 
    selected, hovered, 
    onClick, onMouseEnter
}, ref) => {
    return (
        <li 
            ref={ref}
            className={classNames(['dropdown-item', (hovered&&'hover'), (selected&&'active')])}
            onMouseEnter={onMouseEnter}
            onClick={onClick}
        >{
            itemTemplate 
                ? (typeof itemTemplate === 'function' ? itemTemplate(item.option) : itemTemplate) 
                : item.label
        }</li>
    )
})