import Button, { ButtonIcon } from 'components/Button'
import DataTable from 'components/DataTable'
import Input from 'components/Input'
import InputCalendar from 'components/InputCalendar'
import InputLayout from 'components/layouts/InputLayout'
import { estados } from 'data/constSolicitud'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { formatDate } from 'utils/utils'
import { buildFilters } from 'utils/utils'
import { verifyAccess } from 'utils/rbac.util'
import CustomInput from 'components/CustomInput'

/** @module Pages/Solicitud/Components/TableSolicitud */

// Valores iniciales del formulario.
const initialValues = {
    identificador: '',
    fecha: '',
    gestion: ''
}

const currentYear = formatDate(new Date(), 'Y')
const currentDate = formatDate(new Date(), 'Y-m-d')

// Opciones de solicitudes de DAF.
const actionsDaf = [
    {
        icon: 'bi-file-text', title: 'Adjuntar memorandum', asLink: true,
        to: (item) => ('../memorandum/create/'+item.id), 
        condition: (item) => (item.estado==='A'&&verifyAccess('memoCreate'))
    },
    {
        icon: 'bi-x-circle', title: 'Denegar', action: 'deny', 
        condition: (item) => (item.estado==='A'&&verifyAccess('solicitudDeny'))
    },
]
// Opciones de solicitudes de Autoridad.
const actionsAutoridad = [
    {
        icon: 'bi-check-circle', title: 'Autorizar', action: 'authorize', 
        condition: (item) => (item.estado==='R'&&verifyAccess('solicitudAuthorize'))
    },
    {
        icon: 'bi-x-circle', title: 'Denegar', action: 'deny', 
        condition: (item) => (item.estado==='R'&&verifyAccess('solicitudDeny'))
    },
]
// Opciones de solicitudes de Beneficiario.
const actionsBeneficiario = [
    {
        icon: 'bi-file-earmark-pdf-fill', title: 'Ver PDf', action: 'view-file', 
        condition: (item) => (item.estado!=='C'&&item.adjunto&&item.propietario&&verifyAccess('solicitudUpload'))
    },
    {
        icon: 'bi-cloud-upload', title: 'Adjuntar Solicitud', action: 'file', 
        condition: (item) => (item.estado!=='C'&&!item.adjunto&&item.propietario&&verifyAccess('solicitudUpload'))
    },
    {
        icon: 'bi-pencil', title: 'Editar', action: 'update', 
        condition: (item) => {
            return item.propietario
                && (
                    item.estado==='D'
                    || (
                        currentDate<=`${formatDate(item.fecha_creacion, 'Y-m-d')} 23:59:59`
                        && item.estado==='A'
                    )
                )
                && verifyAccess('solicitudUpdate')
        }
    },
    {
        icon: 'bi-file-earmark-text-fill', title: 'Ver Informe', action: 'report', 
        condition: (item) => (item.informe&&item.estado==='M'&&item.presupuestado&&item.beneficiario&&verifyAccess('informeCreate'))
    },
    {
        icon: 'bi-file-earmark-plus', title: 'Registrar Informe', action: 'report', 
        condition: (item) => (!item.informe&&item.estado==='M'&&item.presupuestado&&item.beneficiario&&verifyAccess('informeCreate'))
    },
]
// Opciones de solicitudes de Presupuestos.
const actionsPresupuestos = [
    {
        icon: 'bi-cash', 
        title: 'Certificado', 
        action: 'budget', 
        condition: (item) => (['A', 'M'].includes(item.estado)&&!item.presupuestado&&verifyAccess('solicitudBudget'))
    },
    {
        icon: 'bi-cash-stack', 
        title: 'Certificado', 
        action: 'budget', 
        condition: (item) => {
            if (item.presupuestado) {
                const fechaPre = new Date(item.fecha_presupuestado)
                fechaPre.setDate(fechaPre.getDate()+3)
                const fechaRe = new Date(item.fecha_creacion)
                fechaRe.setDate(fechaRe.getDate()+3)
                return ['A', 'M'].includes(item.estado)&&verifyAccess('solicitudBudget')&&(formatDate(fechaPre, 'Y-m-d')>=currentDate||formatDate(fechaRe, 'Y-m-d')>=currentDate)
            } else {
                return false
            }
        }
    },
    {
        icon: 'bi-x-circle', 
        title: 'Denegar', 
        action: 'deny', 
        condition: (item) => (['A', 'M'].includes(item.estado)&&!item.presupuestado&&verifyAccess('solicitudDeny'))
    },
]

/**
 * Componente, Tabla de solicitudes.
 * @param {object} props Propiedades del componente.
 * @param {React.Ref<any>} props.tableRef Referencia de la Tabla.
 * @param {Array<object>} props.solicitudes Solicitudes.
 * @param {object} props.pagination Información de la paginación de las solicitudes.
 * @param {Function} props.handleOpenDialog Handler de acciones de solicitudes.
 * @param {React.ReactNode} props.toolbarOptions Contenido de la barra de herramientas de la tabla.
 * @param {object} props.filterHandlers Handlers de filtros de useHook para el DataTable del componente.
 * @param {Function} props.filterHandlers.handlePage Callback que se ejecuta cuando se cambia el número de página en la tabla.
 * @param {Function} props.filterHandlers.handleSort Callback que se ejecuta cuando se cambia el orden de una columna de la tabla.
 * @param {Function} props.filterHandlers.setFilters Handler de filtros de la tabla.
 * @param {object} props.dataTableProps Propiedades del DataTable del componente.
 * @returns {JSX.Element} Retorna el componente TableSolicitud.
 */
function TableSolicitud({ 
    tableRef, solicitudes, pagination, handleOpenDialog, toolbarOptions,
    filterHandlers: {handlePage, handleSort, setFilters}, dataTableProps
}) {
    const [values, setValues] = useState(initialValues)
    const {sectionKey} = useSelector(state => state.system)

    const handleFilter = (values) => {
        const {identificador, fecha, gestion} = values
        const _values = {
            identificador,
            fecha_creacion: [
                `${formatDate(gestion, 'Y')}-${fecha?formatDate(fecha, 'm-d'):'01-01'} 00:00:00`,
                `${formatDate(gestion, 'Y')}-${fecha?formatDate(fecha, 'm-d'):'12-31'} 23:59:59`
            ]
        }
        const _filters = { filter: buildFilters(_values, {contain: ['identificador'], between: ['fecha_creacion']}) }
        setFilters(filters => {
            if (filters.sort) _filters.sort = filters.sort
            if (filters.pageSize) _filters.pageSize = filters.pageSize
            return _filters
        })
    }
    const handleClean = () => {
        tableRef?.current?.clean()
        setValues(initialValues)
        handleFilter(initialValues)
    }
    const handleInput = (e) => {
        const _values = {...values, [e.target.name]: e.target.value}
        setValues(_values)
    }
    const handleSearch = () => handleFilter(values)
    const searchOnEnter = (e) => {
        if (e.key==='Enter') {
            handleSearch()
        }
    }

    const optionsBodyTemplate = (rowData) => {
        const getOptions = (actions) => {
            return actions.reduce((carry, option, index) => {
                if ((typeof option.condition === 'function' && option.condition(rowData)) || typeof option.condition !== 'function') {
                    if (option.asLink) {
                        carry.push(
                            <Link 
                                className='btn btn-text-secondary btn-icon-sm'
                                title={option.title} key={index} 
                                to={option.to(rowData)}
                            ><i className={option.icon}/></Link>
                        )
                    } else {
                        carry.push(
                            <ButtonIcon 
                                variant='text-secondary' rounded
                                icon={option.icon} title={option.title} key={index} 
                                onClick={() => handleOpenDialog(option.action, rowData)}
                            />
                        )
                    }
                }
                return carry
            }, [])
        }
        const options = []
        if ((sectionKey === 'beneficiario' && rowData.propietario) || sectionKey !== 'beneficiario') {
            options.push(
                <ButtonIcon 
                    variant='text-secondary' rounded
                    icon='bi-eye' title='Información' key='Información'
                    onClick={() => handleOpenDialog('view', rowData)}
                />
            )
        }
        if (sectionKey === 'daf') {
            options.push(...getOptions(actionsDaf))
        } else if (sectionKey === 'autoridad') {
            options.push(...getOptions(actionsAutoridad))
        } else if (sectionKey === 'beneficiario') {
            options.push(...getOptions(actionsBeneficiario))
        } else if (sectionKey === 'presupuestos') {
            options.push(...getOptions(actionsPresupuestos))
        }
        return <div className='table-options'>{options}</div>
    }
    const columns = [
        {
            field: 'numero_solicitud',
            header: 'Nro.',
            body: rowData => `${rowData.numero_solicitud}/${formatDate(rowData.fecha_creacion, 'y')}`,
            bodyClassName: 'text-end',
            sortable: true,
            style: { width: '6rem' },
        },
        {
            field: 'identificador',
            header: 'ID',
            body: rowData => rowData.identificador||'-'
        },
        {
            field: 'objetivo_viaje',
            header: 'Objetivo'
        },
        {
            field: 'fecha_creacion',
            header: 'Fecha',
            body: (rowData) => formatDate(rowData.fecha_creacion, 'd/m/Y'),
            sortable: true,
            style: { width: '8rem' },
        },
        {
            field: 'estado',
            header: 'Estado',
            sortable: true,
            body: (rowData) => <div className='text-center'>{estados[rowData.estado]}</div>,
            style: { width: '10rem' },
        },
        {
            field: 'observaciones',
            header: 'Observaciones',
            body: (rowData) => {
                let observacion = 'Ninguna'
                if (rowData.observaciones) {
                    const lastObservacion = rowData.observaciones.split(';').pop()
                    if (lastObservacion) observacion = lastObservacion
                }
                return <div className='text-truncate'>{observacion}</div>
            },
            style: {width: '12rem', maxWidth: '15rem'},
        },
        {
            field: 'id',
            header: 'Opciones',
            filter: true,
            body: optionsBodyTemplate,
            style: { width: '10rem' },
        }
    ]

    return <>
        <div className='datatable-toolbar row g-2'>
            <div className='col-xl-auto'>
                {toolbarOptions}
            </div>
            <div className='col-xl-auto ms-auto'>
                <div className='row g-2'>
                    <InputLayout label='Solicitud:' inline className='col-md-auto ms-auto'>
                        <Input 
                            name='identificador' 
                            value={values.identificador} 
                            onChange={handleInput}
                            className='solicitud-filtro'
                            placeholder='nro o id'
                            onKeyUp={searchOnEnter}
                        />
                    </InputLayout>
                    <InputLayout label='Fecha:' inline className='col-md-auto'>
                        <InputCalendar 
                            name='fecha' 
                            value={values.fecha} 
                            onChange={handleInput} 
                            formatLabel='d/m'
                            containerClassName='solicitud-filtro'
                            hideYear
                            placeholder='dd/mm'
                        />
                    </InputLayout>
                    <InputLayout label='Gestión' inline className='col-md-auto'>
                        <InputCalendar 
                            name='gestion'
                            containerClassName='solicitud-filtro'
                            value={values.gestion} 
                            onChange={handleInput} 
                            onlyYearPicker formatLabel='Y'
                            customInput={<CustomInput 
                                className='form-control fw-bold' 
                                startIcon={<div className='text-black'>Gestión</div>} 
                                placeholder={currentYear} 
                            />} 
                        />
                    </InputLayout>
                    <div className='col-lg-auto text-end'>
                        <Button startIcon='pi pi-filter-slash' variant='outline-blue' onClick={handleClean} title='Limpiar' className='me-2'/>
                        <Button startIcon='bi-search' onClick={handleSearch} title='Buscar'/>
                    </div>
                </div>
            </div>
        </div>
        <DataTable 
            innerRef={tableRef}
            values={solicitudes} 
            columns={columns}
            onSort={handleSort}
            pagination
            rowsPerPage={pagination.pageSize}
            totalRecords={pagination.totalRecords}
            page={pagination.currentPage}
            onPage={handlePage}
            {...dataTableProps}
        />
    </>
}
export default TableSolicitud