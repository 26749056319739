import Button from 'components/Button'
import DataTable from 'components/DataTable'
import TitlePage from 'components/TitlePage'
import { recursosObj } from 'data/const'
import Loading from 'pages/Loading'
import ReembolsoPDF from 'pdf/reembolso'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ApiService from 'services/api.service'
import { ReembolsoService } from 'services/reembolso.service'
import { formatCurrency, formatDate } from 'utils/utils'

/** @module Pages/Reembolso/ReembolsoDetail */

/**
 * Página de información de un reembolso.
 * @returns {JSX.Element} Retorna el componente ReembolsoDetail.
 */
function ReembolsoDetail() {
    const [reembolso, setReembolso] = useState(null)
    const [loading, setLoading] = useState(true)
    const {id} = useParams() 
    const pdfR = new ReembolsoPDF()

    useEffect(() => {
        const loadData = async () => {
            const response = await ReembolsoService.viewReembolso(id)
            if (response.status === 200) {
                setReembolso(response.data.data)
                setLoading(false)
            }
        }
        if (id) loadData()
    }, [id])

    const generatePdf = async () => {
        if (reembolso) {
            const response = await ApiService.config.search({keys: 'EncargadoCaja'})
            const jefeCaja = response.status===200 ? response.data['EncargadoCaja']?.valor||'' : ''
            pdfR.reembolsoViaticosDetallado({...reembolso, jefe_caja: jefeCaja})
        }
    }

    if (loading) return <Loading/>
    const {
        numero_reembolso, recursos, cheque, fecha_creacion, 
        unidad, papeletas, comprobante,
        total_aereo, total_terrestre, total_devolucion_terrestre, total_pasaje_terrestre,
        total_devolucion_aereo, total_pasaje_aereo,
        total_viaticos, total_devolucion_viaticos, total_monto_viaticos, total_monto
    } = reembolso

    const columns = [
        {
            field: 'fecha_registro',
            header: 'Fecha Emi.',
            alignHeader: 'center',
            body: rowData => <div className='text-center'>{formatDate(rowData.fecha_registro, 'd-M-Y', '')}</div>,
            style: {width: '9rem'},
        },
        {
            field: 'numero_papeleta', 
            header: 'N° Viáti.', 
            alignHeader: 'center',
            bodyClassName: 'text-center', 
            style: {width: '6.5rem'}
        },
        {
            field: 'nombre_beneficiario', 
            header: 'Nombre', 
            alignHeader: 'center',
            body: rowData => rowData.nombre_beneficiario === 'TOTAL' 
                ? <div className='text-center fw-bold'>TOTAL</div> 
                : (rowData.apellido_beneficiario + ' ' + rowData.nombre_beneficiario),
            footer: 'TOTALES',
            footerClassName: 'text-center'
        },
        {field: 'lugar_viaje', header: 'Lugar', alignHeader: 'center', style: {width: '10rem'}},
        {
            field: 'pasaje_aereo', 
            header: 'Pasaje Aéreo', 
            alignHeader: 'center',
            body: rowData => formatCurrency(rowData.pasaje_aereo),
            bodyClassName: 'text-end',
            footer: formatCurrency(total_aereo),
            footerClassName: 'text-end fw-normal',
            style: {width: '8rem'}
        },
        {
            field: 'devolucion_aereo', 
            header: 'Devolución Pas. Aéreo', 
            alignHeader: 'center',
            body: rowData => formatCurrency(rowData.devolucion_aereo),
            bodyClassName: 'text-end', 
            footer: formatCurrency(total_devolucion_aereo),
            footerClassName: 'text-end fw-normal',
            style: {width: '7rem'}
        },
        {
            field: 'pasaje_aereo_devolucion', 
            header: 'P. Aéreo Utilizado', 
            alignHeader: 'center',
            body: rowData => formatCurrency(rowData.pasaje_aereo_devolucion),
            bodyClassName: 'text-end', 
            footer: formatCurrency(total_pasaje_aereo),
            footerClassName: 'text-end',
            style: {width: '7rem'}
        },
        {
            field: 'pasaje_terrestre', 
            header: 'Pasaje Terrestre', 
            alignHeader: 'center',
            body: rowData => formatCurrency(rowData.pasaje_terrestre),
            bodyClassName: 'text-end', 
            footer: formatCurrency(total_terrestre),
            footerClassName: 'text-end fw-normal',
            style: {width: '7rem'}
        },
        {
            field: 'devolucion_terrestre', 
            header: 'Devolución Pas. Terr.', 
            alignHeader: 'center',
            body: rowData => formatCurrency(rowData.devolucion_terrestre),
            bodyClassName: 'text-end', 
            footer: formatCurrency(total_devolucion_terrestre),
            footerClassName: 'text-end fw-normal',
            style: {width: '7rem'}
        },
        {
            field: 'pasaje_terrestre_devolucion', 
            header: 'Pas. Terr. Utilizado', 
            alignHeader: 'center',
            body: rowData => formatCurrency(rowData.pasaje_terrestre_devolucion),
            bodyClassName: 'text-end', 
            footer: formatCurrency(total_pasaje_terrestre),
            footerClassName: 'text-end',
            style: {width: '7rem'}
        },
        {
            field: 'subtotal_viaticos', 
            header: 'Viáticos', 
            alignHeader: 'center',
            body: rowData => formatCurrency(rowData.subtotal_viaticos),
            bodyClassName: 'text-end', 
            footer: formatCurrency(total_viaticos),
            footerClassName: 'text-end fw-normal',
            style: {width: '7rem'}
        },
        {
            field: 'devolucion_viaticos', 
            header: 'Devolución Viáticos', 
            alignHeader: 'center',
            body: rowData => formatCurrency(rowData.devolucion_viaticos),
            bodyClassName: 'text-end', 
            footer: formatCurrency(total_devolucion_viaticos),
            footerClassName: 'text-end fw-normal',
            style: {width: '7rem'}
        },
        {
            field: 'monto_viaticos', 
            header: 'Viáticos Utilizado', 
            alignHeader: 'center',
            body: rowData => formatCurrency(rowData.monto_viaticos),
            bodyClassName: 'text-end', 
            footer: formatCurrency(total_monto_viaticos),
            footerClassName: 'text-end',
            style: {width: '7rem'}
        },
        {
            field: 'monto', 
            header: 'Total', 
            alignHeader: 'center',
            body: rowData => formatCurrency(rowData.monto),
            bodyClassName: 'text-end',
            footer: formatCurrency(total_monto),
            footerClassName: 'text-end',
            style: {width: '7rem'}
        }
    ]

    return <>
        <TitlePage title={`Reembolso Nro. ${numero_reembolso}/${formatDate(fecha_creacion, 'y')}`}/>
        <div className='content'>
            <div className='mb-3 text-end'>
                <Button startIcon='pi pi-file-pdf' onClick={generatePdf}>Generar PDf</Button>
            </div>
            <div className='row g-2 mb-2'>
                <div className='col-sm-6 col-md-4'><span className='fw-bold'>Número de Reembolso:</span> {numero_reembolso}</div>
                <div className='col-sm-6 col-md-4'><span className='fw-bold'>Fecha de registro:</span> {formatDate(fecha_creacion, 'd/m/Y H:i')}</div>
                <div className='col-sm-6 col-md-4'><span className='fw-bold'>Unidad:</span> {unidad}</div>
                <div className='col-sm-6 col-md-4'><span className='fw-bold'>Recursos:</span> {recursosObj[recursos]}</div>
                {
                    cheque ? <>
                        <div className='col-sm-6 col-md-4'><span className='fw-bold'>Cheque:</span> {cheque}</div>
                        <div className='col-sm-6 col-md-4'><span className='fw-bold'>Comprobante:</span> {comprobante}</div>
                    </> : (
                        <div className='col-sm-6 col-md-4'><span className='fw-bold'>Cheque:</span> Sin cheque</div>
                    )
                }
                <div className='col-12'><span className='fw-bold me-2'>Papeletas de viáticos:</span></div>
            </div>
            <DataTable 
                values={papeletas}
                columns={columns}
                tableStyle={{fontSize: '1rem'}}
            />
        </div>
    </>
}
export default ReembolsoDetail