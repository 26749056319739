export const ITEMTOKEN = 'user'
export const CURRENCIES = ['$us', 'Bs']
export const numEscalas = 5
export const initialPagination = {
    previousPage: null,
    currentPage: 1,
    nextPage: null,
    totalPages: 1,
    pageSize: 10,
    totalRecords: 0
}
export const categorias = [
    'Categoría 1',
    'Categoría 2',
    'Categoría 3',
    'Categoría 4',
    'Categoría 5',
    'Categoría 6',
]
export const estadosViaticos = [
    {label: 'Todos', value: ''},
    {label: 'Nuevos', value: 'N'},
    {label: 'Pagados', value: 'P'},
    {label: 'Anulados', value: 'A'},
    {label: 'Revertidos', value: 'R'},
]

export const recursosObj = {
    I: 'Institucionales',
    P: 'Propios',
    M: 'Gremios'
}
export const recursos = [
    {label: recursosObj['I'], value: 'I'},
    {label: recursosObj['P'], value: 'P'},
    {label: recursosObj['M'], value: 'M'},
]
export const recursosFilter = [
    {label: 'Todos', value: ''},
    {label: recursosObj['I'], value: 'I'},
    {label: recursosObj['P'], value: 'P'},
    {label: recursosObj['M'], value: 'M'},
]
export const sections = {
    administracion: 'Administración',
    beneficiario: 'Beneficiario',
    autoridad: 'Autoridad',
    presupuestos: 'Presupuestos',
    tesoreria: 'Tesorería',
    daf: 'DAF',
    caja: 'Caja',
}
export const estadosObj = {
    '': 'Todos',
    N: 'Registrado',
    P: 'Pagado',
    E: 'Espera',
    T: 'Reembolsado',
    R: 'Revertido',
    A: 'Anulado'
}