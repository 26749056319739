import { useEffect, useState } from 'react'
import PapeletaService from 'services/papeleta.service'
import { formatDate } from 'utils/utils'

/** @module Pages/Papeleta/Components/ViewPapeleta */

/**
 * Componente, Información de papeleta.
 * @param {object} props Propiedades del componente.
 * @param {Array} props.id ID de la papeleta.
 * @returns {JSX.Element} Retorna el componente ViewPapeleta.
 */
function ViewPapeleta({id}) {
    const [papeleta, setPapeleta] = useState(null)

    useEffect(() => {
        const loadData = async () => {
            const response = await PapeletaService.viewPapeleta(id)
            if (response.status === 200) setPapeleta(response.data.data)
        }
        if (id) loadData()
    }, [id])

    if (!papeleta) return <div>Papeleta no encontrado.</div>

    return (
        <div>
            <div className='d-flex justify-content-between mb-2 px-1'>
                <div>Papeleta N° {papeleta.numero_papeleta}/{formatDate(papeleta.fecha_registro, 'y')}</div>
                <div>Cochabamba, {formatDate(papeleta.fecha_registro, 'd F Y').replace(/ /g, ' de ')}</div>
            </div>
            <div className='d-flex gap-3 mb-2'>
                <div className='flex-1 d-flex flex-column'>
                    <div className='flex-1 d-flex flex-column p-3 border rounded mb-3 gap-4'>
                        <div>Nombre del Funcionario: {papeleta.beneficiario || papeleta.nombre_beneficiario}  </div>
                        <div>Lugar de Viaje: {papeleta.lugar_viaje.toUpperCase()}</div>
                        <div className='row'>
                            <div className='col-5'>Desde el: {formatDate(papeleta.fecha_salida, 'd-M-y H:i')}</div>
                            <div className='col-5'>Hasta el: {formatDate(papeleta.fecha_llegada, 'd-M-y H:i')}</div>
                            <div className='col-2'>días: {papeleta.dias}</div>
                        </div>
                        <div>Autorizado Mediante: {papeleta.doc_autorizacion.toUpperCase()}</div>
                        <div className='row'>
                            <div className='col-6'>
                                <div className='d-flex'>
                                    <div className='text-nowrap me-2'>Carga Actividad:</div> 
                                    <div>{papeleta.codigo_actividad}</div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='d-flex'>
                                    <div className='text-nowrap me-2'>Cargo Cta: </div>
                                    {papeleta.codigo_cargo_cta} {papeleta.recursos} {papeleta.da_abreviacion_cargo_cta} {papeleta.da_codigo_cargo_cta}
                                </div>
                            </div>
                            <div className='col-6 text-center'>
                                <div>{papeleta.nombre_corto_actividad||papeleta.actividad}</div>
                            </div>
                            <div className='col-6 text-center'>
                                <div>{papeleta.cargo_cta}</div>
                            </div>
                        </div>
                    </div>
                    <div className='border rounded p-3 mb-3'>
                        <div>NOTA: {papeleta.nota}</div>
                    </div>
                    <div className='border rounded p-3'>
                        <div>Glosa: {papeleta.glosa}</div>
                    </div>
                </div>
                <div className='d-flex flex-column' style={{width: '15.5rem'}} >
                    <div className='flex-1 d-flex flex-column p-3 border rounded mb-3'>
                        <div className='mb-1'>Pasajes</div>
                        <div className='d-flex text-end'>
                            <div style={{width: '5.5rem'}}>{papeleta.num_terrestre} 
                                <div className='d-inline-block' style={{width: '3.5rem'}}>Terr.:</div>
                            </div>
                            <div style={{width: '8rem'}}>{papeleta.pasaje_terrestre}</div>
                        </div>
                        <div className='d-flex text-end'>
                            <div style={{width: '5.5rem'}}>{papeleta.num_aereo} 
                                <div className='d-inline-block' style={{width: '3.5rem'}}>Aéreo:</div>
                            </div>
                            <div style={{width: '8rem'}}>{papeleta.pasaje_aereo}</div>
                        </div>
                        <div className='d-flex text-end'>
                            <div style={{width: '5.5rem'}}>Sub Total:</div>
                            <div style={{width: '8rem'}}>{papeleta.subtotal_pasajes}</div>
                        </div>
                        <div className='my-1'>Viáticos</div>
                        <div className='d-flex text-end'>
                            <div style={{width: '5.5rem'}}>{papeleta.dias_viaticos} 
                                <div className='d-inline-block' style={{width: '3.5rem'}}>Viátic.:</div>
                            </div>
                            <div style={{width: '8rem'}}>{papeleta.viaticos}</div>
                        </div>
                        <div className='d-flex text-end'>
                            <div style={{width: '5.5rem'}}>{papeleta.rciva!=='0.00' ? 'S' : 'N'} 
                                <div className='d-inline-block' style={{width: '3.5rem'}}>RCIVA:</div>
                            </div>
                            <div style={{width: '8rem'}}>{papeleta.rciva}</div>
                        </div>
                        <div className='d-flex text-end'>
                            <div style={{width: '5.5rem'}}>Sub Total:</div>
                            <div style={{width: '8rem'}}>{papeleta.subtotal_viaticos}</div>
                        </div>
                        <div className='d-flex text-end mt-2'>
                            <div style={{width: '5.5rem'}}>Total Bs.:</div>
                            <div style={{width: '8rem'}}>{papeleta.total}</div>
                        </div>
                    </div>
                    <div className='d-flex flex-column p-3 border rounded'>
                        <div className='mb-1'>Devoluciones</div>
                        <div className='d-flex text-end'>
                            <div style={{width: '5.5rem'}}>
                                <div className='d-inline-block'>Pas. Terr.:</div>
                            </div>
                            <div style={{width: '8rem'}}>{papeleta.devolucion_terrestre}</div>
                        </div>
                        <div className='d-flex text-end'>
                            <div style={{width: '5.5rem'}}>
                                <div className='d-inline-block'>Pas. Aéreo:</div>
                            </div>
                            <div style={{width: '8rem'}}>{papeleta.devolucion_aereo}</div>
                        </div>
                        <div className='d-flex text-end'>
                            <div style={{width: '5.5rem'}}>
                                <div className='d-inline-block'>Viáticos:</div>
                            </div>
                            <div style={{width: '8rem'}}>{papeleta.devolucion_viaticos}</div>
                        </div>
                        <div className='d-flex text-end mt-2'>
                            <div style={{width: '5.5rem'}}>Total Bs.:</div>
                            <div style={{width: '8rem'}}>{papeleta.monto}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ViewPapeleta