import { GenericRequest, SignalRequest } from './request'

const SolicitudService = {

    searchBeneficiario: (params) => GenericRequest.get('solicitud/search-beneficiario', params),
    getLugaresViaje: (params) => SignalRequest.get('solicitud/get-lugares-viaje', params),
    /**
     * Obtiene información de la solicitud para crear el memo
     * @param {int} id ID de la solicitud
     * @param {object} data body data
     * @returns {object} {success: true, solicitud: {object}} o {success: false, message: 'string'}
     */
    getSolicitudMemo: (id, data) => GenericRequest.post('solicitud/get-solicitud-memo', data, {id}),
    getSolicitudInforme: (id) => GenericRequest.get('solicitud/get-solicitud-informe', {id}),
    getSolicitudToUpdate: (id) => GenericRequest.get('solicitud/get-solicitud-to-update', {id}),

    createSolicitud: (data) => GenericRequest.post('solicitud/create', data),
    updateSolicitud: (id, data) => GenericRequest.post('solicitud/update', data, {id}),

    // getSolicitudes: (params) => GenericRequest.get('solicitud/get-solicitudes', params),
    indexSolicitud: (params) => GenericRequest.get('solicitud', params),
    indexSolicitudPost: (data, params) => GenericRequest.post('solicitud', data, params),
    viewSolicitud: (params) => GenericRequest.get('solicitud/view', params),

    getBeneficiarios: (id) => GenericRequest.get('solicitud/get-beneficiarios', {id}),

    denegar: (data, id) => GenericRequest.post('solicitud/denegar', data, {id}),
    autorizar: (id) => GenericRequest.get('solicitud/autorizar', {id}),
    verificar: (id) => GenericRequest.get('solicitud/verificar', {id}),
    anular: (id) => GenericRequest.get('solicitud/anular', {id}),

    addPresupuesto: (data, params) => GenericRequest.post('solicitud/add-presupuesto', data, params),
    getDestinatarios: () => GenericRequest.get('solicitud/get-verificadores'),
    uploadSolicitud: (data, id) => GenericRequest.post('solicitud/upload-solicitud', data, {id})
}

export default SolicitudService