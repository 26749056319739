/** @module Utils/formatNumber */

/**
 * Formatea un valor con el formato especificado.
 * @param {number | string} value Valor a formatear.
 * @param {Intl.NumberFormatOptions} format Opciones de formato para el valor.
 * @returns {string} Valor formateado.
 */
export function formatNumber(value, format={}) {
    const {locale, ...options} = format
    return new Intl.NumberFormat(locale||'en-US', options||{}).format(value)
}

/**
 * Formatea un valor a formato de numero con 2 decimales, coma de mil y punto decimal 
 * @param {number | string} value Valor a formatear.
 * @returns {string} Valor formateado.
 */
export function formatCurrency(value) {
    return new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(value)
}