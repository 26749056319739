import store from 'store'

export default function getFooter({currentPage, pageCount}, {margins}) {
    const {userInfo} = store.getState().auth
    return [
        { 
            text: ['Procesado por: ', userInfo.username], 
            style: ['text-end', 'm-right-page'], 
            margin: [margins.marginLeft, 0, margins.marginRight, 0] 
        }
    ]
}