import { useEffect, useState } from 'react'
import { Form, Formik, useFormikContext } from 'formik'
import { CheckboxFormik, InputFormik, SelectFormik } from 'components/formik/formikFormComponents'
import ApiService from 'services/api.service'
import { unidadValidators } from 'utils/validators/validators'
import InputLayout from 'components/layouts/InputLayout'
import Input from 'components/Input'

/** @module Pages/Unidad/Components/FormUnidad */

/**
 * Componente, Formulario de registro/edición de Unidad.
 * @param {object} props Propiedades del componente.
 * @param {React.MutableRefObject<any>} props.formRef Referencia del formulario.
 * @param {Function} props.onSubmit Callback a ejecutar cuando se de submit del formulario.
 * @param {object} props.selectedUdEjecutora Unidad seleccionada.
 * @param {"admin" | "viaticos"} props.type Especifica la sección de la que se mostrarán las opciones.
 * @returns {JSX.Element} Retorna el componente FormUnidad.
 */
function FormUnidad({formRef, onSubmit, selectedUnidad, type}) {
    const [dirAdtvas, setDirAdtvas] = useState([])
    const [udEjecutoras, setUdEjecutoras] = useState([])
    const initialValues = selectedUnidad ? selectedUnidad : { 
        programa: '', 
        proyecto: '', 
        actividad: '', 
        descripcion: '', 
        nombre_corto: '',
        ue_id: '',
        da_id: '',
        activo: true
    }

    useEffect(()=>{
        const loadDirAdtvas = async () => {
            const response = await ApiService.dirAdtvas.getAll()
            if (response.status === 200) setDirAdtvas(response.data.data)
        }
        loadDirAdtvas()
    }, [])

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={unidadValidators}
            onSubmit={onSubmit}
        >{() => (
            <Form>
                {type === 'admin' && <>
                    <div className='row'>
                        <SelectFormik 
                            name='da_id' 
                            label='Dirección Administrativa (DA)'
                            options={dirAdtvas}
                            optionLabel='descripcion'
                            optionValue='id'
                            containerClassName='col-12'
                            itemTemplate={item=>`${item.codigo} ${item.descripcion}`}
                            valueTemplate={value=>`${value.codigo} ${value.descripcion}`}
                        />
                        <SelectFormik 
                            name='ue_id' 
                            label='Unidad Ejecutora (UE)'
                            options={udEjecutoras}
                            optionLabel='descripcion'
                            optionValue='id'
                            containerClassName='col-12'
                            itemTemplate={item=>`${item.codigo} ${item.descripcion}`}
                            valueTemplate={value=>`${value.codigo} ${value.descripcion}`}
                        />
                        <InputFormik name='programa' label='Programa' type='number' containerClassName='col-md-3' />
                        <InputFormik name='proyecto' label='Proyecto' type='number' containerClassName='col-md-6' />
                        <InputFormik name='actividad' label='Actividad' type='number' containerClassName='col-md-3' />
                        <InputFormik name='descripcion' label='Nombre' containerClassName='col-12' />
                        <InputFormik 
                            name='nombre_corto' 
                            label='Nombre Corto' 
                            containerClassName='col-12' 
                            info='Utilizado en el pdf de las Papeletas de Pago de Pasajes y/o Viáticos.'
                        />
                        {/* <InputFormik name='finfun' label='Fin.Fun.' containerClassName='col-md-6' /> */}
                        <CheckboxFormik name='activo' containerClassName='col-12 mb-3 align-self-end' style={{fontSize: '1.25rem'}} />
                    </div>
                    <ObserverFormik setUdEjecutoras={setUdEjecutoras} />
                </>}
                {type === 'viaticos' && <>
                    <InputLayout label='Descripción'>
                        <Input value={selectedUnidad.descripcion} readOnly />
                    </InputLayout>
                    <InputFormik 
                        name='nombre_corto' 
                        label='Nombre Corto' 
                        info='Utilizado en el pdf de las Papeletas de Pago de Pasajes y/o Viáticos.'
                    />
                </>}
            </Form>
        )}</Formik>
    )
}
export default FormUnidad

const ObserverFormik = ({setUdEjecutoras}) => {
    const { values } = useFormikContext();
 
    useEffect(() => {
        const loadDirAdtvas = async (id) => {
            const response = await ApiService.udEjecutora.getAll(id)
            if (response.status === 200) setUdEjecutoras(response.data.data)
        }
        if(values.da_id) loadDirAdtvas(values.da_id)
    }, [values.da_id]) 
 
    return null
 
}