import { formatDate } from 'utils/utils'

export default function getHeader({currentPage, pageCount, pageSize}, {seccion, title, filters=null, margins}) {
    const currentDate = new Date();
    return [
        {columns: [
            { text: [
                'UNIVERSIDAD MAYOR DE SAN SIMON', '\n',
                seccion, '\n',
                'Cochabamba - Bolivia',
            ], style: ['text-center'], width: 'auto' },
            { text: '', width: '*'},
            { text: [
                'SISTEMA DE VIÁTICOS', '\n',
                'FECHA: ', formatDate(currentDate, 'd-M-Y'), ' ', formatDate(currentDate, 'H:i:s'), '\n',
                'Página ', currentPage, '/', pageCount,
            ], style: ['text-center'], width: 'auto' },
        ], margin: [margins.marginLeft, margins.marginTop, margins.marginRight, 0]},
        { text: title, style: ['title'], marginBottom: 10 },
        ...(filters||[])
    ]
}