import { useState } from 'react'
import { buildFilters } from 'utils/utils'

/** @module Hooks/useFilters */

/**
 * Maneja el estado de filtros (DataFilter de Yii) para del componente DataTable,
 * estructura filtros: {filter: {}, sort: 'string', page: number, pageSize: number}
 * @param {object} config 
 * @param {object|null} config.initialFilters filtros iniciales
 * @param {object} config.filterOptions Opciones para construir los filtros
 * @param {boolean} config.filterOptions.empty true construye los filtros con valores vacíos, default false
 * @param {array} config.filterOptions.equal array de propiedades del objeto data a filtrar con el operador "="
 * @param {array} config.filterOptions.contain array de propiedades del objeto data a filtrar con el operador "ilike"
 * @param {array} config.filterOptions.gt array de propiedades del objeto data a filtrar con el operador ">"
 * @param {array} config.filterOptions.lt array de propiedades del objeto data a filtrar con el operador "<"
 * @param {array} config.filterOptions.in array de propiedades del objeto data a filtrar con el operador "in"
 * @param {array} config.filterOptions.is array de propiedades del objeto data a filtrar con el operador "is"
 * @param {array} config.filterOptions.nin array de propiedades del objeto data a filtrar con el operador "not in"
 * @param {array} config.filterOptions.between array de propiedades del objeto data a filtrar con el operador ">" y "<"
 * @param {array} config.filterOptions.null array de propiedades del objeto data a filtrar, true si es "NULL", false si no es "NULL"
 * @param {array} config.filterOptions.bool array de propiedades del objeto data a filtrar solo por un valor booleano true o false
 * @returns {object} Retorna el estado de filtros y sus controladores del estado
 */
function useFilters({initialFilters={}, filterOptions={}}={}) {
    const [filters, setFilters] = useState(initialFilters)

    const handleFilter = (filterValues, clear) => {
        const values = {...filterValues}
        const _filters = {filter: buildFilters(values, filterOptions)}
        if (filters && !clear) {
            if (filters.sort) _filters.sort = filters.sort
            if (filters.pageSize) _filters.pageSize = filters.pageSize
        }
        setFilters(_filters)
    }
    const handleSort = (field, order, clear) => {
        if (!clear) {
            const {sort, ..._filters} = filters||{}
            const _sort = order === 1 
                ? { sort: field } 
                : ( order === 0 
                        ? { sort: `-${field}` } 
                        : {} 
                )
            setFilters({ ..._filters, ..._sort})
        }
    }
    const handlePage = (page, pageSize) => setFilters({ ...(filters||{}), ...(pageSize&&{pageSize}), page })
    const rebootFilters = () => setFilters({...initialFilters})
    const clearFilters = () => setFilters({})
    const reloadFilters = () => setFilters({...(filters||{})})

    return {filters, setFilters, handleFilter, handleSort, handlePage, rebootFilters, clearFilters, reloadFilters}
}

export default useFilters