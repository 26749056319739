import { classNames } from 'utils/utils'
import { forwardRef, useMemo } from 'react'
import { Form } from 'react-bootstrap'

/** @module Components/Input */

/**
 * @typedef {object} InputPropsType 
 * @property {"text" | "password" | "file" | "number" | "decimal" | "currency" | undefined} type Tipo del input.
 * @property {boolean | undefined} readOnly Especifica si el input sera solo de lectura.
 * @property {number | undefined} maxFractionDigits Cantidad maxima de dígitos decimales, solo disponible en input de tipo decimal.
 * @typedef {InputPropsType & React.InputHTMLAttributes<HTMLInputElement>} InputProps
*/

/**
 * Componente input, construido en base al Form.Control de react-bootstrap
 * @method
 * @param {InputProps} props Propiedades del componente. InputPropsType + React.InputHTMLAttributes<HTMLInputElement>.
 * @param {React.Ref<HTMLInputElement>} ref 
 * @returns {JSX.Element} Retorna el componente Input.
 */
const Input = forwardRef(({readOnly, type='text', maxFractionDigits, ...props}, ref) => {
    const [typeInput, isNumeric, isDecimal] = useMemo(() => {
        const _isDecimal = ['decimal', 'currency'].includes(type)
        const _isNumeric = _isDecimal || type === 'number'
        return [_isNumeric?'text':type, _isNumeric, _isDecimal]
    }, [type])
    
    const handleOnKeyDown = (e) => {
        const regex = isDecimal ? /[0-9,.]/ : /[0-9]/
        // Si ctrl no esta presionado y la tecla es un carácter.
        if (!e.ctrlKey && e.key.length===1) {
            // Si no pasa el regex o si es decimal, la tecla es punto o coma y el valor actual del input ya tiene un punto.
            if (!regex.test(e.key) || (isDecimal && ['.', ','].includes(e.key) && e.currentTarget.value.includes('.'))) {
                e.preventDefault()
            }
        }
        typeof props.onKeyDown === 'function' && props.onKeyDown(e)
    }
    const handleOnChange = (e) => {
        const regex = type === 'decimal' 
            ? new RegExp(`^\\d*([.]{0,1}[0-9]{0,${maxFractionDigits||''}})?$`) 
            : (type === 'currency' 
                ? /^\d*([.]{0,1}\d{0,2})?$/ 
                : /^\d+$/
            )
        let _value = e.target.value
        if (_value.length > 0 && !regex.test(_value)) {
            // Si NO es valido.
            const regexReplace = isDecimal ? /[^0-9,.]/ : /[^0-9]/
            // limpiar caracteres no numéricos y/o diferentes de punto o coma.
            _value = _value.replace(regexReplace, '')
            // convertir comas en puntos.
            if (isDecimal) _value = _value.replace(/,/g, '.')
            _value = _value.split('').reduce((carry, item) => regex.test(carry+item) ? carry+item : carry, '')
            e.target.value = _value
        }
        typeof props.onChange === 'function' && props.onChange(e)
    }

    return (
        readOnly 
            ? <div 
                className={classNames([
                    'form-control', 
                    props.className, 
                    (props.isInvalid && 'is-invalid')
                ])}
                style={{minHeight: '2.25rem', ...props.style}}
            >{props.value}</div> 
            : <Form.Control 
                ref={ref} 
                {...props} 
                {...(isNumeric && { onChange: handleOnChange, onKeyDown: handleOnKeyDown })}
                type={typeInput}
            />
    )
})

export default Input
