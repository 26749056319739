import { forwardRef } from 'react'
import { classNames } from 'utils/utils'

const Radio = forwardRef(({onChange, value, ...props}, ref) => {
    if (typeof onChange === 'function') props.onChange = (e) => {
        onChange({
            value: e.target.checked, 
            target: {id: e.target.id, name: e.target.name, value: e.target.checked},
            originalEvent: e
        })
    }
    props.className = classNames(['form-check-input', props.className])

    return <input ref={ref} checked={value} {...props} type='radio' />
})

export default Radio