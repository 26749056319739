import { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'

import { CalendarFormik, InputFormik } from 'components/formik/formikFormComponents'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import Button from 'components/Button'
import SearchBeneficiario from 'components/SearchBeneficiario'
import { informeValidators } from 'utils/validators/validators'

/** @module Pages/Solicitud/Components/FormInforme */

/**
 * Componente, Formulario de informe de viaje.
 * @param {object} props Propiedades del componente.
 * @param {React.MutableRefObject<any>} props.formRef Referencia del formulario.
 * @param {Function} props.onSubmit Callback a ejecutar cuando se de submit del formulario.
 * @param {object} props.solicitud Solicitud seleccionada. 
 * @returns {JSX.Element} Retorna el componente FormInforme.
 */
function FormInforme({ formRef, onSubmit, solicitud }) {
    const [beneficiario, setBeneficiario] = useState(null)
    const [showSearcher, setShowSearcher] = useState(false)
    const [showPopover, setShowPopover] = useState(true)
    const initialValues = {
        lugar_emision: 'Cochabamba',
        fecha_salida: '',
        fecha_llegada: '',
        fecha_registro: new Date(),
        lugar_viaje: '',
        objetivo_viaje: '',
        antecedentes: '',
        desarrollo: '',
        conclusiones: '',
        adjuntos: '',
        jefe: '',
        nombre_beneficiario: '',
        solicitud_beneficiario_id: ''
    }

    useEffect(() => {
        const {beneficiario: {nombre_beneficiario, apellido_beneficiario, ...beneficiario}, objetivo_viaje, informe} = solicitud
        if (informe) {
            formRef?.current?.resetForm({
                values: {
                    ...initialValues,
                    ...informe,
                    informe_id: informe.id,
                    objetivo_viaje,
                    nombre_beneficiario: nombre_beneficiario+' '+apellido_beneficiario,
                }
            })
        } else {
            formRef?.current?.resetForm({
                values: {
                    ...initialValues,
                    ...beneficiario,
                    objetivo_viaje,
                    nombre_beneficiario: nombre_beneficiario+' '+apellido_beneficiario,
                }
            })
        }
    }, [solicitud]) 

    const popover = (
        <Popover style={!showPopover ? {display: 'none'} : {}}>
            <Popover.Header as='h3'>Buscar Beneficiario</Popover.Header>
            <Popover.Body>
                <SearchBeneficiario 
                    placeholder='Documento' showLabel={false}
                    onSearch={() => setShowPopover(false)}
                    onSelect={selection => {
                        setBeneficiario({...selection, nombre_beneficiario: selection.nombre_beneficiario+' '+selection.apellido_beneficiario, id: selection.beneficiario_id})
                    }}
                    onHide={() => setShowSearcher(false)} 
                />
            </Popover.Body>
        </Popover>
    )

    return (
        <div>
            <Formik
                innerRef={formRef}
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={informeValidators}
            >
                {() => (
                    <Form>
                        <div className='d-flex flex-column gap-3 mb-3'>
                            <div className='row g-3'>
                                <InputFormik name='lugar_emision' label='Lugar de Emisión:' inline containerClassName='col-sm-6' />
                                <CalendarFormik name='fecha_registro' label='Fecha:' inline containerClassName='col-sm-6'/>
                            </div>
                            <div className='d-flex'>
                                <div style={{flex: 1}}>
                                    <InputFormik 
                                        name='nombre_beneficiario' inline label='Nombres y Apellidos:'
                                        disabled showError={false}
                                    />
                                </div>
                                {!solicitud &&
                                    <OverlayTrigger show={showSearcher} placement='left' overlay={popover}>
                                        <Button startIcon='pi pi-search' variant='text-secondary' className='ms-2' onClick={() => setShowSearcher(!showSearcher)} />
                                    </OverlayTrigger>
                                }
                            </div>
                            <div className='row g-3'>
                                <CalendarFormik name='fecha_salida' label={<span className='d-block' style={{width: '6.5rem'}}>Fecha salida:</span>} inline containerClassName='col-sm-6'/>
                                <CalendarFormik name='fecha_llegada' label={<span className='d-block' style={{width: '6.5rem'}}>Fecha llegada:</span>} inline containerClassName='col-sm-6'/>
                            </div>
                            <InputFormik name='lugar_viaje' label={<span className='d-block' style={{width: '6.5rem'}}>Lugar de viaje:</span>} inline/>
                        </div>
                        
                        <InputFormik 
                            name='antecedentes' as='textarea' style={{ resize: 'none' }}
                            label='Antecedentes'
                        />
                        <InputFormik 
                            name='objetivo_viaje' as='textarea' style={{ resize: 'none' }}
                            label='Objetivo de viaje:'
                        />
                        <InputFormik 
                            name='desarrollo' as='textarea' style={{ resize: 'none' }}
                            label='Desarrollo'
                        />
                        <InputFormik 
                            name='conclusiones' as='textarea' style={{ resize: 'none' }}
                            label='Conclusiones y resultados u objetivos específicos alcanzados'
                        />
                        <InputFormik 
                            name='adjuntos' as='textarea' style={{ resize: 'none' }}
                            label='Documentos que adjunta'
                        />
                        <InputFormik name='jefe' label='Jefe Inmediato:' inline />
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default FormInforme