import ScrollUp from "components/ScrollUp";
import TitlePage from "components/TitlePage";
import reglamento from "data/reglamentoViaticos";

export default function ReglamentoViaticos() {
    return <>
        <TitlePage>Reglamento de Viáticos</TitlePage>
        <div className='content align-items-center'>
            <div className="w-xl-50" style={{whiteSpace: 'pre-line'}}>
                <div className="text-center fs-3 mb-5">
                    {reglamento.titulo}
                    <a href={`${reglamento.url||'#'}`} style={{fontSize: '1.2rem'}} title="Abrir enlace" rel="noreferrer" target="_blank">
                        <span className="ms-2 text-primary bi-box-arrow-up-right"></span>
                    </a>
                </div>
                {reglamento.capitulos.map((capitulo, index) => (
                    <div key={index} className="mb-4">
                        <div className="text-center fw-bold mb-3">
                            <div id={`capitulo-${capitulo.numero}`}>CAPITULO {capitulo.numero}</div>
                            <div>{capitulo.titulo.toUpperCase()}</div>
                        </div>
                        <div>
                            {capitulo.articulos.map((articulo, i) => (
                                <div key={i} className="mb-3">
                                    <div id={`articulo-${articulo.numero}`} className="fw-bold">Articulo {articulo.numero}. ({articulo.titulo})</div>
                                    <div className="text-justify">{articulo.descripcion}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <ScrollUp id='main-container'/>
    </>
}