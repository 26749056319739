import { Form as FormFormik, Formik } from 'formik'

import { InputFormik } from 'components/formik/formikFormComponents'
import { destinoValidators } from 'utils/validators/validators'
import { numEscalas } from 'data/const'

/** @module Pages/EscalaViatico/Components/FormDestino */

/**
 * Componente, Formulario de registro/edición de destino.
 * @param {object} props Propiedades del componente.
 * @param {React.MutableRefObject<any>} props.formRef Referencia del formulario.
 * @param {Function} props.onSubmit Callback a ejecutar cuando se de submit del formulario.
 * @param {object} props.selectedDestino Destino seleccionado.
 * @returns {JSX.Element} Retorna el componente FormDestino.
 */
function FormDestino({formRef, onSubmit, selectedDestino}) {
    const initialValues = selectedDestino ? selectedDestino : { 
        lugar: '', 
        descripcion: '', 
        escalas: (new Array(numEscalas)).fill(1).map((_, index) => ({categoria: `cat${index}`, monto: '', moneda: 'Bs'}))
    }

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={destinoValidators}
            onSubmit={onSubmit}
        >
            {() => (
                <FormFormik>
                    <div>
                        <InputFormik name='lugar' />
                        <InputFormik name='descripcion' label='Descripción' />
                    </div>
                </FormFormik>
            )}
        </Formik>
    )
}

export default FormDestino