export const requiredObject = {
    test: (value) => typeof value === 'object' ? !!value.id : false,
    message: 'Requerido',
}

export const messagesValidators = {
    min: (num, type='caracteres') => {
        return `Debe tener por lo menos ${num} ${type}.`
    },
    max: (num, type='caracteres') => {
        return `Debe tener ${num} ${type} o menos.`
    },
    length: (num, type='caracteres') => {
        return `Debe tener ${num} ${type}.`
    },
    matchDecimal: (decimals) => {
        return `Debe ser un número con ${decimals} decimal${decimals>1?'es':''}.`
    },
    onlyCharacters: 'Solo se permiten caracteres.',
    onlyNumbers: 'Solo se permiten números.',
    required: 'Requerido.',
}