import Button from 'components/Button'
import CustomInput from 'components/CustomInput'
import Dialog from 'components/Dialog'
import Input from 'components/Input'
import Radio from 'components/Radio'
import InputLayout from 'components/layouts/InputLayout'
import { useEffect, useState } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import ApiService from 'services/api.service'

/** @module Pages/Papeleta/Components/SelectEscalaViaticos */

/**
 * Componente, Selección de Escala de Viáticos.
 * @param {object} props Propiedades del componente.
 * @param {object} props.beneficiario Información del beneficiario seleccionado.
 * @returns {JSX.Element} Retorna el componente SelectEscalaViaticos.
 */
function SelectEscalaViaticos({ show, onHide, setValues, beneficiario}) {
    const [categorias, setCategorias] = useState([])
    const [destinos, setDestinos] = useState([])
    const [tipoCambio, setTipoCambio] = useState({})
    const [escala, setEscala] = useState(null)
    let init = false

    useEffect(() => {
        const loadData = async () => {
            const response = await ApiService.getTableEscalas()
            if (response.status === 200) {
                const _d = response.data.destinos
                const _c = response.data.categorias
                const _tc = response.data.tipoCambio
                const numCat = getNumCat(_d)
                const _destinos = _d.map(d => ({...d, escalas: normalizeEscalas(d.escalas, numCat)}))
                const _categorias = normalizeCategorias(_c, numCat)
                setDestinos(_destinos)
                setCategorias(_categorias)
                setTipoCambio(_tc)
            }
        }
        if (!init) {
            init = true
            loadData()
        }
    }, [])

    const normalizeCategorias = (categs, numCat) => {
        const _categorias = {}
        for (let i = 1; i <= numCat; i++) {
            _categorias['cat'+i] = {codigo: 'cat'+i, descripcion: 'Categoría '+i, descripcion_cargos: '', cargos: []}
        }
        const cats = ['cat1', 'cat2', 'cat3', 'cat4', 'cat5'].slice(0, numCat)
        categs.forEach(cat => {
            if (cats.includes(cat.codigo)) {
                _categorias[cat.codigo] = cat
            }
        })
        return Object.values(_categorias)
    }
    const getNumCat = (destinos) => {
        let numCat = 0
        destinos.forEach(d => {
            d.escalas.forEach(e => {
                if (e.categoria==='cat5') {
                    if (numCat<5) numCat = 5
                } else if (e.categoria==='cat4') {
                    if (numCat<4) numCat = 4
                } else if (e.categoria==='cat3') {
                    if (numCat<3) numCat = 3
                } else if (e.categoria==='cat2') {
                    if (numCat<2) numCat = 2
                } else if (e.categoria==='cat1') {
                    if (numCat<1) numCat = 1
                }
            })
        })
        return numCat
    }
    const normalizeEscalas = (escalas, numCat) => {
        const _escalas = {}
        for (let i = 1; i <= numCat; i++) {
            _escalas['cat'+i] = {categoria: 'cat'+i, monto: '0.00', moneda: 'Bs'}
        }
        const cats = ['cat1', 'cat2', 'cat3', 'cat4', 'cat5'].slice(0, numCat)
        escalas.forEach(e => {
            if (cats.includes(e.categoria)) {
                _escalas[e.categoria] = e
            }
        })
        return Object.values(_escalas)
    }
    
    const replaceEscala = (_escala) => {
        const montoBs = (_escala.monto||0)*(tipoCambio[_escala.moneda]||1)
        setEscala({
            id: _escala.id,
            monto: _escala.monto,
            moneda: _escala.moneda,
            montoBs: montoBs?montoBs.toFixed(2):'',
            resolucion: _escala.resolucion||''
        })
    }
    const handleChange = (e) => {
        if (!escala || escala?.id) {
            replaceEscala({...{id: '', monto: '', moneda: 'Bs', resolucion: ''}, [e.target.name]: e.target.value})
        } else {
            replaceEscala({...escala, [e.target.name]: e.target.value})
        }
    }
    const handleSelectEscala = () => {
        if (escala) {
            setValues(values => ({
                ...values, 
                viaticos: escala.montoBs,
                glosa: escala.resolucion,
                resolucion_viaticos: escala.resolucion
            }), false)
        }
        onHide()
    }

    const popoverCargos = (descripcion_cargos) => {
        return (
            <Popover id='popover-basic'>
                <Popover.Header as='h3'>Cargos</Popover.Header>
                <Popover.Body className='py-2'>
                    {descripcion_cargos}
                </Popover.Body>
            </Popover>
        )
    }
    const buildDestinos = (destino, index) => {
        const _destino = <th>{destino.lugar}</th>
        const escalas = destino.escalas.map((e, i) => (
            <td className='border-start px-3' key={i}>
                <label className='d-flex align-items-center justify-content-end'>
                    <span className='me-1'>{e.monto||'0.00'}</span>
                    <span className='d-inline-block text-start' style={e.moneda ? {width: '2rem'} : {}}>
                        {(e.moneda||'')}
                    </span>
                    <Radio
                        style={{fontSize: '1.15rem'}}
                        value={escala?.id===e.id}
                        onChange={() => replaceEscala(e)}
                    />
                </label>
            </td>
        ))
        return <tr key={index}>{_destino}{escalas}</tr>
    }
    const buildCategorias = (cat, index) => {
        const check = beneficiario && cat.cargos.includes(beneficiario.cargo)
        return (
            <th 
                key={index} 
                className={'border-start text-center'+(check ? ' text-primary' : '')}
            >
                <span className='d-inline-block me-2'>{cat.descripcion}</span>
                <OverlayTrigger placement='right' overlay={popoverCargos(cat.descripcion_cargos)}>
                    <span className='cursor-pointer'>
                        <i className={'bi-info-circle'+(!check ? ' text-success' : '')}></i>
                    </span>
                </OverlayTrigger>
            </th>
        )
    }

    const footerDialog = <>
        <Button variant='outline-blue' startIcon='pi pi-times' onClick={onHide}>Cerrar</Button>
        <Button startIcon='pi pi-check' onClick={handleSelectEscala}>Seleccionar</Button>
    </>

    return (
        <Dialog
            show={show}   
            header='Escalas de viáticos' 
            keyboard={false}
            style={{width: '70rem'}}
            footer={footerDialog}
            onHide={onHide}
        >
            <div>
                {beneficiario && <div className='row mb-2'>
                    <div className='col-md-6'><span className='fw-bold'>Beneficiario:</span> {beneficiario.nombre_beneficiario}</div>    
                    <div className='col-md-6'><span className='fw-bold'>Cargo:</span> {beneficiario.funcion||beneficiario.cargo}</div>    
                </div>}
                <div className='me-2'>
                    <span className='fw-bold'>Tipo de Cambio: </span> 
                    {Object.keys(tipoCambio).map((item, index) => <span key={index} className='me-2'>{`1 ${item} = ${tipoCambio[item]} Bs`}</span>)}
                </div>
                <hr/>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>
                                <div className='lh-1 text-end'>Categorías</div>
                                <div className='border-top' style={{transform: 'rotate(5deg)'}}></div>
                                <div className='lh-1'>Destinos</div>
                            </th>
                            {categorias.map(buildCategorias)}
                        </tr>
                    </thead>
                    <tbody>
                        {destinos.map(buildDestinos)}
                    </tbody>
                </table>
                <div className='d-flex gap-3'>
                    <InputLayout label='Otro monto:' inline className='w-50'>
                        <CustomInput 
                            name='monto'
                            value={escala?.id?'':(escala?.monto||'')} 
                            onChange={handleChange}
                            type='currency' 
                            endIcon='Bs' 
                            className='form-control text-end'
                        />
                    </InputLayout>
                    <InputLayout label='Documento:' inline className='w-50'>
                        <Input
                            name='resolucion'
                            value={escala?.id?'':(escala?.resolucion||'')}
                            onChange={handleChange}
                        />
                    </InputLayout>
                </div>
            </div>
        </Dialog>
    )
}
export default SelectEscalaViaticos