import Table from 'react-bootstrap/Table'

function ViewUdEjecutora({selectedUnidad}) {
    const { 
        da, ue, programa, proyecto, actividad, descripcion, codigo_da, codigo_ue,
        nombre_corto, finfun,
    } = selectedUnidad

    return (
        <Table striped bordered hover>
            <tbody>
                <tr>
                    <th>Dirección Administrativa</th>
                    <td>{codigo_da} {da}</td>
                </tr>
                <tr>
                    <th>Unidad Ejecutora</th>
                    <td>{codigo_ue} {ue}</td>
                </tr>
                <tr>
                    <th>Programa</th>
                    <td>{programa}</td>
                </tr>
                <tr>
                    <th>Proyecto</th>
                    <td>{proyecto}</td>
                </tr>
                <tr>
                    <th>Actividad</th>
                    <td>{actividad}</td>
                </tr>
                <tr>
                    <th>Unidad</th>
                    <td>{descripcion}</td>
                </tr>
                <tr>
                    <th>Nombre Corto</th>
                    <td>{nombre_corto||'-'}</td>
                </tr>
                <tr>
                    <th>Fin.Fun.</th>
                    <td>{finfun}</td>
                </tr>
            </tbody>
        </Table>
    )
}

export default ViewUdEjecutora