export default function Loading() {
    return (
        <div className='flex-1 d-flex justify-content-center align-items-center'>
            <div 
                className='spinner-border text-primary' 
                role='status' 
                style={{width: '3.5rem', height: '3.5rem', borderWidth: '6px'}}
            >
                <span className='visually-hidden'>Loading...</span>
            </div>
        </div>
    )
}