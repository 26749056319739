
import { Editor, EditorState, convertFromRaw, convertToRaw, RichUtils } from 'draft-js'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import 'draft-js/dist/Draft.css'
import { classNames } from 'utils/utils'

/** @module Components/DraftEditor */

/**
 * Componente DraftEditor, construido en base a Editor de draft-js.
 * @param {object} props Propiedades del componente.
 * @param {object | undefined} props.value Valor, Ejemplo {blocks: [{ key: '', text: '', type: ''}, ...] ,entityMap: {}}
 * @param {Function | undefined} props.onChange Callback que se ejecuta cada vez que cambia el valor del editor.
 * @param {string | undefined} props.placeholder Descripción por defecto que se muestra en el editor.
 * @param {React.ReactNode | undefined} props.otherTools Contenido extra para la barra de herramientas.
 * @param {string | undefined} props.className Clase de estilos del componente.
 * @param {React.CSSProperties | undefined} props.style Estilo en linea del componente.
 * @param {React.Ref<any> | undefined} props.innerRef Referencia al Editor de draft-js.
 * @returns {JSX.Element} Retorna el componente DraftEditor.
 */
function DraftEditor({value, onChange, placeholder='', innerRef, otherTools, className, style, ...props}) {
    const editor = useRef(null)
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(convertFromRaw({ blocks: [], entityMap: {} }))
    )

    useEffect(() => {
        if (value) {
            setEditorState(
                EditorState.createWithContent(convertFromRaw(value))
            )
        }
    }, [])

    const focusEditor = () => editor?.current?.focus();
    const handleKeyCommand = (command) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return true;
        }
        return false;
    }
    const handleChange = (editorState) => {
        const contentState = editorState.getCurrentContent()
        setEditorState(editorState);
        const raw = convertToRaw(contentState)
        if (typeof onChange === 'function' && JSON.stringify(value) !== JSON.stringify(raw)) {
            onChange(raw)
        }
    }
    const load = (_value) => {
        setEditorState(
            EditorState.createWithContent(convertFromRaw(_value))
        )
        if (typeof onChange === 'function') onChange(_value)
    }

    return (
        <div className={classNames(["editor-wrapper", className])} style={style}>
            <Toolbar editorState={editorState} setEditorState={setEditorState} otherTools={otherTools} />
            <div className="editor-container" onClick={focusEditor}>
                <Editor
                    ref={editor}
                    placeholder={placeholder}
                    handleKeyCommand={handleKeyCommand}
                    editorState={editorState}
                    customStyleMap={styleMap}
                    blockStyleFn={myBlockStyleFn}
                    onChange={handleChange}
                    {...props}
                />
            </div>
            <Func ref={innerRef} load={load} />
        </div>
    )
}

const Func = forwardRef(({load, ...props}, ref) => {
    useImperativeHandle(ref, () => ({
        load(value) {
            load(value)
        },
        // getElement() {
        //     return contentRef.current
        // },
    }));
    return null
})

const Toolbar = ({ editorState, setEditorState, otherTools }) => {
    const tools = [
        {
            label: "Bold",
            style: "BOLD",
            icon: <i className="bi-type-bold icon-lg"/>,
            method: "inline",
        },
        {
            label: "Italic",
            style: "ITALIC",
            icon: <i className="bi-type-italic icon-lg"/>,
            method: "inline",
        },
        {
            label: "Underline",
            style: "UNDERLINE",
            icon: <i className="bi-type-underline icon-lg"/>,
            method: "inline",
        },
        {
            label: "Highlight",
            style: "HIGHLIGHT",
            icon: <i className="bi-pen-fill"/>,
            method: "inline",
        },
        // {
        //     label: "Strike-through",
        //     style: "STRIKETHROUGH",
        //     icon: <i className="bi-type-strikethrough icon-lg"/>,
        //     method: "inline",
        // },
        // {
        //     label: "Super Indice",
        //     style: "SUPERSCRIPT",
        //     icon: <i className="bi-superscript icon-lg"/>,
        //     method: "inline",
        // },
        // {
        //     label: "Sub Indice",
        //     style: "SUBSCRIPT",
        //     icon: <i className="bi-subscript icon-lg"/>,
        //     method: "inline",
        // },
    //   {
    //     label: "Monospace",
    //     style: "CODE",
    //     icon: <FontAwesomeIcon icon={faTextWidth} transform="grow-3" />,
    //     method: "inline",
    //   },
    //   {
    //     label: "Blockquote",
    //     style: "blockQuote",
    //     icon: <FontAwesomeIcon icon={faQuoteRight} transform="grow-2" />,
    //     method: "block",
    //   },
        // {
        //     label: "Lista Desordenada",
        //     style: "unordered-list-item",
        //     method: "block",
        //     icon: <i className="bi-list-ul icon-lg"/>,
        // },
        // {
        //     label: "Lista Ordenada",
        //     style: "ordered-list-item",
        //     method: "block",
        //     icon: <i className="bi-list-ol icon-lg"/>,
        // },
        // {
        //     label: "Código",
        //     style: "CODEBLOCK",
        //     icon: <i className="bi-code-slash icon-lg"/>,
        //     method: "inline",
        // },
        // {
        //     label: "Mayúscula",
        //     style: "UPPERCASE",
        //     icon: <i className="bi-chevron-up icon-lg"/>,
        //     method: "inline",
        // },
        // {
        //     label: "Minúscula",
        //     style: "LOWERCASE",
        //     icon: <i className="bi-chevron-down icon-lg"/>,
        //     method: "inline",
        // },
        {
            label: "Alinear Izquierda",
            style: "leftAlign",
            icon: <i className="bi-justify-left icon-lg"/>,
            method: "block",
        },
        {
            label: "Alinear Centro",
            style: "centerAlign",
            icon: <i className="bi-text-center icon-lg"/>,
            method: "block",
        },
        {
            label: "Alinear Derecho",
            style: "rightAlign",
            icon: <i className="bi-justify-right icon-lg"/>,
            method: "block",
        },
        {
            label: "Alinear Justificado",
            style: "justifyAlign",
            icon: <i className="bi-justify icon-lg"/>,
            method: "block",
        },
        { label: "H1", style: "header-one", method: "block" },
        { label: "H2", style: "header-two", method: "block" },
        // { label: "H3", style: "header-three", method: "block" },
        // { label: "H4", style: "header-four", method: "block" },
        // { label: "H5", style: "header-five", method: "block" },
        // { label: "H6", style: "header-six", method: "block" },
    ];
  
    const applyStyle = (e, style, method) => {
        e.preventDefault();
        method === "block"
            ? setEditorState(RichUtils.toggleBlockType(editorState, style))
            : setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    }
  
    const isActive = (style, method) => {
        if (method === "block") {
            const selection = editorState.getSelection();
            const blockType = editorState
                .getCurrentContent()
                .getBlockForKey(selection.getStartKey())
                .getType();
            return blockType === style;
        } else {
            const currentStyle = editorState.getCurrentInlineStyle();
            return currentStyle.has(style);
        }
    }
  
    return (
        <div className="toolbar-grid">
            {tools.map((item, idx) => (
                <button
                    style={{
                        color: isActive(item.style, item.method)
                            ? "rgba(0, 0, 0, 1)"
                            : "rgba(0, 0, 0, 0.5)",
                    }}
                    key={`${item.label}-${idx}`}
                    title={item.label}
                    onClick={(e) => applyStyle(e, item.style, item.method)}
                    onMouseDown={(e) => e.preventDefault()}
                >
                    {item.icon || item.label}
                </button>
            ))}
            {otherTools}
        </div>
    )
}


// FOR INLINE STYLES
const styleMap = {
    CODE: {
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
    HIGHLIGHT: {
        backgroundColor: "#8BF878",
    },
    UPPERCASE: {
        textTransform: "uppercase",
    },
    LOWERCASE: {
        textTransform: "lowercase",
    },
    CODEBLOCK: {
        fontFamily: '"fira-code", "monospace"',
        fontSize: "inherit",
        background: "#f2f2f2",
        lineHeight: 1.5,
        padding: "0.3rem 0.5rem",
        borderRadius: " 0.2rem",
    },
    SUPERSCRIPT: {
        verticalAlign: "super",
        fontSize: "80%",
    },
    SUBSCRIPT: {
        verticalAlign: "sub",
        fontSize: "80%",
    },
}

// FOR BLOCK LEVEL STYLES(Returns CSS Class From DraftEditor.css)
const myBlockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();
    switch (type) {
    case "blockQuote":
        return "superFancyBlockquote";
    case "leftAlign":
        return "DraftEditor-alignLeft";
    case "rightAlign":
        return "DraftEditor-alignRight";
    case "centerAlign":
        return "DraftEditor-alignCenter";
    case "justifyAlign":
        return "DraftEditor-alignJustify";
    default:
        break;
    }
}

export default DraftEditor