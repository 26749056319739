const marginLeft = 21 
const marginTop = 21
const marginRight = 21
const marginBottom = 21
const marginHeader = 6
export const stylesPage = {
    marginLeft,
    marginTop,
    marginRight,
    marginBottom,
    margins: [marginLeft, marginTop, marginRight, marginBottom],
}

export const styles = {
    'font-roboto-c': {
        font: 'RobotoCondensed'
    },
    'font-manuscript-w': {
        font: 'ManuscriptWide'
    },
    'font-century-gothic': {
        font: 'CenturyGothic'
    },
    'stretch-header': {
        font: 'RobotoCondensed',
        alignment: 'center',
        bold: true
    },
    tableHeader: {
        bold: true,
        alignment: 'center',
        fillColor: '#EDEDED',
    },
    'bg-gray': {
        fillColor: '#EDEDED',
        background: '#EDEDED'
    },
    'text-center': {
        alignment: 'center'
    },
    'text-end': {
        alignment: 'right'
    },
    'text-start': {
        alignment: 'left'
    },
    'text-justify': {
        alignment: 'justify'
    },
    'text-decoration-underline': {
        decoration: 'underline'
    },
    highlight: {
        background: '#8BF878'
    },
    'fw-bold': {
        bold: true
    },
    'text-red': {
        color: '#FF0000'
    },
    title: {
        alignment: 'center',
        fontSize: 14,
        bold: true,
    },
    'm-top-page': {
        marginTop
    },
    'm-bottom-page': {
        marginBottom
    },
    'm-left-page': {
        marginLeft
    },
    'm-right-page': {
        marginRight
    },
    header: {
        bold: true,
        marginBottom: marginHeader,
        marginTop: marginHeader,
    },
    'mt-1': { marginTop: 5 },
    'my-1': { marginTop: 5, marginBottom: 5 },
    'my-2': { marginTop: 10, marginBottom: 10 },
    'my-3': { marginTop: 15, marginBottom: 15 },
    'mx-1': { marginLeft: 5, marginRight: 5 },
    'mx-2': { marginLeft: 10, marginRight: 10 },
    'mx-3': { marginLeft: 15, marginRight: 15 },
}