import { Field, ErrorMessage } from 'formik'
import { toPascalCase } from '../../utils/utils'
import InputLayout from 'components/layouts/InputLayout'
import Input from 'components/Input'
import { classNames } from 'utils/utils'
import { propFromName } from 'utils/utils'

/**
 * @typedef {object} InputFormikProps
 * @property {string} props.label
 * @property {string} props.containerClassName
 * @property {React.CSSProperties} props.containerStyle
 * @property {boolean} props.inline
 * @property {boolean} props.showError
 */

/**
 * 
 * @param {InputFormikProps & import('components/Input').InputProps} props 
 * @returns {JSX.Element} Retorna el componente InputFormik
 */
export default function InputFormik({
    name='', label='', type='text', 
    containerClassName, 
    containerStyle, 
    inline=false, 
    showError=true,
    info,
    ...props
}) {
    
    const input = ({field, form: {errors, touched}}) => {
        if (propFromName(touched, name) && propFromName(errors, name)) props.isInvalid = true
        return <Input {...field} {...props} type={type} />
    }

    return (
        <InputLayout 
            label={label ? label : toPascalCase(name)}
            className={classNames(['input-formik', containerClassName])}
            style={containerStyle}
            inline={inline}
            info={info}
        >
            <Field name={name}>{input}</Field>
            { name && showError ? <ErrorMessage name={name}>{msg => <div className='invalid-feedback'>{msg}</div>}</ErrorMessage> : null}
        </InputLayout>
    )
}