import { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import ApiService from 'services/api.service'
import { formatDate } from 'utils/FormatDate'

/** @module Pages/Beneficiario/Components/ViewBeneficiario */

/**
 * Componente de información de beneficiario.
 * @param {object} props Propiedades del componente.
 * @param {number} props.id ID del beneficiario.
 * @returns {JSX.Element} Retorna el componente ViewBeneficiario.
 */
function ViewBeneficiario({id}) {
    const [beneficiario, setBeneficiario] = useState(null)

    useEffect(() => {
        const loadData = async () => {
            const response = await ApiService.beneficiario.view(id)
            if (response.status === 200) setBeneficiario(response.data.data) 
        }
        if (id) loadData()
    }, [id])

    return beneficiario && (
        <Table striped bordered hover>
            <tbody>
                <tr>
                    <th>Apellidos</th>
                    <td>{beneficiario.apellido_1} {beneficiario.apellido_2} {beneficiario.apellido_3}</td>
                </tr>
                <tr>
                    <th>Nombres</th>
                    <td>{beneficiario.nombre_1} {beneficiario.nombre_2}</td>
                </tr>
                <tr>
                    <th>Email</th>
                    <td>{beneficiario.email||'-'}</td>
                </tr>
                <tr>
                    <th>Fecha actualización</th>
                    <td>{formatDate(beneficiario.fecha_verificacion, 'd/m/Y H:i')}</td>
                </tr>
                <tr>
                    <th>Cargo(s)</th>
                    <td>
                        <div>
                            {(beneficiario.cargos||[]).map((cargo) => (
                                <div key={cargo.id}>{(beneficiario.cargos||[]).length > 1 && '- '} {cargo.funcion||cargo.descripcion}</div>
                            ))}
                        </div>
                    </td>
                </tr>
            </tbody>
        </Table>
    )
}
export default ViewBeneficiario