import Modal from 'react-bootstrap/Modal'
import { ModalProps } from 'react-bootstrap' // eslint-disable-line
import { forwardRef, useState } from 'react'
import { classNames } from 'utils/utils'

/** @module Components/Dialog */

/**
 * @typedef {object} DialogTypeProps
 * @property {string | undefined} header Contenido de la cabecera del Dialog.
 * @property {React.ReactNode | undefined} footer Contenido del pie del Dialog.
 * @property {1 | 2 | 3 | undefined} level Nivel en que se mostrará el Dialog.
 * @property {boolean | undefined} closeButton Especifica si el Dialog tendrá el botón de cerrar o no.
 * @property {string | undefined} className Clase de estilos del .modal-content.
 * @property {React.CSSProperties | undefined} style Estilos en linea del .modal-content.
 * @property {string | undefined} containerClassName Clase de estilos del componente.
 * @property {React.CSSProperties | undefined} containerStyle Estilos en linea del componente.
 * 
 * @typedef {DialogTypeProps & ModalProps} DialogProps
 */

/**
 * Componente Dialog, construido en base al Modal de bootstrap.
 * @param {DialogProps} props Propiedades del componente.
 * @returns {JSX.Element} Retorna el componente Dialog.
 */
function Dialog({
    header,
    children,
    footer,
    level,
    closeButton = true,
    containerClassName,
    containerStyle,
    className,
    style,
    ...props
}) {
    props.dialogProps = { style, className, containerClassName, containerStyle }
    if (level) props.backdropClassName = classNames([props.backdropClassName, `level-${level}`])

    return (
        <Modal
            backdrop='static'
            {...props}
            className={classNames(['bs-dialog', level && `level-${level}`])}
            dialogAs={BsDialog}
        >
            {header && (
                <Modal.Header closeButton={closeButton}>
                    <Modal.Title>{header}</Modal.Title>
                </Modal.Header>
            )}
            <Modal.Body>{children}</Modal.Body>
            {footer && <Modal.Footer>{footer}</Modal.Footer>}
        </Modal>
    )
}

/**
 * @typedef {object} ConfirmDialogTypeProps
 * @property {Function | undefined} onAccept Callback que se ejecuta cuando el botón "Si" es presionado.
 * @property {Function | undefined} onReject Callback que se ejecuta cuando el botón "No" es presionado.
 * @property {React.ReactNode | undefined} icon Clase de estilo del icono del Dialog.
 * 
 * @typedef {ConfirmDialogTypeProps & DialogProps} ConfirmDialogProps
 */

/**
 * Componente Dialog de confirmación.
 * @param {ConfirmDialogProps} props Propiedades del componente.
 * @returns {JSX.Element} Retorna el Componente ConfirmDialog
 */
function ConfirmDialog({ header, children, onAccept, onReject, icon, variant = 'primary', ...props }) {
    const [loading, setLoading] = useState(false)

    const handleAccept = typeof onAccept === 'function' ? () => onAccept({ setLoading }) : onAccept
    const handleReject = typeof onReject === 'function' ? () => onReject({ setLoading }) : onReject

    const footerDialog = (
        <>
            <button
                className={`d-inline-flex align-items-center btn btn-outline-${variant}`}
                style={{ width: '4.5rem' }}
                onClick={handleReject}
            >
                <span className='bi-x-lg me-2'></span>
                <span style={{ flex: 1 }}>No</span>
            </button>
            <button
                className={`d-inline-flex align-items-center btn btn-${variant}`}
                style={{ width: '4.5rem' }}
                onClick={handleAccept}
                {...(loading === true && { disabled: true })}
            >
                {loading === true ? (
                    <div className='spinner-border spinner-border-sm me-2' role='status'></div>
                ) : (
                    <span className='bi-check2 me-2'></span>
                )}
                <span style={{ flex: 1 }}>Si</span>
            </button>
        </>
    )

    return (
        <Dialog {...props} header={header || 'Confirmar'} footer={footerDialog}>
            <div className='dialog-message'>
                <div>
                    <div className={`dialog-message-icon text-${variant}`}>
                        {icon || <span className='bi-question-circle-fill'></span>}
                    </div>
                </div>
                <div>{children}</div>
            </div>
        </Dialog>
    )
}

// Iconos de variante.
const variantIcons = {
    success: 'bi-check-circle-fill',
    primary: 'bi-exclamation-circle-fill',
    info: 'bi-exclamation-circle-fill',
    secondary: 'bi-exclamation-circle-fill',
    warning: 'bi-exclamation-triangle-fill',
    danger: 'bi-x-circle-fill',
}

/**
 * @typedef {object} InfoDialogTypeProps
 * @property {React.ReactNode | undefined} footer Footer adicional del Dialog.
 * @property {Function | undefined} onAccept  Callback que se ejecuta cuando el botón "Si" es presionado.
 * @property {React.ReactNode | undefined} icon Clase de estilo del icono del Dialog.
 * @property {'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | undefined} variant
 * 
 * @typedef {InfoDialogTypeProps & DialogProps} InfoDialogProps
 */

/**
 * Componente Dialog de información.
 * @param {InfoDialogProps} props Propiedades del componente.
 * @returns {JSX.Element} Retorna el Componente InfoDialog
 */
function InfoDialog({ header, children, footer, onAccept, icon, variant = 'primary', ...props }) {
    const [loading, setLoading] = useState(false)

    const handleAccept = typeof onAccept === 'function' ? () => onAccept({ setLoading }) : onAccept

    const footerDialog = (
        <>
            {footer}
            <button
                className={`d-inline-flex align-items-center btn btn-${variant}`}
                onClick={handleAccept}
                {...(loading === true && { disabled: true })}
            >
                {loading === true ? (
                    <div className='spinner-border spinner-border-sm me-2' role='status'></div>
                ) : (
                    <span className='bi-check2 me-2'></span>
                )}
                <span style={{ flex: 1 }}>Aceptar</span>
            </button>
        </>
    )

    return (
        <Dialog {...props} header={header || 'Información'} footer={footerDialog}>
            <div className='dialog-message'>
                <div>
                    <div className={`dialog-message-icon text-${variant}`}>
                        {icon || <span className={variantIcons[variant]}></span>}
                    </div>
                </div>
                <div>{children}</div>
            </div>
        </Dialog>
    )
}

/**
 * Componente BsDialog. Estructura del Modal.
 * @param props Propiedades del componente.
 * @param props.scrollable Especifica si el modal podrá tener scroll o no.
 * @param props.children
 * @param props.dialogProps
 * @param props.dialogProps.className
 * @param props.dialogProps.style
 * @param props.dialogProps.containerClassName
 * @param props.dialogProps.containerStyle
 * @param ref Referencia del componente.
 * @returns Retorna el componente BsDialog.
 */
const BsDialog = forwardRef(({ scrollable, children, dialogProps }, ref) => {
    return (
        <div
            ref={ref}
            className={classNames([
                'bs-modal-dialog',
                scrollable && 'modal-dialog-scrollable',
                dialogProps?.containerClassName,
            ])}
            style={dialogProps?.containerStyle}
        >
            <div className={classNames(['modal-content', dialogProps?.className])} style={dialogProps?.style}>
                {children}
            </div>
        </div>
    )
})

export default Dialog
export { Dialog, ConfirmDialog, InfoDialog }
