import Button, { ButtonIcon } from 'components/Button'
import CustomInput from 'components/CustomInput'
import DataTable from 'components/DataTable'
import InputCalendar from 'components/InputCalendar'
import InputDropdown from 'components/InputDropdown'
import { recursosFilter, recursosObj } from 'data/const'
import { formatDate } from 'utils/utils'
import { verifyAccess } from 'utils/rbac.util'

/** @module Pages/Reembolso/Components/TableReembolso */

// Opciones de los reembolsos.
const actions = [
    {icon: 'bi-list-columns-reverse', title: 'Lista Viáticos', action: 'view'},
    {
        icon: 'bi-file-earmark-text', title: 'Nota', action: 'nota',
        condition: () => verifyAccess('reembolsoNota')
    },
    {
        icon: 'bi-ticket-detailed', title: 'Comprobante', action: 'cheque', 
        condition: (item) => (!item.cheque&&verifyAccess('reembolsoAddCheque'))
    },
    {
        icon: 'pi pi-ban', title: 'Anular', action: 'cancel', 
        condition: (item) => (!item.cheque&&verifyAccess('reembolsoCancel'))
    },
]
const currentYear = formatDate(new Date(), 'Y')

/**
 * Componente, Tabla de reembolsos.
 * @param {object} props Propiedades del componente.
 * @param {React.Ref<any>} props.tableRef Referencia de la Tabla.
 * @param {Array<object>} props.reembolsos Reembolsos.
 * @param {object} props.pagination Información de la paginación de los reembolsos.
 * @param {Function} props.handleAction Handler de acciones de reembolsos.
 * @param {object} props.filterHandlers Handlers de filtros de useHook para el DataTable del componente.
 * @param {Function} props.filterHandlers.handlePage Callback que se ejecuta cuando se cambia el número de página en la tabla.
 * @param {Function} props.filterHandlers.handleSort Callback que se ejecuta cuando se cambia el orden de una columna de la tabla.
 * @param {Function} props.filterHandlers.setFilters Handler de filtros de la tabla.
 * @returns {JSX.Element} Retorna el componente TableReembolso.
 */
function TableReembolso({
    tableRef, 
    reembolsos, 
    pagination, 
    handleAction, 
    filterHandlers: {handleFilter, handleSort, handlePage}
}) {
    const optionsBodyTemplate = (rowData) => {
        const _options = actions.reduce((carry, option) => {
            if ((typeof option.condition === 'function' && option.condition(rowData)) || typeof option.condition !== 'function') {
                carry.push(
                    <ButtonIcon 
                        variant='text-secondary' rounded
                        icon={option.icon} title={option.title} key={option.title} 
                        onClick={() => handleAction(option.action, rowData)}
                    />
                )
            }
            return carry
        }, [])
        return <div className='table-options'>{_options}</div>
    }
    const columns = [
        {
            field: 'numero_reembolso',
            header: 'Nro. R',
            filter: true,
            sortable: true,
            body: rowData => <div className='text-center'>{rowData.numero_reembolso}</div>,
            style: {width: '8rem'}
        },
        {
            field: 'unidad',
            header: 'Unidad',
            filter: true,
            sortable: true,
        },
        {
            field: 'recursos',
            header: 'Recursos',
            filter: true,
            sortable: true,
            filterElement: (options) => (
                <InputDropdown 
                    options={recursosFilter} 
                    value={options.value} 
                    onChange={(e) => options.filterCallback(e.value)} 
                    className='w-100'
                />
            ),
            body: rowData => recursosObj[rowData.recursos]
        },
        {
            field: 'cheque',
            header: 'Cheque',
            body: rowData => <div>{rowData.cheque ? rowData.cheque : 'Sin cheque'}</div>,
            filter: true,
            sortable: true,
        },
        {
            field: 'comprobante',
            header: 'Comprobante',
            body: rowData => <div>{rowData.comprobante ? rowData.comprobante : 'Sin comprobante'}</div>,
            filter: true,
            sortable: true,
        },
        {
            field: 'fecha_creacion',
            header: 'Fecha',
            body: rowData => <div className='text-center'>{formatDate(rowData.fecha_creacion, 'd/m/Y')}</div>,
            filter: true,
            filterElement: (options) => (
                <InputCalendar 
                    value={options.value} onChange={(e) => options.filterCallback(e.value)} 
                    onlyYearPicker formatLabel='Y'
                    customInput={<CustomInput className='form-control fw-bold' startIcon={<div className='text-black'>Gestión</div>} placeholder={currentYear} />} 
                />
            ),
            sortable: true,
            style: {width: '11rem'}
        },
        {
            field: 'id',
            header: 'Opciones',
            filter: true,
            filterElement: () => <div className='d-flex justify-content-center'>
                <Button startIcon='pi pi-filter-slash' onClick={cleanFilters}>Limpiar</Button>
            </div>,
            body: optionsBodyTemplate,
            style: {width: '12rem'}
        }
    ]
    const onFilter = (filterValues) => {
        const gestion = formatDate(filterValues.fecha_creacion, 'Y')||currentYear
        handleFilter({
            ...filterValues, 
            fecha_creacion: [gestion + '-01-01 00:00:00', gestion + '-12-31 23:59:59']
        })
    }
    const cleanFilters = () => tableRef?.current?.clean()

    return (
        <DataTable
            innerRef={tableRef}
            values={reembolsos}
            columns={columns}
            pagination
            rowsPerPage={pagination.pageSize}
            totalRecords={pagination.totalRecords}
            page={pagination.currentPage}
            filterable
            onFilter={onFilter}
            onSort={handleSort}
            onPage={handlePage}
            initialFilters={{fecha_creacion: new Date()}}
            rowClassName={(rowData) => rowData.cheque?'bg-green-50':'bg-orange-50'}
            tableStyle={{borderColor: '#c7cace'}}
            tableClassName=''
        />
    )
}
export default TableReembolso