import { useEffect, useRef, useState } from "react"

/**
 * Componente ScrollUp.
 * @param {object} props Propiedades del componente.
 * @param {string} id ID del contenedor del scroll.
 * @param {string} title Descripción a mostrar al enfocar el componente.
 * @param {number} showHeight Altura a la que se mostrara el componente.
 * @returns Retorna el componente ScrollUp.
 */
export default function ScrollUp({id, title, showHeight}) {
    const [container, setContainer] = useState(null)
    const iconRef = useRef(null)

    useEffect(() => {
        setContainer(document.getElementById(id))
    }, [])
    useEffect(() => {
        const handleScroll = () => {
            if (iconRef?.current) {
                const _showHeight = showHeight||500
                const isVisible = iconRef.current.classList.contains('show')
                if (container?.scrollTop > _showHeight) {
                    !isVisible && iconRef.current.classList.add('show')
                } else {
                    isVisible && iconRef.current.classList.remove('show')
                }
            }
        }
        container?.addEventListener('scroll', handleScroll)
        return () => {
            container?.removeEventListener('scroll', handleScroll)
        }
    }, [container])

    const onClick = () => {
        container?.scroll({
            top: 0,
            behavior: "smooth"
        })
    }

    return (
        <span 
            className="scroll-up" 
            title={title||"Volver arriba"}
            onClick={onClick}
            ref={iconRef}
        >
            <span className="bi-chevron-up"></span>
        </span>
    )
}