import { useEffect } from 'react'
import PickList from 'components/PickList'
import ApiService from 'services/api.service'

/** @module Pages/Usuario/Components/FormAssignRoles */

/**
 * Componente, Formulario de asignación de roles para un usuario.
 * @param {object} props Propiedades del componente.
 * @param {Array<object>} props.source Estado, Permisos disponibles para asignar al rol.
 * @param {Array<object>} props.target Estado, Permisos asignados al rol.
 * @param {React.Dispatch<React.SetStateAction<object>>} props.setSource Dispatch para actualizar el estado de source.
 * @param {React.Dispatch<React.SetStateAction<object>>} props.setTarget Dispatch para actualizar el estado de target.
 * @param {object} props.selectedUser Usuario seleccionado.
 * @returns {JSX.Element} Retorna el componente FormAssignRoles.
 */
function FormAssignRoles({source, target, setSource, setTarget, selectedUser}) {

    useEffect(()=>{
        const loadRoles = async () => {
            const response = await ApiService.usuario.getRolesToAssign(selectedUser.id)
            if (response.status === 200) {
                setTarget(response.data.userRoles)
                setSource(response.data.roles)
            }
        }
        if (selectedUser) loadRoles()
    }, [selectedUser]) 

    const handleChange = (e) => {
        setTarget(e.target)
        setSource(e.source)
    }

    return (
        <PickList 
            source={source} target={target} 
            sourceHeader='Roles disponibles' targetHeader='Roles asignados'
            onChange={handleChange} 
            filterBy='name' optionLabel='description'
            style={{height: '25rem'}}
        />
    )
}

export default FormAssignRoles