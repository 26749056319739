import { GenericRequest, SignalRequest } from './request'

const MemoService = {

    indexMemos: (params) => SignalRequest.get('memo', params),
    indexMemosPost: (data, params) => SignalRequest.post('memo', data, params),
    createMemo: (data) => GenericRequest.post('memo/create', data),
    cancelMemo: (id) => GenericRequest.get('memo/cancel', {id}),
    getBeneficiarios: (id) => GenericRequest.get('memo/get-beneficiarios', {id}),
    getMemoViaticos: (memoBeneficiarioId) => GenericRequest.get('memo/get-memo-viaticos', {memoBeneficiarioId}),
    searchMemos: (numeroMemo) => SignalRequest.get('memo/search-memos', {numeroMemo}),

    uploadMemo: (data, id) => GenericRequest.post('memo/upload-memo', data, {id}),

    // plantilla memo
    plantillaMemo: {
        getAll: () => GenericRequest.get('plantilla-memo/mis-plantillas-memo'),
        create: (data) => GenericRequest.post('plantilla-memo/create', data),
        update: (data, id) => GenericRequest.put('plantilla-memo/update', data, {id}),
        delete: (id) => GenericRequest.delete('plantilla-memo/delete', {id}),
    }

}

export default MemoService