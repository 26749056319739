import { ErrorMessage, Field } from 'formik'
import EditableSelect from 'components/EditableSelect'
import { toPascalCase } from 'utils/utils'
import InputLayout from 'components/layouts/InputLayout'
import { classNames } from 'utils/utils'
import { propFromName } from 'utils/utils'

function EditableSelectFormik({
    name='', label='', 
    options, disabled, 
    inline=false,
    showError=true,
    containerClassName,
    containerStyle,
    info,
    ...props
}) {
    const getInput = ({ field, form: {errors, touched} }) => {
        if (disabled) props.disabled = true
        if (propFromName(touched, name) && propFromName(errors, name)) props.isInvalid = true
        return <EditableSelect {...props} options={options} {...field} />
    }
    return (
        <InputLayout
            label={label ? label : toPascalCase(name)}
            className={classNames(['input-formik', containerClassName])}
            style={containerStyle}
            inline={inline}
            info={info}
        >
            <Field name={name}>{getInput}</Field>
            { name && showError ? <ErrorMessage name={name}>{msg => <div className='invalid-feedback d-block'>{msg}</div>}</ErrorMessage> : null}
        </InputLayout>
    )
}

export default EditableSelectFormik