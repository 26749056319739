import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { setHttpMessage } from 'store/messageSlice'
import ApiService from 'services/api.service'

import Dialog, { ConfirmDialog } from 'components/Dialog'
import Button, { ButtonIcon } from 'components/Button'

import FormRole from './components/FormRole'
import FormAssignPermissions from './components/FormAssignPermissions'
import ViewRole from './components/ViewRole'
import TitlePage from 'components/TitlePage'
import DataTable from 'components/DataTable'
import { formatDate } from 'utils/utils'

/** @module Pages/Role/RoleIndex */

// Opciones de roles.
const actions = [
    {icon: 'bi-eye', title: 'Información', action: 'view'},
    {icon: 'bi-gear', title: 'Permisos', action: 'assign'},
    {icon: 'bi-pencil', title: 'Editar', action: 'update'},
    {icon: 'bi-trash', title: 'Eliminar', action: 'delete'},
]

/**
 * Componente de administración de roles.
 * @returns {JSX.Element} Retorna el componente RoleIndex.
 */
function RoleIndex() {
    const dispatch = useDispatch()
    const [roles, setRoles] = useState([])
    const [selectedRole, setSelectedRole] = useState(null)
    const [show, setShow] = useState({form: false, delete: false, info: false, assign: false})
    const [reload, setReload] = useState(false)
    const [rolePermissions, setRolePermissions] = useState([])
    const [availablePermissions, setAvailablePermissions] = useState([])
    const [submitting, setSubmitting] = useState(false)
    const formRef = useRef()
    
    useEffect(()=>{
        const getRoles = async () =>{
            const response = await ApiService.rol.index()
            if (response.status === 200) setRoles(response.data)
        }
        getRoles()
    }, [reload])

    // Controlador para abrir los modales
    const handleOpenDialog = (action, rowData) => {
        setSelectedRole(rowData)
        if (action === 'update' || action === 'create') {
            openDialog('form')
        } else if (action === 'delete') {
            openDialog('delete')
        } else if (action === 'view') {
            openDialog('info')
        }else if (action === 'assign') {
            openDialog('assign')
        }
    }
    const openDialog = (name) => setShow({...show, [name]: true})
    const closeFormDialog = () => setShow({...show, form: false})
    const closeDeleteDialog = () => setShow({...show, delete: false})
    const closeInfoDialog = () => setShow({...show, info: false})
    const closeAssignDialog = () => setShow({...show, assign: false})

    // Controladores de eventos
    const handleSubmit = () => formRef?.current?.handleSubmit()
    const handleCreate = async (values) => {
        setSubmitting(true)
        const response = await ApiService.rol.create(values)
        dispatch(setHttpMessage({status: response.status, title: response.data.message}))
        if (response.status === 201) {
            setReload(!reload)
            closeFormDialog()
        }
        setSubmitting(false)
    }
    const handleUpdate = async (values) => {
        if (selectedRole) {
            setSubmitting(true)
            const response = await ApiService.rol.update(values, selectedRole.name)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                setReload(!reload)
                closeFormDialog()
            }
            setSubmitting(false)
        }
    }
    const handleDelete = async () => {
        if (selectedRole) {
            const response = await ApiService.rol.delete(selectedRole.name)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                setReload(!reload)
                closeDeleteDialog()
            }
        }
    }
    const handleAssign = async () => {
        if (selectedRole) {
            const response = await ApiService.rol.assignPermissions({permissions: rolePermissions.map(el=>el.name)}, selectedRole.name)
            if (response.status === 200) {
                setReload(!reload)
                closeAssignDialog()
            }
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
        }
    }
    const clearSelected = () => setSelectedRole(null)

    const actionsBodyTemplate = (rowData) => {
        const _options = actions.reduce((carry, option) => {
            if ((typeof option.condition === 'function' && option.condition(rowData)) || typeof option.condition !== 'function') {
                carry.push(
                    <ButtonIcon 
                        variant='text-secondary' rounded 
                        icon={option.icon} title={option.title} key={option.title} 
                        onClick={() => handleOpenDialog(option.action, rowData)}
                    />
                )
            }
            return carry
        }, [])
        return <div>{_options}</div>
    }
    const columns = [
        {
            field: 'name',
            header: 'Código',
        },
        {
            field: 'description',
            header: 'Nombre',
        },
        {
            field: 'created_at',
            header: 'Fecha',
            body: rowData => <div className='text-center'>{rowData.createdAt ? formatDate(rowData.createdAt, 'd/m/Y') : ''}</div>,
            style: {width: '10rem'}
        },
        {
            field: 'id',
            header: 'Opciones',
            body: actionsBodyTemplate,
            style: { width: '11rem' },
        },
    ]
    const formFooterDialog = <>
        <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeFormDialog}>Cancelar</Button>
        <Button 
            variant='text-secondary' startIcon='pi pi-save' 
            type='submit' onClick={handleSubmit}
            loading={submitting}
        >Guardar</Button>
    </>
    const assignFooterDialog = <>
        <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeAssignDialog}>Cancelar</Button>
        <Button variant='text-secondary' startIcon='pi pi-save' type='submit' onClick={handleAssign}>Guardar</Button>
    </>
    const infoFooterDialog = <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeInfoDialog}>Cerrar</Button>
    const options = <>
        <Button startIcon='pi pi-plus' onClick={()=>handleOpenDialog('create', null)}>Nuevo</Button>
    </>

    return <>
        <TitlePage title='Lista de Roles' options={options} />
        <div className='content'>
            <div>
            <DataTable values={roles} columns={columns}/>

                <Dialog
                    show={show.form}   
                    header={selectedRole ? 'Editar Rol' : 'Crear Rol'} 
                    footer={formFooterDialog}
                    keyboard={false}
                    style={{ width: '30rem' }}
                    onHide={closeFormDialog}
                    onExited={clearSelected}
                >
                    <FormRole 
                        formRef={formRef} selectedRole={selectedRole}
                        onSubmit={ selectedRole ? handleUpdate : handleCreate }  
                    />
                </Dialog>
                <Dialog
                    show={show.assign}   
                    header={'Asignar permisos'} 
                    footer={assignFooterDialog}
                    keyboard={false}
                    style={{ width: '60rem' }}
                    onHide={closeAssignDialog}
                    onExited={clearSelected}
                >
                    <FormAssignPermissions
                        source={availablePermissions}
                        setSource={setAvailablePermissions}
                        target={rolePermissions}
                        setTarget={setRolePermissions}
                        selectedRole={selectedRole}
                    />
                </Dialog>
                <ConfirmDialog
                    show={show.delete}
                    onHide={closeDeleteDialog}
                    onReject={closeDeleteDialog}
                    onAccept={handleDelete}
                    onExited={clearSelected}
                >
                    Esta seguro de <span className='text-primary'>ELIMINAR</span> el rol "{selectedRole?.name}"?
                </ConfirmDialog>
                <Dialog
                    show={show.info}   
                    header='Información del Rol' 
                    footer={infoFooterDialog}
                    onHide={closeInfoDialog}
                    style={{ width: '35rem' }}
                    onExited={clearSelected}
                >
                    {selectedRole ? <ViewRole selectedRole={selectedRole} /> : null}
                </Dialog>

            </div>
        </div>
    </>
}
export default RoleIndex