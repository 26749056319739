import store from 'store'

/** @module Utils/verifyAccess */

/**
 * Determina si un usuario tiene los permisos o roles pasados como parámetro.
 * @method
 * @param {string | string[] | undefined} required Permiso(s) y/o rol(es) a comprobar, 
 * en caso de que required sea, un string o valor vacío, devolverá true.
 * @param {"and" | "or"} operator Si required es un array, 
 * - en caso 'and' devuelve true si posee todos los roles y/o permisos del array required
 * - en caso 'or' devuelve true si posee alguno de los roles y/o permisos del array required
 * @returns {boolean} Retorna true en caso de contar con los roles y permisos, en caso contrario false.
 */
export const verifyAccess = (required, operator='and') => {
    // Los roles y permisos se encuentran almacenados en el estado de redux auth.
    const permissions = Object.keys(store.getState().auth.permissions)
    const {roles} = store.getState().auth
    let ok = false
    if (typeof required === 'string') {
        ok = required === '' || permissions.includes(required) || roles.includes(required)
    } else if (Array.isArray(required)) {
        const _permissions = permissions.filter(permission => required.includes(permission))
        const _roles = roles.filter(role => required.includes(role))
        if (operator === 'or') {
            ok = _permissions.length > 0 || _roles.length > 0
        } else {
            ok = _permissions.length + _roles.length  === required.length
        }
    } else if (!required) {
        ok = true
    }
    return ok
}