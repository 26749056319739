import { Viewer, Worker } from '@react-pdf-viewer/core'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import { useEffect, useState } from 'react'

export default function PdfViewer({ url, base64 }) {
    const [fileUrl, setFileUrl] = useState(null)

    useEffect(() => {
        /** @param {string} data */
        const base64toBlob = (data) => {
            // Cut the prefix `data:application/pdf;base64` from the raw base 64
            const base64WithoutPrefix = data.includes('data:application/pdf;base64,')
                ? data.substring('data:application/pdf;base64,'.length)
                : data;
        
            const bytes = atob(base64WithoutPrefix);
            let length = bytes.length;
            let out = new Uint8Array(length);
        
            while (length--) {
                out[length] = bytes.charCodeAt(length);
            }
        
            return new Blob([out], { type: 'application/pdf' });
        }
        if (url) {
            setFileUrl(url)
        } else if (base64) {
            const blob = base64toBlob(base64);
            const _fileUrl = URL.createObjectURL(blob);
            setFileUrl(_fileUrl)
        }
    }, [url, base64])

    return (
        <div className='h-100' style={{minWidth: '655px'}}>
            {
                fileUrl ? (
                    <Worker workerUrl={pdfjsWorker}>
                        <Viewer fileUrl={fileUrl} />
                    </Worker>
                ) : (
                <div className='h-100 d-flex justify-content-center align-items-center'>
                    <div 
                        className='spinner-border text-primary' 
                        role='status' 
                        style={{width: '3.5rem', height: '3.5rem', borderWidth: '6px'}}
                    >
                        <span className='visually-hidden'>Loading...</span>
                    </div>
                </div>
            )}
        </div>
    )
}