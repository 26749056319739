import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { setHttpMessage } from 'store/messageSlice'
import ApiService from 'services/api.service'

import Dialog, { ConfirmDialog } from 'components/Dialog'
import Button, { ButtonIcon } from 'components/Button'

import FormUnidad from './components/FormUnidad'
import ViewUnidad from './components/ViewUnidad'
import TitlePage from 'components/TitlePage'
import useFilters from 'hooks/useFilters'
import DataTable from 'components/DataTable'
import { initialPagination } from 'data/const'
import InputCheckbox from 'components/InputCheckbox'

/** @module Pages/Unidad/UnidadIndex */

// Opciones de filtros para el hook useFilters.
const filterOptions = {contain: [
    'programa', 'proyecto', 'actividad', 'descripcion', 'ue', 'da', 
    'nombre_corto', 'nombre_corto',
    'codigo_ue', 'codigo_da'
], bool: ['activo']}
// Opciones de unidades para Administrador.
const actionsAdmin = [
    {icon: 'bi-eye', title: 'Información', action: 'view'},
    {icon: 'bi-pencil', title: 'Editar', action: 'update'},
    {icon: 'bi-trash', title: 'Eliminar', action: 'delete'},
]
// Opciones de unidades para Tesorería.
const actionsViaticos = [
    {icon: 'bi-pencil', title: 'Editar', action: 'update'},
]

/**
 * Componente de administración de unidades.
 * @param {object} props Propiedades del componente.
 * @param {"admin" | "viaticos"} props.type Especifica la sección de la que se mostrarán las opciones.
 * @returns {JSX.Element} Retorna el componente UnidadIndex.
 */
function UnidadIndex({type}) {
    const dispatch = useDispatch()
    const [unidades, setUnidades] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const {filters, reloadFilters, handleFilter, handlePage, handleSort} = useFilters({initialFilters: null, filterOptions})
    const [selectedUnidad, setSelectedUnidad] = useState(null)
    const [show, setShow] = useState({ form: false, delete: false, info: false })
    const [submitting, setSubmitting] = useState(false)
    const tableRef = useRef()
    const formRef = useRef()
    
    useEffect(()=>{
        const getUnidades = async () =>{
            const response = await ApiService.unidad.index(filters)
            if (response.status === 200) {
                setUnidades(response.data.data)
                setPagination(response.data.pagination)
            }
        }
        if (filters) getUnidades()
    }, [filters])

    // Controlador para abrir los modales
    const handleOpenDialog = (action, rowData) => {
        setSelectedUnidad(rowData)
        if (action === 'update' || action === 'create') {
            openDialog('form')
        } else if (action === 'delete') {
            openDialog('delete')
        } else if (action === 'view') {
            openDialog('info')
        }
    }
    const openDialog = (name) => setShow({...show, [name]: true})
    const closeFormDialog = () => setShow({...show, form: false})
    const closeDeleteDialog = () => setShow({...show, delete: false})
    const closeInfoDialog = () => setShow({...show, info: false})

    const handleSubmit = () => formRef?.current?.handleSubmit()
    const handleCreate = async (values, formikBag) => {
        setSubmitting(true)
        const response = await ApiService.unidad.create(values)
        dispatch(setHttpMessage({status: response.status, title: response.data.message}))
        if (response.status === 201) {
            reloadFilters()
            closeFormDialog()
        } else if (response.status === 422) {
            const errors = response.data.errors
            const _errors = {}
            for (const key in errors) {
                _errors[key] = errors[key][0]
            }
            formikBag.setErrors(_errors)
        }
        setSubmitting(false)
    }
    const handleUpdate = async (values, formikBag) => {
        if (selectedUnidad) {
            setSubmitting(true)
            let _values = {}
            if (type === 'admin') _values = values
            else if (type === 'viaticos') _values = {nombre_corto: values.nombre_corto}
            const response = await ApiService.unidad.update(_values, selectedUnidad.id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                reloadFilters()
                closeFormDialog()
            } else if (response.status === 422) {
                const errors = response.data.errors
                const _errors = {}
                for (const key in errors) {
                    _errors[key] = errors[key][0]
                }
                formikBag.setErrors(_errors)
            }
            setSubmitting(false)
        }
    }
    const handleDelete = async () => {
        if (selectedUnidad) {
            const response = await ApiService.unidad.delete(selectedUnidad.id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                reloadFilters()
                closeDeleteDialog()
            }
        }
    }
    const cleanSelected = () => setSelectedUnidad(null)
    const handleClean = () => tableRef?.current?.clean()

    const actionsBodyTemplate = (rowData) => {
        let actions = type==='admin' ? actionsAdmin : (type==='viaticos' ? actionsViaticos : [])
        const _options = actions.reduce((carry, option) => {
            if ((typeof option.condition === 'function' && option.condition(rowData)) || typeof option.condition !== 'function') {
                carry.push(
                    <ButtonIcon 
                        variant='text-secondary' rounded 
                        icon={option.icon} title={option.title} key={option.title} 
                        onClick={() => handleOpenDialog(option.action, rowData)}
                    />
                )
            }
            return carry
        }, [])
        return <div className={type==='viaticos'?'text-center':null}>{_options}</div>
    }
    const columns = [
        {
            field: 'codigo_da',
            header: 'DA',
            filter: true,
            sortable: true,
            style: { width: '6rem' },
        },
        {
            field: 'codigo_ue',
            header: 'UE',
            filter: true,
            sortable: true,
            style: { width: '6rem' },
        },
        {
            field: 'programa',
            header: 'Prog.',
            filter: true,
            sortable: true,
            style: { width: '7rem' },
        },
        {
            field: 'proyecto',
            header: 'Proy.',
            filter: true,
            sortable: true,
            style: { width: '10rem' },
        },
        {
            field: 'actividad',
            header: 'Actv.',
            filter: true,
            sortable: true,
            style: { width: '7rem' },
        },
        {
            field: 'descripcion',
            header: 'Unidad',
            filter: true,
            sortable: true,
        },
        {
            field: 'nombre_corto',
            header: 'Nombre Corto',
            body: rowData => rowData.nombre_corto||'-',
            filter: true,
            sortable: true,
        },
        {
            field: 'activo',
            header: 'Activo',
            body: rowData => <div className='text-center'>{rowData.activo?'Si':'No'}</div>,
            filterElement: (options) => (
                <div className='d-flex justify-content-center'>
                    <InputCheckbox value={options.value} onChange={(e) => options.filterCallback(e.value)} triState style={{fontSize: '1.5rem'}}/>
                </div>
            ),
            filter: true,
            sortable: true,
            style: {width: '5rem'}
        },
        {
            field: 'id',
            header: 'Opciones',
            filter: true,
            filterElement: () => (<div className='text-center'>
                <Button startIcon='pi pi-filter-slash' onClick={handleClean}>Limpiar</Button>
            </div>),
            body: actionsBodyTemplate,
            style: { width: '10rem' },
        },
    ]
    const formFooterDialog = <>
        <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeFormDialog}>Cancelar</Button>
        <Button 
            variant='text-secondary' startIcon='pi pi-save' 
            type='submit' onClick={handleSubmit}
            loading={submitting}
        >Guardar</Button>
    </>
    const infoFooterDialog = (
        <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeInfoDialog}>Cerrar</Button>
    )
    const options = <>
        {type === 'admin' && <Button startIcon='pi pi-plus' onClick={()=>handleOpenDialog('create', null)} >Nuevo</Button>}
    </>

    return <>
        <TitlePage title='Lista de Unidades' options={options} />
        <div className='content'>
            <div>
                <DataTable 
                    innerRef={tableRef}
                    values={unidades} 
                    columns={columns} 
                    filterable
                    onFilter={handleFilter}
                    onSort={handleSort}
                    pagination
                    rowsPerPage={pagination.pageSize}
                    totalRecords={pagination.totalRecords}
                    page={pagination.currentPage}
                    onPage={handlePage}
                    rowsPerPageOptions={[10, 25, 50]}
                    rowClassName={rowData=>rowData.activo?'':'row-disabled'}
                />

                <Dialog
                    show={show.form}   
                    header={selectedUnidad ? 'Editar Unidad' : 'Registrar Unidad'} 
                    footer={formFooterDialog}
                    keyboard={false}
                    style={{width: '30rem'}}
                    onHide={closeFormDialog}
                    onExited={cleanSelected}
                >
                    <FormUnidad
                        formRef={formRef} selectedUnidad={selectedUnidad}
                        onSubmit={ selectedUnidad ? handleUpdate : handleCreate } 
                        type={type} 
                    />
                </Dialog>
                <ConfirmDialog 
                    show={show.delete} 
                    onHide={closeDeleteDialog}
                    onReject={closeDeleteDialog}
                    onAccept={handleDelete}
                    onExited={cleanSelected}
                >
                    Esta seguro de <span className='text-primary'>ELIMINAR</span> la Unidad "{selectedUnidad?.descripcion}"?
                </ConfirmDialog>
                <Dialog
                    show={show.info}   
                    header='Información' 
                    footer={infoFooterDialog}
                    onHide={closeInfoDialog}
                    style={{ width: '35rem' }}
                    onExited={cleanSelected}
                >
                    {selectedUnidad ? <ViewUnidad selectedUnidad={selectedUnidad} /> : null}
                </Dialog>

            </div>
        </div>
    </>
}
export default UnidadIndex