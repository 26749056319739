import { Routes, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { verifyAccess } from 'utils/rbac.util'

/* Administración */
import CategoriaIndex from 'pages/administracion/Categoria/CategoriaIndex'
import DirAdtvaIndex from 'pages/administracion/DirAdtva/DirAdtvaIndex'
import EscalaViaticosIndex from 'pages/administracion/EscalaViaticos/EscalaViaticosIndex'
import ReglamentoIndex from 'pages/administracion/Reglamento/ReglamentoIndex'
import RoleIndex from 'pages/administracion/Role/RoleIndex'
import UnidadEjecutoraIndex from 'pages/administracion/UnidadEjecutora/UnidadEjecutoraIndex'
import UnidadIndex from 'pages/administracion/Unidad/UnidadIndex'
import UserIndex from 'pages/administracion/User/UserIndex'
import CuentaIndex from 'pages/administracion/Cuentas/CuentaIndex'

/* Beneficiario */
import SolicitudIndex from 'pages/solicitud/SolicitudIndex'
import SolicitudCreate from 'pages/solicitud/SolicitudCreate'

/* Papeletas */
import PapeletaIndex from 'pages/papeleta/PapeletaIndex'
import PapeletaCreate from 'pages/papeleta/PapeletaCreate'
import PapeletaReport from 'pages/papeleta/PapeletaReport'
import PapeletaAction from 'pages/papeleta/PapeletaAction'

/* Memorandum's */
import MemoIndex from 'pages/memorandum/MemoIndex'
import MemoCreate from 'pages/memorandum/MemoCreate'
import MemoTemplate from 'pages/memorandum/MemoTemplate'

/* Reembolsos */
import ReembolsoCreate from 'pages/reembolso/ReembolsoCreate'
import ReembolsoIndex from 'pages/reembolso/ReembolsoIndex'
import ReembolsoReport from 'pages/reembolso/ReembolsoReport'
import ReembolsoEmitidos from 'pages/reembolso/ReembolsoEmitidos'

/* Solicitudes */
import SolicitudDAFIndex from 'pages/solicitud/SolicitudDAFIndex'
import SolicitudAutoridadIndex from 'pages/solicitud/SolicitudAutoridadIndex'
import SolicitudPresupuestoIndex from 'pages/solicitud/SolicitudPresupuestoIndex'

/* Generales */

import Home from 'pages/Home'

import PageNotFound from 'pages/PageNotFound'
import InternalServerError from 'pages/InternalServerError'

import MainLayout from 'components/layouts/MainLayout'

import LoadingSection from 'pages/LoadingSection'

import EstructuraProgramatica from 'pages/administracion/Unidad/EstructuraProgramatica'
import LibretaCuentas from 'pages/administracion/Cuentas/LibretaCuentas'
import Variables from 'pages/Variables'
import UnidadReembolsosIndex from 'pages/reembolso/UnidadReembolsosIndex'
import BeneficiarioIndex from 'pages/administracion/Beneficiario/BeneficiarioIndex'
import PdfViewer from 'components/PdfViewer'
import ReembolsoKardex from 'pages/reembolso/ReembolsoKardex'
import HomeSection from 'pages/HomeSection'
import CargoInvitadoIndex from 'pages/administracion/CargoInvitado/CargoInvitadoIndex'
import ReembolsoDetail from 'pages/reembolso/ReembolsoDetail'
import UserSettings from 'pages/administracion/User/UserSettings'
import ReglamentoViaticos from 'pages/administracion/Reglamento/ReglamentoViaticos'
import Config from 'pages/Config'

function PrivateRoutes() {
    const {secciones} = useSelector(state => state.auth)

    const routesAdministracion = [
        { path: 'categoria', element: <CategoriaIndex/>, permissions: 'categoriaView' },
        { path: 'direccion-administrativa', element: <DirAdtvaIndex/>, permissions: 'daView' },
        { path: 'escala-viaticos', element: <EscalaViaticosIndex/>, permissions: 'escalaViaticosView' },
        { path: 'cuenta', element: <CuentaIndex type='admin'/>, permissions: 'cuentaView' },
        { path: 'reglamento', element: <ReglamentoIndex/>, permissions: 'reglamentoView' },
        { path: 'rol', element: <RoleIndex/>, permissions: 'rolView' },
        { path: 'unidad-ejecutora', element: <UnidadEjecutoraIndex/>, permissions: 'ueView' },
        { path: 'unidad', element: <UnidadIndex type='admin'/>, permissions: 'unidadView' },
        { path: 'usuario', element: <UserIndex/>, permissions: 'usuarioView' },
        { path: 'variables', element: <Variables/> },
        { path: 'beneficiario', element: <BeneficiarioIndex/> },
        { path: 'cargo-invitado', element: <CargoInvitadoIndex/>, permissions: 'cargoInvitadoView' },

        { path: 'estructura-programatica', element: <EstructuraProgramatica/> },
        { path: 'libreta-cuentas', element: <LibretaCuentas/> },
        { path: 'configuracion', element: <Config/> }, 
    ]
    const routesBeneficiario = [
        { path: 'solicitud', element: <SolicitudIndex/>, permissions: 'solicitudView' }, 
        { path: 'solicitud/create', element: <SolicitudCreate/>, permissions: ['solicitudCreate'] },
        { path: 'solicitud/update/:id', element: <SolicitudCreate/>, permissions: 'solicitudUpdate' },
    ]
    const routesCaja = [
        { path: 'viaticos', element: <PapeletaIndex/>, permissions: 'papeletaView' },
        { path: 'viaticos/accion', element: <PapeletaAction/>, permissions: ['papeletaPay', 'papeletaReverse'] },
        { path: 'reembolso', element: <ReembolsoIndex/>, permissions: 'reembolsoView' },
        { path: 'reembolso/view/:id', element: <ReembolsoDetail/>, permissions: 'reembolsoView' },
        { path: 'reembolso/create', element: <ReembolsoCreate/>, permissions: 'reembolsoCreate' },
        { path: 'listados', element: <ReembolsoReport/>, permissions: 'reembolsoReport' },
        { path: 'listados/emitidos', element: <ReembolsoEmitidos/>, permissions: 'reembolsoReport' },
        { path: 'listados/kardex', element: <ReembolsoKardex/>, permissions: 'reembolsoReport' },
        { path: 'unidad', element: <UnidadReembolsosIndex/>, permissions: 'unidadReembolsosView' },
        { path: 'estructura-programatica', element: <EstructuraProgramatica/> },
        { path: 'libreta-cuentas', element: <LibretaCuentas/> },
    ]
    const routesTesoreria = [
        { path: 'viaticos', element: <PapeletaIndex/>, permissions: 'papeletaView' },
        { path: 'viaticos/create', element: <PapeletaCreate/>, permissions: 'papeletaCreate' },
        { path: 'viaticos/create/:mbId', element: <PapeletaCreate/>, permissions: 'papeletaCreate' },
        { path: 'reporte/viaticos', element: <PapeletaReport/>, permissions: 'papeletaReport' },
        { path: 'memorandum', element: <MemoIndex/>, permissions: 'memoView' },
        { path: 'estructura-programatica', element: <EstructuraProgramatica/> },
        { path: 'libreta-cuentas', element: <LibretaCuentas/> },
        { path: 'unidad', element: <UnidadIndex type='viaticos'/>, permissions: 'unidadView' },
        { path: 'cuenta', element: <CuentaIndex type='viaticos'/>, permissions: 'cuentaView' },
    ]
    const routesDaf = [
        { path: 'memorandum', element: <MemoIndex/>, permissions: 'memoView' },
        { path: 'memorandum/create', element: <MemoCreate/>, permissions: 'memoCreate' },
        { path: 'memorandum/create/:sId', element: <MemoCreate/>, permissions: 'memoCreate' },
        { path: 'template-memorandum', element: <MemoTemplate/>, permissions: 'plantillaMemoView' },
        { path: 'solicitud', element: <SolicitudDAFIndex/>, permissions: 'solicitudView' },
    ]
    const routesAutoridad = [
        { path: 'solicitud', element: <SolicitudAutoridadIndex/>, permissions: 'solicitudView' }, 
    ]
    const routesPresupuestos = [
        { path: 'solicitud', element: <SolicitudPresupuestoIndex/>, permissions: 'solicitudView' }, 
        { path: 'estructura-programatica', element: <EstructuraProgramatica/> },
    ]
    const publicRoutes = [
        { path: 'usuario/configuracion', element: <UserSettings/> }, 
        { path: 'reglamento-viaticos', element: <ReglamentoViaticos/> }, 
        { path: '500', element: <InternalServerError/> }, 
        { path: '*', element: <PageNotFound/> }, 

    ]

    const buildRoute = (route, index) => verifyAccess(route.permissions) && <Route key={index} path={route.path} element={route.element}/>

    return <>
        <Routes>
            <Route path='prueba' element={<div><PdfViewer/></div>} />
            {(secciones['*']||secciones.administracion) && (
                <Route path='administracion' element={<MainLayout/>}>
                    <Route index element={<HomeSection/>} />
                    {routesAdministracion.map(buildRoute)}
                    {publicRoutes.map(buildRoute)}
                </Route>
            )}
            {(secciones['*']||secciones.caja) && (
                <Route path='caja' element={<MainLayout/>}>
                    <Route index element={<HomeSection/>} />
                    {routesCaja.map(buildRoute)}
                    {publicRoutes.map(buildRoute)}
                </Route>
            )}
            {(secciones['*']||secciones.tesoreria) && (
                <Route path='tesoreria' element={<MainLayout/>}>
                    <Route index element={<HomeSection/>} />
                    {routesTesoreria.map(buildRoute)}
                    {publicRoutes.map(buildRoute)}
                </Route>
            )}
            {(secciones['*']||secciones.daf) && (
                <Route path='daf' element={<MainLayout/>}>
                    <Route index element={<HomeSection/>} />
                    {routesDaf.map(buildRoute)}
                    {publicRoutes.map(buildRoute)}
                </Route>
            )}
            {(secciones['*']||secciones.beneficiario) && (
                <Route path='beneficiario' element={<MainLayout/>}>
                    <Route index element={<HomeSection/>} />
                    {routesBeneficiario.map(buildRoute)}
                    {publicRoutes.map(buildRoute)}
                </Route>
            )}
            {(secciones['*']||secciones.autoridad) && (
                <Route path='autoridad' element={<MainLayout/>}>
                    <Route index element={<HomeSection/>} />
                    {routesAutoridad.map(buildRoute)}
                    {publicRoutes.map(buildRoute)}
                </Route>
            )}
            {(secciones['*']||secciones.presupuestos) && (
                <Route path='presupuestos' element={<MainLayout/>}>
                    <Route index element={<HomeSection/>} />
                    {routesPresupuestos.map(buildRoute)}
                    {publicRoutes.map(buildRoute)}
                </Route>
            )}

            <Route path='/' element={<MainLayout/>}>
                {Object.values(secciones).length === 1 && !secciones['*'] ? <Route index element={<LoadingSection/>} /> : <Route index element={<Home/>} />}
                {publicRoutes.map(buildRoute)}
            </Route>

        </Routes>
    </>
}

export default PrivateRoutes