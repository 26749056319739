import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import TableReembolso from './components/TableReembolso'
import ViewReembolso from './components/ViewReembolso'
import AddCheque from './components/AddCheque'
import InputLayout from 'components/layouts/InputLayout'
import Dialog, { ConfirmDialog } from 'components/Dialog'
import TitlePage from 'components/TitlePage'
import Button from 'components/Button'
import Input from 'components/Input'

import { initialPagination } from 'data/const'
import { ReembolsoService } from 'services/reembolso.service'
import { setHttpMessage } from 'store/messageSlice'
import { formatDate } from 'utils/utils'
import { buildFilters } from 'utils/utils'
import ReembolsoPDF from 'pdf/reembolso'
import useFilters from 'hooks/useFilters'
import { verifyAccess } from 'utils/rbac.util'
import ApiService from 'services/api.service'

/** @module Pages/Reembolso/ReembolsoIndex */

const initialSolicitud = {destinatario: '', cargo_destinatario: ''}
const filterOptions = {
    contain: ['numero_reembolso', 'cheque', 'unidad', 'comprobante'],
    equal: ['recursos'],
    between: ['fecha_creacion']
}

/**
 * Página, Lista de reembolsos.
 * @returns {JSX.Element} Retorna el componente ReembolsoIndex.
 */
function ReembolsoIndex() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {sectionKey} = useSelector(state => state.system)
    const [reembolsos, setReembolsos] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const [selectedReembolso, setSelectedReembolso] = useState(null)
    const {filters, reloadFilters, ...filterHandlers} = useFilters({initialFilters: null, filterOptions})
    const [reembolso, setReembolso] = useState(null)
    const [show, setShow] = useState({view: false, cheque: false, cancel: false, solicitud: false})
    const [solicitudInfo, setSolicitudInfo] = useState(initialSolicitud)
    const [pendientes, setPendientes] = useState(false)
    const tableRef = useRef()
    const formRef = useRef()
    const pdfR = new ReembolsoPDF()

    useEffect(() => {
        const loadData = async () => {
            const {filter, ..._filters} = filters
            const _filter = {
                ...buildFilters((pendientes?{cheque: true}:{}), {null:['cheque']}),
                ...filter
            }
            const response = await ReembolsoService.indexReembolsosPost({filter: _filter}, _filters)
            if (response.status === 200) {
                setReembolsos(response.data.data)
                setPagination(response.data.pagination)
            }
        }
        if (filters) loadData()
    }, [filters, pendientes]) 
    useEffect(() => {
        const loadData = async () => {
            const response = await ReembolsoService.viewReembolso(selectedReembolso.id)
            if (response.status === 200) {
                setReembolso(response.data.data)
            }
        }
        if (selectedReembolso) {
            if (show.view || show.cheque) loadData()
        } else setReembolso(null)
    }, [selectedReembolso, show.view, show.cheque])

    const openDialog = (name) => setShow({...show, [name]: true})
    const closeViewDialog = () => setShow({...show, view: false})
    const closeChequeDialog = () => setShow({...show, cheque: false})
    const closeCancelDialog = () => setShow({...show, cancel: false})
    const closeSolicitudDialog = () => setShow({...show, solicitud: false})
    const handleAction = (action, rowData) => {
        setSelectedReembolso(rowData)
        if (action === 'view') {
            openDialog('view')
        } else if (action === 'cheque') {
            openDialog('cheque')
        } else if (action === 'nota') {
            setSolicitudInfo({destinatario: rowData.destinatario||'', cargo_destinatario: rowData.cargo_destinatario||''})
            openDialog('solicitud')
        } else if (action === 'cancel') {
            openDialog('cancel')
        }
    }

    const generateSolicitudReembolso = async() => {
        if (selectedReembolso) {
            const response = await ReembolsoService.getSolicitudReembolso(selectedReembolso.id)
            if (response.status === 200) {
                pdfR.solicitudReembolso({...response.data.data, ...solicitudInfo})
                closeSolicitudDialog()
            }
        }
    }
    const cleanSelected = () => setSelectedReembolso(null)
    const addCheque = async (values) => {
        if (selectedReembolso) {
            const response = await ReembolsoService.addCheque(selectedReembolso.id, values)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                reloadFilters()
                closeChequeDialog()
            }
        }
    }
    const handleSubmit = async () => formRef?.current?.handleSubmit()
    const handleGenerate = async () => {
        const response = await ApiService.config.search({keys: 'EncargadoCaja'})
        const jefeCaja = response.status===200 ? response.data['EncargadoCaja']?.valor||'' : ''
        pdfR.reembolsoViaticos({...reembolso, jefe_caja: jefeCaja})
    }
    const handleCancel = async () => {
        if (selectedReembolso) {
            const response = await ReembolsoService.cancelReembolso(selectedReembolso.id)
            if (response.status === 200) {
                reloadFilters()
                closeCancelDialog()
            }
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
        }
    }
    const changePendientes = (value) => setPendientes(value)
    const handleSolicitudChange = (e) => setSolicitudInfo({...solicitudInfo, [e.target.name]: e.target.value})
    const redirectToView = () => {
        navigate(`/${sectionKey}/reembolso/view/${selectedReembolso?.id}`)
    }

    const chequeFooterDialog = <>
        <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeChequeDialog}>Cancelar</Button>
        <Button variant='text-secondary' startIcon='pi pi-save' type='submit' onClick={handleSubmit}>Guardar</Button>
    </>
    const solicitudFooterDialog = <>
        <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeChequeDialog}>Cancelar</Button>
        <Button variant='text-secondary' startIcon='pi pi-file-pdf' onClick={generateSolicitudReembolso}>Generar PDF</Button>
    </>
    const viewFooterDialog = <>
        <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeViewDialog}>Cerrar</Button>
        <Button variant='text-secondary' startIcon='pi pi-list' onClick={redirectToView}>Detallado</Button>
        <Button variant='text-secondary' startIcon='pi pi-file-pdf' onClick={handleGenerate}>Generar PDF</Button>
    </>
    const options = verifyAccess('reembolsoCreate') && <>
        <Link className='btn btn-primary' to='create'> <span className='pi pi-plus me-1'></span> Nuevo</Link>
    </>

    return <>
        <TitlePage title='Lista de Reembolsos' options={options} />
        <div className='content'>
            <div>
                <div className='datatable-toolbar'>
                    <Button variant='tab-red' active={!pendientes} onClick={() => changePendientes(false)}>Todos</Button>
                    <Button variant='tab-red' active={pendientes} onClick={() => changePendientes(true)}>Pendientes</Button>
                </div>
                <TableReembolso 
                    tableRef={tableRef} 
                    reembolsos={reembolsos}
                    pagination={pagination}
                    filters={filters}
                    filterHandlers={filterHandlers}
                    handleAction={handleAction}
                />
                <Dialog 
                    show={show.view}
                    onHide={closeViewDialog}
                    header='Información de Reembolso'
                    footer={viewFooterDialog}
                    style={{width: '70rem'}}
                    onExited={cleanSelected}
                    scrollable
                >
                    {reembolso && <ViewReembolso selectedReembolso={reembolso} />}
                </Dialog>
                <Dialog 
                    show={show.cheque}
                    header='Registrar comprobante'
                    footer={chequeFooterDialog}
                    keyboard={false}
                    style={{width: '30rem'}}
                    onHide={closeChequeDialog}
                    onExited={cleanSelected}
                >
                    {reembolso && <AddCheque formRef={formRef} selectedReembolso={reembolso} onSubmit={addCheque} />}
                </Dialog>
                <Dialog 
                    show={show.solicitud}
                    onHide={closeSolicitudDialog}
                    header='Generar Solicitud de Reembolso'
                    footer={solicitudFooterDialog}
                    style={{width: '30rem'}}
                    onExited={() => {cleanSelected(); setSolicitudInfo(initialSolicitud)}}
                >
                    <InputLayout label='Destinatario:'>
                        <Input name='destinatario' value={solicitudInfo.destinatario} onChange={handleSolicitudChange}/>
                    </InputLayout>
                    <InputLayout label='Cargo Destinatario:'>
                        <Input name='cargo_destinatario' value={solicitudInfo.cargo_destinatario} onChange={handleSolicitudChange}/>
                    </InputLayout>
                </Dialog>
                <ConfirmDialog 
                    show={show.cancel}
                    onHide={closeCancelDialog}
                    onReject={closeCancelDialog}
                    onAccept={handleCancel}
                    onExited={cleanSelected}
                >
                    Esta seguro de <span className='text-primary'>ANULAR</span> el 
                    reembolso {selectedReembolso?.numero_reembolso}/{formatDate(selectedReembolso?.fecha_creacion, 'y')}?
                </ConfirmDialog>
            </div>
        </div>
    </>
}
export default ReembolsoIndex