import { Form, Formik } from 'formik'
import { chequeValidator } from 'utils/validators/validators'
import InputLayout from 'components/layouts/InputLayout'
import Input from 'components/Input'
import { formatDate } from 'utils/utils'
import { InputFormik } from 'components/formik/formikFormComponents'

/** @module Pages/Reembolso/Components/AddCheque */

/**
 * Componente, Formulario de registro de cheque-comprobante de reembolso.
 * @param {object} props Propiedades del componente.
 * @param {React.MutableRefObject<any>} props.formRef Referencia del formulario.
 * @param {Function} props.onSubmit Callback a ejecutar cuando se de submit del formulario.
 * @param {object} props.selectedReembolso Reembolso seleccionado.
 * @returns {JSX.Element} Retorna el componente AddCheque.
 */
function AddCheque({formRef, selectedReembolso, onSubmit}) {
    const {numero_reembolso, fecha_creacion, total_monto} = selectedReembolso

    return (
        <Formik
            innerRef={formRef}
            initialValues={{cheque: '', comprobante: ''}}
            onSubmit={onSubmit}
            validationSchema={chequeValidator}
        >
            {() => (
                <Form>
                    <div className='row g-3'>
                        <InputLayout label='Nro. Reembolso' className='col-6'>
                            <Input value={numero_reembolso+'/'+formatDate(fecha_creacion, 'y')} readOnly/>
                        </InputLayout>
                        <InputLayout label='Monto' className='col-6'>
                            <Input value={total_monto} readOnly/>
                        </InputLayout>
                    </div>
                    <InputFormik name='cheque' label='Cheque:' type='number' />
                    <InputFormik name='comprobante' label='Comprobante:' type='number'/>
                </Form>
            )}
        </Formik>
    )
}

export default AddCheque