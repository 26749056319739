import { useEffect, useState } from 'react'
import SearchBeneficiario from 'components/SearchBeneficiario'
import ApiService from 'services/api.service'

/** @module Pages/Usuario/Components/FormBeneficiarioUser */

/**
 * Componente, Formulario de registro de usuario desde un beneficiario.
 * @param {object} props Propiedades del componente.
 * @param {boolean} props.submit true ejecuta la función para crear al usuario
 * @param {Function} props.onRequest Función que se ejecuta después de realizar la petición
 * @param {Function} props.onEndSubmit Función que se ejecuta después de realizar el submit
 * @returns {JSX.Element} Retorna el componente FormBeneficiarioUser.
 */
function FormBeneficiarioUser({submit, onEndSubmit, onRequest}) {
    const [beneficiario, setBeneficiario] = useState(null)
    const [message, setMessage] = useState('')

    useEffect(() => {
        if (submit) {
            onSubmit()
            onEndSubmit()
        }
    }, [submit]) 
    useEffect(() => {
        const verify = async () => {
            const response = await ApiService.usuario.verifyIfUserExist({username: beneficiario.email, beneficiarioId: beneficiario.id})
            if (response.status === 200 && response.data) setMessage('El usuario ya existe') 
        }
        setMessage('')
        if (beneficiario) {
            verify()
        }
    }, [beneficiario])

    const handleSelect = (selection) => {
        if (selection) {
            setBeneficiario(selection)
        }
    }
    
    const onSubmit = async () => {
        if (beneficiario) {
            const response = await ApiService.usuario.createFromBeneficiario(beneficiario.id)
            if (typeof onRequest === 'function') onRequest(response)
        }   
    }

    return (
        <div>
            <div className='mb-3'>
                <div className='fw-bold mb-2'>Beneficiario</div>
                <div className='mb-2'>
                    <SearchBeneficiario onSelect={handleSelect} oneByCargo={false}/>
                </div>
                <div>Nombre: {beneficiario?.nombre_beneficiario} {beneficiario?.apellido_beneficiario}</div>
            </div>
            <div className='mb-3'>
                <div className='fw-bold mb-2'>Nuevo usuario</div>
                <div>Nombre: {beneficiario?.nombre_beneficiario} {beneficiario?.apellido_beneficiario}</div>
                <div>Usuario: {beneficiario?.email}</div>
                <div>Contraseña: {beneficiario?.documento}</div>
            </div>
            {message && (
                <div>
                    <div className='alert alert-danger py-2'>{message}</div>
                    <div className='alert alert-primary py-2'>
                        En caso que el usuario exista, al GUARDAR sobrescribirá la información del usuario con la del beneficiario con excepción de la contraseña.
                    </div>
                </div>
            )}
        </div>
    )
}
export default FormBeneficiarioUser