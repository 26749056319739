import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'

import { InputFormik } from 'components/formik/formikFormComponents'
import DraftEditor from 'components/DraftEditor'
import InputLayout from 'components/layouts/InputLayout'
import Button, { ButtonIcon } from 'components/Button'
import SearchBeneficiario from 'components/SearchBeneficiario'

import { memoValidators } from 'utils/validators/validators'
import MemoService from 'services/memo.service'
import SolicitudService from 'services/solicitud.service'
import ApiService from 'services/api.service'
import { setHttpMessage } from 'store/messageSlice'
import { formatDate, parseDate } from 'utils/utils'
import Loading from 'pages/Loading'
import MemoPdf from 'pdf/memo'
import InputDropdown from 'components/InputDropdown'
import TitlePage from 'components/TitlePage'
import CustomInput from 'components/CustomInput'
import { eachDayOfInterval } from 'date-fns'

/** @module Pages/Memo/MemoCreate */

// Valores iniciales del formulario.
const initialValues = {numero_memo: '', info_adicional: '', descripcion: ''}

const date = new Date()

/**
 * Página, Registro de memorándum.
 * @returns {JSX.Element} Retorna el componente MemoCreate.
 */
function MemoCreate() {
    const { sId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {sectionKey} = useSelector(state => state.system)
    const [descripcion, setDescripcion] = useState({blocks: [], entityMap: {}})
    const [selectedBeneficiarios, setSelectedBeneficiarios] = useState([])
    const [loading, setLoading] = useState(false)
    const [jefeTesoreria, setJefeTesoreria] = useState(null)
    const [templates, setTemplates] = useState([])
    const [infoSolicitud, setInfoSolicitud] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const formRef = useRef()
    const editorRef = useRef()
    let init = false

    useEffect(() => {
        loadJefeTesoreria()
        loadTemplates()
    }, [])
    useEffect(() => {
        const loadData = async () => {
            const response = await SolicitudService.getSolicitudMemo(sId, {estado: 'A'})
            if (response.status === 200) {
                const {solicitud, beneficiarios} = response.data
                setSelectedBeneficiarios(beneficiarios)
                setInfoSolicitud(solicitud)
                setLoading(false)
            } else {
                dispatch(setHttpMessage({status: response.status}))
                navigate(`/${sectionKey}/solicitud`)
            }
        }
        if (sId && !init) {
            init = true
            setLoading(true)
            loadData()
        }
    }, [sId]) 

    const loadTemplates = async () => {
        const response = await MemoService.plantillaMemo.getAll()
        if (response.status === 200) setTemplates([{nombre: 'Ninguno', plantilla_json: { blocks: [], entityMap: {} }} ,...response.data])
    }

    const onSubmit = async (values) => {
        setSubmitting(true)
        const { numero_memo, info_adicional } = values
        const _data = {
            numero_memo: numero_memo,
            info_adicional,
            descripcion: getDescripcion(),
            descripcion_json: descripcion,
            solicitud_id: sId||null,
            beneficiarios: selectedBeneficiarios,
            jefe_tesoreria: jefeTesoreria?.nombre||'',
        }
        const {status, data} = await MemoService.createMemo(_data)
        dispatch(setHttpMessage({status, title: data.message}))
        if (status === 201) {
            const pdfM = new MemoPdf()
            const config = JSON.parse(data.configMemo)
            pdfM.memo(data.memo, config)
            navigate(`/${sectionKey}/memorandum`)
        }
        setSubmitting(false)
    }
    const handleSubmit = () => formRef?.current?.handleSubmit()
    const handleSelect = (selection) => {
        const _beneficiario = selectedBeneficiarios.find(beneficiario => beneficiario.beneficiario_id === selection.beneficiario_id)
        if (!_beneficiario) {
            setSelectedBeneficiarios([...selectedBeneficiarios, selection])
        }
    }
    const validate = () => {
        const errors = {}
        if (getDescripcion().replace(/ /g, '').replace(/\n/g, '') === '') {
            errors.descripcion = 'Requerido'
        }
        if (selectedBeneficiarios.length === 0) {
            errors.beneficiarios = 'Requerido'
        }
        return errors
    }
    const getDescripcion = () => {
        return descripcion.blocks.reduce((carry, item) => {
            const text = item.text ? item.text : "\n"
            return carry + text
        }, '')
    }
    const loadJefeTesoreria = async () => {
        const response = await ApiService.config.search({keys: 'JefeDepTyCP'})
        if (response.status === 200) {
            let jefe = null
            if (response.data['JefeDepTyCP']) {
                jefe = {
                    nombre: response.data['JefeDepTyCP'].valor,
                    cargo: response.data['JefeDepTyCP'].descripcion
                }
            }
            setJefeTesoreria(jefe)
        }
    }
    const loadTemplate = (e) => {
        const template = buildTemplate(e.target.value.plantilla_json)
        editorRef?.current?.load(template)
    }
    const buildTemplate = (template) => {
        if (infoSolicitud) {
            const {numero_solicitud, nombre_solicitante, apellido_solicitante, objetivo_viaje, fecha_creacion} = infoSolicitud
            const destinos = getDestinos(selectedBeneficiarios)
            const destino = destinos.length > 0 ? destinos[0] : null
            const dias = destino ? getDiasViaje(destino.fecha_salida, destino.fecha_llegada) : ''
            const beneficiarios = destino ? getBeneficiarios(destino.beneficiarios) : ''
            template.blocks = template.blocks.map(block => {
                block = {...block, ...insertData(block, objetivo_viaje, '{{objetivo_viaje}}')}
                block = {...block, ...insertData(block, nombre_solicitante.trim()+' '+apellido_solicitante.trim(), '{{remitente_solicitud}}')}
                block = {...block, ...insertData(block, 'Solicitud '+numero_solicitud+'/'+formatDate(fecha_creacion, 'y'), '{{numero_solicitud}}')}
                block = {...block, ...insertData(block, destino?.lugar_viaje||'', '{{lugar_viaje}}')}
                block = {...block, ...insertData(block, dias, '{{dias_viaje}}')}
                block = {...block, ...insertData(block, beneficiarios, '{{beneficiarios}}')}
                return block
            })
        }
        return template
    }
    const getDestinos = (beneficiarios) => {
        const destinos = {}
        beneficiarios.forEach(beneficiario => {
            const {lugar_viaje, fecha_salida, fecha_llegada} = beneficiario
            const key = [lugar_viaje, fecha_salida, fecha_llegada].join('|')
            if (destinos[key]) {
                destinos[key].beneficiarios.push(beneficiario)
            } else {
                destinos[key] = {
                    lugar_viaje, fecha_salida, fecha_llegada,
                    beneficiarios: [beneficiario]
                }
            }
        })
        return Object.values(destinos)
    }
    const getDiasViaje = (fecha_salida, fecha_llegada) => {
        let text = ''
        const range = eachDayOfInterval({
            start: parseDate(fecha_salida),
            end: parseDate(fecha_llegada)
          })
        if (range.length === 1) {
            const date = range[0]
            text = formatDate(date, 'j F Y').replace(/ /g, ' de ')
        } else if (range.length > 1) {
            const dateIni = range[0]
            const dateEnd = range[range.length-1]
            if (dateIni.getMonth() === dateEnd.getMonth()) {
                text = dateIni.getDate()
            } else {
                if (dateIni.getFullYear() === dateEnd.getFullYear()) {
                    text = formatDate(dateIni, 'j M').replace(/ /g, ' de ')
                } else {
                    text = formatDate(dateIni, 'j M Y').replace(/ /g, ' de ')
                }
            }
            text += (range.length === 2 ? ' y ' : ' al ') + formatDate(dateEnd, 'j F Y').replace(/ /g, ' de ')
        }
        return text
    }
    const getBeneficiarios = (beneficiarios) => {
        return beneficiarios.reduce((carry, item, index) => {
            const concat = index === 0 ? '' : (
                index === beneficiarios.length-1 ? ' y ' : ', '
            )
            return carry + concat + item.nombre_beneficiario + (item.apellido_beneficiario ? ' ' + item.apellido_beneficiario : '')
        }, '')
    }
    const insertData = (block, value, target) => {
        let {text, inlineStyleRanges} = block
        let posTarget = text.search(target)
        while (posTarget >= 0) {
            text = text.replace(target, value)
            inlineStyleRanges = inlineStyleRanges.map(i => {
                return i.offset >= posTarget ? {...i, offset: i.offset+value.length-target.length} : i
            })
            posTarget = text.search(target)
        }
        return { text, inlineStyleRanges }
    }
    const replaceBeneficiario = (index, beneficiario) => {
        setSelectedBeneficiarios(selectedBeneficiarios.map((b, i) => i===index?beneficiario:b))
    }
    const removeBeneficiario = (index) => {
        setSelectedBeneficiarios(selectedBeneficiarios.filter((b, i) => index!==i))
    }
    const buildCardBeneficiario = (beneficiario, index) => (
        <div className='d-flex align-items-stretch mb-2 group' key={index}>
            <div 
                className='flex-1 p-2' style={{border: '1px solid #102644'}}
            >
                <div><span className='fw-bold'>Nombre:</span> {beneficiario.nombre_beneficiario} {beneficiario.apellido_beneficiario}</div>
                <div><span className='fw-bold'>Cargo:</span> {beneficiario.funcion||beneficiario.cargo}</div>
                <InputLayout label={<span className='fw-bold'>Pas. Aéreo:</span>} inline>
                    <CustomInput 
                        value={beneficiario.pasaje_aereo||''} type='currency'
                        onChange={(e) => replaceBeneficiario(index, {...beneficiario, pasaje_aereo: e.target.value})} 
                        size='sm' startIcon={<span className='user-select-none'>Bs.</span>}
                    />
                </InputLayout>
            </div>
            { sId ? null : <Button startIcon='pi pi-times' onClick={() => removeBeneficiario(index)}/> }
        </div>
    )

    return loading ? <Loading/> : <>
        <TitlePage title='Registro de Memo' />
        <div className='content align-items-center' style={{backgroundColor: '#efefef'}}>
            <Card className='w-xxl-75'>
                <div className='row g-0'>
                    <div className='col-lg-8 p-3 border-bottom'>
                        <Formik
                            innerRef={formRef}
                            initialValues={initialValues}
                            validate={validate}
                            validationSchema={memoValidators}
                            onSubmit={onSubmit}
                        >{() => (
                            <Form>
                                <div className='fs-5'>Información</div>
                                <hr />
                                <div className='d-flex align-items-center mb-3'>
                                    <div className='d-flex align-items-center'>
                                        Documento: 
                                        <div className='d-flex align-items-center fw-bold ps-2' style={{fontSize: '1.15rem'}}>
                                            MEMO DAF
                                            <InputFormik 
                                                name='numero_memo' 
                                                label={<></>} 
                                                showError={false} 
                                                className='me-2' 
                                                style={{width: '5rem'}}
                                                inline 
                                            />
                                            / {formatDate(date, 'y')}
                                        </div>
                                    </div>
                                    <div className='ms-auto'>Fecha: <span style={{fontSize: '1.15rem'}}>{formatDate(date, 'd/m/Y')}</span></div>
                                </div>
                                <InputLayout label='Descripción'>
                                    <DraftEditor 
                                        innerRef={editorRef} 
                                        value={descripcion} 
                                        onChange={(value) => setDescripcion(value)}
                                        className='resize-v'
                                        style={{height: '20rem', minHeight: '20rem'}}
                                        otherTools={
                                            <InputDropdown 
                                                className='ms-auto'
                                                options={templates}
                                                placeholder='Plantilla'
                                                optionLabel='nombre'
                                                valueAsOption
                                                onChange={loadTemplate}
                                            />
                                        } 
                                    />
                                    <ErrorMessage name='descripcion'>{msg => <div className='d-block invalid-feedback'>{msg}</div>}</ErrorMessage>
                                </InputLayout>
                                <InputFormik 
                                    name='info_adicional' as='textarea' style={{ resize: 'none', height: '4rem' }}
                                    label='Información adicional'
                                />
                            </Form>
                        )}</Formik>
                    </div>
                    <div className='col-lg-4 p-3 border-bottom border-start'>
                        <div className='fs-5'>Beneficiarios</div>
                        <hr />
                        {
                            sId ? null : (
                                <div>
                                    <SearchBeneficiario onSelect={handleSelect} cleanOnSelect />
                                    <hr />
                                </div>
                            )
                        }
                        <div>{
                            selectedBeneficiarios.length > 0 
                                ? selectedBeneficiarios.map(buildCardBeneficiario)
                                : <div className='text-center text-danger'>Sin beneficiarios</div>
                        }</div>
                    </div>
                    <div className='col-12 p-3'>
                        <div className='border rounded p-3'>
                            <span className='fw-bold me-2'>Jefe de Departamento de Tesorería y Crédito Público:</span> 
                            {
                                jefeTesoreria 
                                    ? jefeTesoreria.nombre 
                                    : <>
                                        <span className='text-danger me-1'>No encontrado</span>
                                        <ButtonIcon 
                                            variant='text-secondary' 
                                            size='sm' rounded
                                            icon='pi pi-refresh' title='Volver a buscar'
                                            onClick={loadJefeTesoreria}
                                        />
                                    </>
                            }
                        </div>
                    </div>
                    <div className='col-12 d-flex justify-content-center p-3 border-top'>
                        <Button 
                            startIcon='pi pi-save'
                            type='submit' onClick={handleSubmit}
                            loading={submitting}
                        >Guardar</Button>
                    </div>
                </div>
            </Card>
        </div>
    </>
}
export default MemoCreate