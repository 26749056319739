import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import RobotoBold from './fonts/Roboto/RobotoBold'
import RobotoCondensed from './fonts/Roboto/RobotoCondensed'
import RobotoCondensedBold from './fonts/Roboto/RobotoCondensedBold'
import { stylesPage, styles } from './config/styles'
import getHeader from './components/header'
import getFooter from './components/footer'
import ManuscriptWide from './fonts/Manuscript/ManuscriptWide'
import ManuscriptWideBold from './fonts/Manuscript/ManuscriptWideBold'
import printJS from 'print-js'
import CenturyGothic from './fonts/CenturyGothic/CenturyGothic'
import CenturyGothicBold from './fonts/CenturyGothic/CenturyGothicBold'
import CenturyGothicItalic from './fonts/CenturyGothic/CenturyGothicItalic'
import CenturyGothicBoldItalic from './fonts/CenturyGothic/CenturyGothicBoldItalic'

class PDF {

    seccion = ''
    oneCmInPts = 28.346456693

    constructor() {
        pdfMake.vfs = {
            ...pdfFonts.pdfMake.vfs,
            'Roboto-Bold.ttf': RobotoBold,
            'Roboto-Condensed-Regular.ttf': RobotoCondensed,
            'Roboto-Condensed-Bold.ttf': RobotoCondensedBold,
            'Manuscript-Wide-Regular.ttf': ManuscriptWide,
            'Manuscript-Wide-Bold.ttf': ManuscriptWideBold,
            'Century-Gothic-Regular.ttf': CenturyGothic,
            'Century-Gothic-Bold.ttf': CenturyGothicBold,
            'Century-Gothic-Italic.ttf': CenturyGothicItalic,
            'Century-Gothic-Bold-Italic.ttf': CenturyGothicBoldItalic,
        }
        pdfMake.fonts = {
            // Default font should still be available
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Medium.ttf',
                italics: 'Roboto-Italic.ttf',
                bolditalics: 'Roboto-Italic.ttf'
            },
            // Add font Roboto Condensed
            RobotoCondensed: {
                normal: 'Roboto-Condensed-Regular.ttf',
                bold: 'Roboto-Condensed-Bold.ttf',
                // italics: 'Roboto-Italic.ttf',
                // bolditalics: 'Roboto-Italic.ttf'
            },
            // Add font Manuscript Wide
            ManuscriptWide: {
                normal: 'Manuscript-Wide-Regular.ttf',
                bold: 'Manuscript-Wide-Bold.ttf',
                // italics: 'Roboto-Italic.ttf',
                // bolditalics: 'Roboto-Italic.ttf'
            },
            // Add font Century Gothic
            CenturyGothic: {
                normal: 'Century-Gothic-Regular.ttf',
                bold: 'Century-Gothic-Bold.ttf',
                italics: 'Century-Gothic-Italic.ttf',
                bolditalics: 'Century-Gothic-Bold-Italic.ttf'
            }
        }
    }

    /**
     * Convierte centímetros a medida de puntos de pdfmake.
     * @param {number} cm Cantidad de centímetros.
     * @returns {number} Medida de puntos de pdfmake.
     */
    cmToPts(cm) {
        let pts = 0
        if (cm > 0) {
            const _pts = (cm*this.oneCmInPts).toString()
            const parts = _pts.split('.')
            pts = parseFloat(`${parts[0]}${parts.length>1?'.'+parts[1].slice(0,4):''}`)
        }
        return pts
    }

    /**
     * Convierte los valores de las propiedades de medida a la unidad especificada.
     * @param {object} props Propiedades de pdfmake.
     * @param {"pt" | "cm" | string} unit Unidad de medida de las propiedades.
     * @returns Propiedades convertidas a la unidad especificada.
     */
    getProps(props, unit='pt') {
        const _props = props||{}
        const getSize = (size, value) => {
            const _size = {}
            if (value) {
                if (unit==='pt'||value.includes('%')||value.includes('*')) {
                    _size[size] = value
                } else {
                    if (unit==='cm') {
                        _size[size] = this.cmToPts(value)
                    }
                }
            }
            return _size[size]
        }
        if (unit==='cm') {
            if (_props.marginLeft) _props.marginLeft = this.cmToPts(_props.marginLeft)
            if (_props.marginTop) _props.marginTop = this.cmToPts(_props.marginTop)
            if (_props.marginRight) _props.marginRight = this.cmToPts(_props.marginRight)
            if (_props.marginBottom) _props.marginBottom = this.cmToPts(_props.marginBottom)
            if (_props.width) _props.width = getSize('width', _props.width)
            if (_props.height) _props.height = getSize('height', _props.height)
        }
        return _props
    }

    /**
     * Función que obtiene una regla tamaño carta.
     * @returns Devuelve una regla tamaño carta.
     */
    ruler() {
        const w = 27.346456693
        const layout = {
            hLineWidth: (i, node) => 1,
            vLineWidth: (i) => {
                if (i === 0 || i === 22) {
                    return 0;
                }
                return 1;
            },
            hLineColor: (i) => 'black',
            paddingLeft: (i) => 0,
            paddingRight: (i, node) => 0,
            paddingTop: (i) => 0,
            paddingBottom: (i, node) => 0
        }
        return [
            {
                layout,
                table: {
                    widths: [w,w,w,w,w,w,w,w,w,w,w,w,w,w,w,w,w,w,w,w,w,'*'],
                    body: [
                        ['', '1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21']
                    ]
                }
            }
        ]
    }

    /**
     * Construye un pdf con header y footer predefinidos
     * @param {string} title titulo del reporte
     * @param {any} content contenido del reporte
     * @param {object} options Opcional, opciones del reporte, 
     * - config, configuraciones del pdf
     * * - pageSize tamaño de pagina
     * * - pageOrientation orientación de la pagina 'portrait' o 'landscape'
     * * - pageMargins margenes de pagina
     * * - defaultStyle estilos por defecto del documento
     * - filters, filtros que se muestran en cabecera
     * - seccion, sección que se muestra en cabecera
     * @param {object} options.config configuraciones del pdf
     * @param {string} options.config.pageSize tamaño de pagina
     * @param {"portrait" | "landscape"} options.config.pageOrientation orientación de la pagina
     * @param {array} options.config.pageMargins margenes de pagina
     * @param {object} options.config.defaultStyle estilos por defecto del documento
     * @param {array} options.filters filtros que se muestran en cabecera
     * @param {string} options.seccion sección que se muestra en cabecera
     */
    generateReport(title, content, options={}) {
        options = {
            filters: null,
            seccion: '',
            config: {},
            ...options
        }
        const {seccion, filters, config} = options
        const margins = config.pageMargins ? {
            marginLeft: config.pageMargins[0],
            marginTop: config.pageMargins[1],
            marginRight: config.pageMargins[2],
            marginBottom: config.pageMargins[3],
        } : stylesPage
        const header = (currentPage, pageCount, pageSize) => {
            return getHeader({currentPage, pageCount, pageSize}, {seccion: (seccion||this.seccion), title, filters, margins})
        }
        const footer = (currentPage, pageCount) => {
            return getFooter({currentPage, pageCount}, {margins})
        }
        config.pageMargins = [
            margins.marginLeft,
            margins.marginTop+70+(filters?(12*filters.length):0),
            margins.marginRight,
            margins.marginBottom+12,
        ]
        this.generatePDF(content, header, footer, config)
    }

    /**
     * Construye un pdf y lo abre en otra pestaña
     * @param {any} content contenido del pdf
     * @param {any} header header del pdf
     * @param {any} footer footer del pdf
     * @param {object} config Opcional, Configuraciones del pdf,
     * - pageSize tamaño de pagina
     * - pageOrientation orientación de la pagina 'portrait' o 'landscape'
     * - pageMargins margenes de pagina
     * - defaultStyle estilos por defecto del documento
     * @param {string} config.pageSize tamaño de pagina
     * @param {string} config.pageOrientation orientación de la pagina 'portrait' o 'landscape'
     * @param {array} config.pageMargins margenes de pagina
     * @param {object} config.defaultStyle estilos por defecto del documento
     * @param {"to_print" | "on_new_tab" | string} config.open Especifica como abrirá el pdf.
     */
    generatePDF(content, header=null, footer=null, config={}) {
        const initialConfig = {
            pageSize: 'letter',
            pageMargins: stylesPage.margins,
            defaultStyle: {
                font: 'Roboto',
                fontSize: 10
            },
            ...config
        }
        const { pageSize, pageMargins, defaultStyle, pageOrientation, open } = initialConfig
        const docDefinitions = {
            pageOrientation,
            pageSize,
            pageMargins,
            header,
            content,
            footer,
            defaultStyle,
            styles,
        }
        const pdfDocument = pdfMake.createPdf(docDefinitions)
        if (open==='to_print') {
            pdfDocument.getBase64((base64) => {
                printJS({
                    printable: base64,
                    type: 'pdf',
                    base64: true
                })
            })
        } else {
            pdfDocument.open()
        }
    }
}

export default PDF