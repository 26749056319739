import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { setHttpMessage } from 'store/messageSlice'

import Button, { ButtonIcon } from 'components/Button'
import Dialog, { ConfirmDialog } from 'components/Dialog'

import FormUser from './components/FormUser'

import ApiService from 'services/api.service'
import FormAssignRoles from './components/FormAssignRoles'
import FormBeneficiarioUser from './components/FormBeneficiarioUser'
import TitlePage from 'components/TitlePage'
import { formatDate } from 'utils/utils'
import DataTable from 'components/DataTable'
import useFilters from 'hooks/useFilters'
import InputCheckbox from 'components/InputCheckbox'
import ViewBeneficiario from '../Beneficiario/components/ViewBeneficiario'
import { initialPagination } from 'data/const'

/** @module Pages/Usuario/UserIndex */

// Opciones de filtros para el hook useFilters.
const filterOptions = {contain: ['username', 'nombre'], bool: ['activo']}
// Opciones de los usuarios.
const actions = [
    {icon: 'bi-person-slash', title: 'Deshabilitar', action: 'toggleActivo', condition: (item) => item.activo},
    {icon: 'bi-person-lock', title: 'Habilitar', action: 'toggleActivo', condition: (item) => !item.activo},
    {icon: 'bi-gear', title: 'Asignar roles', action: 'assign'},
    {icon: 'bi-pencil', title: 'Editar', action: 'update', condition: (item) => !item.beneficiario_id},
    {icon: 'bi-person', title: 'Beneficiario', action: 'infoBen', condition: (item) => item.beneficiario_id},
    // {icon: 'bi-trash', title: 'Eliminar', action: 'delete'},
]

/**
 * Página de administración de Usuarios.
 * @returns {JSX.Element} Retorna el componente UserIndex.
 */
function UserIndex() {
    const dispatch = useDispatch()
    const {filters, handleFilter, handleSort, handlePage, clearFilters, reloadFilters} = useFilters({filterOptions})
    const [users, setUsers] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const [selectedUser, setSelectedUser] = useState(null)
    const [submit, setSubmit] = useState({ formBen: false })
    const [availableRoles, setAvailableRoles] = useState([])
    const [userRoles, setUserRoles] = useState([])
    const [show, setShow] = useState({
        form: false, delete: false, assign: false, formBen: false, toggle: false, infoBen: false
    })
    const [submitting, setSubmitting] = useState(false)
    const tableRef = useRef()
    const formRef = useRef()
    
    useEffect(()=>{
        const getUsers = async () =>{
            const response = await ApiService.usuario.index(filters)
            if (response.status === 200) {
                setUsers(response.data.data)
                setPagination(response.data.pagination)
            }
        }
        getUsers()
    }, [filters])

    // Controlador para abrir los modales
    const handleOpenDialog = (action, rowData=null) => {
        if (rowData) setSelectedUser(rowData)
        if (action === 'update' || action === 'create') {
            openDialog('form')
        } else if (action === 'delete') {
            openDialog('delete')
        } else if (action === 'view') {
            openDialog('info')
        } else if (action === 'assign') {
            openDialog('assign')
        } else if (action === 'createFromBen') {
            openDialog('formBen')
        } else if (action === 'toggleActivo') {
            openDialog('toggle')
        } else if ('infoBen') {
            openDialog('infoBen')
        }
    }
    const openDialog = (name) => setShow({...show, [name]: true})
    const closeToggleDialog = () => setShow({...show, toggle: false})
    const closeFormDialog = () => setShow({...show, form: false})
    const closeFormBenDialog = () => setShow({...show, formBen: false})
    const closeInfoBenDialog = () => setShow({...show, infoBen: false})
    const closeDeleteDialog = () => setShow({...show, delete: false})
    const closeAssignDialog = () => setShow({...show, assign: false})
    const clearSelected = () => setSelectedUser(null)

    const handleSubmit = () => formRef?.current?.handleSubmit()
    const handleCreate = async (values) => {
        setSubmitting(true)
        const response = await ApiService.usuario.create(values)
        dispatch(setHttpMessage({status: response.status, title: response.data.message}))
        if (response.status === 201) {
            closeFormDialog()
            handleClean()
        }
        setSubmitting(false)
    }
    const handleUpdate = async (values) => {
        if (selectedUser) {
            setSubmitting(true)
            const response = await ApiService.usuario.update(values, selectedUser.id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                reloadFilters()
                closeFormDialog()
            }
            setSubmitting(false)
        }
    }
    const handleDelete = async () => {
        if (selectedUser) {
            const response = await ApiService.usuario.delete(selectedUser.id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            reloadFilters()
            closeDeleteDialog()
        }
    }
    const handleAssign = async () => {
        setSubmitting(true)
        if (selectedUser) {
            const response = await ApiService.usuario.assignRoles({roles: userRoles.map(el=>el.name)}, selectedUser.id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                reloadFilters()
                closeAssignDialog()
            }
        }
        setSubmitting(false)
    }
    const handleClean = () => { 
        tableRef?.current?.clean() 
        clearFilters()
    }
    const handleCreateFromBen = (response) => {
        if (response.status === 200 || response.status === 201) {
            closeFormBenDialog()   
        }
        dispatch(setHttpMessage({status: response.status, title: response.data.message}))
        handleClean()
    }
    const handleToggleActivo = async () => {
        if (selectedUser) {
            const response = await ApiService.usuario.toggleActivo(selectedUser.id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                reloadFilters()
                closeToggleDialog()
            }
        }
    }

    const optionsBodyTemplate = (rowData) => {
        const _options = actions.reduce((carry, option) => {
            if ((typeof option.condition === 'function' && option.condition(rowData)) || typeof option.condition !== 'function') {
                carry.push(
                    <ButtonIcon 
                        variant='text-secondary' 
                        rounded
                        icon={option.icon} title={option.title} key={option.title} 
                        onClick={() => handleOpenDialog(option.action, rowData)}
                    />
                )
            }
            return carry
        }, [])
        return <div className='table-options'>{_options}</div>
    }
    const columns = [
        {
            field: 'username',
            header: 'Usuario',
            filter: true,
            sortable: true,
        },
        {
            field: 'nombre',
            header: 'Nombre',
            filter: true,
            sortable: true,
        },
        {
            field: 'activo',
            header: 'Activo',
            body: rowData => <div className='text-center'>{rowData.activo ? 'Si' : 'No'}</div>,
            filter: true,
            filterElement: (options) => <div className='d-flex justify-content-center'>
                <InputCheckbox value={options.value} onChange={(e) => options.filterCallback(e.value)} triState style={{fontSize: '1.5rem'}} />
            </div>,
            sortable: true,
            style: { width: '6rem' }
        },
        {
            field: 'fecha_creacion',
            header: 'Fecha',
            body: rowData => <div className='text-center'>{formatDate(rowData.fecha_creacion, 'd/m/Y')}</div>,
            sortable: true,
            style: { width: '10rem' }
        },
        {
            field: 'id',
            header: 'Opciones',
            filter: true,
            filterElement: () => <div className='d-flex justify-content-center'><Button startIcon='pi pi-filter-slash' onClick={handleClean}>Limpiar</Button></div>,
            body: optionsBodyTemplate,
            style: { width: '10rem' },
        },
    ]

    const formBenFooterDialog = <>
        <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeFormBenDialog}>Cancelar</Button>
        <Button variant='text-secondary' startIcon='pi pi-save' type='submit' onClick={() => setSubmit({...submit, formBen: true})}>Guardar</Button>
    </>
    const formFooterDialog = <>
        <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeFormDialog}>Cancelar</Button>
        <Button 
            variant='text-secondary' startIcon='pi pi-save' 
            type='submit' onClick={handleSubmit}
            loading={submitting}
        >Guardar</Button>
    </>
    const assignFooterDialog = <>
        <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeAssignDialog}>Cancelar</Button>
        <Button 
            variant='text-secondary' startIcon='pi pi-save' 
            type='submit' onClick={handleAssign}
            loading={submitting}
        >Guardar</Button>
    </>
    const infoBenFooterDialog = <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeInfoBenDialog}>Cerrar</Button>
    const options = <>
        <Button startIcon='pi pi-plus' onClick={()=>handleOpenDialog('create')} className='me-3'>Nuevo</Button>
        <Button startIcon='pi pi-download' onClick={()=>handleOpenDialog('createFromBen')}>Beneficiario</Button>
    </>

    return <>
        <TitlePage title='Lista de Usuarios' options={options} />
        <div className='content'>
            <div>
                <DataTable 
                    innerRef={tableRef}
                    values={users} 
                    columns={columns} 
                    filterable
                    onFilter={handleFilter}
                    onSort={handleSort}
                    pagination
                    rowsPerPage={pagination.pageSize}
                    totalRecords={pagination.totalRecords}
                    page={pagination.currentPage}
                    onPage={handlePage}
                />

                <Dialog
                    show={show.form}   
                    header={selectedUser ? 'Editar Usuario' : 'Registrar Usuario'} 
                    footer={formFooterDialog}
                    keyboard={false}
                    style={{ width: '30rem' }}
                    onHide={closeFormDialog}
                    onExited={clearSelected}
                >
                    <FormUser 
                        formRef={formRef} selectedUser={selectedUser}
                        onSubmit={ selectedUser ? handleUpdate : handleCreate }  
                    />
                </Dialog>
                <Dialog
                    show={show.formBen}   
                    header={'Crear Usuario'} 
                    footer={formBenFooterDialog}
                    keyboard={false}
                    style={{ width: '35rem' }}
                    onHide={closeFormBenDialog}
                >
                    <FormBeneficiarioUser 
                        submit={submit.formBen} 
                        onEndSubmit={() => setSubmit({...submit, formBen: false})} 
                        onRequest={handleCreateFromBen} 
                    />
                </Dialog>
                <ConfirmDialog 
                    show={show.toggle}
                    style={{ width: '25rem' }}
                    onAccept={handleToggleActivo}
                    onReject={closeToggleDialog}
                    onHide={closeToggleDialog}
                    onExited={clearSelected}
                >
                    Esta seguro de <span className='text-primary'>{selectedUser?.activo ? 'DESHABILITAR' : 'HABILITAR'}</span> la cuenta de usuario "{selectedUser?.username}"?
                </ConfirmDialog>
                <ConfirmDialog 
                    show={show.delete}
                    onAccept={handleDelete}
                    onReject={closeDeleteDialog}
                    onHide={closeDeleteDialog}
                    style={{ width: '25rem' }}
                    onExited={clearSelected}
                >
                    Esta seguro de <span className='text-primary'>ELIMINAR</span> el usuario {selectedUser?.username}?
                </ConfirmDialog>
                <Dialog
                    show={show.assign}   
                    header={'Asignar roles'} 
                    footer={assignFooterDialog}
                    keyboard={false}
                    style={{ width: '60rem' }}
                    onHide={closeAssignDialog}
                    onExited={clearSelected}
                >
                    <FormAssignRoles
                        source={availableRoles}
                        setSource={setAvailableRoles}
                        target={userRoles}
                        setTarget={setUserRoles}
                        selectedUser={selectedUser}
                    />
                </Dialog>
                <Dialog
                    show={show.infoBen}   
                    header='Beneficiario' 
                    footer={infoBenFooterDialog}
                    onHide={closeInfoBenDialog}
                    style={{ width: '25rem' }}
                    onExited={clearSelected}
                >
                    {selectedUser?.beneficiario_id && <ViewBeneficiario id={selectedUser.beneficiario_id}/>}
                </Dialog>

            </div>
        </div>
    </>
}
export default UserIndex