import { ButtonIcon } from 'components/Button'
import Input from 'components/Input'
import List from 'components/List'
import TitlePage from 'components/TitlePage'
import { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import ApiService from 'services/api.service'
import { setHttpMessage } from 'store/messageSlice'

function Variables() {
    const [variables, setVariables] = useState([])

    useEffect(() => {
        const loadData = async () => {
            const response = await ApiService.config.search({type: 'var'})
            if (response.status === 200) setVariables(Object.values(response.data))
        }
        loadData()
    }, [])

    return (
        <>
            <TitlePage title='Variables'/>
            <div className='content align-items-center' style={{backgroundColor: '#efefef'}}>
                <Card className='w-xl-75 p-3'>
                    <div className='d-flex flex-column'>
                        <div 
                            className='d-flex gap-2 fw-bold py-2 px-3 text-center' 
                            style={{borderBottom: '2px solid #dee2e6'}}
                        >
                            <div style={{width: '40%'}}>Variable</div>
                            <div style={{flex: 1}}>Valor</div>
                            <div style={{width: '6rem'}}>Opciones</div>
                        </div>
                        <List
                            items={variables}
                            itemTemplate={(v) => <Variable value={v} />}
                        />
                    </div>
                </Card>
            </div>
        </>
    )
}

function Variable({value}) {
    const dispatch = useDispatch()
    const [variable, setVariable] = useState({})

    useEffect(() => {
        setVariable(value)
    }, [value])

    const handleChange = (e) => {
        setVariable({...variable, [e.target.name]: e.target.value})
    }
    const handleSave = async () => {
        const response = await ApiService.config.update(variable.id, variable)
        dispatch(setHttpMessage({status: response.status, title: response.data.message}))
    }

    return <div className='d-flex gap-2'>
        <div className='d-flex align-items-center' style={{width: '40%'}}>{variable.descripcion}</div>
        <div style={{flex: 1}}>
            <Input name='valor' value={variable.valor} onChange={handleChange} isInvalid={(variable.valor||'').length === 0} />
        </div>
        <div className='text-center' style={{width: '6rem'}}>
            <ButtonIcon icon='pi pi-save' variant='text-secondary' rounded title='Guardar' onClick={handleSave} />
            {/* <Button icon='pi pi-replay' variant='text-secondary' className='btn-icon-sm' title='Deshacer' /> */}
        </div>
    </div>
}

export default Variables