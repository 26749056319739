export const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
		(async () => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                resolve(reader.result.replace('data:application/pdf;base64,', ''));
            };
            reader.onerror = error => {
                reject(error);
            };
        })()
	});
}