import InputFormik from 'components/formik/InputFormik'
import { Form, Formik } from 'formik'
import { beneficiarioValidator } from 'utils/validators/validators'

/** @module Pages/Solicitud/Components/FormBeneficiario */

// Valores iniciales del formulario.
const initialValues = {nombre_beneficiario: '', apellido_beneficiario: '', documento: '', cargo: ''}

/**
 * Componente, Formulario de beneficiario.
 * @param {object} props Propiedades del componente.
 * @param {React.MutableRefObject<any>} props.formRef Referencia del formulario.
 * @param {Function} props.onSubmit Callback a ejecutar cuando se de submit del formulario.
 * @returns {JSX.Element} Retorna el componente FormBeneficiario.
 */
function FormBeneficiario({formRef, onSubmit}) {
    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={beneficiarioValidator}
        >
            {() => (<Form>
                <InputFormik name='nombre_beneficiario' label='Nombres'/>
                <InputFormik name='apellido_beneficiario' label='Apellidos'/>
                <InputFormik 
                    name='documento' 
                    label='CI' 
                    placeholder='CI o Documento'
                    info='Carnet o Documento de Identidad del beneficiario registrado en la Universidad.'
                />
                <InputFormik name='cargo'/>
            </Form>)}
        </Formik>
    )
}
export default FormBeneficiario