import DataTable from 'components/DataTable'
import { recursosObj } from 'data/const'
import { classNames } from 'utils/ClassNames'
import { formatCurrency, formatDate } from 'utils/utils'

/** @module Pages/Reembolso/Components/ViewReembolso */

/**
 * Componente, Información detallada del reembolso.
 * @param {object} props Propiedades del componente.
 * @param {object} props.selectedReembolso Reembolso seleccionado.
 * @returns {JSX.Element} Retorna el componente ViewReembolso.
 */
function ViewReembolso({selectedReembolso}) {
    const {
        numero_reembolso, recursos, cheque, fecha_creacion, 
        unidad, papeletas,  comprobante,
        total_pasaje_terrestre, total_pasaje_aereo,
        total_monto_viaticos, total_monto
    } = selectedReembolso

    const columns = [
        {
            field: 'fecha_registro',
            header: 'Fecha Emi.',
            body: rowData => <div className='text-center'>{formatDate(rowData.fecha_registro, 'd-M-Y', null)}</div>,
            bodyClassName: 'text-nowrap'
        },
        {
            field: 'numero_papeleta', 
            header: 'N° Viáti.', 
            body: rowData => {
                const hasDevolucion = (rowData.devolucion_terrestre>0||rowData.devolucion_aereo>0||rowData.devolucion_viaticos>0)
                return <div className={classNames(['text-center', (hasDevolucion&&'text-primary')])}>
                    {rowData.numero_papeleta}{hasDevolucion&&'*'}
                </div>
            },
            headerClassName: 'text-nowrap'
        },
        {
            field: 'nombre_beneficiario', 
            header: 'Nombre', 
            body: rowData => rowData.nombre_beneficiario === 'TOTAL' 
                ? <div className='text-center fw-bold'>TOTAL</div> 
                : (rowData.apellido_beneficiario + ' ' + rowData.nombre_beneficiario),
            footer: 'TOTALES',
            footerClassName: 'text-center',
        },
        {field: 'lugar_viaje', header: 'Lugar', style: {width: '10rem'}},
        {
            field: 'pasaje_aereo_devolucion', 
            header: 'Pas. Aéreo', 
            body: rowData => formatCurrency(rowData.pasaje_aereo_devolucion),
            bodyClassName: 'text-end', 
            footer: formatCurrency(total_pasaje_aereo),
            footerClassName: 'text-end',
            style: {width: '8rem'}
        },
        {
            field: 'pasaje_terrestre_devolucion', 
            header: 'Terrestre', 
            body: rowData => formatCurrency(rowData.pasaje_terrestre_devolucion),
            bodyClassName: 'text-end', 
            footer: formatCurrency(total_pasaje_terrestre),
            footerClassName: 'text-end',
            style: {width: '6.5rem'}
        },
        {
            field: 'monto_viaticos', 
            header: 'Viáticos', 
            body: rowData => formatCurrency(rowData.monto_viaticos),
            bodyClassName: 'text-end', 
            footer: formatCurrency(total_monto_viaticos),
            footerClassName: 'text-end',
            style: {width: '6.5rem'}
        },
        {
            field: 'monto', 
            header: 'Total', 
            body: rowData => formatCurrency(rowData.monto),
            bodyClassName: 'text-end',
            footer: formatCurrency(total_monto),
            footerClassName: 'text-end',
            style: {width: '6.5rem'}
        }
    ]

    return <>
        <div className='row g-2 mb-2'>
            <div className='col-6'><span className='fw-bold'>Número de Reembolso:</span> {numero_reembolso}</div>
            <div className='col-6'><span className='fw-bold'>Fecha de registro:</span> {formatDate(fecha_creacion, 'd/m/Y H:i')}</div>
            <div className='col-6'><span className='fw-bold'>Unidad:</span> {unidad}</div>
            <div className='col-6'><span className='fw-bold'>Recursos:</span> {recursosObj[recursos]}</div>
            {
                cheque ? <>
                    <div className='col-6'><span className='fw-bold'>Cheque:</span> {cheque}</div>
                    <div className='col-6'><span className='fw-bold'>Comprobante:</span> {comprobante}</div>
                </> : (
                    <div className='col-6'><span className='fw-bold'>Cheque:</span> Sin cheque</div>
                )
            }
            <div className='col-12'><span className='fw-bold me-2'>Papeletas de viáticos:</span></div>
        </div>
        <DataTable 
            values={papeletas}
            columns={columns}
            tableStyle={{fontSize: '1rem'}}
        />
    </>
}
export default ViewReembolso