import { useEffect, useRef, useState } from 'react'

import Button from 'components/Button'
import EditableSelect from 'components/EditableSelect'
import DataTable from 'components/DataTable'
import Dialog from 'components/Dialog'
import Input from 'components/Input'
import InputCalendar from 'components/InputCalendar'
import InputLayout from 'components/layouts/InputLayout'
import TitlePage from 'components/TitlePage'
import InputCheckbox from 'components/InputCheckbox'

import PapeletaService from 'services/papeleta.service'
import ApiService from 'services/api.service'
import { buildFilters } from 'utils/utils'
import { formatDate } from 'utils/utils'
import PapeletaPdf from 'pdf/papeleta'
import { estadosObj, initialPagination } from 'data/const'

/** @module Pages/Papeleta/PapeletaReport */

// Valores iniciales de los filtros.
const initialFilters = {
    actividad: '',
    cuenta: '',
    fechaInicio: new Date(),
    fechaFin: '',
    anulado: null,
    page: 1,
    init: false,
    documento: '',
    apellido_beneficiario: ''
}
const date = new Date()
const currentDate = formatDate(date, 'd/m/Y')

/**
 * Página, Reporte de papeletas emitidas.
 * @returns {JSX.Element} Retorna el componente PapeletaReport.
 */
function PapeletaReport() {
    const [filters, setFilters] = useState(initialFilters)
    const [pagination, setPagination] = useState(initialPagination)
    const [show, setShow] = useState({info: false})
    const [papeletas, setPapeletas] = useState([])
    const pdfP = new PapeletaPdf()
    const tableRef = useRef()

    useEffect(() => {
        if (filters.init) {
            handleSearch()
        } else {
            setFilters({...filters, init: true})
        }
    }, [filters.sort, filters.page]) 

    const openInfoDialog = () => setShow({...show, info: true})
    const closeInfoDialog = () => setShow({...show, info: false})
    const handleGenerate = async () => {
        let _papeletas = []
        if (papeletas.length > 0 && pagination?.totalRecords <= pagination?.pageSize) {
            _papeletas = papeletas
        } else {
            const filter = getFilters(filters)
            const response = await PapeletaService.searchPapeletas({filter}, {all: true})
            if (response.status === 200) {
                _papeletas = response.data.data
            }
        }
        if (_papeletas.length > 0) {
            pdfP.listaEmitidos(_papeletas, filters)
        } else {
            openInfoDialog()
        }
    }
    const handleSearch = async () => {
        const {sort, page, ..._filters} = filters
        const postFilters = getFilters(_filters)
        const response = await PapeletaService.searchPapeletas({filter: postFilters}, {...(sort?{sort}:{}), page})
        if (response.status === 200) {
            setPapeletas(response.data.data)
            setPagination(response.data.pagination)
        }
    }
    const getFilters = (values) => {
        const fechaInicio = formatDate(values.fechaInicio||date, 'Y-m-d')
        const fechaFin = formatDate(values.fechaFin||date, 'Y-m-d')
        const _filters = {
            documento: values.documento,
            apellido_beneficiario: values.apellido_beneficiario,
            actividad_id: values?.actividad?.id||null,
            cuenta_id: values?.cuenta?.id||null,
            fecha_registro: [
                `${fechaInicio} 00:00:00`,
                `${fechaFin} 23:59:59`,
            ],
            ...(typeof values.anulado === 'boolean' && {fecha_anulacion: !values.anulado})
        }
        const postFilters = buildFilters(_filters, {
            equal: ['actividad_id', 'cuenta_id', 'documento'],
            between: ['fecha_registro'],
            contain: ['apellido_beneficiario'],
            null: ['fecha_anulacion']
        })
        return postFilters
    }
    const handleSort = (field, order) => {
        const _sort = order === 1 ? 
            { sort: field } 
            : ( order === 0 ? { sort: `-${field}` } : {} )
        setFilters({ ...filters, ..._sort, page: 1 })
    }
    const handlePage = (page) => {
        setFilters({ ...filters, page })
    }
    const handleClean = () => {
        tableRef?.current?.clean()
        setFilters(initialFilters)
        setPapeletas([])
        setPagination(initialPagination)
    }
    const clearPapeletas = () => {
        if (papeletas.length > 0) {
            setPapeletas([])
            setPagination(initialPagination)
        }
    }
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') handleSearch()
    }

    const columns = [
        {
            field: 'numero_papeleta',
            header: 'Nro. P',
            body: rowData => rowData.numero_papeleta + '/' + formatDate(rowData.fecha_registro, 'y'),
            style: {width: '6rem'}
        },
        {
            field: 'fecha_registro',
            header: 'Fecha',
            body: rowData => formatDate(rowData.fecha_registro, 'd-M-Y'),
            bodyClassName: 'text-center',
            sortable: true,
            style: {width: '9rem'}
        },
        {
            field: 'apellido_beneficiario',
            header: 'Beneficiario',
            body: rowData => (rowData.apellido_beneficiario + ' ' + rowData.nombre_beneficiario),
            sortable: true,
        },
        {
            field: 'actividad',
            header: 'Actividad',
            body: rowData => `${rowData.codigo_actividad} - ${rowData.actividad}`,
            bodyClassName: 'text-truncate',
            sortable: true,
        },
        {
            field: 'cuenta',
            header: 'Cuenta',
            body: rowData => `${rowData.codigo_cuenta} - ${rowData.cuenta}`,
            bodyClassName: 'text-truncate',
            sortable: true,
        },
        {
            field: 'estado',
            header: 'Estado',
            body: rowData => estadosObj[rowData.estado],
            style: {width: '10rem'}
        },
    ]
    const infoFooterDialog = <Button variant='text-secondary' startIcon='bi-x-lg' onClick={closeInfoDialog}>Cerrar</Button>

    return <>
        <TitlePage title='Reporte - Lista de Viáticos Emitidos' />
        <div className='content'>
            <div>
                <div className='fs-5 mb-3'>Filtros</div>
                <div onKeyUp={handleKeyDown}>
                    <FiltersPapeletas 
                        filters={filters} setFilters={setFilters} 
                        onClean={handleClean} onSearch={handleSearch}
                        clearPapeletas={clearPapeletas}
                    />
                </div>
                <div className='d-flex align-items-center mb-3'>
                    <div className='fs-5'>Papeletas</div>
                    <div className='ms-auto'>
                        <Button startIcon='pi pi-file-pdf' onClick={handleGenerate}>Generar PDF</Button>
                    </div>
                </div>
                <DataTable 
                    innerRef={tableRef}
                    className='mb-3'
                    values={papeletas}
                    columns={columns}
                    pagination
                    rowsPerPage={pagination.pageSize}
                    totalRecords={pagination.totalRecords}
                    page={pagination.currentPage}
                    onPage={handlePage}
                    onSort={handleSort}
                />
                <Dialog
                    show={show.info}
                    footer={infoFooterDialog}
                >
                    <div className='d-flex align-items-center gap-3'>
                        <div><i className='bi-info-circle fs-3'></i></div>
                        <div>No se encontraron registros</div>
                    </div>
                </Dialog>
            </div>
        </div>
    </>
}
export default PapeletaReport

/**
 * Componente, Filtros de papeletas.
 * @ignore
 * @param {object} props 
 * @param {object} props.filters 
 * @param {function} props.setFilters 
 * @param {function} props.onSearch 
 * @param {function} props.onClean 
 * @returns {JSX.Element} Retorna el componente FiltersPapeletas.
 */
function FiltersPapeletas({filters, setFilters, onSearch, onClean, clearPapeletas}) {
    const [actividades, setActividades] = useState([])
    const [cuentas, setCuentas] = useState([])
    
    const handleFilters = (e) => {
        setFilters({...filters, [e.target.name]: e.target.value})
        clearPapeletas()
    }
    const searchActividades = (actividad) => {
        const loadData = async () => {
            const response = await ApiService.unidad.search(actividad)
            if (response.status === 200) {
                setActividades(response.data)
            }
        }
        if (actividad.length > 0) loadData()
        else setActividades([])
    }
    const searchCuentas = (cuenta) => {
        const loadData = async () => {
            const response = await ApiService.cuenta.search(cuenta)
            if (response.status === 200) setCuentas(response.data)
        }
        if (cuenta.length > 0) loadData()
        else setCuentas([])
    }

    return (
        <div className='d-flex gap-3 mb-4 border rounded py-3 px-4'>
            <div className='row g-3'>
                <InputLayout label={<div style={{width: '5.5rem'}}>Fecha inicio:</div>} inline className='col-md-4'>
                    <InputCalendar name='fechaInicio' value={filters.fechaInicio} onChange={handleFilters} placeholder={currentDate}/>
                </InputLayout>
                <InputLayout label={<div style={{width: '5.5rem'}}>Fecha fin:</div>} inline className='col-md-4'>
                    <InputCalendar name='fechaFin' value={filters.fechaFin} onChange={handleFilters} placeholder='dd/mm/yyyy' />
                </InputLayout>

                <InputLayout label={<div style={{width: '5.5rem'}}>Actividad:</div>} inline className='col-md-4'>
                    <EditableSelect 
                        name='actividad'
                        options={actividades} 
                        value={filters.actividad} 
                        onChange={handleFilters} 
                        onSearch={searchActividades}
                        optionValue='codigo_actividad'
                        valueAsOption placeholder='Buscar' lazy
                        itemTemplate={(item) => <>{item.codigo_actividad + ' / ' + item.abreviacion_da + ' - ' + item.descripcion}</>}
                    />
                </InputLayout>

                <InputLayout label={<div style={{width: '5.5rem'}}>Documento:</div>} inline className='col-md-4'>
                    <Input name='documento' value={filters.documento} onChange={handleFilters} placeholder='CI'/>
                </InputLayout>
                <InputLayout label={<div style={{width: '5.5rem'}}>Beneficiario:</div>} inline className='col-md-4'>
                    <Input name='apellido_beneficiario' value={filters.apellido_beneficiario} onChange={handleFilters} placeholder='Apellido'/>
                </InputLayout>
                <InputLayout label={<div style={{width: '5.5rem'}}>Cuenta:</div>} inline className='col-md-4'>
                    <EditableSelect 
                        name='cuenta'
                        options={cuentas} 
                        value={filters.cuenta} 
                        onChange={handleFilters} 
                        onSearch={searchCuentas}
                        optionValue='codigo_cuenta'
                        valueAsOption placeholder='Buscar' lazy
                        itemTemplate={(item) => <>{item.codigo_cuenta + ' / ' + item.descripcion}</>}
                    />
                </InputLayout>
            </div>
            <div className='row justify-content-end gap-3'>
                <InputLayout label='Anulado:' inline style={{minHeight: '2.25rem'}} className='col-12'>
                    <InputCheckbox name='anulado' value={filters.anulado} onChange={handleFilters} triState style={{fontSize: '1.75rem'}} />
                </InputLayout>
                <div className='col-12 d-flex gap-3'>
                    <Button startIcon='pi pi-filter-slash' variant='outline-blue' onClick={onClean}>Limpiar</Button>
                    <Button startIcon='pi pi-search' onClick={onSearch}>Buscar</Button>
                </div>
            </div>
        </div>
    )
}