import { useMemo, useRef, useState } from 'react'
import { classNames } from 'utils/utils'

/** @module Components/CustomInput */

/**
 * @typedef {object} CustomInputPropsType
 * @property {"text" | "password" | "number" | "decimal" | "currency" | undefined} type Tipo del input.
 * @property {boolean | undefined} isInvalid Especifica si el input tendrá estilos de validación incorrecta.
 * @property {boolean | undefined} isValid Especifica si el input tendrá estilos de validación correcta.
 * @property {boolean | undefined} readOnly Especifica si el input sera solo de lectura.
 * @property {string | undefined} icon Clase de estilo del icono del input.
 * @property {"right" | "left" | undefined} iconPos Posición del icono de la propiedad icon.
 * @property {React.ReactNode | undefined} startIcon Contenido del icono del inicio.
 * @property {React.ReactNode | undefined} endIcon Contenido del icono del final.
 * @property {number | undefined} maxFractionDigits Cantidad maxima de dígitos decimales, solo disponible en input de tipo decimal.
 * @typedef {CustomInputPropsType & React.InputHTMLAttributes<HTMLInputElement>} CustomInputProps
 */

/**
 * Componente CustomInput.
 * @param {CustomInputProps} props Propiedades del componente. CustomInputPropsType + React.InputHTMLAttributes<HTMLInputElement>.
 * @param {React.Ref<HTMLInputElement>} ref
 * @returns Retorno el componente CustomInput.
 */
function CustomInput({
    className='form-control', type='text',
    style, size, icon, iconPos, startIcon, endIcon, 
    maxFractionDigits,
    readOnly, isInvalid, ...props
}) {
    const [typeInput, isNumeric, isDecimal] = useMemo(() => {
        const _isDecimal = ['decimal', 'currency'].includes(type)
        const _isNumeric = _isDecimal || type === 'number'
        return [_isNumeric?'text':type, _isNumeric, _isDecimal]
    }, [type])
    const [focus, setFocus] = useState(false)
    const inputRef = useRef(null)
    const leftIcon = useMemo(() => {
        let _icon = null
        if (startIcon) {
            _icon = startIcon
        } else if (icon && iconPos !== 'right') {
            _icon = <i className={icon}></i>
        }
        return _icon && <span className={'custom-input-icon input-icon-left'}>{_icon}</span>
    }, [startIcon, icon, iconPos])
    const rightIcon = useMemo(() => {
        let _icon = null
        if (endIcon) {
            _icon = endIcon
        } else if (icon && iconPos === 'right') {
            _icon = <i className={icon}></i>
        }
        return _icon && <span className={'custom-input-icon input-icon-right'}>{_icon}</span>
    }, [endIcon, icon, iconPos])

    const handleFocus = () => {
        if (!focus) setFocus(true)
        inputRef?.current?.focus()
    }
    const handleBlur = () => {
        if (focus) setFocus(false)
    }
    const handleOnKeyDown = (e) => {
        const regex = isDecimal ? /[0-9,.]/ : /[0-9]/
        // Si ctrl no esta presionado y la tecla es un carácter.
        if (!e.ctrlKey && e.key.length===1) {
            // Si no pasa el regex o si es decimal, la tecla es punto o coma y el valor actual del input ya tiene un punto.
            if (!regex.test(e.key) || (isDecimal && ['.', ','].includes(e.key) && e.currentTarget.value.includes('.'))) {
                e.preventDefault()
            }
        }
        typeof props.onKeyDown === 'function' && props.onKeyDown(e)
    }
    const handleOnChange = (e) => {
        const regex = type === 'decimal' 
            ? new RegExp(`^\\d*([.]{0,1}[0-9]{0,${maxFractionDigits||''}})?$`) 
            : (type === 'currency' 
                ? /^\d*([.]{0,1}\d{0,2})?$/ 
                : /^\d+$/
            )
        let _value = e.target.value
        if (_value.length > 0 && !regex.test(_value)) {
            // Si NO es valido.
            const regexReplace = isDecimal ? /[^0-9,.]/ : /[^0-9]/
            // limpiar caracteres no numéricos y/o diferentes de punto o coma.
            _value = _value.replace(regexReplace, '')
            // convertir comas en puntos.
            if (isDecimal) _value = _value.replace(/,/g, '.')
            _value = _value.split('').reduce((carry, item) => regex.test(carry+item) ? carry+item : carry, '')
            e.target.value = _value
        }
        typeof props.onChange === 'function' && props.onChange(e)
    }

    return (
        <div 
            className={classNames([
                'custom-input', 
                className, 
                (size&&'custom-input-'+size), 
                props.disabled&&'disabled', 
                isInvalid&&'is-invalid',
                focus&&'focus'
            ])} 
            style={style} 
            onFocus={handleFocus}
            onClick={handleFocus}
            onBlur={handleBlur}
        >
            {leftIcon}
            {
                readOnly 
                    ? props.value 
                    : <input 
                        ref={inputRef} 
                        {...props} 
                        {...(isNumeric && { onChange: handleOnChange, onKeyDown: handleOnKeyDown })}
                        type={typeInput} 
                    />
            }
            {rightIcon}
        </div>
    ) 
}
export default CustomInput