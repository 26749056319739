/**
 * Obtiene los parametros de la URL como objeto.
 * @param {string} search Parametros de la URL.
 * @returns {object} Objeto con los parametros de la url. 
 */
export function getQueryParams(search) {
    let _search = {}
    search = search.charAt(0)==='?' ? search.substring(1) : search
    if (search) {
        try {
            _search = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
        } catch (error) {}
    }
    return _search
}