import { useEffect, useState, useRef } from 'react'
import { classNames } from 'utils/utils'

/** @module Components/InputDropdown */

/**
 * Componente InputDropdown
 * @param {object} props Propiedades del componente
 * @param {Array<string | object> | undefined} props.options Opciones del dropdown.
 * @param {React.ReactNode | undefined} props.itemTemplate Plantilla que se mostrará en las opciones del dropdown.
 * @param {string | undefined} props.name Propiedad name del input.
 * @param {string | undefined} props.id ID del checkbox.
 * @param {string | undefined} props.placeholder Descripción por defecto que se muestra en el dropdown.
 * @param {string | object | undefined} props.value Valor seleccionado.
 * @param {string | undefined} props.optionLabel Especifica la propiedad que se mostrará en las opciones.
 * @param {string | undefined} props.optionsValue Especifica la propiedad del valor seleccionado que se devolverá.
 * @param {boolean | undefined} props.valueAsOption Especifica si el valor devuelto sera igual a la opción seleccionada o no.
 * @param {React.ReactNode | undefined} props.valueTemplate Plantilla que se mostrará del valor seleccionado.
 * @param {string | boolean | undefined} props.filterBy Especifica si se mostrará el filtro de opciones.
 * @param {boolean | undefined} props.lazy Especifica si al filtrar las opciones se cargan dinámicamente o no.
 * @param {Function | undefined} props.onChange Callback que se ejecuta cada vez que el valor de la propiedad value cambia.
 * @param {Function | undefined} props.onBlur Callback que se ejecuta cuando el input pierde el foco.
 * @param {string | undefined} props.className Clase de estilos del componente.
 * @param {React.CSSProperties | undefined} props.style Estilos en linea del componente.
 * @param {boolean | undefined} props.isInvalid Especifica si el input tendrá estilos de validación incorrecta.
 * @param {boolean | undefined} props.isValid Especifica si el input tendrá estilos de validación correcta.
 * @returns {JSX.Element} Retorna el componente InputDropdown.
 */
function InputDropdown({
    name, id, value=null, onChange, onBlur, style,
    options, itemTemplate, placeholder='Seleccione', valueAsOption,
    valueTemplate, lazy, showClear, className,
    optionLabel='label', optionValue='value', filterBy, isInvalid, isValid
}) {
    const [filterValue, setFilterValue] = useState('')
    const [filterOptions, setFilterOptions] = useState([])
    const [selected, setSelected] = useState(null)
    const [selectedOption, setSelectedOption] = useState(null)
    const itemsRef = useRef([])
    const menuRef = useRef()

    useEffect(() => {
        if (options) {
            let filterIndex = null
            let _selectedOption = null
            const _options = options.filter((option, index) => {
                let result = false
                const typeOption = typeof option
                const typeValue = value === null ? 'number' : typeof value
                if (!lazy && filterBy && filterValue) {
                    const _value = (typeOption === 'object' ? option[filterBy] : option).toLowerCase()
                    result = _value.includes(filterValue.toLowerCase())
                    if (result) filterIndex = filterIndex === null ? 0 : filterIndex+1
                } else {
                    result = true
                }
                if (!_selectedOption) {
                    if (typeValue === 'object') {
                        if (typeOption === 'object' && option[optionValue] === value[optionValue]) {
                            _selectedOption = {
                                option, index, filterIndex, type: typeOption, 
                                label: option[optionLabel], value: option[optionValue]
                            }
                        }
                    } else {
                        if (typeOption === 'object' && !valueAsOption) {
                            if (option[optionValue] === value) {
                                _selectedOption = {
                                    option, index, filterIndex, type: typeOption, 
                                    label: option[optionLabel], value: option[optionValue]
                                }
                            }
                        } else if (option === value) {
                            _selectedOption = {option, index, filterIndex, type: typeOption, label: option, value: option}
                        }
                    }
                }
                return result
            })
            setFilterOptions(_options)
            setSelectedOption(_selectedOption)
            if (_selectedOption) setSelected(filterBy ? _selectedOption.filterIndex : _selectedOption.index)
            menuRef.current.scrollTop = 0

        }
    }, [options, filterValue, value]) // eslint-disable-line

    const handleSelected = (_value) => {
        if (typeof onChange === 'function') {
            onChange(getEvent(_value))
        }
        if (menuIsOpen) closeMenu()
    }
    const handleBlur = () => {
        if (typeof onBlur === 'function') {
            const timeout = setTimeout(() => {
                onBlur(getEvent(value))
                clearTimeout(timeout)
            }, 150)
        }
    }
    const getEvent = (_value) => { return { target: { name: name||'', value: _value, id: id||'' }, value: _value } }
    const handlePrevent = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }
    const getValue = (_option) => {
        let _value = ''
        if (valueAsOption) {
            _value = _option
        } else {
            _value = typeof _option === 'object' ? _option[optionValue] : _option
        }
        return _value
    }

    const getItems = () => {
        const _items = filterOptions.map((option, index) => {
            const typeOption = typeof option
            const _value = getValue(option)
            const _label = typeOption === 'object' ? option[optionLabel] : option
            const _val = typeOption === 'object' ? option[optionValue] : option
            const customLabel = typeof itemTemplate === 'function' ? itemTemplate(option) : itemTemplate
            return (
                <li 
                    ref={(el) => (itemsRef.current[index] = el)}
                    className={classNames([
                        'dropdown-item', 
                        index===selected&&'hover', 
                        (selectedOption && selectedOption.value===_val)&&'active'
                    ])}
                    onMouseEnter={() => {
                        setSelected(index)
                    }}
                    onClick={() => {
                        handleSelected(_value)
                        setSelected(index)
                    }}
                    key={(_val||index).toString().replace(/ /g, '')}
                >
                    {itemTemplate ? customLabel : _label}
                </li>
            )
        })
        const emptyOptions = [
            <li className='px-3 fw-normal' key='empty' >
                Sin resultados.
            </li>
        ]
        return _items.length > 0 ? _items : emptyOptions
    }
    const handleKeyDown = (e) => {
        if (['Enter'].includes(e.key)) {
            e.preventDefault()
        }
    }
    const handleKeyUp = (e) => {
        const open = menuIsOpen()
        if (e.key === 'Enter') {
            if (open && selected !== null) {
                const _optionSelected = filterOptions[selected]
                if (value && typeof value === 'object' ? _optionSelected[optionValue]!==value[optionValue] : _optionSelected!==value) {
                    handleSelected(getValue(_optionSelected))
                    closeMenu()
                }
            }
        } else if (e.key === 'ArrowDown') {
            if (open) {
                if (selected === null) {
                    itemsRef.current[0].scrollIntoView({block: 'nearest'})
                    setSelected(0)
                } else if (selected+1<filterOptions.length) {
                    itemsRef.current[selected+1].scrollIntoView({block: 'nearest'})
                    setSelected(selected+1)
                }
            }
        } else if (e.key === 'ArrowUp') {
            if (open) {
                if (selected===0) setSelected(null)
                else if (selected>0) {
                    itemsRef.current[selected-1].scrollIntoView({block: 'nearest'})
                    setSelected(selected-1)
                }
            }
        }
    }
    const menuIsOpen = () => menuRef?.current?.classList.contains('show')
    const closeMenu = () =>  menuRef?.current?.classList.remove('show')
    const handleClear = (e) => {
        handleSelected('')
        setSelected(null)
    }

    const filter = !filterBy ? null : (
        <li className='dropdown-filter' onClick={handlePrevent} >
            <input 
                className='form-control' 
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
                onKeyUp={handleKeyUp}
            />
        </li>
    )

    return (
        <div 
            className={classNames([
                'dropdown input-dropdown', 
                className, 
                (isInvalid && 'is-invalid'),
                (isValid && 'is-valid'),
            ])||undefined} 
            onBlur={handleBlur}
            style={style}
        >
            <div 
                className={classNames([
                    'dropdown-select form-control', 
                    isInvalid && 'is-invalid', 
                    showClear && 'dropdown-clearable'
                ])} 
                tabIndex={0}
                data-bs-toggle='dropdown' aria-expanded='false'
                onKeyUp={handleKeyUp} onKeyDown={handleKeyDown}
            >
                {
                    selectedOption 
                        ? <>
                            <span className='dropdown-label'>{
                                valueTemplate ? (
                                    typeof valueTemplate === 'function' ? valueTemplate(selectedOption.option) : valueTemplate
                                ) : selectedOption.label
                            }</span>
                            {showClear && <i className='clear-icon bi-x' onClick={handleClear}></i>}
                            
                        </>
                        : <span className='dropdown-label'>{placeholder}</span>
                }
                <span className='dropdown-icon bi-chevron-down'></span>
            </div>
            <ul ref={menuRef} className='dropdown-menu' >
                {filter}
                {getItems()}
            </ul>
        </div>
    )
}
export default  InputDropdown