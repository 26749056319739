import PdfViewer from 'components/PdfViewer'
import { useEffect, useState } from 'react'
import ApiService from 'services/api.service'

/** @module Components/ViewFilePdf */
// Modificar componente selected por filename
/**
 * Visualizador de archivos pdf. Busca el archivo en servicio por su nombre.
 * @param {object} props
 * @param {object} props.filename Nombre del archivo a mostrar 
 * @returns {JSX.Element} Retorna el componente VieweFilePdf
 */
function ViewFilePdf({selected}) {
    const [file, setFile] = useState(null)

    useEffect(() => {
        const loadData = async () => {
            const response = await ApiService.getServiceFile(selected.adjunto)
            if (response.status === 200) setFile(response.data.data.b64_file)
        }
        if (selected) loadData()
    }, [selected])

    return <PdfViewer base64={file} />
}
export default ViewFilePdf