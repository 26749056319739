/** @module Components/Welcome */
/**
 * Pantalla de inicio de sección.
 * @param {object} props Propiedades del componente.
 * @param {string} props.img Ruta de la imagen.
 * @param {string} props.description Descripción del inicio de sección.
 * @param {string} props.section Nombre de la sección.
 * @returns {JSX.Element} Retorna el componente Welcome
 */
function Welcome({img, description, section}) {

    return (
        <div className='home-section flex-1 d-flex flex-column justify-content-center'>
            <div className='home-container row g-0'>
                <div className='col-sm-auto bg-blue text-center p-5'>
                    <img src={img} alt={section} style={{height: '15rem'}} />
                </div>
                <div className='col text-white d-flex flex-column align-items-center justify-content-center gap-3 p-5' style={{backgroundColor: '#013976'}}>
                    <div className='text-center home-text-1' style={{lineHeight: 1.1}}>
                        <div className='fw-lighter'>Bienvenido</div>
                        <div className='fw-light home-text-2'>a la sección de</div>
                        <div className='fw-bold' style={{letterSpacing: '-2px'}}>{section.toUpperCase()}</div>
                    </div>
                    <div className='text-center fw-light fs-5'>
                        {description}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Welcome