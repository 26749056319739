import { useEffect, useState } from 'react'
import { Form as FormFormik, Formik } from 'formik'
import { InputFormik } from 'components/formik/formikFormComponents'
import PickList from 'components/PickList'
import ApiService from 'services/api.service'

/** @module Pages/Categoria/Components/FormCategoria */

/**
 * Componente, Formulario de registro/edición de categorías.
 * @param {object} props Propiedades del componente.
 * @param {React.MutableRefObject<any>} props.formRef Referencia del formulario.
 * @param {Function} props.onSubmit Callback a ejecutar cuando se de submit del formulario.
 * @param {object} props.selected Categoría seleccionado.
 * @returns {JSX.Element} Retorna el componente FormCategoria.
 */
function FormCategoria({formRef, onSubmit, selected}) {
    const [cargos, setCargos] = useState({
        currentCargos: [],
        availableCargos: []
    })
    const initialValues = selected ? selected : { 
        descripcion_cargos: '',
        cargos: [],
    }
    
    useEffect(()=>{
        console.log(selected)
        const loadCargos = async () => {
            const response = await ApiService.cargo.getToAssign(selected?.reglamento_id)
            if (response.status === 200) {
                const availableCargos = response.data
                setCargos({availableCargos, currentCargos: selected?selected.cargos:[]})
            }
        }
        loadCargos()
    }, [selected])

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={values=>onSubmit({...values, cargos: cargos.currentCargos})}
        >
            {() => (
                <FormFormik>
                    <div>
                        <InputFormik name='descripcion_cargos' label='Descripción Cargos' as='textarea' style={{resize: 'none'}}/>
                        <div>
                            <label className='form-label'>Cargos</label>
                            <PickList 
                                style={{height: '25rem'}}
                                optionLabel='descripcion' 
                                source={cargos.availableCargos} 
                                target={cargos.currentCargos} 
                                sourceHeader='Disponible'
                                targetHeader='Seleccionado'
                                onChange={e=>setCargos({ availableCargos: e.source, currentCargos: e.target })} 
                                filterBy='descripcion'
                            />
                        </div>
                    </div>
                </FormFormik>
            )}
        </Formik>
    )
}

export default FormCategoria