import { ErrorMessage, Field } from 'formik'
import { toPascalCase } from 'utils/utils'
import InputCheckbox from '../InputCheckbox'

export default function CheckboxFormik({name='', label='', containerClassName, containerStyle, reverse, ...props}) {
    label = label ? label : toPascalCase(name)
    return (
        <div className={containerClassName} style={containerStyle}>
            <label className='d-flex align-items-center'>
                {reverse && <span className='me-2'>{label}</span>}
                <Field name={name}>
                    {({field}) => <InputCheckbox {...props} {...field}/>}
                </Field>
                {!reverse && <span className='ms-2'>{label}</span>}
            </label>
            { name ? <ErrorMessage name={name}>{msg => <div className='invalid-feedback d-block'>{msg}</div>}</ErrorMessage> : null}
        </div>
    )
}
