import { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'

import { CheckboxFormik, InputFormik, SelectFormik } from 'components/formik/formikFormComponents'
import { cuentaValidators } from 'utils/validators/validators'
import ApiService from 'services/api.service'
import InputLayout from 'components/layouts/InputLayout'
import Input from 'components/Input'

/** @module Pages/Cuenta/Components/FormCuenta */

/**
 * Componente, Formulario de registro/edición de cuenta.
 * @param {object} props Propiedades del componente.
 * @param {React.MutableRefObject<any>} props.formRef Referencia del formulario.
 * @param {Function} props.onSubmit Callback a ejecutar cuando se de submit del formulario.
 * @param {object} props.selected Cuenta seleccionado.
 * @param {"admin" | "viaticos"} props.type Especifica la sección de la que se mostrarán las opciones.
 * @returns {JSX.Element} Retorna el componente FormCuenta.
 */
function FormCuenta({formRef, onSubmit, selectedCuenta, type}) {
    const [dirAdtvas, setDirAdtvas] = useState([])
    const initialValues = selectedCuenta ? selectedCuenta : { 
        codigo: '', 
        descripcion: '', 
        da_id: '',
        nombre_corto: ''
    }

    useEffect(()=>{
        const loadDirAdtvas = async () => {
            const response = await ApiService.dirAdtvas.getAll()
            if (response.status === 200) setDirAdtvas(response.data.data)
        }
        loadDirAdtvas()
    }, [])

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={cuentaValidators}
            onSubmit={onSubmit}
        >
            {() => (
                <Form>
                    {type === 'admin' && <>
                        <SelectFormik 
                            name='da_id' 
                            label='Dirección Administrativa (DA)'
                            options={dirAdtvas}
                            optionLabel='descripcion'
                            optionValue='id'
                            itemTemplate={item=>`${item.codigo} ${item.descripcion}`}
                            valueTemplate={value=>`${value.codigo} ${value.descripcion}`}
                        />
                        <InputFormik name='codigo' label='Código' />
                        <InputFormik name='descripcion' label='Nombre' />
                        <InputFormik 
                            name='nombre_corto' 
                            label='Nombre Corto'
                            info='Utilizado en el pdf de las Papeletas de Pago de Pasajes y/o Viáticos.'
                        />
                        <CheckboxFormik 
                            name='activo' 
                            containerClassName='col-12 mb-3 align-self-end' 
                            style={{fontSize: '1.25rem'}} 
                        />
                    </>}
                    {type === 'viaticos' && <>
                        <InputLayout label='Nombre'>
                            <Input value={selectedCuenta.descripcion} readOnly />
                        </InputLayout>
                        <InputFormik 
                            name='nombre_corto' 
                            label='Nombre Corto'
                            info='Utilizado en el pdf de las Papeletas de Pago de Pasajes y/o Viáticos.' 
                        />
                    </>}
                </Form>
            )}
        </Formik>
    )
}
export default FormCuenta