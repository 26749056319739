import { API } from './connection'
import authHeader from './authHeader'

const AuthService = {
    
    login: async (credentials) => {
        return API.post('user/login', credentials)
            .then(res => { 
                return {...res.data, status: res.status} 
            })
            .catch(err => { 
                return Promise.reject(err.response ? {...err.response.data, status: err.response.status} : {}) 
            })
    },

    loginGoogle: async (token) => {
        let response = {}
        await API.post('user/login-google', {token})
            .then(res => response = res)
            .catch(err => response = err.response ? err.response : {})
        return response
    },

    getUserAuth: async () => {
        let response = {}
        await API.get('user/get-user-auth', { headers: authHeader() })
            .then(res => response = res)
            .catch(err => response = err.response ? err.response : {})
        return response
    },

}

export default AuthService
