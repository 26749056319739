/** @module Utils/numberName */

// novecientos noventa y nueve mil millones novecientos noventa y nueve mil novecientos noventa y nueve mil 
//pos num  12 11 10   9 8 7   6 5 4   3 2  1
//num       9  9  9   9 9 9   9 9 9   9 9  9
//pos arr   0  1  2   3 4 5   6 7 8   9 10 11
/**
 * Obtiene el nombre del número.
 * @param {number | string} number Número a obtener el nombre.
 * @param {"floor" | "ceil" | "round" | "trunc"} roundingMethod Tipo de redondeo ['floor', 'ceil', 'round', 'trunc'], default 'floor'
 * @returns {string} Retorna el nombre del número.
 */
export function numberName(number, roundingMethod='floor') {
    if (typeof number === 'string') {
        number = parseInt(number)
    } else if (typeof number !== 'number') {
        number = 0
    }
    if (typeof number === 'number') {
        if (roundingMethod === 'ceil') number = Math.ceil(number)
        else if (roundingMethod === 'round') number = Math.round(number)
        else if (roundingMethod === 'trunc') number = Math.trunc(number)
        else number = Math.floor(number)
    }
    number = number.toString()
    const nn = numberNames
    let name = ''
    const len = number.length
    for (let i = 0; i < len; i++) {
        const num = number.charAt(i)
        const pos = len-i
        if ([12, 9, 6, 3].includes(pos)) {
            if (num !== '0') {
                if (num === '1' && number.charAt(i+1) === '0' && number.charAt(i+2) === '0') {
                    name += 'cien '
                } else {
                    name += (nn[num+'00'] + ' ')
                }
            }
        } else if ([11, 8, 5, 2].includes(pos)) {
            if (num !== '0') {
                if (num === '1') {
                    name += (nn[num+number.charAt(i+1)] + ' ')
                } else if (num === '2') {
                    if (number.charAt(i+1) === '0') {
                        name += 'veinte '
                    } else {
                        name += nn[num+'0']
                    }
                } else {
                    name += (nn[num+'0'] + ' ')
                    if (number.charAt(i+1) !== '0') name += 'y '
                }
            }
        } else if ([10, 7, 4, 1].includes(pos)) {
            if (num !== '0') {
                if (!(len > pos && number.charAt(i-1) === '1')) {
                    if ([10, 7, 4].includes(pos) && num === '1') {
                        name += 'un '
                    } else {
                        name += (nn[num] + ' ')
                    }
                }
            } else {
                if (len === 1) name = 'cero'
            }
            if (pos === 10) name += 'mil millones '
            else if (pos === 7) {
                if (num === '1' && len === pos) {
                    name += 'millón '
                } else {
                    name += 'millones '
                }
            } else if (pos === 4) name += 'mil '
        }
    }
    return name
}

const numberNames = {
    1: 'uno',
    2: 'dos',
    3: 'tres',
    4: 'cuatro',
    5: 'cinco',
    6: 'seis',
    7: 'siete',
    8: 'ocho',
    9: 'nueve',
    10: 'diez',
    11: 'once',
    12: 'doce',
    13: 'trece',
    14: 'catorce',
    15: 'quince',
    16: 'dieciseis',
    17: 'diecisiete',
    18: 'dieciocho',
    19: 'diecinueve',
    20: 'veinti',
    30: 'treinta',
    40: 'cuarenta',
    50: 'cincuenta',
    60: 'sesenta',
    70: 'setenta',
    80: 'ochenta',
    90: 'noventa',
    100: 'ciento',
    200: 'doscientos',
    300: 'trescientos',
    400: 'cuatrocientos',
    500: 'quinientos',
    600: 'seiscientos',
    700: 'setecientos',
    800: 'ochocientos',
    900: 'novecientos',
}