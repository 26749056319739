import { classNames } from "utils/utils"

/**
 * Componente List.
 * @param {object} props Propiedades del componente.
 * @param {string} props.className Clase de estilos del componente.
 * @param {Array<any>} props.items Items de la lista.
 * @param {string} props.itemLabel Propiedad de los items a mostrar en la lista.
 * @param {Function} props.itemTemplate Función que devuelve la plantilla de los items.
 * @param {React.CSSProperties} props.style Estilos en linea del componente. 
 * @returns Retorna el componente List.
 */
export default function List({className, items, itemLabel = 'label', itemTemplate, style}) {

    const buildItem = (item, index) => (
        <div key={index} className="list-item">
            {typeof itemTemplate === 'function'
                ? itemTemplate(item, index)
                : (typeof item === "object" && item !== null ? item[itemLabel] : item)
            }
        </div>
    )

    return (
        <div className={classNames(["list", className])} style={style}>
            {items?.map(buildItem)}
        </div>
    )
}