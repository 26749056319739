import * as Yup from 'yup'
import { messagesValidators, requiredObject } from './validators.util'

export const cuentaValidators = Yup.object({
    codigo: Yup.string().trim()
        .max(6, messagesValidators.max(6))
        .matches(/^[0-9]+$/, messagesValidators.onlyNumbers)
        .required(messagesValidators.required),
    descripcion: Yup.string().trim()
        .max(64, messagesValidators.max(64))
        .required(messagesValidators.required),
    da_id: Yup.string()
        .required(messagesValidators.required),
})

export const destinoValidators = Yup.object({
    lugar: Yup.string().trim()
        .max(32, messagesValidators.max(32))
        .required(messagesValidators.required),
    descripcion: Yup.string().trim()
        .max(255, messagesValidators.max(255))
        .required(messagesValidators.required),
})

export const dirAdtvaValidators = Yup.object({
    codigo: Yup.string().trim()
        .max(2, messagesValidators.max(2, 'números'))
        .required(messagesValidators.required),
    descripcion: Yup.string().trim()
        .max(64, messagesValidators.max(64))
        .required(messagesValidators.required),
    abreviacion: Yup.string().trim()
        .max(10, messagesValidators.max(10))
        .required(messagesValidators.required),
})

export const cargoInvitadoValidators = Yup.object({
    descripcion: Yup.string().trim()
        .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/, messagesValidators.onlyCharacters)
        .required(messagesValidators.required),
})

export const escalaViaticosValidators = Yup.object({
    monto: Yup.string()
        .matches(/^[0-9.]+$/, messagesValidators.onlyNumbers)
        .required(messagesValidators.required),
    moneda: Yup.string()
        .required(messagesValidators.required),
    categoria_id: Yup.string()
        .required(messagesValidators.required),
    destino_id: Yup.string()
        .required(messagesValidators.required),
})

export const loginValidators = Yup.object({
    username: Yup.string()
        .required(messagesValidators.required),
    password: Yup.string()
        .required(messagesValidators.required),
})

export const memoValidators = Yup.object({
    numero_memo: Yup.string().trim()
        .required(messagesValidators.required),
})

export const papeletaValidators = Yup.object({
    lugar_viaje: Yup.string().trim()
        .required(messagesValidators.required),
    doc_autorizacion: Yup.string().trim()
        .required(messagesValidators.required),
    fecha_llegada: Yup.string()
        .required(messagesValidators.required),
    fecha_salida: Yup.string()
        .required(messagesValidators.required),
    actividad: Yup.mixed()
        .test(requiredObject),
    cargo_cta: Yup.mixed()
        .test(requiredObject),
    dias: Yup.string()
        .required(messagesValidators.required),
    pasaje_terrestre: Yup.string().matches(/^[0-9.,]+$/),
    pasaje_aereo: Yup.string().matches(/^[0-9.,]+$/),
    num_terrestre: Yup.string()
        .matches(/^[0-9]+$/)
        .when('pasaje_terrestre', (pasaje_terrestre, schema) => {
            return pasaje_terrestre ? schema.required(messagesValidators.required) : schema
        }),
    num_aereo: Yup.string()
        .matches(/^[0-9]+$/)
        .when('pasaje_aereo', (pasaje_aereo, schema) => {
            return pasaje_aereo ? schema.required(messagesValidators.required) : schema
        }),
    dias_viaticos: Yup.string()
        .matches(/^[0-9]+$/)
        .when('viaticos', (viaticos, schema) => {
            return viaticos ? schema.required(messagesValidators.required) : schema
        }),
})

export const reembolsoValidator = Yup.object({
    unidad: Yup.mixed()
        .test(requiredObject),
    recursos: Yup.string()
        .required(messagesValidators.required),
})

export const chequeValidator = Yup.object({ 
    cheque: Yup.string().trim()
        .max(8, messagesValidators.max(8))
        .required(messagesValidators.required), 
    comprobante: Yup.string().trim()
        .max(8, messagesValidators.max(8))
        .required(messagesValidators.required) 
})

export const unidadReembolsosValidator = Yup.object({ 
    descripcion: Yup.string().trim()
        .required(messagesValidators.required)
})

export const reglamentoValidators = Yup.object({
    tipo: Yup.string()
        .required(messagesValidators.required),
    numero: Yup.string().trim()
        .required(messagesValidators.required),
})

export const roleValidators = Yup.object({
    rol: Yup.string().trim()
        .max(64, messagesValidators.max(64))
        .required(messagesValidators.required),
})

export const solicitudValidator = Yup.object({
    objetivo_viaje: Yup.string().trim()
        .required(messagesValidators.required),
})

export const beneficiarioValidator = Yup.object({
    nombre_beneficiario: Yup.string().trim()
        .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/, messagesValidators.onlyCharacters)
        .required(messagesValidators.required),
    apellido_beneficiario: Yup.string().trim()
        .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/, messagesValidators.onlyCharacters)  
        .required(messagesValidators.required),
    documento: Yup.string().trim()
        .required(messagesValidators.required),
    cargo: Yup.string().trim()
        .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/, messagesValidators.onlyCharacters)
        .required(messagesValidators.required),
})

export const invitadoValidator = Yup.object({
    nombre_beneficiario: Yup.string().trim()
        .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/, messagesValidators.onlyCharacters)
        .required(messagesValidators.required),
    apellido_beneficiario: Yup.string().trim()
        .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/, messagesValidators.onlyCharacters)  
        .required(messagesValidators.required),
    cargo_obj: Yup.mixed()
        .test(requiredObject)
})

export const udEjecutoraValidators = Yup.object({
    codigo: Yup.string().trim()
        .max(2, messagesValidators.max(2, 'números'))
        .required(messagesValidators.required),
    descripcion: Yup.string().trim()
        .max(64, messagesValidators.max(64))
        .required(messagesValidators.required),
    da_id: Yup.string()
        .required(messagesValidators.required),
})

export const unidadValidators = Yup.object({
    programa: Yup.string().trim()
        .length(3, messagesValidators.length(3, 'dígitos'))
        .matches(/^[0-9]+$/, messagesValidators.onlyNumbers)
        .required(messagesValidators.required),
    proyecto: Yup.string().trim()
        .min(3, messagesValidators.min(3, 'dígitos'))
        .max(16, messagesValidators.max(16, 'dígitos'))
        .matches(/^[0-9]+$/, messagesValidators.onlyNumbers)
        .required(messagesValidators.required),
    actividad: Yup.string().trim()
        .length(2, messagesValidators.length(2, 'dígitos'))
        .matches(/^[0-9]+$/, messagesValidators.onlyNumbers)
        .required(messagesValidators.required),
    descripcion: Yup.string().trim()
        .max(128, messagesValidators.max(128))
        .required(messagesValidators.required),
    finfun: Yup.string()
        .nullable()
        .max(5, messagesValidators.max(5)),
    da_id: Yup.string()
        .required(messagesValidators.required),
    ue_id: Yup.string()
        .required(messagesValidators.required),
})

const rulesUser = {
    username: Yup.string().trim()
        .required(messagesValidators.required)
        .max(64, messagesValidators.max(64)),
    nombre: Yup.string().trim()
        .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/, messagesValidators.onlyCharacters)
        .max(64, messagesValidators.max(64))
}

export const userCreateValidators = Yup.object({
    ...rulesUser,
    password: Yup.string()
        .required(messagesValidators.required),
    confirmar: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Ambas contraseñas deben coincidir')
        .required(messagesValidators.required)
})

export const userUpdateValidators = Yup.object(rulesUser)

export const changePasswordValidators = Yup.object({
    currentPassword: Yup.string()
        .required(messagesValidators.required),
    newPassword: Yup.string()
        .required(messagesValidators.required),
    repeatPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Ambas contraseñas deben coincidir')
        .required(messagesValidators.required)
})

export const informeValidators = Yup.object({
    lugar_viaje: Yup.string()
        .required(messagesValidators.required),
    conclusiones: Yup.string()
        .required(messagesValidators.required),
    fecha_salida: Yup.string()
        .required(messagesValidators.required),
    fecha_llegada: Yup.string()
        .required(messagesValidators.required),
    fecha_registro: Yup.string()
        .required(messagesValidators.required),
})