import Button from 'components/Button'
import Input from 'components/Input'
import InputDropdown from 'components/InputDropdown'
import List from 'components/List'
import TitlePage from 'components/TitlePage'
import InputLayout from 'components/layouts/InputLayout'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ApiService from 'services/api.service'
import { setHttpMessage } from 'store/messageSlice'
import { fileToBase64 } from 'utils/FileToBase64'

const types = [
    {label: 'String', value: 'string'},
    {label: 'Integer', value: 'int'},
    {label: 'Float', value: 'float'},
    {label: 'Boolean', value: 'boolean'},
    {label: 'Array', value: 'array'},
    {label: 'JSON', value: 'json'},
]
const manualesKeys = [
    'manualBeneficiario', 
    'manualDAF', 
    'manualPresupuesto', 
    'manualViaticos', 
    'manualCaja', 
    'manualAdministracion'
]
const manualDescriptions = {
    manualBeneficiario: 'Manual de beneficiario', 
    manualDAF: 'Manual de DAF', 
    manualPresupuesto: 'Manual de presupuestador', 
    manualViaticos: 'Manual de encargado de viáticos', 
    manualCaja: 'Manual de encargado de caja', 
    manualAdministracion: 'Manual de administración'
}

function Config() {
    const dispatch = useDispatch()
    const [configs, setConfigs] = useState([])
    const [manuales, setManuales] = useState([])

    useEffect(() => {
        const loadData = async () => {
            const response = await ApiService.config.search({keys: ['configMemo', 'configPapeleta']})
            if (response.status===200) {
                setConfigs(Object.values(response.data).filter(config => !!config).map(config => {
                    let valor = config.valor
                    if (config.tipo_valor==='json') {
                        try {
                            valor = JSON.stringify(JSON.parse(config.valor), null, 4)
                        } catch (error) {}
                    }
                    return {...config, valor}
                }))
            }
        }
        const loadManuales = async () => {
            const response = await ApiService.config.search({keys: manualesKeys})
            if (response.status===200) {
                const {data} = response
                setManuales(manualesKeys.map(key => {
                    return data[key] ? 
                        {...data[key], file: null}
                        : {clave: key, valor: '', descripcion: manualDescriptions[key], file: null}
                }))
            }
        }
        loadData()
        loadManuales()
    }, [])

    const showPdf = async (filename) => {
        if (filename) {
            const response = await ApiService.getLocalFile(filename)
            if (response.status===200) {
                const base64File = response.data.file
                fetch(`data:application/pdf;base64,${base64File}`).then(async (res) => {
                    const fileBlob = await res.blob()
                    const fileURL = URL.createObjectURL(fileBlob)
                    window.open(fileURL)
                })
            } else {
                dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            }
        }
    }

    const updateConfig = (index, data) => {
        setConfigs(configs.map((config, i) => i===index?data:config))
    }
    const handleSaveConfig = async (data) => {
        const {clave, ..._data} = data
        const __data = data.tipo_valor==='json'?{..._data, valor: JSON.stringify(JSON.parse(_data.valor))}:_data
        const response = await ApiService.config.update(clave, __data)
        dispatch(setHttpMessage({status: response.status, title: response.data.message}))
    }
    const configRow = (config, index) => {
        const handleChange = (e) => {
            updateConfig(index, {...config, [e.target.name]: e.target.value})
        }

        return (
            <div className='row g-3'>
                <div className='col-md'>
                    <div className='row g-3'>
                        <InputLayout label='Descripción:' className='col-md-6' inline>
                            <Input name='descripcion' value={config.descripcion} onChange={handleChange} />
                        </InputLayout>
                        <InputLayout label='Tipo valor:' className='col-md-6' inline>
                            <InputDropdown
                                name='tipo_valor' 
                                value={config.tipo_valor} 
                                onChange={handleChange}
                                options={types}
                            />
                        </InputLayout>
                        <InputLayout label='Valor:' className='col mb-0'>
                            <Input 
                                name='valor'
                                value={config.valor}
                                onChange={handleChange} 
                                as={config.tipo_valor==='json'?'textarea':undefined} 
                                style={{
                                    minHeight: '10rem', 
                                    fontFamily: 'var(--bs-font-monospace)'
                                }}
                            />
                        </InputLayout>
                    </div>
                </div>
                <Button 
                    className='col-md-auto' 
                    startIcon='pi pi-save' 
                    title='Guardar'
                    onClick={() => handleSaveConfig(config)}
                />
            </div>
        )
    }

    const updateManual = (index, data) => {
        setManuales(manuales.map((manual, i) => i===index?data:manual))
    }
    const handleSaveConfigFile = async (data, index) => {
        let base64File = ''
        let _data = data
        if (data.file) {
            base64File = await fileToBase64(data.file)
            _data = {...data, file: base64File}
        }
        const response = await ApiService.config.updateFile(_data)
        dispatch(setHttpMessage({status: response.status, title: response.data.message}))
        if (response.status===200) updateManual(index, {...data, valor: response.data.filename})
    }
    const manualRow = (manual, index) => {
        const handleChange = (e) => {
            const name = e.target.name
            const value = name==='file' ? e.target.files[0] : e.target.value
            updateManual(index, {...manual, [name]: value})
        }

        return (
            <div className='row g-3'>
                <div className='col-md'>
                    <div className='row g-3'>
                        <InputLayout label='Descripción:' className='col-md-6' inline>
                            <Input name='descripcion' value={manual.descripcion} onChange={handleChange} />
                        </InputLayout>
                        <InputLayout label='Valor:' className='col-md-6' inline>
                            <Input 
                                name='file'
                                onChange={handleChange}
                                type='file'
                                accept='.pdf'
                            />
                        </InputLayout>
                    </div>
                </div>
                <Button 
                    variant='tab-blue'
                    active
                    disabled={!manual.valor}
                    startIcon='bi-file-earmark-pdf' 
                    className='col-md-auto me-2' 
                    style={{fontSize: '1.25rem', lineHeight: 1}}
                    onClick={() => showPdf(manual.valor)}
                />
                <Button 
                    className='col-md-auto' 
                    startIcon='pi pi-save' 
                    title='Guardar'
                    onClick={() => handleSaveConfigFile(manual, index)}
                />
            </div>
        )
    }

    return <>
        <TitlePage title='Configuraciones'/>
        <div className='content align-items-center'>
            <div className='w-xl-75'>
                <List
                    items={configs}
                    itemTemplate={configRow}
                />
                <div className='py-3 fs-5'>Manuales</div>
                <List
                    items={manuales}
                    itemTemplate={manualRow}
                />
            </div>
        </div>
    </>
}

export default Config