import { classNames } from "utils/utils"

/**
 * Componente Avatar.
 * @param {object} props Propiedades del componente.
 * @param {React.ReactNode} props.children Contenido del Avatar.
 * @param {"rounded" | "circular" | "square" | string} props.shape Forma del Avatar.
 * @param {string} props.className Clase de estilos del Avatar.
 * @param {React.CSSProperties} props.style Estilos en linea del Avatar.
 * @param {"sm" | "lg" | string} props.size Tamaño del Avatar.
 * @param {object} props.imgProps Propiedades de la imagen. El Avatar se toma como imagen.
 * @param {object} props.iconProps Propiedades del icono. El avatar se toma como icono.
 * @returns {JSX.Element} Retorna el componente Avatar.
 */
export default function Avatar({children, className, iconProps, imgProps, shape, size, style}) {
    return (
        <div 
            className={classNames([
                "avatar", 
                className, 
                `avatar-${shape||"circular"}`, 
                (size&&`avatar-${size}`)
            ])}
            style={style}
        >
            {
                children 
                || (typeof imgProps === "object" ? <img alt='' {...imgProps}/> : '')
                || (typeof iconProps === "object" ? <span {...iconProps}></span> : '')
            }
        </div>
    )
}