import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { ErrorMessage, Form, Formik, useFormikContext } from 'formik'
import { differenceInDays, differenceInMinutes, format } from 'date-fns'

import { CalendarFormik, EditableSelectFormik, InputFormik, SelectFormik, ToggleButtonFormik } from 'components/formik/formikFormComponents'
import Button, { ButtonIcon } from 'components/Button'
import SelectEscalaViaticos from './SelectEscalaViaticos'

import { papeletaValidators } from 'utils/validators/validators'
import SolicitudService from 'services/solicitud.service'
import PapeletaService from 'services/papeleta.service'
import MemoService from 'services/memo.service'
import ApiService from 'services/api.service'
import { formatDate } from 'utils/utils'
import { parseDate } from 'utils/utils'
import { setHttpMessage } from 'store/messageSlice'
import { recursos } from 'data/const'
import PapeletaPdf from 'pdf/papeleta'
import SearchBeneficiario from 'components/SearchBeneficiario'
import DateTimeFormik from 'components/formik/DateTimeFormik'

/** @module Pages/Papeleta/Components/FormPapeleta */

// Valores iniciales del formulario.
const initialValues = {
    beneficiario: null,
    lugar_viaje: '',
    fecha_salida: '',
    fecha_llegada: '',
    dias: '',
    doc_autorizacion: '',
    memo_id: '',
    actividad: { id: '', codigo_actividad: '', descripcion: '', nombre_corto: '' },
    cargo_cta: { id: '', codigo_cuenta: '', descripcion: '', nombre_corto: '' },
    cargo_apt_dsc_id: '',
    cargo_cta2: '',
    num_terrestre: '',
    num_aereo: '',
    pasaje_terrestre: '',
    pasaje_aereo: '',
    dias_viaticos: '',
    con_rciva: false,
    viaticos: '',
    rciva: '',
    subtotal_pasajes: '',
    subtotal_viaticos: '',
    resolucion_viaticos: '',
    nota: '',
    glosa: '',
    solicitud_beneficiario_id: '',
    fecha_registro: '',
    recursos: 'I',
    total: '',
}
const currentYear = formatDate(new Date(), 'Y')
const currentDate = formatDate(new Date(), 'd/m/Y')

/**
 * Componente, Formulario de registro de papeleta de pago de pasajes y viáticos.
 * @returns {JSX.Element} Retorna el componente FormPapeleta.
 */
function FormPapeleta() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const formRef = useRef()
    const { mbId } = useParams()
    const {sectionKey} = useSelector(state => state.system)
    const [actividades, setActividades] = useState([])
    const [cuentas, setCuentas] = useState([])
    const [showEscalasDialog, setShowEscalasDialog] = useState(false)
    const [showSearcher, setShowSearcher] = useState(false)
    const [showPopover, setShowPopover] = useState(true)
    const [lugares, setLugares] = useState([])
    const [jefeTesoreria, setJefeTesoreria] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const [numPapeleta, setNumPapeleta] = useState('')
    const [gestionPapeleta, setGestionPapeleta] = useState('')
    const pdfP = new PapeletaPdf()

    useEffect(() => {
        loadJefeTesoreria()
    }, [])
    useEffect(() => {
        const loadData = async () => {
            const {status, data} = await MemoService.getMemoViaticos(mbId)
            if (status === 200 && formRef.current) {
                const { 
                    fecha_salida, 
                    fecha_llegada,
                    beneficiario_id, 
                    nombre_beneficiario, 
                    cargo_id,
                    cargo, 
                    funcion, 
                    beneficiario_cargo_id,

                    lugar_viaje,
                    pasaje_aereo,

                    memo_id, 
                    numero_memo, 
                    memo_beneficiario_id,

                    actividad_id,
                    codigo_actividad, 
                    nombre_actividad, 
                    nombre_corto_actividad,
                } = data.data
                const fechaSalida = parseDate(fecha_salida)
                const fechaLlegada = parseDate(fecha_llegada)
                const dias = calcDiasViaticos(fechaSalida, fechaLlegada)
                formRef.current.resetForm({
                    values: {
                        ...initialValues,
                        beneficiario: {
                            id: beneficiario_id,
                            nombre_beneficiario,
                            cargo_id,
                            cargo,
                            funcion: funcion || '',
                            beneficiario_cargo_id
                        },
                        actividad: {
                            id: actividad_id || '',
                            codigo_actividad: codigo_actividad || '',
                            descripcion: nombre_actividad || '',
                            nombre_corto: nombre_corto_actividad || ''
                        },

                        fecha_salida: fechaSalida,
                        fecha_llegada: fechaLlegada,
                        dias,
                        dias_viaticos: dias,
                        lugar_viaje,
                        pasaje_aereo: pasaje_aereo || '',
                        num_aereo: pasaje_aereo ? 1 : '',
                        subtotal_pasajes: pasaje_aereo || '',

                        memo_id,
                        doc_autorizacion: numero_memo || '',
                        memo_beneficiario_id,
                    }
                })
            } 
        }
        if (mbId) loadData()
    }, [mbId])
    useEffect(() => {
        setShowPopover(true)
    }, [showSearcher])
    useEffect(() => {
        loadNumPapeleta()
    }, [gestionPapeleta])

    const loadJefeTesoreria = async () => {
        const response = await ApiService.config.search({keys: 'JefeDepTyCP'})
        if (response.status === 200) {
            let jefe = null
            if (response.data['JefeDepTyCP']) {
                jefe = {
                    nombre: response.data['JefeDepTyCP'].valor,
                    cargo: response.data['JefeDepTyCP'].descripcion
                }
            }
            setJefeTesoreria(jefe)
        }
    }
    const loadNumPapeleta = async () => {
        const gestion = gestionPapeleta||currentYear
        const response = await PapeletaService.getNextNumPapeleta({gestion})
        if (response.status === 200) setNumPapeleta(response.data)
    }

    const openEscalasDialog = () => setShowEscalasDialog(true)
    const closeEscalasDialog = () => setShowEscalasDialog(false)

    const handleKeyDown = (e) => {
        const name = e.target.name
        if (['num_terrestre', 'num_aereo', 'dias_viaticos'].includes(name)) {
            if (e.target.value.length > 1 && e.key !== 'Backspace') {
                e.preventDefault()
            }
        } else if (name==='viaticos') {
            if (e.key.length!=='Enter') {
                e.preventDefault()
            }
        }
    }
    const handleKeyUp = (e) => {
        e.preventDefault()
        if (e.key === 'Enter') {
            openEscalasDialog()
        }
    }
    const handleSubmit = async (values) => {
        setSubmitting(true)
        const { actividad, cargo_cta, beneficiario } = values
        const _data = {
            actividad_id: actividad.id,
            cargo_cta_id: cargo_cta.id,
            beneficiario_id: beneficiario.id,
            cargo_id: beneficiario.cargo_id,
            ...getMainDataPapeleta(values),
            viaticos: Math.round(values.viaticos*values.dias_viaticos),
        }
        const {status, data} = await PapeletaService.createPapeleta(_data)
        dispatch(setHttpMessage({status: status, title: data.message}))
        if (status === 201) {
            const { impresion, configPapeleta } = data
            pdfP.papeleta(impresion, configPapeleta)
            navigate(`/${sectionKey}/viaticos`)
        }
        setSubmitting(false)
    }
    /** Obtienen los principales datos para la papeleta. */
    const getMainDataPapeleta = (data) => {
        const {
            con_rciva,
            dias, 
            dias_viaticos,
            doc_autorizacion, 
            fecha_llegada,
            fecha_registro,
            fecha_salida,
            glosa,
            lugar_viaje,
            memo_beneficiario_id,
            memo_id,
            nota,
            num_aereo,
            num_terrestre,
            pasaje_aereo,
            pasaje_terrestre,
            rciva,
            recursos,
            resolucion_viaticos,
            viaticos,
        } = data
        return {
            con_rciva,
            dias,
            dias_viaticos,
            doc_autorizacion,
            fecha_llegada,
            fecha_registro,
            fecha_salida,
            glosa,
            lugar_viaje,
            memo_beneficiario_id,
            memo_id,
            nota,
            num_aereo,
            num_terrestre,
            pasaje_aereo,
            pasaje_terrestre,
            rciva,
            recursos,
            resolucion_viaticos,
            viaticos,
        }
    }
    const validate = (values) => {
        const _errors = {}
        if (!values.beneficiario?.id) _errors.nombre_beneficiario = 'Requerido'
        if (!(values.total*1 > 0)) _errors.total = 'El total debe ser mayor a cero'
        return _errors
    }

    const searchActividades = (actividad) => {
        const loadData = async () => {
            const response = await ApiService.unidad.search(actividad)
            if (response.status === 200) {
                setActividades(response.data)
            }
        }
        if (actividad.length > 0) loadData()
        else setActividades([])
    }
    const searchCuentas = (cuenta) => {
        const loadData = async () => {
            const response = await ApiService.cuenta.search(cuenta)
            if (response.status === 200) {
                setCuentas(response.data)
            }
        }
        if (cuenta.length > 0) loadData()
        else setCuentas([])
    }
    const searchLugares = (lugar) => {
        const loadData = async () => {
            const response = await SolicitudService.getLugaresViaje({lugar})
            if (response.status === 200) {
                setLugares(response.data)
            }
        }
        if (lugar.length > 0) {
            if (lugar.length <= 8) loadData()
        } else setLugares([])
    }

    return (
        <Formik     
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={papeletaValidators}
            validate={validate}
        >{({values, setValues, setFieldValue}) => (
            <Form>
                <div className='d-flex align-items-center mb-4'>
                    <div className='fs-5'>
                        Nro. de Papeleta: {numPapeleta}
                        <ButtonIcon 
                            variant='text-secondary' className='ms-1'
                            size='sm' rounded
                            icon='pi pi-refresh' title='Recargar Nro.'
                            onClick={loadNumPapeleta}
                        />
                    </div>
                    <CalendarFormik 
                        name='fecha_registro' 
                        label='Fecha:' 
                        inline 
                        containerClassName='ms-auto'
                        placeholder={currentDate}
                    />
                </div>
                <div className='d-flex gap-3 mb-3'>
                    <div className='d-flex flex-column p-3 border rounded' style={{gap: '2.25rem', flex: 1}} >
                        <div className='d-flex'>
                            <div style={{flex: 1}} >
                                <InputFormik 
                                    name='beneficiario.nombre_beneficiario' inline label='Nombre del Funcionario:'
                                    disabled showError={false}
                                />
                            </div>
                            {!mbId &&
                                <OverlayTrigger 
                                    show={showSearcher} 
                                    placement='left' 
                                    overlay={(
                                        <Popover style={!showPopover ? {display: 'none'} : {}}>
                                            <Popover.Header as='h3'>Buscar Beneficiario</Popover.Header>
                                            <Popover.Body>
                                                <SearchBeneficiario 
                                                    placeholder='Documento' 
                                                    showLabel={false}
                                                    onSearch={() => setShowPopover(false)}
                                                    onSelect={selection => {
                                                        setFieldValue('beneficiario', {
                                                            id: selection.beneficiario_id,
                                                            nombre_beneficiario: selection.apellido_beneficiario.trim()+' '+selection.nombre_beneficiario, 
                                                            cargo_id: selection.cargo_id,
                                                            cargo: selection.cargo,
                                                            funcion: selection.funcion,
                                                            beneficiario_cargo_id: selection.beneficiario_cargo_id
                                                        })
                                                    }}
                                                    onHide={() => setShowSearcher(false)} 
                                                    byName={true}
                                                    autoFocus
                                                />
                                            </Popover.Body>
                                        </Popover>
                                    )}
                                >
                                    <Button 
                                        startIcon='pi pi-search' 
                                        variant='text-secondary' 
                                        className='ms-2' 
                                        onClick={() => setShowSearcher(!showSearcher)} 
                                    />
                                </OverlayTrigger>
                            }
                        </div>
                        <EditableSelectFormik 
                            name='lugar_viaje' 
                            label='Lugar de viaje:' 
                            lazy inline 
                            onSearch={searchLugares}
                            options={lugares} 
                            showError={false} 
                        />
                        <div className='row g-3'>
                            <DateTimeFormik
                                name='fecha_salida' 
                                label='Desde el:' 
                                placeholderDate='dd/mm/yyyy'
                                placeholderTime='hh:mm'
                                inline
                                containerClassName='col-5' 
                                showError={false} 
                            />
                            <DateTimeFormik 
                                name='fecha_llegada' 
                                label='Hasta el:' 
                                defaultTime='23:59'
                                placeholderDate='dd/mm/yyyy'
                                placeholderTime='hh:mm'
                                inline 
                                containerClassName='col-5' 
                                showError={false} 
                            />
                            <InputFormik name='dias' label='días:' type='number' containerClassName='col-2' inline showError={false} />
                        </div>
                        <InputFormik 
                            name='doc_autorizacion' 
                            label='Autorización Mediante:' 
                            inline 
                            showError={false}
                        />
                        <div>
                            <div className='row g-3 mb-1'>
                                <EditableSelectFormik 
                                    name='actividad' 
                                    label='Carga Actividad:' 
                                    showError={false}
                                    options={actividades} 
                                    optionValue='codigo_actividad'
                                    containerClassName='col' 
                                    lazy inline valueAsOption
                                    onSearch={searchActividades}
                                    placeholder='Buscar' 
                                    itemTemplate={(item) => <>{item.codigo_actividad + ' / ' + item.abreviacion_da + ' - ' + item.descripcion}</>}
                                />
                                <EditableSelectFormik 
                                    name='cargo_cta' 
                                    label='Cargo Cta1:' 
                                    showError={false}
                                    options={cuentas} 
                                    optionValue='codigo_cuenta' 
                                    placeholder='Buscar' 
                                    containerClassName='col' 
                                    lazy inline valueAsOption
                                    onSearch={searchCuentas}
                                    itemTemplate={(item) => <>{item.codigo_cuenta + ' / ' + item.descripcion}</>}
                                />
                                <SelectFormik 
                                    name='recursos' options={recursos} optionLabel='value'
                                    containerClassName='only-input' containerStyle={{width: '5rem'}}
                                />
                            </div>
                            <div className='row g-3'>
                                <div className='col text-center'>{values.actividad?.nombre_corto||values.actividad?.descripcion}</div>
                                <div className='col text-center'>{values.cargo_cta?.nombre_corto||values.cargo_cta?.descripcion}</div>
                                <div style={{width: '5rem'}}></div>
                            </div>
                        </div>
                        <div className='row g-3 d-none'>
                            <InputFormik name='cargo_apt_dsc_id' label='Carga Apt/Dsc.:' containerClassName='col-6' inline />
                            <InputFormik name='cargo_cta2_id' label='Cargo Cta2:' containerClassName='col-6' inline />
                        </div>
                    </div>
                    <div style={{width: '25rem'}}>
                        <div className='d-flex flex-column gap-2 p-3 border rounded mb-3' >
                            <div className='mb-2'>Pasajes:</div>
                            <div className='d-flex gap-3'>
                                <InputFormik 
                                    name='num_terrestre' type='number'
                                    containerClassName='only-input' containerStyle={{width: '4.5rem'}}
                                    onKeyDown={handleKeyDown} 
                                />
                                <InputFormik 
                                    name='pasaje_terrestre' type='currency'
                                    label={<div className='text-end' style={{width: '3.5rem'}}>Terre.:</div>}
                                    containerStyle={{flex: 1}} className='text-end' inline 
                                />
                            </div>
                            <div className='d-flex gap-3'>
                                <InputFormik 
                                    name='num_aereo'  type='number'
                                    containerClassName='only-input' containerStyle={{width: '4.5rem'}}
                                    onKeyDown={handleKeyDown} 
                                />
                                <InputFormik 
                                    name='pasaje_aereo' type='currency'
                                    label={<div className='text-end' style={{width: '3.5rem'}}>Aéreo:</div>}
                                    containerStyle={{flex: 1}} className='text-end' inline 
                                />
                            </div>
                            <InputFormik 
                                name='subtotal_pasajes' type='currency'
                                label={<div className='text-end' style={{width: '9rem'}}>Sub-total:</div>}
                                containerStyle={{flex: 1}}
                                className='text-end' inline 
                                disabled
                            />
                            <div className='mb-2'>Viáticos:</div>
                            <div className='d-flex gap-3'>
                                <InputFormik 
                                    name='dias_viaticos' type='number'
                                    containerClassName='only-input' containerStyle={{width: '4.5rem'}}
                                    onKeyDown={handleKeyDown} 
                                />
                                <InputFormik 
                                    name='viaticos' type='currency'
                                    label={<div className='text-end' style={{width: '3.5rem'}}>Viati.:</div>}
                                    containerStyle={{flex: 1}} className='text-end' inline 
                                    onKeyUp={handleKeyUp} onKeyDown={handleKeyDown}
                                />
                            </div>  
                            <div className='d-flex gap-3'>
                                <ToggleButtonFormik 
                                    name='con_rciva'
                                    containerClassName='only-input' containerStyle={{width: '4.5rem'}}
                                    onKeyDown={handleKeyDown} 
                                />
                                <InputFormik 
                                    name='rciva' type='currency'
                                    label={<div className='text-end' style={{width: '3.5rem'}}>RCIVA:</div>}
                                    containerStyle={{flex: 1}} className='text-end' inline 
                                    disabled
                                />
                            </div>
                            <InputFormik 
                                name='subtotal_viaticos' type='decimal'
                                label={<div className='text-end' style={{width: '9rem'}}>Sub-total:</div>}
                                containerStyle={{flex: 1}}
                                className='text-end' inline 
                                disabled
                            />

                        </div>
                        <div className='p-3 border rounded'>
                            <InputFormik 
                                name='total'
                                label={<div className='text-end' style={{width: '9rem'}}>Total:</div>}
                                containerStyle={{flex: 1}}
                                className='text-end' inline 
                                disabled showError={false}
                            />
                            <ErrorMessage name='total'>{msg => <div className='d-block invalid-feedback text-end'>{msg}</div>}</ErrorMessage>
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-column gap-3 mb-3' >
                    {/* <InputFormik name='nota' label='Nota:' inline /> */}
                    <InputFormik name='glosa' label='Glosa:' inline />
                </div>
                <div className='border rounded p-3 mb-3'>
                    <span className='fw-bold me-2'>Jefe de Departamento de Tesorería y Crédito Público:</span> 
                    {
                        jefeTesoreria 
                            ? jefeTesoreria.nombre 
                            : <>
                                <span className='text-danger me-1'>No encontrado</span>
                                <ButtonIcon 
                                    variant='text-secondary' 
                                    size='sm' rounded 
                                    icon='pi pi-refresh' title='Volver a buscar'
                                    onClick={loadJefeTesoreria}
                                />
                            </>
                    }
                </div>
                <div className='d-flex justify-content-center gap-3'>
                    <Button disabled={submitting} onClick={() => navigate(`/${sectionKey}/viaticos`)} startIcon='pi pi-times'>Cancelar</Button>
                    <Button type='submit' loading={submitting} startIcon='pi pi-save'>Guardar</Button>
                </div>
                <Observer 
                    setGestionPapeleta={setGestionPapeleta}
                />
                <SelectEscalaViaticos setValues={setValues} show={showEscalasDialog} onHide={closeEscalasDialog} beneficiario={values.beneficiario} />
            </Form>
        )}</Formik>
    )
}
export default FormPapeleta

const calcDiasViaticos = (fecha_salida, fecha_llegada) => {
    let days = ''
    if (fecha_salida && fecha_llegada) {
        const salida = fecha_salida
        const llegada = fecha_llegada
        days = differenceInDays(llegada, new Date(format(salida, 'yyyy-MM-dd') + ' 00:00:00'))
        if (days === 0) {
            const minutes = differenceInMinutes(llegada, salida)
            if (minutes >= 359) days++
        } else if (llegada.getHours() >= 12) days++
    }
    return days
}

function Observer({ setGestionPapeleta }) {
    const { values, setValues, setFieldValue } = useFormikContext()
    
    /**
     * Calcula el subtotal de los pasajes
     */
    useEffect(() => {
        const {pasaje_aereo, pasaje_terrestre} = values
        const subtotal = pasaje_terrestre*1 + pasaje_aereo*1
        setFieldValue('subtotal_pasajes', subtotal.toFixed(2))
    }, [values.num_aereo, values.num_terrestre, values.pasaje_terrestre, values.pasaje_aereo]) 
    /**
     * Calcula el subtotal de los viáticos y el rciva
     */
    useEffect(() => {
        const viaticos = Math.round(values.viaticos*values.dias_viaticos)
        const rciva = values.con_rciva ? Math.round(viaticos*0.13) : ''
        const subtotal = viaticos - rciva
        setValues({
            ...values, 
            rciva: typeof rciva === 'number' ? rciva.toFixed(2) : rciva, 
            subtotal_viaticos: subtotal.toFixed(2) 
        })
    }, [values.dias_viaticos, values.con_rciva, values.viaticos]) 
    /**
     * Calcula el total de pasajes y viáticos
     */
    useEffect(() => {
        const total = (1*values.subtotal_pasajes)+(1*values.subtotal_viaticos)
        setFieldValue('total', total.toFixed(2))
    }, [values.subtotal_pasajes, values.subtotal_viaticos]) 
    /**
     * Calcula los dias de viaje
     */
    useEffect(() => {
        const days = calcDiasViaticos(values.fecha_salida, values.fecha_llegada)
        setFieldValue('dias', days)
    }, [values.fecha_salida, values.fecha_llegada]) 
    /**
     * Actualiza los dias de viaticos según los dias de viaje
     */
    useEffect(() => {
        if (values.dias !== '') setFieldValue('dias_viaticos', values.dias)
    }, [values.dias])
    useEffect(() => {
        setGestionPapeleta(formatDate(values.fecha_registro, 'Y')||currentYear)
    }, [values.fecha_registro])

    return null
}