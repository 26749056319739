import { Form as FormFormik, Formik } from 'formik'
import { InputFormik } from 'components/formik/formikFormComponents'
import { cargoInvitadoValidators } from 'utils/validators/validators'

/** @module Pages/CargoInvitado/Components/FormCagoInvitado */

/**
 * Componente, Formulario de registro/edición de cargo de invitado.
 * @param {object} props Propiedades del componente.
 * @param {React.MutableRefObject<any>} props.formRef Referencia del formulario.
 * @param {Function} props.onSubmit Callback a ejecutar cuando se de submit del formulario.
 * @param {object} props.selected Cargo de invitado seleccionado.
 * @returns {JSX.Element} Retorna el componente FormCagoInvitado.
 */
export default function FormCargoInvitado({formRef, onSubmit, selected}) {
    const initialValues = selected ? {descripcion: selected.cargo} : {descripcion: ''}

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={cargoInvitadoValidators}
            onSubmit={onSubmit}
        >{() => (
            <FormFormik>
                <InputFormik name='descripcion' label='Nombre' />
            </FormFormik>
        )}</Formik>
    )
}