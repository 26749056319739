
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import Button from 'components/Button'
import Dialog, { ConfirmDialog } from 'components/Dialog'
import TableSolicitud from './components/TableSolicitud'
import ViewSolicitud from './components/ViewSolicitud'
import FormDeny from './components/FormDeny'

import SolicitudService from 'services/solicitud.service'
import { setHttpMessage } from 'store/messageSlice'
import TitlePage from 'components/TitlePage'
import { buildFilters, formatDate } from 'utils/utils'
import useFilters from 'hooks/useFilters'
import { initialPagination } from 'data/const'

/** @module Pages/Solicitud/SolicitudAutoridadIndex */

const currentYear = formatDate(new Date(), 'Y')

/**
 * Página, Lista de solicitudes de Autoridad.
 * @returns {JSX.Element} Retorna el componente SolicitudAutoridadIndex.
 */
function SolicitudAutoridadIndex() {
    const dispatch = useDispatch()
    const [solicitudes, setSolicitudes] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const [selected, setSelected] = useState(null)
    const {filters, reloadFilters, ...filterHandlers} = useFilters()
    const [show, setShow] = useState({view: false, deny: false, authorize: false})
    const [pendientes, setPendientes] = useState(false)
    const tableRef = useRef()
    const formRef = useRef()

    useEffect(() => {
        const loadSolicitudes = async () => {
            const {filter, ..._filters} = filters
            const _filter = {
                ...filter,
                ...buildFilters(
                    {
                        ...(pendientes&&{estado: ['R']}),
                        ...(!filter?.fecha_creacion&&{
                            fecha_creacion: [
                                `${currentYear}-01-01 00:00:00`,
                                `${currentYear}-12-31 23:59:59`
                            ]
                        })
                    }, 
                    {in: ['estado']}
                )
            }
            const response = await SolicitudService.indexSolicitudPost({filter: _filter}, {..._filters, owner: 'autoridad'})
            if (response.status === 200) {
                setSolicitudes(response.data.data)
                setPagination(response.data.pagination)
            }
        }
        loadSolicitudes()
    }, [filters, pendientes])

    const openDialog = (name) => setShow({...show, [name]: true})
    const closeInfoDialog = () => setShow({...show, info: false})
    const closeDenyDialog = () => setShow({...show, deny: false})
    const closeAuthorizeDialog = () => setShow({...show, authorize: false})

    const handleOpenDialog = (action, rowData) => {
        setSelected(rowData)
        if (action === 'deny') {
            openDialog('deny')
        } else if (action === 'view') {
            openDialog('info')
        } else if (action === 'authorize') {
            openDialog('authorize')
        } else if (action === 'pdf') {
            
        }
    }

    const handleAuthorize = async () => {
        if (selected) {
            const response = await SolicitudService.autorizar(selected.id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                reloadFilters()
                closeAuthorizeDialog()
            }
        }
    }
    const handleDeny = async (values) => {
        if (selected) {
            const response = await SolicitudService.denegar(values, selected.id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                reloadFilters()
                closeDenyDialog()
            }
        }
    }
    const cleanSelected = () => setSelected(null)
    const changePendientes = (value) => setPendientes(value)

    const footerDenyDialog = <>
        <Button startIcon='pi pi-times' variant='text-secondary' onClick={closeDenyDialog}>Cerrar</Button>
        <Button startIcon='pi pi-check' variant='text-secondary' onClick={() => formRef?.current?.handleSubmit()}>Guardar</Button>
    </>
    const toolbarOptions = <div className='btn-group'>
        <Button variant='tab-red' active={!pendientes} onClick={() => changePendientes(false)}>Todos</Button>
        <Button variant='tab-red' active={pendientes} onClick={() => changePendientes(true)}>Pendientes</Button>
    </div>

    return <>
        <TitlePage title='Lista de Solicitudes' />
        <div className='content'>
            <div>
                <TableSolicitud 
                    tableRef={tableRef}
                    solicitudes={solicitudes}
                    pagination={pagination}
                    filterHandlers={filterHandlers}
                    handleOpenDialog={handleOpenDialog}
                    toolbarOptions={toolbarOptions}
                />
                
                <Dialog
                    show={show.info}
                    header='Información'
                    footer={<Button startIcon='pi pi-times' variant='text-secondary' onClick={closeInfoDialog}>Cerrar</Button>}
                    onHide={closeInfoDialog}
                    style={{width: '60rem'}}
                    onExited={cleanSelected}
                    scrollable
                >
                    <ViewSolicitud selected={selected} />
                </Dialog>
                <Dialog
                    show={show.deny}
                    header='Denegar'
                    footer={footerDenyDialog}
                    onHide={closeDenyDialog}
                    style={{width: '30rem'}}
                    onExited={cleanSelected}
                >
                    <FormDeny formRef={formRef} onSubmit={handleDeny} selected={selected} />
                </Dialog>
                <ConfirmDialog 
                    show={show.authorize}
                    onHide={closeAuthorizeDialog}
                    onReject={closeAuthorizeDialog}
                    onAccept={handleAuthorize}
                >
                    <div>
                        <div className='mb-2'>Esta seguro de AUTORIZAR la Solicitud {selected.numero_solicitud}?</div>
                        <div className='text-primary'>Una vez se autorice la solicitud será enviada a la DAF</div>
                    </div>
                </ConfirmDialog>
            </div>
        </div>
    </>
}
export default SolicitudAutoridadIndex