import { Routes, Route } from 'react-router-dom'

import Login from 'pages/Login'
import InternalServerError from 'pages/InternalServerError'

function PublicRoutes()
{
    return (
        <Routes>
            <Route path='500' element={<InternalServerError/>} />
            <Route path='*' element={<Login/>} />
        </Routes>
    )
}

export default PublicRoutes