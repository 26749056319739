/** @module Utils/classNames */

/**
 * Concatena las clases de estilo de un array.
 * Solo toma en cuenta strings no vacíos.
 * @param {Array<any>} classList array de clases a concatenar
 * @returns string de clases de estilo
 */
export function classNames(classList) {
    return classList.filter(item => (typeof item === 'string' && item.length > 0)).join(' ')
}