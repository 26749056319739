import { ITEMTOKEN } from '../data/const'

/**
 * Retorna la cabecera con Autorización con el token asociado al usuario
 * que inicio sesión en el sistema
 */
export default function authHeader(headers={}) {
    // obtiene el token del almacenamiento local
    const item = localStorage.getItem(ITEMTOKEN);
    const token = item ? JSON.parse(item) : null
    
    // verifica si hay un usuario activo (logueado) en el sistema
    if (token) {
        // retorna la cabecera de Authorization con el token del usuario correspondiente
        return { ...headers, 'Authorization': 'Bearer ' + token.accessToken }
    }else {
        return headers;
    }
}
