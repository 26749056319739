import { ErrorMessage, Field } from 'formik'
import InputDropdown from 'components/InputDropdown'
import { toPascalCase } from 'utils/utils'
import InputLayout from 'components/layouts/InputLayout'
import { classNames } from 'utils/utils'
import { propFromName } from 'utils/utils'

function SelectFormik({
    name='', label='', 
    options,
    containerClassName,
    containerStyle,
    inline=false,
    showError=true,
    info,
    ...props
}) {

    const getDropdown = ({field, form: {errors, touched}}) => {
        if (propFromName(touched, name) && propFromName(errors, name)) props.isInvalid = true

        return <InputDropdown {...props} options={options} {...field} />
    }

    return (
        <InputLayout
            label={label ? label : toPascalCase(name)}
            className={classNames(['input-formik', containerClassName])}
            style={containerStyle}
            inline={inline}
            info={info}
        >
            <Field name={name}>{getDropdown}</Field>
            { name && showError ? <ErrorMessage name={name}>{msg => <div className='invalid-feedback d-block'>{msg}</div>}</ErrorMessage> : null}
        </InputLayout>
    )
}

export default SelectFormik