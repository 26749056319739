import { formatDate } from 'utils/utils'

/** @module Utils/buildFilters */

/**
 * Formatea un objeto a filtros para DataFilter de Yii.
 * @param {object} data Objeto con los valores a filtrar
 * @param {object} options Opciones para construir los filtros.
 * @param {boolean} options.empty Especifica si se tomara en cuenta valores vacíos para los filtros, default false.
 * @param {Array<string>} options.equal Array de propiedades del objeto data a filtrar con el operador "=".
 * @param {Array<string>} options.contain Array de propiedades del objeto data a filtrar con el operador "ilike".
 * @param {Array<string>} options.gt Array de propiedades del objeto data a filtrar con el operador ">".
 * @param {Array<string>} options.lt Array de propiedades del objeto data a filtrar con el operador "<".
 * @param {Array<string>} options.in Array de propiedades del objeto data a filtrar con el operador "in".
 * @param {Array<string>} options.is Array de propiedades del objeto data a filtrar con el operador "is".
 * @param {Array<string>} options.nin Array de propiedades del objeto data a filtrar con el operador "not in".
 * @param {Array<string>} options.between Array de propiedades del objeto data a filtrar con el operador ">" y "<".
 * @param {Array<string>} options.null Array de propiedades del objeto data a filtrar, true si es null, false si no es null.
 * @param {Array<string>} options.bool Array de propiedades del objeto data a filtrar solo por un valor booleano true o false.
 * @returns {object} Filtros con formato para DataFilter de Yii.
 */
export function buildFilters(data, options) {
    const filters = {}
    const values = data
    for (const key in values) {
        let value = values[key] === undefined ? '' : values[key]
        // Si ((empty=vacío y value diferente de '') o empty=true) y value diferente de null
        if (((!options.empty && value !== '') || options.empty) && value !== null) {
            if ((options.equal||[]).includes(key)) {
                filters[key] = value
            } else if ((options.contain||[]).includes(key)) {
                filters[key] = {ilike: value}
            } else if ((options.gt||[]).includes(key)) {
                filters[key] = {'>': value}
            } else if ((options.lt||[]).includes(key)) {
                filters[key] = {'<': value}
            } else if ((options.in||[]).includes(key)) {
                filters[key] = {in: value}
            } else if ((options.is||[]).includes(key)) {
                filters[key] = {is: value}
            } else if ((options.nin||[]).includes(key)) {
                filters[key] = {nin: value}
            } else if ((options.between||[]).includes(key)) {
                if (Array.isArray(value) && value.length > 0) {
                    if (value.length === 1) {
                        const dateIni = formatDate(value[0], 'Y-m-d', null)
                        if (dateIni) filters[key] = {'>': dateIni + ' 00:00:00'}
                    } else if (value.length > 1) {
                        const dateIni = formatDate(value[0], 'Y-m-d', null)
                        const dateEnd = formatDate(value[1], 'Y-m-d', null)
                        if (dateIni && dateEnd) {
                            filters[key] = {'>': dateIni + ' 00:00:00', '<': dateEnd + ' 23:59:59'}
                        }
                    }
                    
                }
            } else if ((options.null||[]).includes(key)) {
                if (value === true) {
                    filters[key] = {is: null}
                } else if (value === false) {
                    filters[key] = {isnt: null}
                }
            } else if ((options.bool||[]).includes(key)) {
                if (typeof value === 'boolean') {
                    filters[key] = value ? 1 : 0
                }
            }
        }
    }
    return filters
}