import { Form as FormFormik, Formik } from 'formik'
import { InputFormik } from 'components/formik/formikFormComponents'
import { dirAdtvaValidators } from 'utils/validators/validators'

/** @module Pages/DirAdtva/Components/FormDirAdtva */

/**
 * Componente, Formulario de registro/edición de dirección administrativa.
 * @param {object} props Propiedades del componente.
 * @param {React.MutableRefObject<any>} props.formRef Referencia del formulario.
 * @param {Function} props.onSubmit Callback a ejecutar cuando se de submit del formulario.
 * @param {object} props.selectedDirAdtva Dirección Administrativa seleccionada.
 * @returns {JSX.Element} Retorna el componente FormDirAdtva.
 */
function FormDirAdtva({formRef, onSubmit, selectedDirAdtva}) {
    const initialValues = selectedDirAdtva ? selectedDirAdtva : { 
        codigo: '', 
        descripcion: '', 
        abreviacion: ''
    }

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={dirAdtvaValidators}
            onSubmit={onSubmit}
        >
            {() => (
                <FormFormik>
                    <div>
                        <InputFormik name='codigo' label='Código' type='number' />
                        <InputFormik name='descripcion' label='Nombre' />
                        <InputFormik name='abreviacion' label='Abreviación' />
                    </div>
                </FormFormik>
            )}
        </Formik>
    )
}
export default FormDirAdtva