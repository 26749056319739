import Table from 'react-bootstrap/Table'
import { formatDate } from 'utils/utils'

function ViewRole({selectedRole}) {
    return (
        <Table striped bordered hover>
            <tbody>
                <tr>
                    <th>Código</th>
                    <td>{selectedRole.name}</td>
                </tr>
                <tr>
                    <th>Nombre</th>
                    <td>{selectedRole.description}</td>
                </tr>
                <tr>
                    <th>Fecha Creación</th>
                    <td>{selectedRole.createdAt ? formatDate(new Date(selectedRole.createdAt*1000), 'd/m/Y H:i:s') : '' }</td>
                </tr>
            </tbody>
        </Table>
    )
}

export default ViewRole