import { formatDate } from 'utils/utils'
import { draftJsRawToPdfMakeJson } from 'utils/string.util'
import PDF from './pdf'

// const configMemoPt = {
//     pageMargins: {
//         left: 65,
//         top: 102,
//         right: 65,
//         bottom: 0
//     },
//     fecha: {
//         marginBottom: 6
//     },
//     unidad: {
//         marginLeft: 5,
//         marginBottom: 6
//     },
//     jefe: {
//         marginLeft: 5,
//         marginBottom: 16
//     }
// }
const configMemoCm = {
    unit: 'cm',
    font: 'CenturyGothic',
    fontSize: 10,
    pageMargins: {
        left: 2.3,
        top: 3.6,
        right: 2.3,
        bottom: 0
    },
    fecha: {
        marginBottom: .225
    },
    unidad: {
        marginLeft: .175,
        marginBottom: .225
    },
    jefe: {
        marginLeft: .175,
        marginBottom: .575
    }
}

class MemoPdf extends PDF {

    seccion = 'DAF'

    memo(memo, config=null) {
        const configMemo = config||configMemoCm
        const getProps = (props) => {
            return this.getProps(props, configMemo.unit)
        }
        const content = [
            {
                columns: [
                    { width: '*', text: formatDate(memo.fecha_creacion, 'd/m/Y'), marginLeft: 155 },
                    { width: '*', text: memo.numero_memo.replace('MEMO ', ''), marginLeft: 55 },
                ],
                ...getProps(configMemo.fecha)
            },
            { text: 'DIRECCIÓN ADMINISTRATIVA Y FINANCIERA', ...getProps(configMemo.unidad) },
            { text: [memo.jefe_tesoreria, ', JEFE DPTO. TESORERÍA Y C. PÚBLICO'], ...getProps(configMemo.jefe) },
            { columns: [draftJsRawToPdfMakeJson(memo.descripcion_json)], lineHeight: .95 }
            
        ]
        const pageMargins = configMemo.unit==='cm'
            ? [
                this.cmToPts(configMemo.pageMargins.left),
                this.cmToPts(configMemo.pageMargins.top),
                this.cmToPts(configMemo.pageMargins.right),
                this.cmToPts(configMemo.pageMargins.bottom),
            ]
            : [
                configMemo.pageMargins.left,
                configMemo.pageMargins.top,
                configMemo.pageMargins.right,
                configMemo.pageMargins.bottom
            ]
        this.generatePDF(content, null, null, {
            pageMargins,
            defaultStyle: {
                font: configMemo.font||'CenturyGothic',
                fontSize: configMemo.fontSize||10
            }
        })
    }

}

export default MemoPdf