import { configureStore } from '@reduxjs/toolkit'
import authReducer from './authSlice'
import messageReducer from './messageSlice'
import systemSlice from './systemSlice'

export default configureStore({
	reducer: {
		auth: authReducer,
		message: messageReducer,
		system: systemSlice
	},
})
