import { useRef } from 'react'
import Input from './Input'

/** @module Components/FormUploadFile */

/**
 * Input para subir archivo en pdf
 * @param {File} file Estado que almacenara el archivo seleccionado.
 * @param {Function} setFile Función que guardara el archivo en el estado file.
 * @returns {JSX.Element} Retorna el componente FormUploadFile.
 */
function FormUploadFile({setFile, file}) {
    const fileRef = useRef()

    const validate = (e) => {
        let error = ''
        const files = e.target.files
        if (!(files && files.length > 0)) {
            error = 'Requerido'
        } else if (!files[0].type.includes('pdf')) {
            error = 'Solo se admite formato PDF'
        }
        setFile({value: files[0], error})
    }

    return (
        <div>
            <label className='form-label'>Archivo</label>
            <Input type='file' ref={fileRef} accept='.pdf' onChange={validate} isInvalid={!!file.error}/>
            <div className='invalid-feedback'>{file.error}</div>
        </div>
    )
}

export default FormUploadFile