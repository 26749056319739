import Button from './Button'

/** @module Components/ToggleButton */

/**
 * @typedef {object} ToggleButtonType 
 * @property {string | undefined} name Propiedad name del input.
 * @property {boolean | undefined} value Valor del ToggleButton.
 * @property {string | undefined} onIcon Clase de estilo del icono del botón cuando esta activo.
 * @property {string | undefined} offIcon Clase de estilo del icono del botón cuando esta inactivo.
 * @property {string | undefined} onLabel Label del icono del botón cuando esta activo.
 * @property {string | undefined} offLabel Label del icono del botón cuando esta inactivo.
 * @typedef {ToggleButtonType & React.InputHTMLAttributes<HTMLInputElement>} ToggleButtonProps
*/

/**
 * Componente ToggleButton
 * @param {ToggleButtonProps} props Propiedades del componente. ToggleButtonType + React.InputHTMLAttributes<HTMLInputElement>.
 * @param {React.Ref<HTMLInputElement>} ref 
 * @returns {JSX.Element} Retorna el componente ToggleButton
 */
function ToggleButton({ 
    name, value=false,
    onIcon='pi pi-check', offIcon='pi pi-times', onLabel='Si', offLabel='No', 
    onChange, onBlur, ...props 
}) {
    const originalOnClick = props.onClick

    const handleClick = (e) => {
        onChange(getEvent(!value))
        if (typeof originalOnClick === 'function') originalOnClick(e)
    }
    const handleBlur = () => onBlur(getEvent(value))
    const getEvent = (val) => {
        return { target: { name: name||'', id: props.id||'', value: val }, value: val }
    }

    if (typeof onChange === 'function') props.onClick = handleClick
    if (typeof onBlur === 'function') props.onBlur = handleBlur

    return (
        <Button 
            variant={value ? 'blue' : 'outline-blue'}
            style={{width: '100%'}}
            {...props}
            startIcon={value ? onIcon : offIcon}
        >{value ? onLabel : offLabel}</Button>
    )
}

export default ToggleButton