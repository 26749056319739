import { Form, OverlayTrigger, Popover } from 'react-bootstrap'
import { classNames } from 'utils/utils'

function InputLayout({label, inline, className, children, info, onlyInput, ...props}) {

    const popover = info && (
        <Popover id='popover-basic'>
            <div className='p-2 text-justify'>
                {info}
            </div>
        </Popover>
      )

    return (
        <Form.Group 
            className={classNames([
                'input-layout', 
                (!!inline&&`inline${typeof inline === 'string' ? '-'+inline : ''}`), 
                (onlyInput&&'only-input'), 
                className
            ])}
            {...props}
        >
            <Form.Label>
                {label}
                {info && (
                    <OverlayTrigger trigger={['hover', 'focus', 'click']} placement='right' overlay={popover}>
                        <span className='info-icon text-primary bi-info-circle'></span>
                    </OverlayTrigger>
                )}
            </Form.Label>
            {children}
        </Form.Group>
    )
}

export default InputLayout