import Button, { ButtonIcon } from 'components/Button'
import DataTable from 'components/DataTable'
import Dialog, { ConfirmDialog } from 'components/Dialog'
import TitlePage from 'components/TitlePage'
import { initialPagination } from 'data/const'
import useFilters from 'hooks/useFilters'
import { useEffect, useRef, useState } from 'react'
import ApiService from 'services/api.service'
import ViewBeneficiario from './components/ViewBeneficiario'
import InputCheckbox from 'components/InputCheckbox'
import { useDispatch } from 'react-redux'
import { setHttpMessage } from 'store/messageSlice'

/** @module Pages/Beneficiario/BeneficiarioIndex */

// Opciones de filtros para el hook useFilters.
const filterOptions = {
    contain: ['nombre_1', 'nombre_2', 'apellido_1', 'apellido_2', 'apellido_3', 'documento'],
    bool: ['invitado']
}

/**
 * Página, Lista de Beneficiarios.
 * @returns {JSX.Element} Retorna el componente BeneficiarioIndex.
 */
function BeneficiarioIndex() {
    const dispatch = useDispatch()
    const tableRef = useRef()
    const [beneficiarios, setBeneficiarios] = useState([])
    const [selected, setSelected] = useState(null)
    const [pagination, setPagination] = useState(initialPagination)
    const [show, setShow] = useState({info: false, assign: false, update: false})
    const {filters, handleFilter, handleSort, handlePage} = useFilters({initialFilters: null, filterOptions})

    useEffect(() => {
        const loadData = async () => {
            const response = await ApiService.beneficiario.index(filters)
            if (response.status === 200) {
                setBeneficiarios(response.data.data)
                setPagination(response.data.pagination)
            }
        }
        if (filters) loadData()
    }, [filters])
    
    // Controlador para abrir los modales
    const handleOpenDialog = (action, rowData=null) => {
        if (rowData) setSelected(rowData)
        if (action === 'view') {
            openDialog('info')
        } else if (action==='assign') {
            openDialog('assign')
        } else if (action==='update') {
            openDialog('update')
        }
    }
    const openDialog = (name) => setShow({...show, [name]: true})
    const closeInfoDialog = () => setShow({...show, info: false})
    const closeAssignDialog = () => setShow({...show, assign: false})
    const closeUpdateDialog = () => setShow({...show, update: false})
    const clearSelected = () => setSelected(null)
    const cleanFilters = () => tableRef?.current?.clean()

    const handleAssignCargo = async () => {
        const response = await ApiService.beneficiario.assignDefaultCargo(selected?.id)
        dispatch(setHttpMessage({status: response.status, title: response.data.message}))
        if (response.status===200) closeAssignDialog()
    }
    const handleUpdateCargos = async () => {
        const response = await ApiService.beneficiario.update(selected?.id)
        dispatch(setHttpMessage({status: response.status, title: response.data.message}))
        if (response.status===200) closeUpdateDialog()
    }

    const columns = [
        {header: 'Apellido-1', field: 'apellido_1', sortable: true, filter: true},
        {header: 'Apellido-2', field: 'apellido_2', sortable: true, filter: true},
        {header: 'Apellido-3', field: 'apellido_3', sortable: true, filter: true},
        {header: 'Nombre-1', field: 'nombre_1', sortable: true, filter: true},
        {header: 'Nombre-2', field: 'nombre_2', sortable: true, filter: true},
        {header: 'Documento', field: 'documento', sortable: true, filter: true},
        {
            header: 'Invitado', 
            field: 'invitado', 
            body: rowData => rowData.invitado?'Si':'No',
            bodyClassName: 'text-center',
            sortable: true, 
            filter: true,
            filterElement: (options) => (
                <div className='d-flex justify-content-center'>
                    <InputCheckbox value={options.value} onChange={(e) => options.filterCallback(e.value)} triState style={{fontSize: '1.5rem'}}/>
                </div>
            ),
        },
        {
            header: 'Opciones', field: 'id', filter: true, style: {width: '9rem'},
            filterElement: () => <div className='text-center'>
                <Button startIcon='pi pi-filter-slash' onClick={cleanFilters}>Limpiar</Button>
            </div>,
            body: (rowData) => (<div>
                <ButtonIcon 
                    variant='text-secondary' rounded 
                    icon='bi-eye' title='Información'
                    onClick={() => handleOpenDialog('view', rowData)}
                />
                {!rowData.invitado&&rowData.email.includes('@umss.edu')&&<ButtonIcon 
                    variant='text-secondary' rounded 
                    icon='bi-person-plus' title='Cargo predeterminado'
                    onClick={() => handleOpenDialog('assign', rowData)}
                />}
                {!rowData.invitado&&<ButtonIcon 
                    variant='text-secondary' rounded 
                    icon='bi-person-gear' title='Actualizar datos'
                    onClick={() => handleOpenDialog('update', rowData)}
                />}
            </div>)
        },
    ]
    const infoFooterDialog = <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeInfoDialog}>Cerrar</Button>

    return <>
        <TitlePage title='Lista de Beneficiarios' />
        <div className='content'>
            <div>
                <DataTable 
                    innerRef={tableRef}
                    values={beneficiarios} 
                    columns={columns} 
                    pagination
                    rowsPerPage={pagination.pageSize}
                    rowsPerPageOptions={[10, 25, 50]}
                    totalRecords={pagination.totalRecords}
                    page={pagination.currentPage}
                    filterable
                    onFilter={handleFilter}
                    onSort={handleSort}
                    onPage={handlePage}
                />
                <Dialog
                    show={show.info}   
                    header='Información' 
                    footer={infoFooterDialog}
                    onHide={closeInfoDialog}
                    onExited={clearSelected}
                >
                    {selected?.id && <ViewBeneficiario id={selected.id}/>}
                </Dialog>
                <ConfirmDialog
                    show={show.assign}
                    onHide={closeAssignDialog}
                    onReject={closeAssignDialog}
                    onAccept={handleAssignCargo}
                >
                    Esta seguro de <span className='text-primary'>ASIGNAR EL CARGO PREDETERMINADO</span> al beneficiario
                    <div>{`"${selected?.apellido_1}${selected?.apellido_2?' '+selected.apellido_2: ''} ${selected?.nombre_1}${selected?.nombre_2?' '+selected.nombre_2: ''}"`}?</div>
                </ConfirmDialog>
                <ConfirmDialog
                    show={show.update}
                    onHide={closeUpdateDialog}
                    onReject={closeUpdateDialog}
                    onAccept={handleUpdateCargos}
                >
                    Esta seguro de <span className='text-primary'>ACTUALIZAR LOS DATOS</span> del beneficiario
                    {` "${selected?.apellido_1}${selected?.apellido_2?' '+selected.apellido_2: ''} ${selected?.nombre_1}${selected?.nombre_2?' '+selected.nombre_2: ''}"`}?
                </ConfirmDialog>
            </div>
        </div>
    </>
}
export default BeneficiarioIndex