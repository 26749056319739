import { API } from './connection'
import authHeader from './authHeader'
import store from 'store'
import { logout } from 'store/authSlice'
import { setHttpMessage } from 'store/messageSlice'

const authenticate = (response) => {
    if (response.status === 401) {
        store.dispatch(logout())
        store.dispatch(setHttpMessage({
            status: 401, 
            title: 'Su sesión a expirado', 
            detail: 'El tiempo de su sesión ha terminado o se ha iniciado sesión con su cuenta desde otro navegador.',
            sticky: true
        }))
    }
}

export const GenericRequest = {
    get: async (url, params={}) => {
        let response = {}
        await API.get(url, { params, headers: authHeader() })
            .then(res => response = res)
            .catch(error => response = error.response ? error.response : {})
        authenticate(response)
        return response
    },
    post: async (url, data, params={}) => {
        let response = {}
        await API.post(url, data, { params, headers: authHeader() })
            .then(res => response = res)
            .catch(error => response = error.response ? error.response : {})
        authenticate(response)
        return response
    },
    put: async (url, data, params={}) => {
        let response = {}
        await API.put(url, data, { params, headers: authHeader() })
            .then(res => response = res)
            .catch(error => response = error.response ? error.response : {})
        authenticate(response)
        return response
    },
    delete: async (url, params={}) => {
        let response = {}
        await API.delete(url, { params, headers: authHeader() })
            .then(res => response = res)
            .catch(error => response = error.response ? error.response : {})
        authenticate(response)
        return response
    },
    uploadFile: async (url, data, params={}) => {
        let response = {}
        await API.post(url, data, { params, headers: authHeader({ 'Content-Type': 'multipart/form-data' }) })
            .then(res => response = res)
            .catch(error => response = error.response ? error.response : {})
        authenticate(response)
        return response
    },
}

const controller = {}

export const SignalRequest = {
    get: async (url, params={}) => {
        if (controller[url] instanceof AbortController) controller[url].abort()
        controller[url] = new AbortController()
        let response = {}
        await API.get(url, { params, signal: controller[url].signal, headers: authHeader() })
            .then(res => response = res)
            .catch(error => response = error.response ? error.response : {})
            .finally(() => controller[url] = null)
        authenticate(response)
        return response
    },
    post: async (url, data, params={}) => {
        if (controller[url] instanceof AbortController) controller[url].abort()
        controller[url] = new AbortController()
        let response = {}
        await API.post(url, data, { params, signal: controller[url].signal, headers: authHeader() })
            .then(res => response = res)
            .catch(error => response = error.response ? error.response : {})
            .finally(() => controller[url] = null)
        authenticate(response)
        return response
    },
    put: async (url, data, params={}) => {
        if (controller[url] instanceof AbortController) controller[url].abort()
        controller[url] = new AbortController()
        let response = {}
        await API.put(url, data, { params, signal: controller[url].signal, headers: authHeader() })
            .then(res => response = res)
            .catch(error => response = error.response ? error.response : {})
            .finally(() => controller[url] = null)
        authenticate(response)
        return response
    },
    delete: async (url, params={}) => {
        if (controller[url] instanceof AbortController) controller[url].abort()
        controller[url] = new AbortController()
        let response = {}
        await API.delete(url, { params, signal: controller[url].signal, headers: authHeader() })
            .then(res => response = res)
            .catch(error => response = error.response ? error.response : {})
            .finally(() => controller[url] = null)
        authenticate(response)
        return response
    },
}