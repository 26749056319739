import { CustomInputFormik } from 'components/formik/formikFormComponents'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import ApiService from 'services/api.service'

/** @module Pages/EscalaViatico/Components/FormTipoCambio */

// Valores iniciales del tipo de cambio.
const initialTipoCambio = {valor: '1.00', moneda: 'Bs'}

/**
 * Componente, Formulario de registro/edición de Tipo de cambio.
 * @param {object} props Propiedades del componente.
 * @param {React.MutableRefObject<any>} props.formRef Referencia del formulario.
 * @param {Function} props.onSubmit Callback a ejecutar cuando se de submit del formulario.
 * @returns {JSX.Element} Retorna el componente FormTipoCambio.
 */
export default function FormTipoCambio({formRef, onSubmit}) {
    const [tipoCambio, setTipoCambio] = useState(initialTipoCambio)

    useEffect(() => {
        const loadData = async () => {
            const response = await ApiService.tipoCambio.getActual()
            if (response.status === 200) setTipoCambio(response.data)
        }
        loadData()
    }, [])

    return (
        <Formik
            innerRef={formRef}
            initialValues={{valor: ''}}
            onSubmit={onSubmit}
        >{() => (
            <Form>
                <div className='mb-2 fw-bold'>Actual:</div>
                <div className='mb-3'>1 {tipoCambio.moneda} = {tipoCambio.valor} Bs</div>
                <div className='mb-2 fw-bold'>Nuevo:</div>
                <CustomInputFormik name='valor' label='1$us = ' inline type='currency' startIcon='Bs'/>
            </Form>
        )}</Formik>
    )
}