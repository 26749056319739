import { forwardRef } from 'react'
import { classNames } from 'utils/utils'

/** @module Components/Button */

/**
 * @typedef {object} ButtonType 
 * @property {React.ReactNode} children Contenido del Button.
 * @property {React.ReactNode | undefined} startIcon Contenido del icono del inicio.
 * @property {React.ReactNode | undefined} endIcon Contenido del icono del final.
 * @property {boolean | undefined} loading Especifica si el botón esta en modo cargando.
 * @property {boolean | undefined} active Especifica si el botón esta en modo activo.
 * @property {string | undefined} variant Variante de estilo del botón.
 * @property {boolean | undefined} rounded Especifica si el botón tendrá bordes redondeados.
 * @property {"sm" | "lg" | undefined} size Especifica el tamaño del botón.
 * @typedef {ButtonType & React.ButtonHTMLAttributes<HTMLButtonElement>} ButtonProps
*/

/**
 * Componente Button.
 * @method
 * @param {ButtonProps} props Propiedades del componente. ButtonType + React.ButtonHTMLAttributes<HTMLButtonElement>.
 * @param {React.RefObject<HTMLButtonElement>} ref
 * @returns {JSX.Element} Retorna el componente Button.
 */
const Button = forwardRef(({
    startIcon, endIcon, loading, 
    variant, rounded, size, active,
    children, type='button',
    ...props
}, ref) => {
    props.className = classNames([
        'btn',
        variant === 'none' ? null : (variant ? 'btn-'+variant : 'btn-blue'),
        rounded && (children ? 'rounded-pill' : 'rounded-circle'),
        (size && `btn-${size}`),
        props.className,
        (active && 'active'),
    ])

    const getIcon = (pos) => {
        const icon = pos==='end'?endIcon:startIcon
        let _icon = typeof icon === 'string' ? <i className={icon}></i> : icon
        if (loading && (pos==='start'||(pos==='end'&&!startIcon))) {
            _icon = <span className='spinner-border spinner-border-sm' role='status'></span>
        }
        return <span className={'bs-btn-icon-'+pos}>{_icon}</span>
    }

    const btnStartIcon = startIcon && getIcon('start')
    const btnEndIcon = endIcon && getIcon('end')

    if (loading) props.disabled = !!loading
    return (
        <button ref={ref} type={type} {...props}>
            {btnStartIcon}
            {children!==undefined ? <span className='bs-btn-label'>{children}</span> : ''}
            {btnEndIcon}
        </button>

    )
})

/**
 * @typedef {object} ButtonIconType 
 * @property {React.ReactNode | undefined} icon Contenido del icono.
 * @property {boolean | undefined} loading Especifica si el botón esta en modo cargando.
 * @property {boolean | undefined} active Especifica si el botón esta en modo activo.
 * @property {string | undefined} variant Variante de estilo del botón.
 * @property {boolean | undefined} rounded Especifica si el botón tendrá bordes redondeados.
 * @property {"sm" | "lg" | undefined} size Especifica el tamaño del botón.
 * @typedef {ButtonIconType & React.ButtonHTMLAttributes<HTMLButtonElement>} ButtonIconProps
*/

/**
 * Componente ButtonIcon.
 * @param props Propiedades del componente. ButtonIconType + React.ButtonHTMLAttributes<HTMLButtonElement>.
 * @param ref Referencia del componente.
 * @return Retorna el componente ButtonIcon.
 */
const ButtonIcon = forwardRef(({
    icon, loading, 
    variant, rounded, size, active,
    type='button',
    ...props
}, ref) => {
    props.className = classNames([
        'btn',
        variant === 'none' ? null : (variant ? 'btn-'+variant : 'btn-blue'),
        'btn-icon'+(size?`-${size}`:''),
        (rounded&&'btn-icon-rounded'),
        props.className,
        active && 'active',
    ])

    const getIcon = () => {
        let _icon = typeof icon === 'string' ? <i className={icon}></i> : icon
        if (loading) _icon = <span className='spinner-border spinner-border-sm' role='status'></span>
        return <span className='bs-btn-icon'>{_icon}</span>
    }

    const btnIcon = getIcon()

    if (loading) props.disabled = !!loading
    return (
        <button ref={ref} type={type} {...props}>
            {btnIcon}
        </button>
    )
})

export default Button

export {
    Button,
    ButtonIcon
}