import DatePicker, { DateObject } from 'react-multi-date-picker'
import { classNames, formatDate, parseDate } from 'utils/utils'
import { useMemo, useRef, useState } from 'react'

/** @module Components/InputCalendar */

// Array de nombres de meses en español.
const months = [
    ['Enero', 'Ene'], //[["name","shortName"], ... ]
    ['Febrero', 'Feb'],
    ['Marzo', 'Mar'],
    ['Abril', 'Abr'],
    ['Mayo', 'May'],
    ['Junio', 'Jun'],
    ['Julio', 'Jul'],
    ['Agosto', 'Ago'],
    ['Septiembre', 'Sep'],
    ['Octubre', 'Oct'],
    ['Noviembre', 'Nov'],
    ['Diciembre', 'Dic'],
]
// Array de nombres de días en español.
const weekDays = [
    ['Domingo', 'Do'], //[["name","shortName"], ... ]
    ['Lunes', 'Lu'],
    ['Martes', 'Ma'],
    ['Miercoles', 'Mi'],
    ['Jueves', 'Ju'],
    ['Viernes', 'Vi'],
    ['Sabado', 'Sa'],
]
const encoderFormat = {
    'Y': 'YYYY', // Una representación numérica completa de un año, 4 dígitos - Ejemplos: 1999 o 2003
    'y': 'YY', // Una representación de dos dígitos de un año - Ejemplos: 99 o 03

    'M': 'MMM', // * Una representación textual corta de un mes, tres letras - Jan hasta Dec
    'm': 'MM', // Representación numérica de un mes, con ceros iniciales
    'n': 'M', // Representación numérica de un mes, sin ceros iniciales
    'F': 'MMMM', // * Una representación textual completa de un mes, como January o March

    'D': 'ddd',// Una representación textual de un día, tres letras - Mon hasta Sun
    'd': 'DD', // Día del mes, 2 dígitos con ceros iniciales
    'j': 'D', // Día del mes sin ceros iniciales
    'l': 'dddd',  // * Una representación textual completa del día de la semana - Sunday hasta Saturday
    'z': 'DDD', // El día del año - 0 hasta 365
    'N': 'd', // Representación numérica ISO-8601 del día de la semana - 1 (para lunes) hasta 7 (para domingo)
    
    // 'W': 'W', // Número de la semana del año ISO-8601, las semanas comienzan en lunes - Ejemplo: 42 (la 42ª semana del año)
    
    // 'a': 'a', // * Ante meridiem y Post meridiem en minúsculas - am o pm
    // 'A': 'A', // * Ante meridiem y Post meridiem en mayúsculas - AM o PM

    'h': 'hh', // Formato de 12 horas de una hora con ceros iniciales - 01 hasta 12
    'H': 'HH', // Formato de 24 horas de una hora con ceros iniciales - 00 hasta 23
    'g': 'h', // Formato de 12 horas de una hora sin ceros iniciales - 1 hasta 12
    'G': 'H', // Formato de 24 horas de una hora sin ceros iniciales - 0 hasta 23

    'i': 'mm', // Minutos con ceros iniciales - 00 hasta 59
    's': 'ss', // Segundos con ceros iniciales - 00 hasta 59
    'v': 'SSS' // Milisegundos - Example: 654
}
/** Convierte el formato de fecha de PHP a formato de fecha de React Multi Date Picker. */
const parseFormat = (format) => {
    let _format = ''
    format.split('').forEach(c => {
        if (encoderFormat[c]) {
            _format = `${_format}${encoderFormat[c]}`
        } else {
            _format = `${_format}${c}`
        }
    })
    return _format
}

/**
 * Componente InputCalendar, construido en base al componente DatePicker de react-multi-date-picker.
 * @param {object} props Propiedades del componente
 * @param {string | undefined} props.name Propiedad name del input.
 * @param {string | undefined} props.id ID del input.
 * @param {string | undefined} props.placeholder Descripción por defecto que se muestra en el input.
 * @param {"date" | "datetime" | "unix" | "Date" | "DateObject" | string} props.typeValueDate Tipo de las fechas de la propiedad value.
 * @param {string | number | Date | DateObject | undefined} props.value Valor del input.
 * @param {Function | undefined} props.onChange Callback que se ejecuta cada vez que cambia el valor del input.
 * @param {Function | undefined} props.onBlur Callback que se ejecuta cuando el input pierde el foco.
 * @param {string | undefined} props.formatLabel Formato en que se mostrará las fechas.
 * @param {React.CSSProperties | undefined} props.style Estilos en linea del componente.
 * @param {React.ReactNode | undefined} props.customInput Input personalizado a mostrar.
 * @param {string | undefined} props.containerClassName Clase de estilo del contenedor del input.
 * @param {React.CSSProperties | undefined} props.containerStyle Estilos en linea del contenedor del input.
 * @param {string | defaultClassName} props.defaultClassName Clase de estilos del input predefinida.
 * @param {boolean | undefined} props.isInvalid Especifica si el input tendrá estilos de validación incorrecta.
 * @param {boolean | undefined} props.isValid Especifica si el input tendrá estilos de validación correcta.
 * @returns {JSX.Element} Retorna el componente InputCalendar.
 */
export default function InputCalendar({
    name='', id='', value, 
    isInvalid, isValid, 
    onChange,
    onBlur,
    formatLabel='d/m/Y', 
    typeValueDate='Date',
    customInput, 
    placeholder, 
    containerClassName, containerStyle, defaultClassName,
    ...props
}) {
    const format = useMemo(() => parseFormat(formatLabel), [formatLabel])
    const val = useMemo(() => {
        let _value = value
        const formatValue = (v) => {
            return typeof v === 'number' ? v*1000 : (typeof v === 'string' ? parseDate(v, '') : v)
        }
        if (Array.isArray(value)) {
            _value = value.map(v => Array.isArray(v) 
                ? (v.map(formatValue)) 
                : formatValue(v)
            )
        } else {
            _value = formatValue(value)
        }
        return _value
    }, [value])

    /**
     * Ejecuta la función onChange cada vez que DatePicker cambia de valor.
     * @param {DateObject | null} date Fecha.
     */
    const handleChange = (date) => {
        onChange && onChange(buildEvent(parseValue(date)))
    }
    const handleBlur = () => {
        onBlur && onBlur(buildEvent(value||null))
    }
    const buildEvent = (_value) => {
        return { target: { name, id, value: _value }, value: _value }
    }
    /**
     * Convierte la fecha al tipo de dato especificado por la prop typeValueDate.
     * @param {DateObject | null} date Fecha.
     * @returns 
     */
    const parseValue = (date) => {
        let _value = date
        if (date === null) {
            _value = ['date', 'datetime'].includes(typeValueDate) ? '' : null
        } else {
            if (Array.isArray(date)) {
                _value = date.map(d => Array.isArray(d) 
                    ? d.map(_d => dateToFormatValue(_d)) 
                    : dateToFormatValue(d)
                )
            } else {
                _value = dateToFormatValue(date)
            }
        }
        return _value
    }
    const dateToFormatValue = (date) => {
        let _value = date
        if (typeValueDate==='date') {
            _value = formatDate(new Date(date.format('YYYY-MM-DD HH:mm:ss')), 'Y-m-d') 
        } else if (typeValueDate==='datetime') {
            _value = formatDate(new Date(date.format('YYYY-MM-DD HH:mm:ss')), 'Y-m-d H:i:s') 
        } else if (typeValueDate==='unix') {
            _value = date.toUnix()
        } else if (typeValueDate==='Date') {
            _value = new Date(date.format('YYYY-MM-DD HH:mm:ss'))
        }
        return _value
    }

    return (
        <div 
            className={classNames([
                'input-calendar', 
                containerClassName
            ])} 
            onBlur={handleBlur}
            style={containerStyle}
        >
            <DatePicker
                value={val} 
                onChange={handleChange}
                format={format}
                months={months}
                weekDays={weekDays}
                arrow={false}
                offsetY={4}
                containerClassName='d-block'
                render={customInput||<CustomInputIcon 
                    placeholder={placeholder} 
                    className={classNames([
                        defaultClassName||'form-control', 
                        (isInvalid&&'is-invalid'), 
                        (isValid&&'is-valid')
                    ])} 
                />}

                onOpenPickNewDate={false}
                {...props}
            />
        </div>
    )
}

const regexTime = /^\d{1,2}(:\d{0,2}(:\d{0,2})?)?$/

/**
 * Componente InputDateTime, construido en base al componente DatePicker de react-multi-date-picker.
 * @param {object} props Propiedades del componente
 * @param {string | undefined} props.name Propiedad name del input.
 * @param {string | undefined} props.id ID del input.
 * @param {string | undefined} props.placeholderDate Descripción por defecto que se muestra en la parte de la fecha.
 * @param {string | undefined} props.placeholderTime Descripción por defecto que se muestra en laa parte de la hora.
 * @param {"datetime" | "unix" | "Date" | "DateObject" | string} props.typeValue Tipo de la fecha.
 * @param {string | number | Date | DateObject | undefined} props.value Valor del input.
 * @param {Function | undefined} props.onChange Callback que se ejecuta cada vez que cambia el valor del input.
 * @param {Function | undefined} props.onBlur Callback que se ejecuta cuando el input pierde el foco.
 * @param {string | undefined} props.formatLabelDate Formato en que se mostrará las fechas.
 * @param {React.CSSProperties | undefined} props.style Estilos en linea del componente.
 * @param {string | undefined} props.className Clase de estilo del contenedor del input.
 * @param {string | defaultClassName} props.defaultClassName Clase de estilos del input predefinida.
 * @param {boolean | undefined} props.isInvalid Especifica si el input tendrá estilos de validación incorrecta.
 * @param {boolean | undefined} props.isValid Especifica si el input tendrá estilos de validación correcta.
 * @param {boolean | undefined} props.minutes Especifica si el input aceptara minutos.
 * @param {boolean | undefined} props.seconds Especifica si el input aceptara segundos.
 * @returns {JSX.Element} Retorna el componente InputDateTime.
 */
export function InputDateTime({
    name='', id='', value, 
    onChange,
    onBlur,
    typeValue='Date',
    formatLabelDate='d/m/Y',
    placeholderDate,
    placeholderTime,
    minutes=true,
    seconds=false,
    defaultTime,
    isValid, isInvalid,
    defaultClassName, className,
    style,
}) {
    const [focus, setFocus] = useState(false)
    const format = useMemo(() => parseFormat(formatLabelDate), [formatLabelDate])
    const [time, setTime] = useState('')
    const [init, setInit] = useState(false)
    const formatTime = `H${minutes?(seconds?':i:s':':i'):''}`
    const date = useMemo(() => {
        let _date = ''
        const typeValue = typeof value
        if (typeValue === 'string') {
            _date = parseDate(value, '')
        } else if (typeValue === 'number') {
            const auxDate = new Date(value*1000)
            if (!isNaN(auxDate)) {
                _date = auxDate
            }
        } else if (value instanceof DateObject) {
            const auxDate = new Date(date.format('YYYY-MM-DD HH:mm:ss'))
            if (!isNaN(auxDate)) {
                _date = auxDate
            }
        } else if (value instanceof Date && !isNaN(value)) {
            _date = value
        }
        if (!init && _date) {
            setInit(true)
            setTime(formatDate(_date, formatTime))
        }
        if (!_date && time) {
            setTime('')
        }
        return _date
    }, [value])
    const dateRef = useRef(null)

    const handleChange = (_value) => {
        onChange && onChange(buildEvent(_value))
    }
    const handleChangeDate = (date) => {
        let _date = typeValue==='datetime'?'':null
        if (date && (time==='' || regexTime.test(time))) {
            const auxDate = new Date(date.format('YYYY-MM-DD HH:mm:ss'))
            if (!isNaN(auxDate) && auxDate.getFullYear() > 1000) {
                _date = auxDate
                if (time) {
                    _date = getDateTime(_date, time)
                } else {
                    const _defaultTime = regexTime.test(defaultTime||'')?defaultTime:'00:00:00'
                    _date = getDateTime(_date, _defaultTime)
                    setTime(formatDate(_date, formatTime))
                }
                _date = dateToFormatValue(_date)
            }
        }
        handleChange(_date)
    }
    const handleChangeTime = (e) => {
        let _date = typeValue==='datetime'?'':null
        const _time = e.target.value
        setTime(_time)
        if (date) {
            const _defaultTime = regexTime.test(defaultTime||'')?defaultTime:'00:00:00'
            _date = new Date(date.getTime())
            _date = getDateTime(_date, _time&&regexTime.test(_time)?_time:_defaultTime)
            _date = dateToFormatValue(_date)
        }
        handleChange(_date)
    }
    /**
     * Adiciona la hora a una fecha.
     * @param {Date} _date Fecha.
     * @param {string} _time Hora
     * @returns {Date} Fecha con la hora especificada.
     */
    const getDateTime = (_date, _time) => {
        if (_time) {
            const parts = _time.split(':')
            if (parts[0]<24) {
                _date.setHours(parts[0])
                if (minutes && parts.length>1 && parts[1]<60) {
                    _date.setMinutes(parts[1])
                    if (seconds && parts.length>2 && parts[2]<60) {
                        _date.setSeconds(parts[2])
                    } else {
                        _date.setSeconds(0)
                    }
                } else {
                    _date.setMinutes(0)
                    _date.setSeconds(0)
                }
            } else {
                _date.setHours(0)
            }
        }
        return _date
    }
    const handleFocus = () => { !focus && setFocus(true) }
    const handleBlur = () => { 
        focus && setFocus(false)
        onBlur && onBlur(buildEvent(value))
    }

    const buildEvent = (_value) => {
        return { target: { name, id, value: _value }, value: _value }
    }
    /**
     * Convierte un Date al tipo especificado en la prop typeValue.
     * @param {Date} date Fecha.
     */
    const dateToFormatValue = (date) => {
        let _value = date
        if (typeValue==='datetime') {
            _value = formatDate(date, `Y-m-d H${minutes?(seconds?':i:s':':i'):''}`) 
        } else if (typeValue==='unix') {
            _value = Math.floor(date.getTime() / 1000)
        } else if (typeValue==='DateObject') {
            _value = new DateObject(_value)
        }
        return _value
    }

    return (
        <div 
            className={classNames([
                'input-datetime',
                (defaultClassName??'form-control'),
                className,
                (focus&&'focus'),
                (isValid&&'is-valid'),
                (isInvalid&&'is-invalid')
            ])}
            onFocus={handleFocus}
            onBlur={handleBlur}
            style={style}
        >
            <DatePicker
                ref={dateRef}
                value={date} 
                onChange={handleChangeDate}
                onKeyUp={(e) => console.log(e)}
                format={format}
                months={months}
                weekDays={weekDays}
                arrow={false}
                offsetY={4}
                onOpenPickNewDate={false}
                containerClassName='input-datetime-date'
                render={<CustomInputCalendar placeholder={placeholderDate}/>}
            />
            <input
                value={time}
                onChange={handleChangeTime}
                className='input-datetime-time'
                placeholder={placeholderTime}
                onFocus={() => dateRef?.current?.closeCalendar()}
            />
        </div>
    )
}

function CustomInputCalendar({value, onChange, placeholder, openCalendar, onKeyDown}) {
    return (
        <input 
            placeholder={placeholder}
            onKeyDown={onKeyDown}
            value={value}
            onChange={onChange}
            onFocus={openCalendar}
        />
    )
}

function CustomInputIcon({value, onChange, placeholder, openCalendar, className, onKeyDown}) {
    return (
        <div className='d-flex align-items-center position-relative'>
            <input 
                className={className}
                placeholder={placeholder}
                onKeyDown={onKeyDown}
                value={value}
                onChange={onChange}
                onFocus={openCalendar}
            />
            <div 
                className='position-absolute cursor-pointer' 
                style={{right: '.5rem', lineHeight: 1}}
                onClick={openCalendar}
            >
                <span className='bi-calendar3'></span>
            </div>
        </div>
    )
}
