import { GenericRequest } from './request'

const InformeService = {

    create: (data) => GenericRequest.post('informe/create', data),
    update: (data, id) => GenericRequest.post('informe/update', data, {id}),
    view: (id) => GenericRequest.get('informe/view', {id}),

}

export default InformeService