import { GenericRequest, SignalRequest } from './request'

export const ReembolsoService = {
    indexReembolsos: (params) => SignalRequest.get('reembolso', params),
    indexReembolsosPost: (data, params) => GenericRequest.post('reembolso', data, params),
    searchReembolsos: (data, params) => GenericRequest.post('reembolso/search-reembolsos', data, params),
    viewReembolso: (id) => GenericRequest.get('reembolso/view', {id}),
    createReembolso: (data) => GenericRequest.post('reembolso/create', data),
    getNextNumReembolso: () => GenericRequest.get('reembolso/get-next-num-reembolso'),
    addCheque: (id, data) => GenericRequest.post('reembolso/add-cheque', data, {id}),
    cancelReembolso: (id) => GenericRequest.get('reembolso/anular', {id}),
    getSolicitudReembolso: (id) => GenericRequest.get('reembolso/get-solicitud-reembolso', {id}),
    getKardexViaticos: (data) => GenericRequest.post('reembolso/kardex-viaticos', data),
    searchUnidades: (unidad) => GenericRequest.get('reembolso/search-unidad-reembolso', {unidad}),
    
    fondoRotatorio: {
        get: () => GenericRequest.get('reembolso/get-fondo-rotatorio'),
        update: (data) => GenericRequest.post('reembolso/update-fondo-rotatorio', data),
        close: () => GenericRequest.get('reembolso/close-fondo-rotatorio'),
    },

    unidad: {
        index: (data, params) => SignalRequest.post('unidad-reembolsos', data, params),
        view: (id) => GenericRequest.get('unidad-reembolsos/view', {id}),
        create: (data) => GenericRequest.post('unidad-reembolsos/create', data),
        update: (data, id) => GenericRequest.put('unidad-reembolsos/update', data, {id}),
        delete: (id) => GenericRequest.delete('unidad-reembolsos/delete', {id}),
    }
}