import { useEffect } from 'react'
import PickList from 'components/PickList'
import ApiService from 'services/api.service'

/** @module Pages/Role/Components/FormAssignPermissions */

/**
 * Componente, Formulario de asignación de permisos de un Rol.
 * @param {object} props Propiedades del componente.
 * @param {Array<object>} props.source Estado, Permisos disponibles para asignar al rol.
 * @param {Array<object>} props.target Estado, Permisos asignados al rol.
 * @param {React.Dispatch<React.SetStateAction<object>>} props.setSource Dispatch para actualizar el estado de source.
 * @param {React.Dispatch<React.SetStateAction<object>>} props.setTarget Dispatch para actualizar el estado de target.
 * @param {object} props.selectedRole Rol seleccionado.
 * @returns {JSX.Element} Retorna el componente FormAssignPermissions.
 */
function FormAssignPermissions({source, target, setSource, setTarget, selectedRole}) {

    useEffect(()=>{
        const loadPermissions = async () => {
            const response = await ApiService.rol.getPermissions(selectedRole.name)
            if (response.status === 200) {
                setTarget(response.data.rolePermissions)
                setSource(response.data.permissions)
            }
        }
        if (selectedRole) loadPermissions()
    }, [selectedRole]) 

    const handleChange = (e) => {
        setTarget(e.target)
        setSource(e.source)
    }

    return (
        <PickList 
            source={source} target={target} 
            sourceHeader='Permisos disponibles' targetHeader='Permisos asignados'
            onChange={handleChange} optionLabel='description' 
            filterBy='description'
            style={{height: '25rem'}}
        />
    )
}

export default FormAssignPermissions