import { useEffect, useRef, useState } from 'react'
import Input from './Input'
import InputLayout from './layouts/InputLayout'
import Button from './Button'
import ListOfValues from './ListOfValues'
import ApiService from 'services/api.service'
import { classNames } from 'utils/utils'

/** @module Components/SearchBeneficiario */

/**
 * Buscador de beneficiarios por documento de identidad.
 * @param {object} props Propiedades del componente.
 * @param {Function} props.onSelect Callback que se ejecuta cuando se presiona el botón "Seleccionar".
 * @param {Function} props.onSearch Callback que se ejecuta cuando se realiza la búsqueda.
 * @param {Function} props.onHide Callback que se ejecuta cuando se cierra la lista de beneficiarios encontrados.
 * @param {React.ReactNode} props.label label del input de búsqueda.
 * @param {string} props.placeholder Descripción por defecto que se muestra en el input de búsqueda.
 * @param {boolean} props.showLabel Especifica si el label del input se mostrará o no.
 * @param {boolean} props.cleanOnSelect Especifica si el input se limpiara tras presionar el botón "Seleccionar".
 * @param {boolean} props.oneByCargo Especifica si se obtendrá un registro por cada cargo del beneficiario o no.
 * @param {string} props.description Descripción.
 * @returns {JSX.Element} Retorna el componente SearchBeneficiario
 */
function SearchBeneficiario({
    onSelect, onSearch, onHide, description,
    placeholder='', label='Documento:',
    showLabel=true, cleanOnSelect, oneByCargo=true, byName, autoFocus
}) {
    const tableRef = useRef(null)
    const [value, setValue] = useState('')
    const [show, setShow] = useState(false)
    const [beneficiarios, setBeneficiarios] = useState([])
    const [searching, setSearching] = useState(false)

    useEffect(() => {
        if (show && beneficiarios.length > 0) {
            tableRef.current?.focusRow(0)
        }
    }, [beneficiarios, show])

    const openLOV = () => setShow(true)
    const closeLOV = () => {
        typeof onHide === 'function' && onHide()
        setShow(false)
    }

    const handleSearch = async () => {
        if (value.length > 0) {
            setSearching(true)
            const _byName = byName && countDigits(value)===0
            const {status, data} = await ApiService.beneficiario.search(value, oneByCargo, _byName)
            if (status === 200) {
                setBeneficiarios(data)
                openLOV()
            }
            if (onSearch) onSearch()
            setSearching(false)
        }
    }
    const countDigits = (text) => {
        return text.split('').reduce((carry, char) => {
            return (/[0-9]/).test(char) ? carry+1 : carry
        }, 0)
    }
    const handleSelect = (selection) => {
        if (selection) {
            typeof onSelect === 'function' && onSelect(selection)
            cleanOnSelect && setValue('')
            closeLOV()
        }
    }
    const handleKeyUp = (e) => {
        if (e.key === 'Enter') handleSearch()
    }
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') e.preventDefault()
    }

    const columns = [
        { field: 'apellido_beneficiario', header: 'Apellidos',  },
        { field: 'nombre_beneficiario', header: 'Nombres' },
        ...(oneByCargo
            ? [{ field: 'cargo', header: 'Cargo', body: rowData => rowData.funcion||rowData.cargo }]
            : []
        ),
        { field: 'documento', header: 'Documento', style: {width: '8rem'} },
    ]

    return (
        <>
            <div className='d-flex'>
                <InputLayout label={label} inline className={classNames(['w-100', !showLabel && 'only-input'])}>
                    <Input 
                        value={value} 
                        onChange={(e) => setValue(e.target.value)} 
                        onKeyUp={handleKeyUp} 
                        onKeyDown={handleKeyDown} 
                        placeholder={placeholder} 
                        autoFocus={autoFocus}
                    />
                </InputLayout>
                <Button startIcon='pi pi-search' className='ms-3' onClick={handleSearch} loading={searching} title='Buscar' />
            </div>
            <ListOfValues 
                show={show}
                header='Beneficiarios'
                onHide={closeLOV}
                style={{width: '50rem', minHeight: '25rem'}}
                columns={columns}
                rows={beneficiarios}
                onSelect={handleSelect}
                description={description||''}
                tableClassName='table-beneficiarios'
                tableRef={tableRef}
            />
        </>
    )
}

export default SearchBeneficiario