import { useEffect, useState } from 'react'
import Button from './Button'
import { classNames } from 'utils/utils'

/** @module Components/PickList */

/**
 * Componente PickList
 * @param {object} props Propiedades del componente.
 * @param {string} props.optionLabel propiedad a mostrar como label.
 * @param {Array<object>} props.source Array de items del lado izquierdo.
 * @param {Array<object>} props.target Array de items del lado derecho.
 * @param {string} props.sourceHeader Titulo del lado derecho.
 * @param {string} props.targetHeader Titulo del lado izquierdo.
 * @param {Function} props.onChange Callback que se ejecuta cada vez que un item cambia de lado.
 * @param {string} props.filterBy Propiedad por la que se filtran los items.
 * @param {string} props.className Clase de estilo del componente.
 * @param {React.ReactNode} props.style Estilos en linea del componente.
 * @returns {JSX.Element} Retorna el componente PickList
 */
function PickList({optionLabel, source, target, sourceHeader, targetHeader, onChange, className, style={}, filterBy}) {
    const [selectedItem, setSelectedItem] = useState(null)
    const [sourceFilterValue, setSourceFilterValue] = useState('')
    const [targetFilterValue, setTargetFilterValue] = useState('')
    const [sourceFilteredItems, setSourceItems] = useState([])
    const [targetFilteredItems, setTargetItems] = useState([])

    useEffect(() => {
        const getItems = () => {
            return filterBy ? source.filter(item => {
                const _value = (typeof item === 'object' ? item[filterBy] : item).toLowerCase()
                return _value.includes(sourceFilterValue.toLowerCase())
            }) : source
        }
        setSourceItems(getItems())
    }, [source, sourceFilterValue]) // eslint-disable-line
    useEffect(() => {
        const getItems = () => {
            return filterBy ? target.filter(item => {
                const _value = (typeof item === 'object' ? item[filterBy] : item).toLowerCase()
                return _value.includes(targetFilterValue.toLowerCase())
            }) : target
        }
        setTargetItems(getItems())
    }, [target, targetFilterValue]) // eslint-disable-line

    const handleSourceFilter = (value) => setSourceFilterValue(value)
    const handleTargetFilter = (value) => setTargetFilterValue(value)

    const addItem = () => {
        if(source.includes(selectedItem)) {
            const _source = source.filter((item) => item !== selectedItem)
            const _target = [selectedItem, ...target]
            if(typeof onChange === 'function') onChange({ source: _source, target: _target })
        }
    }
    const addAllItems = () => {
        const _target = [...sourceFilteredItems, ...target]
        const _source = source.filter(s => !sourceFilteredItems.includes(s))
        if(typeof onChange === 'function') onChange({ source: _source, target: _target })
        setSelectedItem(null)
    }

    const removeItem = () => {
        if(target.includes(selectedItem)) {
            const _target = target.filter((item) => item !== selectedItem)
            const _source = [...source, selectedItem]
            if(typeof onChange === 'function') onChange({ source: _source, target: _target })
        }
    }
    const removeAllItems = () => {
        const _source = [...source, ...targetFilteredItems]
        const _target = target.filter(t => !targetFilteredItems.includes(t))
        if(typeof onChange === 'function') onChange({ source: _source, target: _target })
        setSelectedItem(null)
    }

    return (
        <div className={classNames(['bs-picklist', className])} style={style}>
            {/* Source */}
            <ListWrapperPickList 
                optionLabel={optionLabel}
                items={sourceFilteredItems} 
                selectedItem={selectedItem} 
                setSelectedItem={setSelectedItem} 
                headerText={sourceHeader}
                filterBy={filterBy}
                filterValue={sourceFilterValue}
                handleFilter={handleSourceFilter}
            />
            <div className='bs-picklist-buttons'>
                <Button startIcon='bi-chevron-double-right' className='mb-3' onClick={addAllItems} />
                <Button startIcon='bi-chevron-right' className='mb-3' onClick={addItem} />
                <Button startIcon='bi-chevron-left' className='mb-3' onClick={removeItem} />
                <Button startIcon='bi-chevron-double-left' onClick={removeAllItems} />
            </div>
            {/* Target */}
            <ListWrapperPickList 
                optionLabel={optionLabel}
                items={targetFilteredItems} 
                selectedItem={selectedItem} 
                setSelectedItem={setSelectedItem} 
                headerText={targetHeader}
                filterBy={filterBy}
                filterValue={targetFilterValue}
                handleFilter={handleTargetFilter}
            />
        </div>
    )
}

function ListWrapperPickList({
    optionLabel, items, 
    selectedItem, setSelectedItem, 
    headerText, filterBy, 
    handleFilter=()=>{}, filterValue=''
}) {

    const header = typeof headerText === 'string' ?
        <div className='bs-picklist-header'>{headerText}</div>
        : null

    const filter = filterBy ?
        <div className='bs-picklist-filter'>
            <input 
                className='form-control' 
                value={filterValue}
                onChange={(e) => handleFilter(e.target.value)}
            />
        </div>
        : null

    return (
        <div className='bs-picklist-list-wrapper'>
            {header}
            {filter}
            <div className='bs-picklist-list' >
                {items.map((item, index) => (
                    <ItemPickList 
                        optionLabel={optionLabel} item={item} selectedItem={selectedItem} 
                        setSelectedItem={setSelectedItem} key={index} 
                    />
                ))}
            </div>
        </div>
    )
}

function ItemPickList({optionLabel, item, selectedItem, setSelectedItem}) {
    return (
        <div 
            className={`bs-picklist-item${item === selectedItem ? ' active' : ''}`}
            onClick={() => setSelectedItem(item)}
        >
            {optionLabel ? item[optionLabel] : item}
        </div>
    )
}

export default PickList
