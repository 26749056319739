const styles = {
    justifyAlign: 'text-justify',
    rightAlign: 'text-end',
    leftAlign: 'text-start',
    centerAlign: 'text-center',
    BOLD: 'fw-bold',
    ITALIC: 'fst-italic',
    UNDERLINE: 'text-decoration-underline',
    HIGHLIGHT: 'highlight',
    'header-one': 'header1',
    'header-two': 'header2',
}

/**
 * Convierte un objeto raw de DraftJs en objetos de texto de PdfMake
 * @ignore
 * @param {object} raw 
 * @returns Array de objetos de texto de PdfMake
 */
function draftJsRawToPdfMakeJson(raw) {
    raw = typeof raw === 'string' ? JSON.parse(raw) : raw
    return raw.blocks.map(block => {
        let text = block.text.trim() === '' ? "\n" : block.text
        const style = []
        if (block.type !== 'unstyled') {
            // Asigna estilo de bloque
            style.push(styles[block.type])
        }
        if (block.inlineStyleRanges.length > 0) {
            // Ordena los estilos en linea según su offset
            const inStRa = block.inlineStyleRanges.sort((a, b) => {
                if (a.offset < b.offset) {
                    return -1
                } else if (a.offset > b.offset) {
                    return 1
                }
                if (a.length < b.length) {
                    return -1
                } else if (a.length > b.length) {
                    return 1
                }
                return 0;
            })
            // Obtiene todos los offsets de los estilos
            const offsets = inStRa.map(element => element.offset)
            const auxText = []
            let controlOffset = 0
            inStRa.forEach((element, index) => {
                const offset = element.offset
                const endOffset = offset+element.length
                if (offset > controlOffset) {
                    auxText.push({ 
                        text: text.substring(controlOffset, offset), style: [], offset: controlOffset, length: offset 
                    })
                }
                const nextOffsets = offsets.filter(next => next > offset && next < endOffset)
                if (nextOffsets.length > 0) {
                    let initOffset = offset
                    nextOffsets.forEach(next => {
                        auxText.push({ 
                            text: text.substring(initOffset, next), style: [styles[element.style]],
                            offset: initOffset, length: next-initOffset 
                        })
                        initOffset = next
                    })
                    auxText.push({ 
                        text: text.substring(initOffset, endOffset), style: [styles[element.style]],
                        offset: initOffset, length: endOffset-initOffset 
                    })
                } else {
                    const lastTexts = auxText.filter(last => last.offset === offset)
                    if (lastTexts.length > 0) {
                        lastTexts.forEach(last => {
                            last.style.push(styles[element.style])
                        })
                        const lastText = lastTexts[lastTexts.length-1]
                        if (lastText.offset+lastText.length < endOffset) {
                            auxText.push({ 
                                text: text.substring(lastText.offset+lastText.length, endOffset), style: [styles[element.style]],
                                offset: offset, length: endOffset-offset 
                            })
                        }
                    } else {
                        auxText.push({ 
                            text: text.substring(offset, endOffset), style: [styles[element.style]],
                            offset: offset, length: endOffset-offset 
                        })
                    }
                }
                if (index === inStRa.length-1) {
                    auxText.push({ 
                        text: text.substring(endOffset, text.length), style: [],
                        offset: endOffset, length: text.length-endOffset 
                    }) 
                }
                controlOffset = endOffset
            })
            text = auxText.reduce((carry, item) => {
                const { text, style } = item
                if (text.trim()) {
                    if (style.length > 0) {
                        carry.push({text, style})
                    } else {
                        carry.push({text})
                    }
                }
                return carry
            }, [])
        }
        return style.length > 0 ? { text, style } : { text }
    })
}

/**
 * Convierte un array de objetos de texto de PdfMake a HTML
 * @ignore
 * @param {array} array 
 * @returns Array de elementos HTML
 */
function pdfMakeJsonToHTML(array) {
    return array.map((line, index) => {
        let text = null
        if (Array.isArray(line.text)) {
            let _text = line.text.map((element, _index) => element.style ? <span key={_index} className={element.style.join(' ')}>{element.text}</span> : element.text)
            if (line.style) {
                text = <div key={index} className={line.style.join(' ')}>{_text}</div>
            } else {
                text = <div key={index}>{_text}</div>
            }
        } else {
            if (line.text === "\n") {
                text = <br/>
            } else {
                if (line.style) {
                    text = <div key={index} className={line.style.join(' ')}>{line.text}</div>
                } else {
                    text = <div key={index}>{line.text}</div>
                }
            }
        }
        return text
    })
}

/**
 * Convierte un objeto raw de DraftJs en HTML
 * @ignore
 * @param {object} raw 
 * @returns Array de elementos HTML
 */
function draftJsRawToHTML(raw) {
    return pdfMakeJsonToHTML(draftJsRawToPdfMakeJson(raw))
}

const StringUtil = {
    draftJsRawToPdfMakeJson,
    pdfMakeJsonToHTML,
    draftJsRawToHTML,
}

export default StringUtil

export {
    draftJsRawToPdfMakeJson,
    pdfMakeJsonToHTML,
    draftJsRawToHTML,
}