import ToggleButton from 'components/ToggleButton'
import InputLayout from 'components/layouts/InputLayout'
import { ErrorMessage, Field } from 'formik'
import { toPascalCase } from 'utils/utils'

function ToggleButtonFormik({ 
    name='', label='', containerClassName, containerStyle, inline=false, info, ...props
}) {
    return (
        <InputLayout 
            label={label ? label : toPascalCase(name)}
            className={containerClassName}
            style={containerStyle}
            inline={inline}
            info={info}
        >
            <Field name={name}>
                { ({field}) => <ToggleButton {...field} {...props} /> }
            </Field>
            { name ? <ErrorMessage name={name}>{msg => <div className='invalid-feedback d-block'>{msg}</div>}</ErrorMessage> : null}
        </InputLayout>
    )
}

export default ToggleButtonFormik