import axios from 'axios'
import { formatDate } from 'utils/utils'

/**
 * Convierte los datos de tipo Date a string
 * Para mantener la hora local
 * @ignore
 * @param {any} data 
 * @returns {object} los datos del body de la petición.
 */
const dateTransformer = data => {
    if (data instanceof Date) {
      return formatDate(data, 'Y-m-d H:i:s')
    }
    if (Array.isArray(data)) {
      return data.map(val => dateTransformer(val))
    }
    if (typeof data === "object" && data !== null) {
      return Object.fromEntries(Object.entries(data).map(([ key, val ]) =>
        [ key, dateTransformer(val) ]))
    }
    return data
  }

export const API = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    transformRequest: [ dateTransformer ].concat(axios.defaults.transformRequest)
})