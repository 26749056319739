import { createSlice } from '@reduxjs/toolkit'
import { sections } from 'data/const'

const initialState = {
	section: '',
	sectionKey: '',
    titlePage: ''
}

const systemSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setSystemSection: (state, {payload}) => {
			state.section = payload.section
			state.sectionKey = payload.sectionKey
		},
        setSystemTitlePage: (state, {payload}) => {
			state.titlePage = payload.titlePage
		},
	},
})

export const setSection = (sectionKey) => (dispatch) => {
	const section = sections[sectionKey]
	const payload = section ? {section, sectionKey} : {section: '', sectionKey: ''}
	dispatch(setSystemSection(payload))
}
export const setTitlePage = (titlePage) => (dispatch) => {
    dispatch(setSystemTitlePage({titlePage}))
}

export const { setSystemSection, setSystemTitlePage } = systemSlice.actions
export default systemSlice.reducer