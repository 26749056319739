import { createRef, forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { classNames } from 'utils/utils'

const t = {
    appear: 'bs-toast-on-show',
    appearActive: 'bs-toast-on-show-active',
    appearDone: 'show',
    enter: 'bs-toast-on-show',
    enterActive: 'bs-toast-on-show-active',
    enterDone: 'show',
    exit: 'bs-toast-on-close',
    exitActive: 'bs-toast-on-close-active',
    exitDone: '',
}
const severityIcons = {
    success: 'bi-check2',
    info: 'bi-info-circle',
    warning: 'bi-exclamation-triangle',
    error: 'bi-x-lg',
}

/** @module Components/Toast */

/**
 * Componente Toast
 * @method
 * @param {object} props Propiedades del componente.
 * @param {"top-right" | "top-left" | "top-center" | "bottom-right" | "bottom-left" | "bottom-center" | "center"} props.position Posición donde se mostraran los mensajes.
 * @param {Function | undefined} props.onShow Callback que se ejecuta cada vez que un mensaje se muestra.
 * @param {Function | undefined} props.onHide Callback que se ejecuta cada vez que un mensaje se oculta.
 * @param {React.Ref<any>} ref
 * @example 
 * const toastRef = useRef(null)
 * const show = () => {
 *      toastRef.current.show({{
 *          severity: 'success',
 *          title: 'Success Title',
 *          detail: 'Message',
 *      })
 * }
 * return <>
 *      <Toast ref={toastRef}>
 *      <Button onClick={show}>Show Message</Button>
 * </>
 * @returns {JSX.Element} Retorna el componente Toast.
 */
const Toast = forwardRef(({position='top-right', className, style, baseZIndex, onShow, onHide}, ref) => {
    const contentRef = useRef()
    const [items, setItems] = useState([])

    const clear = () => setItems([])
    const getElement = () =>  {
        return contentRef.current
    }
    const show = (data) => {
        const _toasts = Array.isArray(data) 
            ? data.map(item => ({...item, id: Math.random().toString()})) 
            : [{...data, id: Math.random().toString()}]
        setItems([...items, ..._toasts])
    }

    useImperativeHandle(ref, () => ({
        clear,
        getElement,
        show
    }))

    const onShowItem = () => {
        onShow && onShow();
    }
    const onHideItem = () => {
        onHide && onHide();
    }

    return (
        <div 
            ref={contentRef} className={classNames(['bs-toast', position, className])} 
            style={{zIndex: baseZIndex||1090, ...style}}
        >
            <TransitionGroup>
                {items.map((data, index) => {
                    const toastRef= createRef()
                    return (
                        <CSSTransition
                            key={data.id}
                            nodeRef={toastRef}
                            classNames={t}
                            timeout={{enter: 300, exit: 300}}
                            unmountOnExit={true}
                            index={index}
                            onEntered={onShowItem}
                            onExited={onHideItem}
                        >
                            <ToastItem 
                                {...data} 
                                ref={toastRef} 
                                onClose={() => {
                                    setItems((items) => items.filter((item) => item.id !== data.id))
                                }} 
                            />
                        </CSSTransition>
                    )
                })}
            </TransitionGroup>
        </div>
    )
        
})
/**
 * Componente ToastItem. Muestra el mensaje en el Toast.
 * @method
 * @param {"success" | "info" | "warning" | "error"} props.severity Estilo del toast 
 * - Valor predeterminado 'info'
 * @param {object} props Propiedades del componente.
 * @param {string | undefined} props.title Titulo del toast
 * @param {string | undefined} props.detail Detalle del toast
 * @param {int | undefined} props.delay Tiempo para cerrar el toast
 * - Valor predeterminado 5000
 * @param {boolean | undefined} props.sticky True el toast se mantendrá hasta ser cerrado manualmente
 * @param {string | undefined} props.className Clase de estilos del toast
 * @param {React.CSSProperties | undefined} props.style Estilos en linea del toast
 * @param {React.ReactNode | undefined} props.content Contenido personalizado para el toast, las propiedades title y detail son ignoradas
 * @param {string | undefined} props.contentClassName Clase de estilos del contenedor del mensaje
 * @param {React.CSSProperties | undefined} props.contentStyle Estilos en linea del contenedor del mensaje
 * @param {React.Ref<any>} ref
 * @return {JSX.Element} Retorna el componente ToastItem.
 */
const ToastItem = memo(forwardRef(({ 
    title, detail, delay, severity='info', sticky, className, style,
    content, contentClassName, contentStyle, onClose
}, ref) => {
    const timeout = useRef(null)
    const [hover, setHover] = useState(false)

    const clear = useCallback(() => {
        timeout?.current && clearTimeout(timeout.current)
    }, [timeout.current]);
    
    useEffect(() => {
        if (!sticky && !hover) {
            timeout.current = setTimeout(() => {
                onClose()
            }, delay||5000)
            return clear
        } else {
            clear()
        }
    }, [hover])
    useEffect(() => {
        return clear
    }, [])

    const handleMouseEnter = () => {
        clear()
        setHover(true)
    }
    const handleMouseLeave = () => {
        setHover(false)
    }

    return (
        <div 
            ref={ref} role='alert' 
            style={style}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={classNames([
                'bs-toast-content', 
                severity, 
                className
            ])} 
        >
            <div 
                className={classNames(['bs-toast-message-content', contentClassName])} 
                style={contentStyle} 
            >
                {
                    content || <>
                        <span className={classNames(['bs-toast-icon', severity?severityIcons[severity]:''])}></span>
                        <div className='bs-toast-message'>
                            <span className='bs-toast-title'>{title}</span>
                            <div className='bs-toast-detail'>{detail}</div>
                        </div>
                    </>
                }
                <div>
                    <button type='button' className='bs-toast-btn-close' onClick={onClose}>
                        <span className='pi pi-times'></span>
                    </button>
                </div>
            </div>
        </div>
    )
        
}))

export default Toast