import Input from 'components/Input'
import { InputFormik } from 'components/formik/formikFormComponents'
import InputLayout from 'components/layouts/InputLayout'
import { Form, Formik } from 'formik'
import { formatDate } from 'utils/utils'

/** @module Pages/Solicitud/Components/FormDeny */

/**
 * Componente, Formulario de denegación de solicitud.
 * @param {object} props Propiedades del componente.
 * @param {React.MutableRefObject<any>} props.formRef Referencia del formulario.
 * @param {Function} props.onSubmit Callback a ejecutar cuando se de submit del formulario.
 * @param {object} props.selected Solicitud seleccionada.
 * @returns {JSX.Element} Retorna el componente FormDeny.
 */
function FormDeny({ formRef, onSubmit, selected }) {
    return (
        <Formik
            innerRef={formRef}
            initialValues={{observaciones: ''}}
            onSubmit={onSubmit}
        >
            {() => (
                <Form>
                    <InputLayout label='Número de solicitud'>
                        <Input value={selected.numero_solicitud+'/'+formatDate(selected.fecha_creacion, 'y')} readOnly />
                    </InputLayout>
                    <InputFormik 
                        name='observaciones' as='textarea' 
                        style={{ resize: 'none', height: '8rem' }}
                        label='Observaciones:'
                    />
                </Form>
            )}
        </Formik>
    )
}
export default FormDeny