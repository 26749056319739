import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { setHttpMessage } from 'store/messageSlice'

import ApiService from 'services/api.service'
import Dialog from 'components/Dialog'
import Button, { ButtonIcon } from 'components/Button'
import TitlePage from 'components/TitlePage'
import DataTable from 'components/DataTable'
import InputDropdown from 'components/InputDropdown'
import FormCategoria from './components/FormCategoria'
import { numEscalas } from 'data/const'

/** @module Pages/Categoria/CategoriaIndex */

/**
 * Página de administración de Categorías.
 * @returns {JSX.Element} Retorna el componente CategoriaIndex.
 */
export default function CategoriaIndex() {
    const dispatch = useDispatch()
    const [categorias, setCategorias] = useState([])
    const [selectedCategoria, setSelectedCategoria] = useState(null)
    const [reglamentos, setReglamentos] = useState([])
    const [selectedReglamento, setSelectedReglamento] = useState('')
    const [show, setShow] = useState({form: false})
    const [submitting, setSubmitting] = useState(false)
    const formRef = useRef()
    
    useEffect(()=>{
        const loadReglamentos = async () => {
            const response = await ApiService.reglamento.getAll()
            if (response.status === 200) {
                const _reglamentos =response.data
                let activeReglamento = ''
                _reglamentos.forEach(r => {
                    if (r.activo) activeReglamento = r
                })
                if (activeReglamento) setSelectedReglamento(activeReglamento)
                setReglamentos(_reglamentos)
            }
        }
        loadReglamentos()
    }, [])
    useEffect(() => {
        if (selectedReglamento) loadCategorias()
    }, [selectedReglamento])

    const loadCategorias = async () => {
        const response = await ApiService.categoria.getByReglamento(selectedReglamento.id)
        if (response.status === 200) {
            const _categorias = normalizeCategorias(response.data, numEscalas)
            setCategorias(_categorias)
        }
    }
    const normalizeCategorias = (categs, numCat=5) => {
        const _categorias = {}
        for (let i = 1; i <= numCat; i++) {
            _categorias['cat'+i] = {codigo: 'cat'+i, descripcion: 'Categoría '+i, descripcion_cargos: '', reglamento_id: selectedReglamento.id, cargos: []}
        }
        const cats = ['cat1', 'cat2', 'cat3', 'cat4', 'cat5'].slice(0, numCat)
        categs.forEach(cat => {
            if (cats.includes(cat.codigo)) {
                _categorias[cat.codigo] = cat
            }
        })
        return Object.values(_categorias)
    }

    // Controlador para abrir los modales
    const handleOpenDialog = (action, rowData) => {
        setSelectedCategoria(rowData)
        if (action === 'update' || action === 'create') {
            openDialog('form')
        }
    }
    const openDialog = (name) => setShow({...show, [name]: true})
    const closeFormDialog = () => setShow({...show, form: false})

    const handleSubmit = () => { if (formRef.current) formRef.current.handleSubmit() }
    const handleCreate = async (values) => {
        if (selectedReglamento?.id) {
            setSubmitting(true)
            const response = await ApiService.categoria.create(values)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 201) {
                loadCategorias()
                closeFormDialog()
            }
            setSubmitting(false)
        }
    }
    const handleUpdate = async (values) => {
        if (selectedCategoria?.id) {
            setSubmitting(true)
            const _values = { ...values }
            const response = await ApiService.categoria.update(_values, selectedCategoria.id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                loadCategorias()
                closeFormDialog()
            }
            setSubmitting(false)
        }
    }
    const clearSelected = () => setSelectedCategoria(null)

    const formFooterDialog = <>
        <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeFormDialog}>Cancelar</Button>
        <Button 
            variant='text-secondary' startIcon='pi pi-save' 
            type='submit' onClick={handleSubmit}
            loading={submitting}
        >Guardar</Button>
    </>
    const columns = [
        {
            field: 'descripcion',
            header: 'Descripción',
            style: { width: '25%' },
        },
        {
            field: 'descripcion_cargos',
            header: 'Descripción Cargos',
        },
        ...(selectedReglamento?.activo ? [{
            field: 'id',
            header: 'Opciones',
            body: (rowData) => (
                <div className='text-center'>
                    <ButtonIcon 
                        variant='text-secondary' rounded 
                        icon='bi-pencil' title='Editar' 
                        onClick={() => handleOpenDialog('update', rowData)}
                    />
                </div>
            ),
            style: { width: '7rem' },
        }]: []),
    ]

    return <>
        <TitlePage title='Categorías'/>
        <div className='content'>
            <div>
                <div className='d-flex align-items-center mb-4'>
                    <span className='me-2'>Reglamento:</span>
                    <InputDropdown 
                        value={selectedReglamento}
                        onChange={(e) => setSelectedReglamento(e.target.value)} 
                        label='Reglamento'
                        options={reglamentos}
                        optionValue='id'
                        valueAsOption
                        valueTemplate={(option)=>`${option.tipo} ${option.numero}`}
                        itemTemplate={(option)=>`${option.tipo} ${option.numero}`}
                    />
                </div>
                <DataTable values={categorias} columns={columns}/>

                <Dialog
                    show={show.form}
                    header={'Editar Categoría'} 
                    footer={formFooterDialog}
                    keyboard={false}
                    style={{width: '60rem'}}
                    onHide={closeFormDialog}
                    onExited={clearSelected}
                >
                    <FormCategoria
                        formRef={formRef} selected={selectedCategoria}
                        onSubmit={selectedCategoria?.id ? handleUpdate : handleCreate}  
                    />
                </Dialog>

            </div>
        </div>
    </>
}