import Input from 'components/Input'
import ScrollUp from 'components/ScrollUp'
import TitlePage from 'components/TitlePage'
import InputLayout from 'components/layouts/InputLayout'
import { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import ApiService from 'services/api.service'

/** @module Pages/Unidad/EstructuraProgramatica */

/**
 * Página, Estructura programática.
 * @returns {JSX.Element} Retorna el componente EstructuraProgramatica.
 */
function EstructuraProgramatica() {
    const [estructura, setEstructura] = useState([])
    const [filters, setFilters] = useState({da: '', ue: '', u: ''})

    useEffect(() => {
        const loadData = async () => {
            const response = await ApiService.getEstructuraProgramatica()
            if (response.status === 200) setEstructura(response.data)
        }
        loadData()
    }, [])

    const handleChange = (e) => setFilters({...filters, [e.target.name]: e.target.value})
    const getEstructura = () => {
        const _filters = {
            u: filters.u.toLowerCase(),
            ue: filters.ue.toLowerCase(),
            da: filters.da.toLowerCase()
        }
        return estructura.reduce((carry, da) => {
            const ues = da.unidadEjecutoras.reduce((_carry, ue) => {
                const unidades = ue.unidades.reduce((__carry, u) => {
                    if (u.descripcion.toLowerCase().includes(_filters.u)||u.programa.includes(_filters.u)||u.proyecto.includes(_filters.u)||u.actividad.includes(_filters.u)) {
                        __carry.push(<tr>
                            <td>{da.codigo}</td>
                            <td>{ue.codigo}</td>
                            <td>{u.programa}</td>
                            <td>
                                {parseInt(u.proyecto)>0 && u.proyecto.length>3 
                                    ? <>
                                        <span style={{color: '#888888'}}>{u.proyecto.substring(0, 5)}</span>
                                        {u.proyecto.substring(5, 8)}
                                        <span style={{color: '#888888'}}>{u.proyecto.substring(9)}</span>
                                    </>
                                    : <span style={{color: '#888888'}}>{u.proyecto}</span>
                                }
                            </td>
                            <td>{parseInt(u.actividad)>0 ? u.actividad : <span style={{color: '#888888'}}>{u.actividad}</span>}</td>
                            <td className='text-start'>{u.descripcion}</td>
                        </tr>)
                    }
                    return __carry
                }, [])
                if (((unidades.length > 0 && _filters.u)||!_filters.u) && (ue.descripcion.toLowerCase().includes(_filters.ue)||ue.codigo.includes(_filters.ue))) {
                    _carry.push(<>
                        <tr>
                            <th className='border-end-0'></th>
                            <th className='border-0'>{ue.codigo}</th>
                            <th className='text-start border-start-0' colSpan={4}>{ue.descripcion}</th>
                        </tr>
                        {unidades}
                    </>)
                }
                return _carry
            }, [])
            if (((ues.length > 0 && _filters.ue)||!_filters.ue) && (da.descripcion.toLowerCase().includes(_filters.da)||da.codigo.includes(_filters.da))) {
                carry.push(<>
                    <tr>
                        <th className='border-end-0'>{da.codigo}</th>
                        <th className='text-start border-start-0' colSpan={5}>{da.descripcion}</th>
                    </tr>
                    {ues}
                </>)
            }
            return carry
        }, [])
    }

    return <>
        <TitlePage title='Estructura Programática' /> 
        <div className='content align-items-center' style={{backgroundColor: '#efefef'}}>
            <Card className='w-xl-75 p-5'>
                <div>
                    <div className='border rounded position-relative p-3 pt-4 mb-3'>
                        <div className='position-absolute bg-white px-2' style={{top: '-.65rem'}}>Filtros</div>
                        <div className='row g-3'>
                            <InputLayout className='col-md-4 col-xxl-auto' label='DA:' inline>
                                <Input name='da' value={filters.da} onChange={handleChange} />
                            </InputLayout>
                            <InputLayout className='col-md-4 col-xxl-auto' label='UE:' inline>
                                <Input name='ue' value={filters.ue} onChange={handleChange} />
                            </InputLayout>
                            <InputLayout className='col-md-4 col-xxl-auto' label='Unidad:' inline>
                                <Input name='u' value={filters.u} onChange={handleChange} />
                            </InputLayout>
                        </div>
                    </div>
                    <table 
                        className='table table-sm table-bordered text-center' 
                        style={{borderColor: '#000', verticalAlign: 'middle'}}
                    >
                        <thead style={{backgroundColor: '#f0f0f0'}}>
                            <tr><th colSpan={6}>ESTRUCTURA PROGRAMÁTICA UMSS</th></tr>
                            <tr>
                                <th style={{width: '5rem'}}>DA</th>
                                <th style={{width: '5rem'}}>UE</th>
                                <th style={{width: '5rem'}}>PROG.</th>
                                <th style={{width: '10rem'}}>PROY.</th>
                                <th style={{width: '5rem'}}>ACTV.</th>
                                <th>DETALLE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getEstructura()}
                        </tbody>
                    </table>
                </div>
            </Card>
        </div>
        <ScrollUp id='main-container'/>
    </>
}
export default EstructuraProgramatica