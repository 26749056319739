import Button, { ButtonIcon } from "components/Button"
import DataTable from "components/DataTable"
import Dialog, { ConfirmDialog } from "components/Dialog"
import TitlePage from "components/TitlePage"
import { useEffect, useRef, useState } from "react"
import FormCargoInvitado from "./components/FormCargoInvitado"
import ApiService from "services/api.service"
import { useDispatch } from "react-redux"
import { setHttpMessage } from "store/messageSlice"

/** @module Pages/CargoInvitado/CargoInvitadoIndex */

// Opciones de los cargos de invitados.
const actions = [
    {icon: 'bi-pencil', title: 'Editar', action: 'update'},
    {icon: 'bi-trash', title: 'Eliminar', action: 'delete'},
]

/**
 * Página de administración de Cargos de invitados.
 * @returns {JSX.Element} Retorna el componente CargoInvitadoIndex.
 */
function CargoInvitadoIndex() {
    const dispatch = useDispatch()
    const [cargos, setCargos] = useState([])
    const [selected, setSelected] = useState(null)
    const [show, setShow] = useState({form: false, delete: false})
    const [reload, setReload] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const formRef = useRef(null)

    useEffect(() => {
        const loadData = async () => {
            const response = await ApiService.cargo.getInvitados()
            if (response.status === 200) setCargos(response.data)
        }
        loadData()
    }, [reload])

    const handleAction = (action, rowData=null) => {
        if (rowData) setSelected(rowData)
        if (['create', 'update'].includes(action)) {
            openDialog('form')
        } else if (action==='delete') {
            openDialog('delete')
        }
    }
    const openDialog = (name) => setShow({...show, [name]: true})
    const closeFormDialog = () => setShow({...show, form: false})
    const closeDeleteDialog = () => setShow({...show, delete: false})
    const clearSelected = () => setSelected(null)
    const handleSubmit = () => formRef?.current?.handleSubmit()
    const handleCreate = async (values) => {
        setSubmitting(true)
        const response = await ApiService.cargo.create(values)
        dispatch(setHttpMessage({status: response.status, title: response.data.message}))
        if (response.status === 201) {
            setReload(!reload)
            closeFormDialog()
        }
        setSubmitting(false)
    }
    const handleUpdate = async (values) => {
        if (selected) {
            setSubmitting(true)
            const response = await ApiService.cargo.update(values, selected.id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                setReload(!reload)
                closeFormDialog()
            }
            setSubmitting(false)
        }
    }
    const handleDelete = async () => {
        if (selected) {
            const response = await ApiService.cargo.delete(selected.id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                setReload(!reload)
            }
            closeDeleteDialog()
        }

    }

    const actionsBodyTemplate = (rowData) => {
        const _options = actions.reduce((carry, option) => {
            if ((typeof option.condition === 'function' && option.condition(rowData)) || typeof option.condition !== 'function') {
                carry.push(
                    <ButtonIcon 
                        variant='text-secondary' rounded 
                        icon={option.icon} title={option.title} key={option.title} 
                        onClick={() => handleAction(option.action, rowData)}
                    />
                )
            }
            return carry
        }, [])
        return <div>{_options}</div>
    }
    const columns = [
        {field: 'cargo', header: 'Cargo'},
        {field: 'id', header: 'Opciones', body: actionsBodyTemplate, style: {width: '12rem'}},
    ]
    const formFooterDialog = <>
        <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeFormDialog}>Cancelar</Button>
        <Button 
            variant='text-secondary' startIcon='pi pi-save' 
            type='submit' onClick={handleSubmit}
            loading={submitting}
        >Guardar</Button>
    </>
    const options = <>
        <Button startIcon='pi pi-plus' onClick={()=>handleAction('create')} >Nuevo</Button>
    </>

    return <>
        <TitlePage title='Lista de Cargos de Invitados' options={options}/>
        <div className='content'>
            <DataTable
                values={cargos}
                columns={columns}
            />
            <Dialog
                show={show.form}   
                header={ selected ? 'Editar Cargo Invitado' : 'Crear Cargo Invitado' } 
                footer={formFooterDialog}
                keyboard={false}
                onHide={closeFormDialog}
                onExited={clearSelected}
            >
                <FormCargoInvitado formRef={formRef} onSubmit={selected?handleUpdate:handleCreate} selected={selected}/>
            </Dialog>

            <ConfirmDialog
                show={show.delete}
                onHide={closeDeleteDialog}
                onReject={closeDeleteDialog}
                onAccept={handleDelete}
                onExited={clearSelected}
            >
                Esta seguro de <span className='text-primary'>ELIMINAR</span> lel Cargo {selected?.cargo}?
            </ConfirmDialog>
        </div>
    </>
}
export default CargoInvitadoIndex