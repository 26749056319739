import TitlePage from 'components/TitlePage'
import FormChangePassword from './components/FormChangePassword'

/** @module Pages/Usuario/UserSettings */

/**
 * Página de Configuración de cuenta de usuario.
 * @returns {JSX.Element} Retorna el componente UserSettings.
 */
function UserSettings() {
    return <>
        <TitlePage title='Configuración de Cuenta' />
        <div className='content align-items-center'>
            <div className='w-100 w-xl-50'>
                <div className='fs-4 mb-3'>Cambiar contraseña</div>
                <FormChangePassword/>
            </div>
        </div>
    </>
}
export default UserSettings