import Button, { ButtonIcon } from 'components/Button'
import DataTable from 'components/DataTable'
import Dialog, { ConfirmDialog } from 'components/Dialog'
import TitlePage from 'components/TitlePage'
import { initialPagination } from 'data/const'
import useFilters from 'hooks/useFilters'
import { useEffect, useRef, useState } from 'react'
import { ReembolsoService } from 'services/reembolso.service'
import FormUnidadReembolso from './components/FormUnidadReembolso'
import { useDispatch } from 'react-redux'
import { setHttpMessage } from 'store/messageSlice'
import { verifyAccess } from 'utils/rbac.util'

/** @module Pages/Reembolso/UnidadReembolsoIndex */

const actions = [
    {icon: 'bi-pencil', title: 'Editar', action: 'update', condition: () => verifyAccess('unidadReembolsosUpdate')},
    {icon: 'bi-trash', title: 'Eliminar', action: 'delete', condition: () => verifyAccess('unidadReembolsosDelete')},
]
const filterOptions = {contain: ['descripcion', 'destinatario', 'cargo_destinatario']}

/**
 * Página de administración de unidades de reembolso.
 * @returns {JSX.Element} Retorna el componente UnidadReembolsoIndex.
 */
function UnidadReembolsosIndex() {
    const dispatch = useDispatch()
    const [unidades, setUnidades] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const {filters, handleFilter, handleSort, handlePage, reloadFilters} = useFilters({filterOptions})
    const [selectedUnidad, setSelectedUnidad] = useState(null)
    const [show, setShow] = useState({form: false, info: false, delete: false})
    const tableRef = useRef()
    const formRef = useRef()
    const optionsBodyTemplate = (rowData) => {
        const _options = actions.reduce((carry, option) => {
            if ((typeof option.condition === 'function' && option.condition(rowData)) || typeof option.condition !== 'function') {
                carry.push(
                    <ButtonIcon 
                        variant='text-secondary' rounded
                        icon={option.icon} title={option.title} key={option.title} 
                        onClick={() => handleOpenDialog(option.action, rowData)}
                    />
                )
            }
            return carry
        }, [])
        return <div className='table-options'>{_options}</div>
    }
    const columns = [
        {header: 'Unidad', field: 'descripcion', filter: true, sortable: true},
        {
            header: 'Destinatario', field: 'destinatario', filter: true, sortable: true,
            body: rowData => (rowData.destinatario||'No asignado')
        },
        {
            header: 'Cargo Destinatario', field: 'cargo_destinatario', filter: true, sortable: true,
            body: rowData => (rowData.cargo_destinatario||'No asignado')
        },
        {
            header: 'Opciones', field: 'id', filter: true,
            filterElement: () => <div className='text-center'><Button startIcon='pi pi-filter-slash' onClick={cleanFilters}>Limpiar</Button></div>,
            body: optionsBodyTemplate, style: {width: '8rem'}
        }
    ]

    useEffect(() => {
        const {filter, ..._filters} = filters
        const loadData = async () => {
            const response = await ReembolsoService.unidad.index({filter}, _filters)
            if (response.status === 200) {
                setUnidades(response.data.data)
                setPagination(response.data.pagination)
            }
        }
        loadData()
    }, [filters])

    // Controlador para abrir los modales
    const handleOpenDialog = (action, rowData=null) => {
        if (rowData) setSelectedUnidad(rowData)
        if (action === 'update' || action === 'create') {
            openDialog('form')
        } else if (action === 'delete') {
            openDialog('delete')
        } else if (action === 'view') {
            openDialog('info')
        }
    }
    const openDialog = (name) => setShow({...show, [name]: true})
    const closeInfoDialog = () => setShow({...show, info: false})
    const closeFormDialog = () => setShow({...show, form: false})
    const closeDeleteDialog = () => setShow({...show, delete: false})

    const handleSubmit = () => formRef?.current?.handleSubmit()
    const handleDelete = async () => {
        if (selectedUnidad?.id) {
            const response = await ReembolsoService.unidad.delete(selectedUnidad.id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                reloadFilters()
                closeDeleteDialog()
            }
        }
    }
    const clearSelected = () => setSelectedUnidad(null)
    const handleCreate = async (values) => {
        const response = await ReembolsoService.unidad.create(values)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 201) {
                reloadFilters()
                closeFormDialog()
            }
    }
    const handleUpdate = async (values) => {
        if (selectedUnidad?.id) {
            const response = await ReembolsoService.unidad.update(values, selectedUnidad.id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                reloadFilters()
                closeFormDialog()
            }
        }
    }
    const cleanFilters = () => tableRef?.current?.clean()

    const formFooterDialog = <>
        <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeFormDialog}>Cancelar</Button>
        <Button variant='text-secondary' startIcon='pi pi-save' type='submit' onClick={handleSubmit}>Guardar</Button>
    </>
    const infoFooterDialog = <Button variant='text-secondary' onClick={closeInfoDialog}>Cerrar</Button>
    const options = verifyAccess('unidadReembolsosCreate') && <>
        <Button startIcon='pi pi-plus' onClick={()=>handleOpenDialog('create', null)}>Nuevo</Button>
    </>

    return <>
        <TitlePage title='Lista de Unidades' options={options} />
        <div className='content'>
            <div>
                <DataTable 
                    innerRef={tableRef}
                    values={unidades} 
                    columns={columns} 
                    filterable
                    onFilter={handleFilter}
                    onSort={handleSort}
                    pagination
                    rowsPerPage={pagination.pageSize}
                    totalRecords={pagination.totalRecords}
                    page={pagination.currentPage}
                    onPage={handlePage}
                />
                <Dialog
                    show={show.form}   
                    header={selectedUnidad ? 'Editar Unidad' : 'Registrar Unidad'} 
                    footer={formFooterDialog}
                    keyboard={false}
                    style={{width: '40rem'}}
                    onHide={closeFormDialog}
                    onExited={clearSelected}
                >
                    <FormUnidadReembolso 
                        formRef={formRef} 
                        onSubmit={selectedUnidad?handleUpdate:handleCreate} 
                        selected={selectedUnidad}
                    />
                </Dialog>
                <ConfirmDialog 
                    show={show.delete}
                    onAccept={handleDelete}
                    onReject={closeDeleteDialog}
                    onHide={closeDeleteDialog}
                    onExited={clearSelected}
                >
                    Esta seguro de <span className='text-primary'>ELIMINAR</span> el unidad "{selectedUnidad?.descripcion}"?
                </ConfirmDialog>
                <Dialog
                    show={show.info}   
                    header='Información de la Unidad' 
                    footer={infoFooterDialog}
                    onHide={closeInfoDialog}
                    style={{ width: '35rem' }}
                    onExited={clearSelected}
                >
                    Info
                </Dialog>
            </div>
        </div>
    </>
}
export default UnidadReembolsosIndex