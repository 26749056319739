import { Form } from 'react-bootstrap'

function RadioGroup({options, value, containerClassName='mb-3', ...props}) {
    return (
        <div className={containerClassName}>
            {options.map(option => (
                <Form.Check {...props} {...option} checked={value===option.value} key={option.value} type='radio' />
            ))}
        </div>
    )
}

export default RadioGroup