import { ErrorMessage, Field } from 'formik'
import { InputDateTime } from 'components/InputCalendar'
import InputLayout from 'components/layouts/InputLayout'
import { toPascalCase } from 'utils/utils'
import { classNames } from 'utils/utils'
import { propFromName } from 'utils/utils'

function DateTimeFormik({
    name='', label='',
    containerClassName, 
    containerStyle,
    showError=true, 
    inline=false, 
    info,
    ...props
}) {

    const getInput = ({field, form: {errors, touched}}) => {
        if (propFromName(touched, name) && propFromName(errors, name)) props.isInvalid = true 
        return <InputDateTime {...field} {...props}/>
    }

    return (
        <InputLayout
            label={label ? label : toPascalCase(name)}
            className={classNames(['input-formik', containerClassName])}
            style={containerStyle}
            inline={inline}
            info={info}
        >
            <Field name={name}>{getInput}</Field>
            { name && showError ? <ErrorMessage name={name}>{msg => <div className='d-block invalid-feedback'>{msg}</div>}</ErrorMessage> : null}
        </InputLayout>
    )
}

export default DateTimeFormik