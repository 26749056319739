import Table from 'react-bootstrap/Table'

function ViewUdEjecutora({selectedUdEjecutora}) {
    return (
        <Table striped bordered hover>
            <tbody>
                <tr>
                    <th>Código</th>
                    <td>{selectedUdEjecutora.codigo}</td>
                </tr>
                <tr>
                    <th>Descripción</th>
                    <td>{selectedUdEjecutora.descripcion}</td>
                </tr>
                <tr>
                    <th>Dirección Administrativa</th>
                    <td>{selectedUdEjecutora.codigo_da} {selectedUdEjecutora.da}</td>
                </tr>
            </tbody>
        </Table>
    )
}

export default ViewUdEjecutora