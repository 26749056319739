import Input from 'components/Input'
import { CustomInputFormik } from 'components/formik/formikFormComponents'
import { Form, Formik, useFormikContext } from 'formik'
import Loading from 'pages/Loading'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PapeletaService from 'services/papeleta.service'
import { setHttpMessage } from 'store/messageSlice'
import { formatDate } from 'utils/utils'

/** @module Pages/Papeleta/Components/FormDevolucion */

// Valores iniciales del formulario.
const initialValues = {
    devolucion_terrestre: '', 
    devolucion_aereo: '', 
    devolucion_viaticos: ''
}

/**
 * Componente, Formulario de registro de devoluciones de pasajes y viáticos.
 * @param {object} props Propiedades del componente.
 * @param {React.MutableRefObject<any>} props.formRef Referencia del formulario.
 * @param {Function} props.onSubmit Callback a ejecutar cuando se de submit del formulario.
 * @param {object} props.selected Papeleta seleccionada.
 * @returns {JSX.Element} Retorna el componente FormDevolucion.
 */
function FormDevolucion({formRef, onSubmit, selected}) {
    const dispatch = useDispatch()
    const [papeleta, setPapeleta] = useState(null)
    const [dias, setDias] = useState('')

    useEffect(() => {
        const loadData = async () => {
            const response = await PapeletaService.getMontosPapeletas(selected.id)
            if (response.status === 200) {
                const _papeleta = response.data.data
                formRef?.current?.resetForm({
                    values: {
                        ...initialValues,
                        devolucion_terrestre: (_papeleta.devolucion_terrestre*1)||'', 
                        devolucion_aereo: (_papeleta.devolucion_aereo*1)||'', 
                        devolucion_viaticos: (_papeleta.devolucion_viaticos*1)||''
                    }
                })
                setPapeleta(_papeleta)
            } else {
                setPapeleta(null)
                dispatch(setHttpMessage({status: response.status, title: response.data.message}))}
            }
        if (selected?.id) loadData()
        else setPapeleta(null)
    }, [selected])

    const validate = (values) => {
        const {pasaje_terrestre, pasaje_aereo, subtotal_viaticos} = papeleta
        const _errors = {}
        if (pasaje_terrestre*1 && values.devolucion_terrestre > 1*pasaje_terrestre) _errors.devolucion_terrestre = 'La devolución debe ser menor al pasaje terrestre'
        if (pasaje_aereo*1 && values.devolucion_aereo > 1*pasaje_aereo) _errors.devolucion_aereo = 'La devolución debe ser menor al pasaje aéreo'
        if (subtotal_viaticos*1 && values.devolucion_viaticos > 1*subtotal_viaticos) _errors.devolucion_viaticos = 'La devolución debe ser menor a los viáticos'
        return _errors
    }
    const handleChange = e=>{
        const value = e.target.value 
        if (value.length<3) {setDias(value)}
    }

    return !papeleta ? <Loading /> : (
        <Formik
            innerRef={formRef}
            onSubmit={onSubmit}
            validate={validate}
            initialValues={initialValues}
        >
            {() => (
                <Form>
                    <div className='d-flex flex-column gap-2 mb-3'>
                        <div>Nro. de Papeleta: {papeleta.numero_papeleta}/{formatDate(papeleta?.fecha_registro, 'y')}</div>
                        <div>Beneficiario: {selected.nombre_beneficiario}</div>
                        <div>Lugar de Viaje: {selected.lugar_viaje}</div>
                        <div className='d-flex'>
                            <div className='w-50'>Pas. Terrestre: Bs. {papeleta.pasaje_terrestre}</div>
                            <div className='w-50'>Pas. Aéreo: Bs. {papeleta.pasaje_aereo}</div>
                        </div>
                        <div className='d-flex'>
                            <div className='w-50'>Viáticos: Bs. {papeleta.subtotal_viaticos}</div>
                            <div className='w-50'>Días: {papeleta.dias_viaticos}</div>
                        </div>
                    </div>
                    <div className='d-flex flex-column gap-3'>
                        <CustomInputFormik 
                            name='devolucion_terrestre' type='currency' 
                            label={<div style={{width: '8rem'}}>Pas. Terrestre:</div>} 
                            startIcon='Bs' inline
                            disabled={!(papeleta.pasaje_terrestre*1)}
                        />
                        <CustomInputFormik 
                            name='devolucion_aereo' type='currency' 
                            label={<div style={{width: '8rem'}}>Pas. Aéreo:</div>} 
                            startIcon='Bs' inline
                            disabled={!(papeleta.pasaje_aereo*1)}
                        />
                        <CustomInputFormik 
                            name='devolucion_viaticos' type='currency' 
                            label={<div className='d-flex align-items-center' style={{width: '8rem'}}>
                                <div className='me-2'>Viáticos:</div> 
                                <Input value={dias} onChange={handleChange} placeholder='Días' disabled={!(papeleta.viaticos*1)}/>
                            </div>}
                            startIcon='Bs' inline
                            disabled={!(papeleta.viaticos*1)}
                        />
                    </div>
                    <Observer dias={dias} papeleta={papeleta} />
                </Form>
            )}
        </Formik>
    )
}
export default FormDevolucion

function Observer({ dias, papeleta }) {
    const { setFieldValue } = useFormikContext()

    useEffect(() => {
        if (dias && dias <= papeleta.dias_viaticos) {
            const devolucion = (papeleta.subtotal_viaticos/papeleta.dias_viaticos)*dias
            setFieldValue('devolucion_viaticos', Math.round(devolucion).toFixed(2))
        } else {
            setFieldValue('devolucion_viaticos', '')
        }
    }, [dias])

    return null
}