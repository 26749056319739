/** @module Utils/formatDate */

/**
 * Formatea una fecha según un formato definido.
 * @param {Date|string|number} date Fecha a formatear. Unix en caso de date numérico.
 * @param {string} format Formato (PHP), por defecto 'Y-m-d'.
 * @param {any} defaultValue Valor devuelto si la fecha es no valida, por defecto string vacío.
 * @returns Retorna la fecha con el formato definido, en caso que la fecha sea no valida retorna defaultValue.
 */
export function formatDate(date, format='Y-m-d', defaultValue='') {
    date = parseDate(date)
    if (date !== null) {
        const year = date.getFullYear()
        const month = date.getMonth()
        const day = date.getDate()
        const weekDay = date.getDay()
        let result = ''
        format.split('').forEach(value => {
            let add = ''
            switch (value) {
                case 'Y':
                    add = year
                    break;
                case 'y':
                    add = year.toString().slice(-2)
                    break;
                case 'm':
                    add = `0${month+1}`.slice(-2)
                    break;
                case 'n':
                    add = month+1
                    break;
                case 'M':
                    add = mesesCorto[month]
                    break;
                case 'F':
                    add = meses[month]
                    break;
                case 'd':
                    add = `0${day}`.slice(-2)
                    break;
                case 'j':
                    add = day
                    break;
                case 'w':
                    add = weekDay
                    break;
                case 'N':
                    add = weekDay===0 ? 7 : weekDay
                    break;
                case 'D':
                    add = diasCorto[weekDay]
                    break;
                case 'l':
                    add = dias[weekDay]
                    break;
                case 'H':
                    add = `0${date.getHours()}`.slice(-2)
                    break;
                case 'i':
                    add = `0${date.getMinutes()}`.slice(-2)
                    break;
                case 's':
                    add = `0${date.getSeconds()}`.slice(-2)
                    break;
                default:
                    add = value;
                    break;
            }
            result = `${result}${add}`
        })
        return result
    } else {
        return defaultValue
    }
}

/**
 * Convierte un dato a tipo Date.
 * @ignore
 * @param {Date|string|number} date Dato a convertir a Date. 
 * @param {any} defaultValue Valor por defecto devuelto en caso que el Date sea no valido, por defecto null.
 * @returns Retorna fecha de tipo Date, en caso que la fecha sea no valida retorna defaultValue.
 */
export const parseDate = (date, defaultValue=null) => {
    let result = date
    if (typeof date === 'number') {
        result = new Date(date*1000)
    } else if (typeof date === 'string') {
        result = new Date(date.includes(':') ? date : `${date} 00:00:00`)
    }
    return isValidDate(result) ? result : defaultValue
}

/**
 * Verifica si un dato es de tipo Date valido.
 * @param {any} date
 * @returns true si es un Date valido, false en caso contrario.
 */
export function isValidDate(date) {
    return date instanceof Date && !isNaN(date)
}

/**
 * Obtiene el nombre del mes.
 * @param {number} month Número del mes donde 1 es Enero y 12 es Diciembre.
 * @param {boolean} short Especifica si el nombre del mes sera corto o no.
 * @returns Retorna el nombre del mes.
 */
export function monthName(month, short=false) {
    return (short ? mesesCorto[month+1] : meses[month+1])||''
}
/**
 * Obtiene el nombre del día.
 * @param {number} day Número del día donde 1 es Lunes y 7 es Domingo.
 * @param {boolean} short Especifica si el nombre del día sera corto o no.
 * @returns Retorna el nombre del día.
 */
export function dayName(day, short=false) {
    day = parseInt(day)
    day = day>0 && day<=7 ? (day===7 ? 0 : day) : null
    return (short ? diasCorto[day] : dias[day])||''
}

const meses = [
    'Enero', 'Febrero', 'Marzo', 
    'Abril', 'Mayo', 'Junio', 
    'Julio', 'Agosto', 'Septiembre',
    'Octubre',  'Noviembre',  'Diciembre'
]
const mesesCorto = [
    'Ene', 'Feb', 'Mar', 
    'Abr', 'May', 'Jun', 
    'Jul', 'Ago', 'Sep',
    'Oct',  'Nov',  'Dic'
]
const dias = [
    'Domingo',
    'Lunes', 'Martes', 'Miércoles', 
    'Jueves', 'Viernes', 'Sábado'
]
const diasCorto = [
    'Do',
    'Lu', 'Ma', 'Mi', 
    'Ju', 'Vi', 'Sa'
]
