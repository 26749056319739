import Footer from 'components/Footer'
import NavBar from 'components/NavBar'
import { Outlet } from 'react-router-dom'

export default function MainLayout() {

    return (
        <div id='main-container' className='main-container'>
            <NavBar/>
            <div className='content-page'>
                <Outlet/>
            </div>
            <Footer />
        </div>
    )
}