import { createSlice } from '@reduxjs/toolkit'
import { HTTPStatusCodes } from 'data/HttpStatusCodes'

// global: true if the message is global it show in a toast or false to show in other element
// severities: success, info, warn, error (optional)
// status: status code of http response (optional)
// title: title of message
// detail: detail of message
const initialState = {
    existMessage: false,
    global: false,
    severity: 'info',
    status: 0,
    title: '',
    detail: '',
    sticky: false,
    delay: undefined
}

const messageSlice = createSlice({
	name: 'message',
	initialState,
	reducers: {
        setMessage: (state, {payload}) => {
            if(typeof payload === 'object') {
                state.existMessage = true
                state.global = payload.global || initialState.global
                state.severity = payload.severity || initialState.severity
                state.status = payload.status || initialState.status
                state.detail = payload.detail || initialState.detail
                state.title = payload.title || initialState.title
                state.sticky = payload.sticky || initialState.sticky
                state.delay = payload.delay
            } else if(typeof payload === 'string') {
                state.existMessage = true
                state.detail = payload
            }
        },
        clearMessage: (state) => {
            state.existMessage = false
            state.global = initialState.global
            state.severity = initialState.severity
            state.status = initialState.status
            state.title = initialState.title
            state.detail = initialState.detail
            state.sticky = initialState.sticky
            state.delay = initialState.delay
        }
	},
})

export const setHttpMessage = ({status, title, detail, sticky, delay}) => async (dispatch) => {
    let severity = 'info'
    if(status >= 200 && status <= 299) {
        severity = 'success'
    } else if (status >= 400 && status <= 599) {
        severity = 'error'
    }
    dispatch(setMessage({
        global: true,
        severity,
        status,
        sticky,
        delay,
        title: title ? title : (HTTPStatusCodes[status].title.es ?
            HTTPStatusCodes[status].title.es
            : HTTPStatusCodes[status].title),
        detail: detail ? detail : (HTTPStatusCodes[status].detail ?
            HTTPStatusCodes[status].detail.es
            : '')
    }))
}

export const { setMessage, clearMessage } = messageSlice.actions
export default messageSlice.reducer
