import { useEffect, useState } from 'react'
import { Form as FormFormik, Formik } from 'formik'

import { InputFormik, SelectFormik } from 'components/formik/formikFormComponents'
import ApiService from 'services/api.service'
import { udEjecutoraValidators } from 'utils/validators/validators'

/** @module Pages/UnidadEjecutora/Components/FormUdEjecutora */

/**
 * Componente, Formulario de registro/edición de Unidad Ejecutora.
 * @param {object} props Propiedades del componente.
 * @param {React.MutableRefObject<any>} props.formRef Referencia del formulario.
 * @param {Function} props.onSubmit Callback a ejecutar cuando se de submit del formulario.
 * @param {object} props.selectedUdEjecutora Unidad Ejecutora seleccionada.
 * @returns {JSX.Element} Retorna el componente FormUdEjecutora.
 */
function FormUdEjecutora({formRef, onSubmit, selectedUdEjecutora}) {
    const [dirAdtvas, setDirAdtvas] = useState([])
    const initialValues = selectedUdEjecutora ? selectedUdEjecutora : { 
        codigo: '', 
        descripcion: '', 
        da_id: ''
    }

    useEffect(()=>{
        const loadDirAdtvas = async () => {
            const response = await ApiService.dirAdtvas.getAll()
            if (response.status === 200) setDirAdtvas(response.data.data)
        }
        loadDirAdtvas()
    }, [])

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={udEjecutoraValidators}
            onSubmit={onSubmit}
        >
            {() => (
                <FormFormik>
                    <div>
                        <SelectFormik 
                            name='da_id' 
                            label='Dirección Administrativa (DA)'
                            options={dirAdtvas}
                            optionLabel='descripcion'
                            optionValue='id'
                            itemTemplate={item=>`${item.codigo} ${item.descripcion}`}
                            valueTemplate={value=>`${value.codigo} ${value.descripcion}`}
                        />
                        <InputFormik name='codigo' label='Código' type='number' />
                        <InputFormik name='descripcion' label='Nombre' />
                    </div>
                </FormFormik>
            )}
        </Formik>
    )
}

export default FormUdEjecutora