import { useSelector } from 'react-redux'

import PublicRoutes from './PublicRoutes'
import PrivateRoutes from './PrivateRoutes'
import Loading from 'pages/Loading'

function Router({ isLogged }) {
    const init = useSelector(state => state.auth.init)
    
    return isLogged ? (init ? <PrivateRoutes /> : <Loading/>) : <PublicRoutes />
}

export default Router