import { InputFormik } from 'components/formik/formikFormComponents'
import { Form, Formik } from 'formik'
import { useEffect } from 'react'
import { ReembolsoService } from 'services/reembolso.service'
import { formatDate } from 'utils/utils'

/** @module Pages/Reembolso/Components/FormFondoRotatorio */

const currentDate = formatDate(new Date(), 'm-d')

/**
 * Componente, Formulario de registro de fondo rotatorio.
 * @param {object} props Propiedades del componente.
 * @param {React.MutableRefObject<any>} props.formRef Referencia del formulario.
 * @param {Function} props.onSubmit Callback a ejecutar cuando se de submit del formulario.
 * @returns {JSX.Element} Retorna el componente FormFondoRotatorio.
 */
function FormFondoRotatorio({formRef, onSubmit}) {

    useEffect(() => {
        const loadData = async () => {
            const response = await ReembolsoService.fondoRotatorio.get()
            if (response.status === 200) {
                formRef?.current?.resetForm({
                    values: {monto: response.data}
                })
            }
        }
        loadData()
    }, [])

    return (
        <Formik
            innerRef={formRef}
            initialValues={{monto: ''}}
            onSubmit={onSubmit}
        >{() => (
            <Form>
                <div className='alert alert-primary py-2'>
                    La edición del Fondo Rotatorio solo esta disponible hasta el 31 de Enero.
                </div>
                <InputFormik name='monto' type='currency' disabled={!('02-01'>currentDate)}/>
            </Form>
        )}</Formik>
    )
}
export default FormFondoRotatorio